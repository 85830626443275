import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Badge } from "reactstrap";

export function NotificationMessageModal({ showModal, toggleModal, messageData, OnReadStatusChange }) {
    if (!messageData) {messageData = {subject: "", message: "", severity: "", is_read: false};}
    const [isRead, setIsRead] = useState(messageData.is_read);

    useEffect(() => {
        setIsRead(messageData.is_read);
    }, [messageData.is_read]);

    function handleReadStatusChange() {
        OnReadStatusChange(messageData.id, !isRead);
        setIsRead(!isRead);
    }
    return (
        <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
            <ModalHeader toggle={toggleModal}>
                {messageData.subject}
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    {isRead ? 
                    <Badge color="success" className="me-2"><i className="ri-check-line"></i>Read</Badge>
                    :
                    <Badge color="primary" className="me-2">Unread</Badge>}

                    {messageData.severity === "critical" ?
                    <Badge color="danger" className="me-2">{messageData.severity}</Badge>
                    :
                    messageData.severity === "major" ?
                    <Badge color="warning" className="me-2">{messageData.severity}</Badge>
                    :
                    <></>
                    }

                </div>
                
                <textarea className="form-control" rows="5" readOnly value={messageData.message}></textarea>
            </ModalBody>
            <ModalFooter>
                
                <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => {handleReadStatusChange()}}>
                        <span>Mark as {`${isRead ? "UnRead":"Read"}`}</span>
                    </Button>
                    <Button color="light" onClick={toggleModal}>
                        <span>Close</span>
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}