import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import Map, { Marker } from "react-map-gl";
import { getBulkDevices } from "@/features/devices/thunk";
import 'mapbox-gl/dist/mapbox-gl.css';

export function MapboxGLMapWidget({ widget }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [viewState, setViewState] = useState({
    latitude: 41.5,
    longitude: -81.68,
    zoom: 10,
  });
  const [coordinates, setCoordinates] = useState([]);
  const mapRef = useRef(null);
  const colorMode = useSelector((state) => state.layout.colorMode);

  const parseAttributeValue = (value) => {
    if (typeof value === "object" || typeof value === "boolean") {
      return null;
    }
    const parsedNumber = Number(value);
    return isNaN(parsedNumber) ? null : parsedNumber;
  };

  function checkValidCoordinate(latitude, longitude) {
    return (
      latitude !== null &&
      longitude !== null &&
      latitude >= -90 &&
      latitude <= 90 &&
      longitude >= -180 &&
      longitude <= 180
    );
  }

  useEffect(() => {
    if (widget.dataSourceType === "currentLocation") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCoordinates([{ lat: position.coords.latitude, lng: position.coords.longitude }]);
          },
          (error) => {
            setErrors("Error getting the current location");
          }
        );
      }
    } else if (widget.dataSourceType === "customLocation") {
      const lat = Number(widget.dataSourceConfig.customLocation.latitude);
      const lng = Number(widget.dataSourceConfig.customLocation.longitude);
      setCoordinates([{ lat, lng }]);
    } else if (widget.dataSourceType === "devicesKeys") {
      const deviceIds = widget.dataSourceConfig.devices;
      if (deviceIds.length === 0) {
        setErrors("No data source devices selected");
        return;
      }
      dispatch(getBulkDevices({ deviceIds })).then((actionResult) => {
        if (getBulkDevices.fulfilled.match(actionResult)) {
          const devicesData = actionResult.payload.results;
          const coordinatesData = devicesData.reduce((acc, device) => {
            const latitudeData = parseAttributeValue(device?.latitude);
            const longitudeData = parseAttributeValue(device?.longitude);
            if (checkValidCoordinate(latitudeData, longitudeData)) {
              acc.push({ lat: latitudeData, lng: longitudeData });
            }
            return acc;
          }, []);
          if (coordinatesData.length === 0) {
            setErrors("No valid coordinates found");
          }
          setCoordinates(coordinatesData);
        } else {
          setErrors("Error fetching devices data");
        }
      });
    }
  }, [widget.dataSourceType, widget.dataSourceConfig.customLocation, dispatch, widget.dataSourceConfig.devices]);

  useEffect(() => {
    if (coordinates.length > 0) {
      setViewState(prev => ({
        ...prev,
        latitude: coordinates[0].lat,
        longitude: coordinates[0].lng
      }));
    }
  }, [coordinates]);

  return (
    <Card className="h-100 w-100 border">
      <WidgetHeader widget={widget} />
      <CardBody className="d-flex flex-column justify-content-center align-items-center p-0">
        {errors ? (
          <div>{errors}</div>
        ) : (
          <Map
            ref={mapRef}
            {...viewState}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_GL_MAP_KEY}
            onMove={evt => setViewState(evt.viewState)}
            style={{ width: "100%", height: "100%" }}
            mapStyle={colorMode === "dark" ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/streets-v11"}
          >
            {coordinates.map((coordinate, index) => (
              <Marker key={index} latitude={coordinate.lat} longitude={coordinate.lng} />
            ))}
          </Map>
        )}
      </CardBody>
    </Card>
  );
}
