import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import Select from "react-select";

export const digitalClockInitialValues = ({ widgetData }) => {
  return {
    showDate: widgetData?.configuration?.showDate !== undefined ? widgetData?.configuration?.showDate : true,
    dateFormat: widgetData?.configuration?.dateFormat || "MMM DD, YYYY",
  };
};

export const digitalClockValidationSchema = {
  showDate: Yup.boolean(),
  dateFormat: Yup.string(),
};

export function DigitalClockSetting({ formik }) {
  return (
    <React.Fragment>
      <h4 className="mb-4">Clock Setting</h4>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="showDate" className="form-label fs-15">
              Show Date
            </Label>
          </Col>
          <Col lg={8}>
            <div className="form-check form-switch form-switch-primary form-switch-lg mb-2">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="showDate"
                id="showDate"
                value={formik.values.showDate || ""}
                checked={formik.values.showDate || false}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="dateFormat" className="form-label fs-15">
              Date Format
            </Label>
          </Col>
          <Col lg={8}>
            <Select
              id="dateFormat"
              name="dateFormat"
              value={{
                value: formik.values.dateFormat,
                label: formik.values.dateFormat,
              }}
              options={[
                { label: "YYYY-MM-DD (2023-11-11)", value: "YYYY-MM-DD" },
                { label: "MM-DD-YYYY (11-11-2023)", value: "MM-DD-YYYY" },
                { label: "DD-MM-YYYY (11-11-2023)", value: "DD-MM-YYYY" },
                {
                  label: "MMM DD, YYYY (Nov 11, 2023)",
                  value: "MMM DD, YYYY",
                },
                {
                  label: "DD MMM YYYY (11 Nov 2023)",
                  value: "DD MMM YYYY",
                },
                {
                  label: "MMMM DD, YYYY (November 11, 2023)",
                  value: "MMMM DD, YYYY",
                },
                {
                  label: "DD MMMM YYYY (11 November 2023)",
                  value: "DD MMMM YYYY",
                },
                { label: "YYYY/MM/DD (2023/11/11)", value: "YYYY/MM/DD" },
                { label: "MM/DD/YYYY (11/11/2023)", value: "MM/DD/YYYY" },
                { label: "DD/MM/YYYY (11/11/2023)", value: "DD/MM/YYYY" },
              ]}
              onChange={(e) => {
                formik.setFieldValue("dateFormat", e.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
    </React.Fragment>
  );
}
