import { useState, useEffect } from 'react';

export function useIsSmallScreen(widthThreshold) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < widthThreshold);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < widthThreshold);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [widthThreshold]);

  return isSmallScreen;
}