import React from "react";
import { FormGroup, Input } from "reactstrap";

export const DataSourceFixedMultipleValues = ({ formik }) => {
  // Handle changes to the comma-separated input, converting it to an array
  const handleInputChange = (e) => {
    const { value } = e.target;
    const valuesArray = value.split(',').map(val => val.trim()).filter(val => val);
    formik.setFieldValue("dataSourceConfig.fixedMultipleValues", valuesArray);
  };

  // Convert the array of values back into a comma-separated string for the input display
  const inputValue = formik.values.dataSourceConfig.fixedMultipleValues.join(", ");

  return (
    <FormGroup>
      <Input
        id="dataSourceConfig.fixedMultipleValues"
        name="dataSourceConfig.fixedMultipleValues"
        placeholder="Please enter multiple values separated by comma, e.g. value1, value2, value3"
        value={inputValue}
        onChange={handleInputChange}
        invalid={!!formik.errors.dataSourceConfig?.fixedMultipleValues}
      />
      {formik.errors.dataSourceConfig?.fixedMultipleValues && (
        <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
          {formik.errors.dataSourceConfig.fixedMultipleValues}
        </div>
      )}
    </FormGroup>
  );
};
