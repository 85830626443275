import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '@/api/axiosClient';
import { AUTH_URLS } from '@/api/apiConfig';


export const editProfile = createAsyncThunk(
    'auth/editProfile',
    async (profileData, { rejectWithValue }) => {
        try {
        const response = await apiClient.put(AUTH_URLS.EDIT_PROFILE, profileData);
        return response.data;
        } catch (errorDetails) {
        return rejectWithValue(errorDetails || 'Failed to edit profile');
        }
    }
);

