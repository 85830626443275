import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  FormFeedback,
  FormText,
  Col,
  Row,
} from "reactstrap";
import { createDevice, updateDevice } from "@/features/devices/thunk";
import { generateRandomToken } from "@/common/utils/stringUtils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { extractErrorMessage } from "@/common/utils/stringUtils";
import Swal from "sweetalert2";

const deviceSchema = Yup.object().shape({
  name: Yup.string().required("Device name is required"),
  access_token: Yup.string().required("Access token is required"),
});

export function AddDeviceModal({ showModal, toggleModal, onSuccess, isEditMode = false, deviceData }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [activeInfoTab, setActiveInfoTab] = useState("deviceInfo");
  const [activeCredentialTab, setActiveCredentialTab] = useState("accessTokenTab");

  const userData = useSelector((state) => state.auth.user);
  const initialValues = useMemo(() => {
    return isEditMode
    ? {
        name: deviceData?.name || "",
        label: deviceData?.label || "",
        user: deviceData?.user || "",
        group: deviceData?.group || "",
        is_gateway: deviceData?.is_gateway || false,
        description: deviceData?.description || "",
        access_token: deviceData?.access_token || "",
      }
    : {
        name: "",
        label: "",
        user: "",
        group: "",
        is_gateway: false,
        description: "",
        access_token: generateRandomToken(32),
      };
  }, [deviceData, isEditMode]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: deviceSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const completeAction = (actionPromise) => {
        actionPromise.then((actionResult) => {
          if (actionResult.meta.requestStatus === "fulfilled") {
            toggleModal();
            resetModal();
            Swal.fire({
              title: "Success!",
              text: "Your device has been" + (isEditMode ? "updated" : "created"),
              icon: "success",
            });
            onSuccess();
          } else {
            const apiError = actionResult.payload;
            if (apiError && apiError.data && apiError.data.error_message) {
              const errorMessage = apiError.data.error_message;
              setErrors(extractErrorMessage(errorMessage));
            }
          }
        });
      };
      values.user = userData?.id || "";
      if (isEditMode) {
        completeAction(dispatch(updateDevice({ deviceId: deviceData.id, data: values })));
      } else {
        completeAction(dispatch(createDevice(values)));
      }
    },
  });

  function resetModal() {
    formik.resetForm();
    setErrors(null);
    setActiveInfoTab("deviceInfo");
    setActiveCredentialTab("accessTokenTab");
  }

  const toggleInfoTab = async (tab) => {
    const errors = await formik.validateForm();

    formik.setTouched({
      name: true,
      label: true,
      user: true,
      group: true,
      is_gateway: true,
      description: true,
      access_token: true,
    });

    if (Object.keys(errors).length === 0) {
      if (activeInfoTab !== tab) setActiveInfoTab(tab);
    }
  };

  const toggleCredentialTab = (tab) => {
    if (activeCredentialTab !== tab) setActiveCredentialTab(tab);
  };

  const refreshAccessToken = () => formik.setFieldValue("access_token", generateRandomToken(32));

  return (
    <>
      <Modal isOpen={showModal} toggle={toggleModal} size="lg" modalClassName="zoomIn" centered backdrop="static">
        <ModalHeader toggle={toggleModal} className="p-3">
          {isEditMode ? "Update" : "Create"} Device
        </ModalHeader>
        <Form onSubmit={formik.handleSubmit}>
          <ModalBody style={{ minHeight: "600px", overflowY: "auto" }}>
            <Nav pills className="nav-justified mb-3">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={activeInfoTab === "deviceInfo" ? "active" : ""}
                  onClick={() => {
                    toggleInfoTab("deviceInfo");
                  }}
                >
                  Device Information
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={activeInfoTab === "credentials" ? "active" : ""}
                  onClick={() => {
                    toggleInfoTab("credentials");
                  }}
                >
                  Credentials (Optional)
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeInfoTab} className="text-muted">
              <TabPane tabId="deviceInfo">
                <Row className="mb-4">
                  <Input type="hidden" name="user_id" value={userData?.id || ""} />
                  <Col lg={12}>
                    <FormGroup>
                      <Label for="name">Device Name *</Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Device name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        invalid={formik.touched.name && !!formik.errors.name}
                        required
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="invalid-feedback">{formik.errors.name}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup>
                      <Label for="label">Label</Label>
                      <Input
                        type="text"
                        name="label"
                        id="label"
                        placeholder="Label"
                        onChange={formik.handleChange}
                        value={formik.values.label}
                        invalid={formik.touched.label && !!formik.errors.label}
                      />
                      {formik.touched.label && formik.errors.label ? (
                        <div className="invalid-feedback">{formik.errors.label}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="user">Owner</Label>
                      <Input type="text" name="user" id="user" value={userData?.username || ""} readOnly />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="group">Group</Label>
                      <Input
                        type="text"
                        name="group"
                        id="group"
                        placeholder="Group"
                        onChange={formik.handleChange}
                        value={formik.values.group}
                        invalid={formik.touched.group && !!formik.errors.group}
                      />
                      {formik.touched.group && formik.errors.group ? (
                        <div className="invalid-feedback">{formik.errors.group}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <div className="form-check form-switch form-switch-success form-switch-lg" dir="ltr">
                        <Label for="is_gateway" className="form-check-label">
                          Is Gateway
                        </Label>
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          name="is_gateway"
                          id="is_gateway"
                          onChange={formik.handleChange}
                          checked={formik.values.is_gateway}
                          value={formik.values.is_gateway}
                          invalid={formik.touched.is_gateway && !!formik.errors.is_gateway}
                        />
                        {formik.touched.is_gateway && formik.errors.is_gateway ? (
                          <div className="invalid-feedback">{formik.errors.is_gateway}</div>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup>
                      <Label for="description">Description</Label>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        rows="5"
                        placeholder="Device Description"
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        invalid={formik.touched.description && !!formik.errors.description}
                      />
                      {formik.touched.description && formik.errors.description ? (
                        <div className="invalid-feedback">{formik.errors.description}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={12} className="text-end">
                    <Button
                      color="info"
                      onClick={() => {
                        toggleInfoTab("credentials");
                      }}
                    >
                      Next: Credentials (Optional)
                    </Button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="credentials">
                <p className="text-muted">Credentials type</p>
                <Nav tabs className="nav-tabs-custom nav-success nav-justified mb-3">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeCredentialTab === "accessTokenTab" ? "active" : ""}
                      onClick={() => {
                        toggleCredentialTab("accessTokenTab");
                      }}
                    >
                      Access Token
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeCredentialTab === "MQTT" ? "active" : ""}
                      onClick={() => {
                        toggleCredentialTab("MQTT");
                      }}
                    >
                      MQTT
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeCredentialTab} className="text-muted">
                  <TabPane tabId="accessTokenTab">
                    <h6 className="mt-4">Access Token*</h6>
                    <InputGroup className="mb-3">
                      <Input
                        type="text"
                        name="access_token"
                        id="access_token"
                        value={formik.values.access_token}
                        onChange={formik.handleChange}
                        invalid={formik.touched.access_token && !!formik.errors.access_token}
                      />
                      {formik.touched.access_token && formik.errors.access_token ? (
                        <div className="invalid-feedback">{formik.errors.access_token}</div>
                      ) : null}
                      <Button outline color="secondary" id="refreshTokenButton" onClick={refreshAccessToken}>
                        <i className="ri-refresh-line"></i> Refresh
                      </Button>
                    </InputGroup>
                  </TabPane>
                  <TabPane tabId="MQTT">
                    <h6>MQTT</h6>
                    <p className="mb-0">
                      Currently, we don&apos;t support MQTT credentials. Please use Access Token instead. MQTT
                      credentials will be supported in the future.
                    </p>
                  </TabPane>
                </TabContent>
              </TabPane>
            </TabContent>
            {errors && (
              <div className="alert alert-danger mt-3" role="alert">
                {errors}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              {isEditMode ? "Update" : "Create"} Device
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
