import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import { LoginHistoryPanel } from "@/components/panels/LoginHistoryPanel";
import { SecurityTabContent } from "@/features/auth/accountSettings/components/SecurityTabContent";
import { ChangePasswordTabContent } from "@/features/auth/accountSettings/components/ChangePasswordTabContent";
import { AccountDetailsTabContent } from "@/features/auth/accountSettings/components/AccountDetailsTabContent";
import { NotificationSettingsTabContent } from "@/features/auth/accountSettings/components/NotificationSettingsTabContent";

import avatar1 from "@/assets/images/users/avatar-1.jpg";
import progileBg from "@/assets/images/authentication/settings-cover.jpg";
import { use } from "i18next";

const Settings = () => {
  const [userAvatar, setUserAvatar] = useState(avatar1);
  const [userName, setUserName] = useState("Unknown User");
  const [userSource, setUserSource] = useState("register");
  const [activeTab, setActiveTab] = useState("accountDetails");

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (user) {
      setUserName(user.username);
      setUserAvatar(user.avatar);
      setUserSource(user.source);
    }
  }, [user]);

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  document.title = "Settings | Linko Cloud";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5 border">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto">
                      {/* <img src={userAvatar} className="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-avatar" /> */}
                      <i
                        className="mdi mdi-account-circle d-block text-muted"
                        style={{ fontSize: 96 }}
                      ></i>
                      {/* <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                        />
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div> */}
                    </div>
                    <h5 className="fs-16 mb-1">{userName}</h5>
                    <p className="text-muted mb-0">Administrator</p>
                  </div>
                </CardBody>
              </Card>
              <LoginHistoryPanel contentMaxHeight={300}/>
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5 border">
                <CardHeader>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-primary card-header-tabs rounded border-bottom-0"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "accountDetails",
                        })}
                        onClick={() => {
                          tabChange("accountDetails");
                        }}
                        type="button"
                      >
                        <i className="fas fa-home"></i>
                        Account Details
                      </NavLink>
                    </NavItem>
                    {userSource === "register" && (
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({
                          active: activeTab === "changePassword",
                        })}
                        onClick={() => {
                          tabChange("changePassword");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Change Password
                      </NavLink>
                    </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({
                          active: activeTab === "notificationSettings",
                        })}
                        onClick={() => {
                          tabChange("notificationSettings");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Notification Settings
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({
                          active: activeTab === "security",
                        })}
                        onClick={() => {
                          tabChange("security");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Security
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="accountDetails">
                      <AccountDetailsTabContent />
                    </TabPane>

                    <TabPane tabId="changePassword">
                      <ChangePasswordTabContent />
                    </TabPane>

                    <TabPane tabId="notificationSettings">
                      <NotificationSettingsTabContent />
                    </TabPane>

                    {/* <TabPane tabId="security">
                      <SecurityTabContent />
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
