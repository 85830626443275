import React from "react";
import * as Yup from "yup";

import { DataSourceType } from "./DataSourceType";
import { DataSourceDevice } from "./DataSourceDevice";
import { DataSourceDevices } from "./DataSourceDevices";
import { DataSourceDeviceKey } from "./DataSourceDeviceKey";
import { DataSourceDeviceKeys } from "./DataSourceDeviceKeys";
import { DataSourceDevicesKeys } from "./DataSourceDevicesKeys";
import { DataSourceDeviceSeries } from "./DataSourceDeviceSeries";
import { DataSourceCustomLocation } from "./DataSourceCustomLocation";
import { DataSourceFixedSingleValue } from "./DataSourceFixedSingleValue";
import { DataSourceDeviceSingleSeries } from "./DataSourceDeviceSingleSeries";
import { DataSourceFixedMultipleValues } from "./DataSourceFixedMultipleValues";

const dataSourceTypeConfig = [
  { value: "device", label: "Device", component: DataSourceDevice },
  { value: "devices", label: "Devices", component: DataSourceDevices },
  { value: "deviceKey", label: "Device", component: DataSourceDeviceKey },
  { value: "deviceKeys", label: "Device", component: DataSourceDeviceKeys },
  { value: "devicesKeys", label: "Devices", component: DataSourceDevicesKeys},
  { value: "deviceSeries", label: "Device", component: DataSourceDeviceSeries },
  { value: "deviceSingleSeries", label: "Device Single Series", component: DataSourceDeviceSingleSeries},
  { value: "currentLocation", label: "Current Location", component: null },
  { value: "customLocation", label: "Custom Location", component: DataSourceCustomLocation },
  { value: "fixedSingleValue", label: "Fixed Single Value", component: DataSourceFixedSingleValue },
  { value: "fixedMultipleValues", label: "Fixed Multiple Values", component: DataSourceFixedMultipleValues },
];

export const dataSourceInitialValues = ({ widgetData }) => {
  return {
    dataSourceType: widgetData?.dataSourceType || "",
    dataSourceConfig: {
      device: widgetData?.dataSourceConfig?.device || "",
      devices: widgetData?.dataSourceConfig?.devices || [],
      deviceKey: widgetData?.dataSourceConfig?.deviceKey || "",
      deviceKeys: widgetData?.dataSourceConfig?.deviceKeys || [],
      devicesKeys: widgetData?.dataSourceConfig?.devicesKeys || [],
      deviceSeries: widgetData?.dataSourceConfig?.deviceSeries || [],
      fixedSingleValue: widgetData?.dataSourceConfig?.fixedSingleValue || "",
      fixedMultipleValues: widgetData?.dataSourceConfig?.fixedMultipleValues || [],
      customLocation: widgetData?.dataSourceConfig?.customLocation || { latitude: "", longitude: "" },
    },
  };
};

export const dataSourceValidationSchema = {
  dataSourceType: Yup.string().required("Data Source Type is required"),
  dataSourceConfig: Yup.object().shape({
    device: Yup.string().when("$dataSourceType", ([dataSourceType], schema) => {
      return ["deviceKey", "deviceKeys", "deviceSeries"].includes(dataSourceType)
        ? schema.required("Data Source Device is required")
        : schema.notRequired();
    }),
    devices: Yup.array().when("$dataSourceType", ([dataSourceType], schema) => {
      return dataSourceType === "devicesKeys"
        ? schema.of(Yup.string().required("Device ID is required")).min(1, "At least one device is required").required("Devices field is required")
        : schema.notRequired();
    }),
    deviceKey: Yup.string().when("$dataSourceType", {
      is: "deviceKey",
      then: (schema) => schema.required("Data Key is required"),
    }),
    deviceKeys: Yup.array().when("$dataSourceType",  ([dataSourceType], schema) => {
      return ["deviceKeys", "devicesKeys"].includes(dataSourceType)
      ? schema.of(Yup.string().required("Data Key is required")).min(1, "At least one key is required")
      : schema.notRequired();
    }),
    deviceSeries: Yup.array().when("$dataSourceType", {
      is: "deviceSeries",
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              key: Yup.string().required("Key is required"),
              label: Yup.string().required("Label is required"),
              unit: Yup.string(),
            })
          )
          .min(1, "At least one series is required"),
    }),
    fixedSingleValue: Yup.string().when("$dataSourceType", {
      is: "fixedSingleValue",
      then: (schema) => schema.required("A value is required"),
    }),
    fixedMultipleValues: Yup.array().when("$dataSourceType", {
      is: "fixedMultipleValues",
      then: (schema) => schema.of(Yup.string()),
    }),
    customLocation: Yup.object().when("$dataSourceType", {
      is: "customLocation",
      then: (schema) =>
        schema.shape({
          latitude: Yup.number()
            .required("Latitude is required")
            .min(-90, "Must be between -90 and 90")
            .max(90, "Must be between -90 and 90"),
          longitude: Yup.number()
            .required("Longitude is required")
            .min(-180, "Must be between -180 and 180")
            .max(180, "Must be between -180 and 180"),
        }),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),
};

export function DataSourceSetting({ formik, allowDataSourceType = []}) {
  const SelectedComponent = dataSourceTypeConfig.find(
    (option) => option.value === formik.values.dataSourceType
  )?.component;

  const dataSourceTypeOptions = dataSourceTypeConfig.filter((option) => allowDataSourceType.includes(option.value));

  return (
    <React.Fragment>
      <DataSourceType formik={formik} dataSourceTypeOptions={dataSourceTypeOptions} />
      {SelectedComponent && <SelectedComponent formik={formik} />}
    </React.Fragment>
  );
}
