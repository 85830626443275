import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

import { Container } from "reactstrap";
import SidebarMenu from "@/components/layouts/SidebarMenu";

import logoSm from "@/assets/images/logo/linkocloud/logo.png";
import logoLight from "@/assets/images/logo/linkocloud/logo-text-light.png";


const Sidebar = () => {

  useEffect(() => {
    // Mobile Menu
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark mt-1">
            <span className="logo-sm ms-3">
              <img src={logoSm} alt="" height="40" />
            </span>
            <span className="logo-lg">
              <img src={logoLight} alt="" height="70" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light mt-1">
            <span className="logo-sm ms-3">
              <img src={logoSm} alt="" height="40" />
            </span>
            <span className="logo-lg">
              <img src={logoLight} alt="" height="70" />
            </span>
          </Link>
          <hr className="mt-1"/>
        </div>
        <React.Fragment>
          <SimpleBar id="scrollbar" className="h-100">
            <Container fluid>
              <ul className="navbar-nav mt-2" id="navbar-nav">
                <SidebarMenu />
              </ul>
            </Container>
          </SimpleBar>
          <div className="sidebar-background"></div>
        </React.Fragment>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
