import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "@/api/axiosClient";
import { AUTH_URLS } from "@/api/apiConfig";


export const sendEmailVerification = createAsyncThunk(
    'auth/emailVerification',
    async (email, { rejectWithValue }) => {
      try {
        const response = await apiClient.post(AUTH_URLS.REQUEST_EMAIL_VERIFICATION, { email });
        return response;
      } catch (errorDetails) {
        return rejectWithValue(errorDetails);
      }
    }
);


export const verifyEmail = createAsyncThunk(
    'auth/verifyEmail',
    async (token, { rejectWithValue }) => {
      try {
        const response = await apiClient.post(AUTH_URLS.VERIFY_EMAIL, { token });
        return response;
      } catch (errors) {
        return rejectWithValue(errors);
      }
    }
);