import React from "react";
import { useState } from "react";
import { Handle, Position, NodeToolbar, useOnSelectionChange } from "reactflow";
import { LimitConectionHandle } from "@/features/rulesEngine/components/LimitConectionHandle";

export function MessageInputNode({ id }) {
  const [selected, setSelected] = useState(false);
  const handleStyle = (type) => ({
    width: "14px",
    height: "30px",
    borderRadius: "3px",
    backgroundColor: type == "source" ? "#1982c4" : "#ff595e",
    zIndex: 1,
  });

  useOnSelectionChange({
    onChange: ({ nodes, _ }) => {
      setSelected(nodes.some(node => node.id === id));
    },
  });

  return (
    <React.Fragment>
      <NodeToolbar
        isVisible={selected}
        position={Position.Top}
        align="end"
        offset={-2}
      >
        <a color="success" className="btn btn-warning btn-sm py-1 me-1" href="https://docs.linkocloud.com/" target="_blank" rel="noopener noreferrer">
          <i className="ri-information-line fs-18"></i>
        </a>
      </NodeToolbar>

      <div className="rule-node rule-node-green">
        <span className="rule-node-title">Message Input</span>
        <i className={`ri-arrow-right-line message-input-icon`}></i>
      </div>
      <LimitConectionHandle
        type="target"
        position={Position.Right}
        style={handleStyle("target")}
        id={`target-${id}`}
        isConnectable={1}
      />
    </React.Fragment>
  );
}
