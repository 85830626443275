import React from "react";
import Select from "react-select";
import { FormGroup } from "reactstrap";


export function DataSourceType( { formik, dataSourceTypeOptions }) {
  return (
    <React.Fragment>
      <h4 className="mb-3">Data Source Type *</h4>
      <FormGroup>
        <Select
          value={dataSourceTypeOptions.find((option) => option.value === formik.values.dataSourceType)}
          onChange={(option) => formik.setFieldValue("dataSourceType", option.value)}
          options={dataSourceTypeOptions}
          placeholder="Select a data source type"
          invalid={formik.touched.dataSourceType && Boolean(formik.errors.dataSourceType)}
        />
        {formik.touched.dataSourceType && formik.errors.dataSourceType && (
          <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
            {formik.errors.dataSourceType}
          </div>
        )}
      </FormGroup>
    </React.Fragment>
  );
}
