import React from 'react';
import { Button } from 'reactstrap';

export function SaveToolBar({ onSave, onCancel, disabled=true }) {
  return (
    <div className="rule-save-toolbar">
        <Button size='lg' color="success" className='me-3 save-btn' disabled={disabled} onClick={onSave}><i className="mdi mdi-check"></i></Button>
        <Button size='lg' color="danger"  className='cancel-btn' disabled={disabled} onClick={onCancel}><i className="mdi mdi-close fs-20"></i></Button>
    </div>
  );
}