import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col, Button, Row } from "reactstrap";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { getAuditLogsByDevices } from "@/features/auditLogs/thunk";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useModals } from "@/hooks/useModals";
import { SearchBox } from "@/components/tables/headerToolSet/SearchBox";
import { ShowDetailsModal } from "@/components/modals/ShowDetailsModal";
import { RefreshDataButton } from "@/components/tables/headerToolSet/RefreshDataButton";

export function DeviceAuditLogsTable({ deviceId }) {
  if (!deviceId) return null;

  const dispatch = useDispatch();
  const [auditLogsList, setAuditLogsList] = useState([]);
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const [totalPages, setTotalPages] = useState(0);
  const [totalDataLength, setTotalDataLength] = useState(0);
  const [modals, modalData, toggleModal] = useModals({ showDetailModal: false });

  useEffect(() => {
    dispatch(getAuditLogsByDevices({ deviceIds: [deviceId] })).then((actionResult) => {
      if (getAuditLogsByDevices.fulfilled.match(actionResult)) {
        const results = actionResult.payload;
        setAuditLogsList(results.results);
        setTotalPages(results.total_pages);
        setTotalDataLength(results.total_count);
      }
    });
  }, [deviceId]);

  function refreshData() {
    resetPageAndSearch();
  }

  const columns = useMemo(
    () => [
      {
        header: "Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Action Type",
        accessorKey: "action_type",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return <span className="text-uppercase">{cell.getValue()}</span>;
        },
      },
      {
        header: "User",
        accessorKey: "user",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <span
              className={`badge text-uppercase ${
                cell.getValue() === "success"
                  ? "bg-success-subtle text-success"
                  : cell.getValue() === "fail"
                  ? "bg-danger-subtle text-danger"
                  : "bg-warning-subtle text-warning"
              }`}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Details",
        accessorKey: "details",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <Button
              color="dark"
              outline
              className="btn btn-ghost-dark"
              onClick={() => toggleModal("showDetailModal", { detailData: cell.getValue() })}
            >
              <i className="ri-eye-fill"></i>
            </Button>
          );
        },
      },
    ],
    [auditLogsList]
  );

  return (
    <React.Fragment>
      <Card className="border card-height-100">
        <CardHeader className="d-flex justify-content-between">
          <Col md="2" className="d-flex justify-content-begin">
            <RefreshDataButton onClick={refreshData} />
          </Col>
          <Col md="6">
            <SearchBox
              handleSearch={handleSearch}
              search={search}
              placeholder="Search Notification Sent Record"
              className="flex-grow-1"
            />
          </Col>
        </CardHeader>
        <CardBody>
          <DynamicDataTable
            columns={columns}
            data={auditLogsList}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalDataLength={totalDataLength}
            dataName="audit log"
            style={{ height: "85%" }}
          />
        </CardBody>
      </Card>
      <ShowDetailsModal
        showModal={modals.showDetailModal}
        toggleModal={() => toggleModal("showDetailModal")}
        title="Audit Log Details"
        data={modalData.showDetailModal?.detailData}
      />
    </React.Fragment>
  );
}
