import React from "react";
import ReactDOM from "react-dom/client";
import App from "@/app/App";
import { store } from "@/app/store";
import { Provider } from "react-redux";
import { TimeProvider } from "@/contexts/TimeContext";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <TimeProvider>
      <App />
      <SpeedInsights />
      <Analytics />
    </TimeProvider>
  </Provider>
);
