/**
 * Generates an OAuth URL for various providers.
 * @param {string} provider - The OAuth provider (e.g., 'github', 'google').
 * @param {Object} params - The parameters including clientId, redirectUri, scope, and state.
 * @returns {string} The OAuth URL.
 */
function generateOAuthURL(provider, params) {
    const baseUrls = {
      github: 'https://github.com/login/oauth/authorize',
      google: 'https://accounts.google.com/o/oauth2/v2/auth',
    };
  
    const queryParamsObject = {
        client_id: params.clientId,
        redirect_uri: params.redirectUri,
        scope: params.scope,
        provider: provider,
      };
      
      if (params.response_type) {
        queryParamsObject.response_type = params.response_type;
      }
      
      const queryParams = new URLSearchParams(queryParamsObject).toString();

  
    const baseUrl = baseUrls[provider];
    if (!baseUrl) {
      throw new Error(`Unsupported provider: ${provider}`);
    }
  
    return `${baseUrl}?${queryParams}`;
  }


export function generateGithubOAuthURL() {
    const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_SITE_URL + '/oauth/github/';
    const scope = 'user:email';
  
    return generateOAuthURL('github', { clientId, redirectUri, scope });
}


export function generateGoogleOAuthURL() {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_SITE_URL + '/oauth/google/';
    const scope = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
    const response_type = 'code';

    return generateOAuthURL('google', { clientId, redirectUri, scope, response_type });
}