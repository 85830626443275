import React from "react";
import { Progress } from "reactstrap";

export function ResourcesUsageItem({
  title,
  icon,
  currentValue,
  totalValue,
  Unit,
}) {
  let percentage;
  if (totalValue === 0) {
    percentage = 0;
  }
  percentage = Math.abs((currentValue / totalValue) * 100);
  return (
    <div className="border p-3">
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <div className="avatar-xs">
            <div
              className={`avatar-title bg-secondary-subtle text-secondary fs-18 rounded`}
            >
              <i className={icon}></i>
            </div>
          </div>
        </div>
        <div className="flex-grow-1 ms-3">
          <div className="d-flex justify-content-between">
            <h5 className="mb-2">{title}</h5>
            <h6 className="mb-2">
              {currentValue} / {totalValue}
              {Unit}
            </h6>
          </div>

          <Progress
            className="animated-progress"
            value={percentage}
            color={percentage > 90 ? "danger" : "secondary"}
          >
            {percentage}%
          </Progress>
        </div>
      </div>
    </div>
  );
}
