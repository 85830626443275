import { createAsyncThunk } from "@reduxjs/toolkit";
import { resetNeedsRefresh } from "@/features/notifyHub/slice";
import { apiClient } from "@/api/axiosClient";
import { NOTIFY_HUB_API_URLS } from "@/api/apiConfig";

export const getNotifyRules = createAsyncThunk(
  "notifyHub/getNotifyHubRules",
  async ({ page, search }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (page !== undefined) {
        params.append("page", page);
      }
      if (search !== undefined) {
        params.append("search", search);
      }

      const response = await apiClient.get(
        `${NOTIFY_HUB_API_URLS.GET_RULES}?${params.toString()}`
      );
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const createNotifyRule = createAsyncThunk(
  "notifyHub/createNotifyRule",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        NOTIFY_HUB_API_URLS.CREATE_RULE,
        data
      );
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const updateNotifyRule = createAsyncThunk(
  "notifyHub/updateNotifyHubRule",
  async ({ ruleId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.patch(
        NOTIFY_HUB_API_URLS.UPDATE_RULE(ruleId),
        data
      );
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteNotifyRule = createAsyncThunk(
  "notifyHub/deleteNotifyHubRule",
  async ({ ruleId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        NOTIFY_HUB_API_URLS.DELETE_RULE(ruleId)
      );
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteMultipleNotifyRules = createAsyncThunk(
  "notifyHub/deleteMultipleNotifyHubRules",
  async ({ ids }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        NOTIFY_HUB_API_URLS.BULK_DELETE_RULES,
        { data: { ids } }
      );
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const getNotifyTemplates = createAsyncThunk(
  "notifyHub/getNotifyHubTemplates",
  async ({ page, search, type }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (page !== undefined) {
        params.append("page", page);
      }
      if (search !== undefined) {
        params.append("search", search);
      }
      if (type !== undefined) {
        params.append("type", type);
      }
      const response = await apiClient.get(
        `${NOTIFY_HUB_API_URLS.GET_TEMPLATES}?${params.toString()}`
      );
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const createNotifyTemplate = createAsyncThunk(
  "notifyHub/createNotifyTemplate",
  async (data, { dispatch, rejectWithValue }) => {
    const postData = {
      user: data.user,
      name: data.name,
      description: data.description,
      type: data.type,
      web_enabled: data.webSwitch,
      email_enabled: data.emailSwitch,
      sms_enabled: data.smsSwitch,
      web_subject: data.webSubject,
      web_message: data.webMessage,
      email_subject: data.emailSubject,
      email_message: data.emailMessage,
      sms_message: data.smsMessage,
    };
    try {
      const response = await apiClient.post(
        NOTIFY_HUB_API_URLS.CREATE_TEMPLATE,
        postData
      );
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const updateNotifyTemplate = createAsyncThunk(
  "notifyHub/updateNotifyHubTemplate",
  async ({ templateId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.patch(
        NOTIFY_HUB_API_URLS.UPDATE_TEMPLATE(templateId),
        data
      );
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteNotifyTemplate = createAsyncThunk(
  "notifyHub/deleteNotifyHubTemplate",
  async ({ templateId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        NOTIFY_HUB_API_URLS.DELETE_TEMPLATE(templateId)
      );
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteMultipleNotifyTemplates = createAsyncThunk(
  "notifyHub/deleteMultipleNotifyHubTemplates",
  async ({ ids }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        NOTIFY_HUB_API_URLS.BULK_DELETE_TEMPLATES,
        {data: { ids }}
      );
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);
