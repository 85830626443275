import { apiClient } from '@/api/axiosClient';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_URLS, OAUTH_TOKEN_URLS } from '@/api/apiConfig';
import { setLoggedInInfo, clearLoggedInInfo } from '@/features/auth/authService';


export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password, token }, { rejectWithValue }) => {
    clearLoggedInInfo();
    try {
      const response = await apiClient.post(AUTH_URLS.LOGIN, {
        email,
        password,
        token
      });
      if (response){
        apiClient.defaults.headers.Authorization = `Bearer ${response.access}`;
        setLoggedInInfo(response.user, response.access, response.refresh);
        return response;
      }
    } catch (errorDetails) {
      return rejectWithValue(errorDetails);
    }
  }
);

export const verifyJwtToken = createAsyncThunk(
  'auth/verifyToken',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(AUTH_URLS.VERIFY_TOKEN, {
        token
      });
      if (response){
        return response;
      }
    } catch (errorDetails) {
      return rejectWithValue(errorDetails);
    }
  }
);


export const loginOauth = createAsyncThunk(
  'login/loginOauth',
  async ({ code, provider, redirectUri }, { rejectWithValue }) => {
    clearLoggedInInfo();
    try {
      const response = await apiClient.post(OAUTH_TOKEN_URLS[provider], { 
        code, redirect_uri: redirectUri
      });
      if (response){
        apiClient.defaults.headers.Authorization = `Bearer ${response.access}`;
        setLoggedInInfo(response.user, response.access, response.refresh);
        return response;
      }
    } catch (errorDetails) {
      return rejectWithValue(errorDetails);
    }
  }
);