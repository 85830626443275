import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { useNavigate } from "react-router-dom";
import navdata from "@/components/layouts/LayoutMenuData";

export function NavigationCard({ widget }) {
  const mode = useSelector((state) => state.dashboards.mode);
  const navigate = useNavigate();
  const navigationData = navdata().props.children.find(
    (item) => item.link === widget.configuration.navigateTo
  );
  const navigateIcon = navigationData?.icon || "mdi mdi-devices";
  const navigateLink = navigationData?.link || "/devices";
  const navigateTitle = navigationData?.label || "Devices";

  function navigateTo(e) {
    if (mode === "edit") return;
    e.preventDefault();
    navigate(navigateLink);
  }

  return (
    <Card className="h-100 w-100 border">
      <WidgetHeader widget={widget} />
      <CardBody
        className="d-flex flex-column justify-content-center align-items-center py-0 bg-primary overflow-hidden"
        style={{ cursor: "pointer" }}
        onClick={navigateTo}
      >
        <i
          className={`${navigateIcon} text-white`}
          style={{ fontSize: "60px" }}
        ></i>
        <h4 className="text-white">{navigateTitle}</h4>
      </CardBody>
    </Card>
  );
}
