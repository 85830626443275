import { createSlice } from '@reduxjs/toolkit';
import { getNotificationsData, getMessagesNotificationsData } from '@/features/notifications/thunk';


export const initialState = {
    isLoading: false,
    needsRefresh: false,
    notificationsData: [],
    unreadNotificationsData: [],
    messageNotificationsData: [],
};


const NotificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNeedsRefresh(state) {
            state.needsRefresh = true;
        },
        setUnreadNotificationsData(state, action) {
            state.unreadNotificationsData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNotificationsData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getNotificationsData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.needsRefresh = false;
            state.notificationsData = action.payload;
        });
        builder.addCase(getNotificationsData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getMessagesNotificationsData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getMessagesNotificationsData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.needsRefresh = false;
            state.messageNotificationsData = action.payload;
        });
        builder.addCase(getMessagesNotificationsData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
    }
});

export default NotificationsSlice.reducer;
export const { setNeedsRefresh, setUnreadNotificationsData } = NotificationsSlice.actions;