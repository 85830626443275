import React from "react";
import { useModals } from "@/hooks/useModals";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";

import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, Button, Badge, CardBody } from "reactstrap";

import { BreadCrumb } from "@/components/common/BreadCrumb";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { ShowDetailsModal } from "@/components/modals/ShowDetailsModal";
import { TimeRangeChart } from "@/components/charts/TimeRangeChart";
import { StatisticsPanelSmall } from "@/components/panels/StatisticsPanel";
import { ListLegendDonutChart } from "@/components/charts/ListLegendDonutChart";

import { getAlarms, getAlarmsStatisticsData, getDistributionData } from "@/features/alarms/thunk";


const Alarms = () => {
  const dispatch = useDispatch();
  const [distributionTimeRange, setDistributionTimeRange] = useState("month");
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const [modals, modalData, toggleModal] = useModals({showDetailModal: false});

  const distributionTimeRangeDefine = ["hour", "day", "week", "month"];

  const severityChartColors = {
    critical: "#f06548",
    major: "#ffbe0b",
    minor: "#3577f1",
    warning: "#4b38b3",
    indeterminate: "#299cdb",
  };

  const serverityTextColors = {
    critical: "bg-danger-subtle text-danger",
    major: "bg-warning-subtle text-warning",
    minor: "bg-info-subtle text-info",
    warning: "bg-light-subtle text-body",
    indeterminate: "bg-light-subtle text-body",
    default: "bg-light-subtle text-body",
  };

  useEffect(() => {
    dispatch(getAlarms({ page, search }));
  }, [page, search]);

  useEffect(() => {
    dispatch(getAlarmsStatisticsData());
  }, []);

  useEffect(() => {
    dispatch(getDistributionData(distributionTimeRange));
  }, [distributionTimeRange]);

  const statisticsData = useSelector((state) => state.alarms.statisticsData);
  const { todayAlarms, pendingAlarms, activeAlarms, severityData } = useMemo(
    () => ({
      todayAlarms: statisticsData?.todays_alarms || 0,
      pendingAlarms: statisticsData?.pending_alarms || 0,
      activeAlarms: statisticsData?.active_alarms || 0,
      severityData: statisticsData?.severity || [],
    }),
    [statisticsData]
  );

  const distributionData = useSelector((state) => state.alarms.distributionData);
  const { distributionDataList } = useMemo(
    () => ({
      distributionDataList: distributionData || [],
    }),
    [distributionData]
  );

  const alarms = useSelector((state) => state.alarms.alarms);
  const { alarmsList, totalPages, totalDataLength } = useMemo(
    () => ({
      alarmsList: alarms?.results || [],
      totalPages: alarms?.total_pages || 0,
      totalDataLength: alarms?.total_count || 0,
    }),
    [alarms]
  );

  function handleDistributionTimeRangeChange(timeRange) {
    setDistributionTimeRange(timeRange);
  }

  function refreshData() {
    resetPageAndSearch();
    dispatch(getAlarms({ page, search }));
  }

  const columns = useMemo(
    () => [
      {
        header: "Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return ["active", "unacknowledged"].includes(cell.getValue()) ? (
            <Badge color={cell.getValue() === "active" ? "danger" : "info"} className="text-uppercase">
              {cell.getValue()}
            </Badge>
          ) : (
            <span className="text-uppercase">{cell.getValue()}</span>
          );
        },
      },
      {
        header: "Device",
        accessorKey: "device_name",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <Link
              to={`/devices/${cell.row.original.device}`}
              className="link-body-emphasis link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-75-hover"
            >
              {cell.getValue()}
            </Link>
          );
        },
      },
      {
        header: "Severity",
        accessorKey: "severity",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <span
              className={`badge round-pill text-uppercase ${
                serverityTextColors[cell.getValue()] || serverityTextColors.default
              }`}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Assigned to",
        accessorKey: "assigned_to",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Details",
        accessorKey: "details",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <Button color="dark" outline className="btn btn-ghost-dark" onClick={() => toggleModal("showDetailModal", {detailData: cell.getValue()})}>
              <i className="ri-eye-fill"></i>
            </Button>
          );
        },
      },
    ],
    [alarmsList]
  );

  document.title = "Alarms | Linko Cloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Alarms" pageTitle="Management" />
          <Row className="d-flex">
            <Col xxl={3}>
              <ListLegendDonutChart
                title="Alarm Severity Breakdown"
                data={severityData}
                dataName="alarm data"
                chartHeight={224}
                colors={severityChartColors}
                minHeight={510}
              />
            </Col>
            <Col xxl={9}>
              <Row>
                <Col lg={4} md={6}>
                  <StatisticsPanelSmall
                    title="Active Alarms"
                    value={activeAlarms}
                    icon="ri-error-warning-fill"
                    iconColor="warning"
                  />
                </Col>
                <Col lg={4} md={6}>
                  <StatisticsPanelSmall
                    title="Pending Alarms"
                    value={pendingAlarms}
                    icon="ri-hourglass-fill"
                    iconColor="primary"
                  />
                </Col>
                <Col lg={4} md={6}>
                  <StatisticsPanelSmall
                    title="Today's Alarms"
                    value={todayAlarms}
                    icon="ri-calendar-event-fill"
                    iconColor="info"
                  />
                </Col>
              </Row>
              <div>
                <TimeRangeChart
                  title="Alarms Distribution"
                  type="bar"
                  data={distributionDataList}
                  dataName="alarm data"
                  timeRange={distributionTimeRange}
                  timeRangeTypes={distributionTimeRangeDefine}
                  onTimeRangeChange={handleDistributionTimeRangeChange}
                  minHeight={405}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="border">
                <CardHeader className="d-flex justify-content-between">
                  <Col md="2" className="d-flex justify-content-begin">
                    <Button className="btn-ghost-dark shadow-none me-2 border fs-5" onClick={refreshData}>
                      <i className="ri-refresh-line"></i>
                    </Button>
                  </Col>
                  <Col md="6">
                    <div className="search-box ms-2">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Alarms"
                        onChange={handleSearch}
                        value={search}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                </CardHeader>
                <CardBody>
                  <DynamicDataTable
                    columns={columns}
                    data={alarmsList}
                    currentPage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    totalDataLength={totalDataLength}
                    dataName="alarm"
                    onRowClick={(row) => {
                      if (row) {
                        toggleModal("showDetailModal", {detailData: row.details});
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ShowDetailsModal
        showModal={modals.showDetailModal}
        toggleModal={() => toggleModal("showDetailModal")}
        title="Alarms Details"
        data={modalData.showDetailModal?.detailData}
      />
    </React.Fragment>
  );
};

export default Alarms;
