import { AUDIT_LOG_API_URLS } from "@/api/apiConfig";
import { apiClient } from "@/api/axiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getAuditLogs = createAsyncThunk(
    "auditLogs/getAuditLogs",
    async ({ page, search }, { rejectWithValue }) => {
        try {
        const params = new URLSearchParams({ page, search });
        const response = await apiClient.get(`${AUDIT_LOG_API_URLS.GET_AUDIT_LOGS}?${params.toString()}`);
        if (response) {
            return response;
        }
        } catch (errors) {
        return rejectWithValue(errors);
        }
    }
);


export const getLoginLogs = createAsyncThunk(
    "auditLogs/getLoginLogs",
    async ({ timeRange }, { rejectWithValue }) => {
        try {
        const params = new URLSearchParams({ time_range: timeRange });
        const response = await apiClient.get(`${AUDIT_LOG_API_URLS.GET_LOGIN_LOGS}?${params.toString()}`);
        if (response) {
            return response;
        }
        } catch (errors) {
        return rejectWithValue(errors);
        }
    }
);


export const getStatisticsData = createAsyncThunk(
    "auditLogs/getStatisticsData",
    async (_, { rejectWithValue }) => {
        try {
        const response = await apiClient.get(AUDIT_LOG_API_URLS.GET_STATISTICS_DATA);
        if (response) {
            return response;
        }
        } catch (errors) {
        return rejectWithValue(errors);
        }
    }
);

export const getAuditLogsByDevices = createAsyncThunk(
    "auditLogs/getAuditLogsByDevices",
    async ({ deviceIds, page, pageSize }, { rejectWithValue }) => {
        try {
        const response = await apiClient.post(AUDIT_LOG_API_URLS.GET_AUDIT_LOGS_BY_DEVICE, { device_ids: deviceIds, page, page_size: pageSize});
        if (response) {
            return response;
        }
        } catch (errors) {
        return rejectWithValue(errors);
        }
    }
);