import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export function WebNotifyTab({ formik }) {
  return (
    <React.Fragment>
      <FormGroup>
        <Label for="webSubject">Web Notify Subject *</Label>
        <Input
          type="text"
          name="webSubject"
          id="webSubject"
          placeholder="Subject"
          onChange={formik.handleChange}
          value={formik.values.webSubject}
          invalid={formik.touched.webSubject && !!formik.errors.webSubject}
        />
        {formik.touched.webSubject && formik.errors.webSubject ? (
          <div className="invalid-feedback">{formik.errors.webSubject}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="webMessage">Web Notify Message</Label>
        <p className="text-muted">
          Messages support the use of variables for personalization. Please
          see the{" "}
          <a
            className="text-secondary text-decoration-underline"
            href="https://docs.linkocloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>{" "}
          for detailed guidance on templating.
        </p>

        <Input
          type="textarea"
          name="webMessage"
          id="webMessage"
          rows="5"
          placeholder="Message"
          onChange={formik.handleChange}
          value={formik.values.webMessage}
          invalid={formik.touched.webMessage && !!formik.errors.webMessage}
        />
        {formik.touched.webMessage && formik.errors.webMessage ? (
          <div className="invalid-feedback">{formik.errors.webMessage}</div>
        ) : null}
      </FormGroup>
    </React.Fragment>
  );
}
