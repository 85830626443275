import React from "react";
import { useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { FormFeedback } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import AuthenticationLayout from "@/components/layouts/AuthenticationLayout";
import { resetPassword } from "@/features/auth/resetPassword/thunk";
import { extractErrorMessage } from "@/common/utils/stringUtils";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Must contain at least one uppercase letter")
    .matches(/\d/, "Must contain at least one number"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .required("Please confirm your password"),
});

const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const token = new URLSearchParams(location.search).get("token");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const handlePasswordFocus = () => setPasswordFocus(true);
  const handlePasswordBlur = () => setPasswordFocus(false);
  const validation = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.token = token;
      console.log("values", values);
      dispatch(resetPassword(values)).then((actionResult) => {
        console.log("actionResult", actionResult);
        if (resetPassword.fulfilled.match(actionResult)) {
          navigate("/login");
        } else {
          const apiError = actionResult.payload;
          if (apiError && apiError.data && apiError.data.error_message) {
            const errorMessage = apiError.data.error_message;
            setErrors(extractErrorMessage(errorMessage));
          }
        }
      });
    },
  });

  return (
    <AuthenticationLayout
      title="Oops! Forgot your password?"
      subTitle="No worries, happens to the best of us."
      withMask={false}
    >
      <Row className="g-0">
        <Col className="mx-auto" xs="11" md="8" lg="7" xl="6">
          <h3 className="text-white mb-4">Reset password</h3>
          <Form className="form-dark needs-validation" noValidate onSubmit={validation.handleSubmit}>
            <FormGroup className="mb-3">
              <Label for="password" className="text-white">
                Password
              </Label>
              <Input
                id="password"
                type="password"
                name="password"
                placeholder="Enter Password"
                onPaste={(e) => {
                  e.preventDefault();
                }}
                onChange={validation.handleChange}
                onBlur={handlePasswordBlur}
                onFocus={handlePasswordFocus}
                value={validation.values.password || ""}
                invalid={validation.touched.password && !!validation.errors.password}
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.password}</div>
                </FormFeedback>
              ) : null}
            </FormGroup>
            <div
              className="mb-2 rounded"
              id="password-validation"
              style={{ display: passwordFocus ? "block" : "none" }}
            >
              <h6 className="text-white text-2">Password must contain:</h6>
              <p className={validation.values.password.length >= 8 ? "valid" : "invalid"}>Minimum 8 characters</p>
              <p className={/[a-z]/.test(validation.values.password) ? "valid" : "invalid"}>
                At least one lowercase letter
              </p>
              <p className={/[A-Z]/.test(validation.values.password) ? "valid" : "invalid"}>
                At least one uppercase letter
              </p>
              <p className={/\d/.test(validation.values.password) ? "valid" : "invalid"}>At least one number</p>
            </div>
            <FormGroup className="mb-3">
              <Label for="confirmPassword" className="text-white">
                Confirm Password
              </Label>
              <Input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                onPaste={(e) => {
                  e.preventDefault();
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.confirmPassword || ""}
                invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
              />
              {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.confirmPassword}</div>
                </FormFeedback>
              ) : null}
            </FormGroup>
            {errors && (
              <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                  viewBox="0 0 16 16"
                  role="img"
                  aria-label="Warning:"
                >
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
                {errors}
              </div>
            )}
            <Button color="primary" className="shadow-none my-2" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default ResetPasswordPage;
