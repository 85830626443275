const changeHTMLAttribute = (attribute, value) => {
    if (document.documentElement) document.documentElement.setAttribute(attribute, value);
    return true;
}
export { changeHTMLAttribute };


export function initLayoutSettings () {
    changeHTMLAttribute("data-topbar", "dark");
    changeHTMLAttribute("data-sidebar", "dark");
    changeHTMLAttribute("data-layout", "vertical");
    changeHTMLAttribute("data-sidebar-size", "lg");
    changeHTMLAttribute("data-layout-width", "fluid");
    changeHTMLAttribute("data-layout-style", "default");
    changeHTMLAttribute("data-layout-position", "fixed");
    changeHTMLAttribute("data-sidebar-visibility", "show");
}