import React from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { NoResultTip } from "@/components/tables/NoResultTip";
import Chart from "react-apexcharts";


export function ListLegendDonutChart({ title, data=[], colors, dataName, chartHeight, minHeight=300}) {
  /**
 * Renders a donut chart for data distribution using Chart.
 *
 * Props:
 * - title: Chart title.
 * - colors: Object mapping data types to chart colors.
 * - dataName: Descriptive name for the data, used when no data is available.
 * - chartHeight: Chart height, can be a number or a percentage string.
 *
 * Expected `data` format:
 * [
 *   { "type": "categoryName", "value": numericValue },
 *   ...
 * ]
 *
 * `data` consists of objects with 'type' (string) and 'value' (number) to represent categories and their values.
 */

  var seriesData = data.map(item => item.value);
  var seriesLabels = data.map(item => item.type.replace(/\b\w/g, char => char.toUpperCase()));
  var seriesColors = data.map(item => colors[item.type]);
  const colorMode = useSelector((state) => state.layout.colorMode);

  var options = {
    labels: seriesLabels,
    chart: {
      type: "donut",
      height: chartHeight,
      background: colorMode === "dark" ? "#212529" : "#fff",
    },
    plotOptions: {
      background: colorMode === "dark" ? "#212529" : "#fff",
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: "20px",
              color: "#343a40",
              fontWeight: 500,
              offsetY: 5,
            },
            total: {
              show: true,
              fontSize: "13px",
              label: "Total Alarms",
              color: "#9599ad",
              fontWeight: 500,
              formatter: function (w) {
                return (
                  w.globals.seriesTotals.reduce(function (a, b) {
                    return a + b;
                  }, 0)
                );
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      lineCap: "round",
      width: 2,
    },
    colors: seriesColors,
  };

  return (
    <Card style={{minHeight: minHeight}} className="border">
      <CardHeader className=" border-0 align-items-center d-flex">
        <CardTitle tag="h4">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        {data && data.length > 0 && 
        <Chart 
          dir="ltr"
          options={options}
          type="donut"
          series={seriesData}
          height={chartHeight}
          className="apex-charts"
        />}
        {data && data.length === 0 && <NoResultTip dataName={dataName} className="mt-10"/>}

        <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
          {data.map((item, index) => {
            return (
              <li className="list-group-item px-0" key={index}>
                <div className="d-flex">
                    <div className="flex-grow-1 ms-2 d-flex align-items-center">
                        <h6 className="mb-0">{item.type}</h6>
                    </div>
                    <div className="flex-shrink-0 text-end d-flex align-items-center">
                        <h6 className="mb-0">{item.value}</h6>
                    </div>
                </div>
            </li>
            );
          })}
        </ul>
      </CardBody>
    </Card>
  );
}