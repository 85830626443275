import { createSlice } from '@reduxjs/toolkit';
import { registerUser } from '@/features/auth/register/thunk';

const initialState = {
    error: null,
    success: false,
    isLoading: false,
};

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        resetRegistrationStatus(state) {
            state.success =  false;
            state.error = null;
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(registerUser.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(registerUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = true;
        })
        .addCase(registerUser.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload || 'Failed to register';
        });
    },
});

export default registerSlice.reducer;
export const { resetRegistrationStatus } = registerSlice.actions;