import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { getFormattedDate } from "@/common/utils/dateFormatOptions";
import { TimeContext } from "@/contexts/TimeContext";

export function DigitalClock({ widget }) {
  const currentTime = useContext(TimeContext);

  const formatTime = new Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false 
  }).format(currentTime);

  const formatDate  = getFormattedDate(currentTime, widget.configuration.dateFormat);

  return (
    <Card className="h-100 w-100 border">
      <WidgetHeader widget={widget} />
      <CardBody className="d-flex flex-column justify-content-center align-items-center">
        <div style={{ fontSize: "80px", textAlign: "center" }}>
          {formatTime}
        </div>
        {widget?.configuration?.showDate && (
          <div style={{ fontSize: "50px", textAlign: "center" }}>
            {formatDate}
          </div>
        )}
      </CardBody>
    </Card>
  );
}
