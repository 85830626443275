import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

export const chartSettingInitialValues = ({ widgetData }) => {
  return {
    chartLegend: widgetData?.configuration?.chartLegend !== undefined ? widgetData?.configuration?.chartLegend : true,
    chartGrid: widgetData?.configuration?.chartGrid !== undefined ? widgetData?.configuration?.chartGrid : true,
    chartToolbar:
      widgetData?.configuration?.chartToolbar !== undefined ? widgetData?.configuration?.chartToolbar : true,
  };
};

export const chartSettingValidationSchema = {
  chartLegend: Yup.boolean(),
  chartGrid: Yup.boolean(),
  chartToolbar: Yup.boolean(),
};

export function ChartSetting({ formik }) {
  return (
    <React.Fragment>
      <h4 className="mb-4 mt-5">Chart Setting</h4>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="chartGrid" className="form-label fs-15">
              Grid
            </Label>
          </Col>
          <Col lg={8}>
            <div className="form-check form-switch form-switch-primary form-switch-lg mb-2">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="chartGrid"
                id="chartGrid"
                value={formik.values.chartGrid || ""}
                checked={formik.values.chartGrid || false}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="chartLegend" className="form-label fs-15">
              Legend
            </Label>
          </Col>
          <Col lg={8}>
            <div className="form-check form-switch form-switch-primary form-switch-lg mb-2">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="chartLegend"
                id="chartLegend"
                value={formik.values.chartLegend || ""}
                checked={formik.values.chartLegend || false}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="chartToolbar" className="form-label fs-15">
              Show Toolkit
            </Label>
          </Col>
          <Col lg={8}>
            <div className="form-check form-switch form-switch-primary form-switch-lg mb-2">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="chartToolbar"
                id="chartToolbar"
                value={formik.values.chartToolbar || ""}
                checked={formik.values.chartToolbar || false}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
    </React.Fragment>
  );
}
