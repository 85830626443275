import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "@/api/axiosClient";
import { AUTH_URLS } from "@/api/apiConfig";


export const requestResetPassword = createAsyncThunk(
    'auth/requestResetPassword',
    async (email, { rejectWithValue }) => {
      try {
        const response = await apiClient.post(AUTH_URLS.REQUEST_RESET_PASSWORD, { email });
        return response.data;
      } catch (errorDetails) {
        return rejectWithValue(errorDetails);
      }
    }
);


export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ token, password, confirmPassword }, { rejectWithValue }) => {
      try {
        const response = await apiClient.post(AUTH_URLS.RESET_PASSWORD, {
          token,
          password,
          confirm_password: confirmPassword
        });
        return response.data;
      } catch (errorDetails) {
        return rejectWithValue(errorDetails);
      }
    }
);

