import React from "react";
import { Card, CardBody, Progress, Button } from "reactstrap";
import { useModals } from "@/hooks/useModals";
import { UpdatePlanModal } from "@/components/modals/UpdatePlanModal";

export function EntityUsagePanel({
  title,
  currentValue,
  totalValue,
  dataType,
  isAnnimated = false,
}) {
  if (totalValue === 0) {
    new Error("Total value cannot be zero");
  }
  const percentage = Math.abs((currentValue / totalValue) * 100);

  const [modals, modalData, toggleModal] = useModals({
    updatePlanModal: false,
  });

  return (
    <React.Fragment>
      <Card className={`${isAnnimated ? "card-animate" : ""} border`}>
        <CardBody>
          <h5 className="card-title fw-semibold mb-3">{title}</h5>
            <Progress
              className="animated-progress"
              value={percentage}
              color={percentage > 90 ? "danger" : "secondary"}
            />
          <p className={`text-muted mb-2`}>
            You used{" "}
            <span className={`text-${percentage > 90 ? "danger" : "muted"}`}>
              {currentValue} of {totalValue}
            </span>{" "}
            of your {dataType}
          </p>
          <div className="text-end">
            <Button
              color="secondary"
              type="button"
              className="btn  btn-sm"
              onClick={() => {
                toggleModal("updatePlanModal");
              }}
            >
              View Info
            </Button>
          </div>
        </CardBody>
      </Card>
      <UpdatePlanModal
        showModal={modals.updatePlanModal}
        toggleModal={() => {
          toggleModal("updatePlanModal");
        }}
      />
    </React.Fragment>
  );
}
