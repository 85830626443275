import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, CardBody, Card } from "reactstrap";

import { BreadCrumb } from "@/components/common/BreadCrumb";
import { DeviceStatus } from "@/features/devices/components/DeviceStatus";
import { DeviceLocationMap } from "@/features/devices/components/DeviceLocationMap";
import { DeviceAttributesTable } from "@/features/devices/components/DeviceAttributesTable";
import { DeviceLastestTelemetryTable } from "@/features/devices/components/DeviceLastestTelemetryTable";
import { DeviceDataTransmissionChart } from "@/features/devices/components/DeviceDataTransmissionChart";
import { DeviceAlarmsTable } from "@/features/devices/components/DeviceAlarmsTable";
import { DeviceAuditLogsTable } from "@/features/devices/components/DeviceAuditLogsTable";

import { getDeviceDetailData } from "@/features/devices/thunk";

const DeviceDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const deviceId = params.deviceId;

  useEffect(() => {
    dispatch(getDeviceDetailData({ deviceId }));
  }, [deviceId]);

  const deviceData = useSelector((state) => state.devices.deviceDetailData);

  document.title = `${deviceData.name} | Linko Cloud`;

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={deviceData.name} pageTitle="Device Detail" />
          <DeviceStatus />
          <Row>
            <Col xl={8}>
              <DeviceDataTransmissionChart deviceId={deviceId} />
            </Col>
            <Col xl={4}>
              <DeviceLocationMap />
            </Col>
          </Row>
          <DeviceAttributesTable />
          <Card className="border card-height-100">
            <CardBody>
              <Row>
                <Col lg={2} className="border">
                  <Nav pills className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`mb-2 ${activeTab === "1" ? "active" : ""}`}
                        onClick={() => toggleTab("1")}
                      >
                        <i className="ri-bar-chart-fill d-block fs-20 mb-1"></i>
                        Latest Telemetry
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`mb-2 ${activeTab === "2" ? "active" : ""}`}
                        onClick={() => toggleTab("2")}
                      >
                        <i className="ri-alarm-warning-line d-block fs-20 mb-1"></i>
                        Alarms
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`mb-2 ${activeTab === "3" ? "active" : ""}`}
                        onClick={() => toggleTab("3")}
                      >
                        <i className="ri-file-list-3-line d-block fs-20 mb-1"></i>
                        Audit Logs
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col lg={10}>
                  <TabContent activeTab={activeTab} className="text-muted mt-3 mt-lg-0" style={{minHeight: "600px"}}>
                    <TabPane tabId="1">
                      <DeviceLastestTelemetryTable deviceId={deviceId} />
                    </TabPane>
                    <TabPane tabId="2">
                      <DeviceAlarmsTable deviceId={deviceId} />
                    </TabPane>
                    <TabPane tabId="3">
                      <DeviceAuditLogsTable deviceId={deviceId} />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DeviceDetail;
