import React from "react";

import styles from "./DocumentListItem.module.scss";

export function DocumentListItem({ title, color, icon, documentURL }) {
  return (
    <a className="card-ratio border card-animate" href={documentURL} target="_blank" rel="noreferrer">
      <div className={styles.documentItem}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <div className="avatar-xs">
              <div className={`avatar-title bg-${color}-subtle text-${color} fs-18 rounded`}>
                <i className={icon}></i>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 ms-3">
            <h5 className="mb-1">{title}</h5>
          </div>
        </div>
      </div>
    </a>
  );
}
