import React from "react";
import { DataSourceDevices } from "./DataSourceDevices";
import { DataSourceKeys } from "./DataSourceKeys";

export function DataSourceDevicesKeys({ formik }) {
  return (
    <React.Fragment>
      <DataSourceDevices formik={formik} />
      <DataSourceKeys formik={formik} multiDevices={true} />
    </React.Fragment>
  );
}
