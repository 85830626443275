import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyEmail } from "@/features/auth/emailVerification/thunk";
import { extractErrorMessage } from "@/common/utils/stringUtils";

import AuthenticationLayout from "@/components/layouts/AuthenticationLayout";

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  const [countdown, setCountdown] = useState(5);

  const token = new URLSearchParams(location.search).get("token");
  useEffect(() => {
    dispatch(verifyEmail(token)).then((actionResult) => {
      if (verifyEmail.fulfilled.match(actionResult)) {
        setSuccess(true);
      } else {
        const apiError = actionResult.payload;
        if (apiError && apiError.data && apiError.data.error_message) {
          const errorMessage = apiError.data.error_message;
          setErrors(extractErrorMessage(errorMessage));
        }
      }
    });
  }, [token]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((currentCountdown) => {
        if (currentCountdown <= 1) {
          clearInterval(timer);
          navigate("/overview");
          return 0;
        }
        return currentCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <AuthenticationLayout subTitle="We care about your account security." withMask={false}>
      <Row className="g-0">
        <Col className="mx-auto" xs="11" md="8" lg="7" xl="6">
          <h3 className="text-white mb-4">{success ? "Email verified" : "Email verification failed"}</h3>
          <lord-icon
            src="https://cdn.lordicon.com/utpmnzxz.json"
            trigger="loop"
            delay="500"
            style={{ width: "180px", height: "180px" }}
            colors="primary:#4b38b3"
          ></lord-icon>
          {success && (
            <>
              <p className="text-white mb-4 mt-3">Your email has been verified successfully.</p>
              <p className="text-white-50 mb-4">
                You will be redirected in <span className="countdown-text">{countdown}</span> seconds. If not, please
                click <Link to="/overview">Continue</Link>.
              </p>
            </>
          )}
          {errors && (
            <div className="alert alert-danger mt-3" role="alert">
              {errors}
            </div>
          )}
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default EmailVerification;
