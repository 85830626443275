import React from "react";
import { DataSourceDevice } from "./DataSourceDevice";
import { DataSourceKeys } from "./DataSourceKeys";

export function DataSourceDeviceKeys({ formik }) {

  return (
    <React.Fragment>
      <DataSourceDevice formik={formik} />
      <DataSourceKeys formik={formik} multiDevices={false} />
    </React.Fragment>
  );
}
