export const setUserProfile = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));
};

export const getUserProfile = () => {
    const user = localStorage.getItem("authUser");
    return user ? JSON.parse(user) : null;
}


export const getAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");
    return accessToken && accessToken !== "undefined" ? JSON.parse(accessToken) : null;
};

export const getRefreshToken = () => {
    const refreshToken = localStorage.getItem("refreshToken");
    return refreshToken && refreshToken !== "undefined" ? JSON.parse(refreshToken) : null;
}


export const setAccessToken = (token) => {
    localStorage.setItem("accessToken", JSON.stringify(token));
}


export const setRefreshToken = (token) => {
    localStorage.setItem("refreshToken", JSON.stringify(token));
}

export const setLoggedInInfo = (userProfile, accessToken, refreshToken) => {
    setUserProfile(userProfile);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
};

export const clearLoggedInInfo = () => {
    setUserProfile(null);
    setAccessToken(null);
    setRefreshToken(null);
};


export const isLoggedIn = () => {
    return !!getAccessToken();
};

