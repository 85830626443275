import React, { useEffect, useState } from 'react';

const FadeOutMasks = ({ onFadeComplete }) => {
  const [style, setStyle] = useState({
    opacity: 1,
    transition: 'opacity 1s ease-out',
    display: 'block',
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 999999999,
    backgroundColor: '#000',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });

  useEffect(() => {
    setStyle((prevStyle) => ({ ...prevStyle, opacity: 0 }));

    const handleTransitionEnd = () => {
      setStyle((prevStyle) => ({ ...prevStyle, display: 'none' }));
      onFadeComplete && onFadeComplete();
    };

    const timer = setTimeout(handleTransitionEnd, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [onFadeComplete]);

  return <div style={style}></div>;
};

export default FadeOutMasks;
