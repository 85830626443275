import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { createRule, updateRule } from "@/features/rulesEngine/thunk";
import { extractErrorMessage } from "@/common/utils/stringUtils";

const ruleChainSchema = Yup.object().shape({
  title: Yup.string().required("Rule name is required"),
  description: Yup.string(),
});

export function AddRuleModal({ showModal, toggleModal, onSuccess, isEditMode = false, ruleData }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);

  const userData = useSelector((state) => state.auth.user);

  const initialValues = useMemo(() => {
  return isEditMode
    ? {
        title: ruleData?.title || "",
        description: ruleData?.description || "",
      }
    : {
        title: "",
        description: "",
      };
  }, [isEditMode, ruleData]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ruleChainSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const completeAction = (actionPromise) => {
        actionPromise.then((actionResult) => {
          if (actionResult.meta.requestStatus === "fulfilled") {
            toggleModal();
            resetModal();
            Swal.fire({ title: "Success!", text: "Rule has been " + (isEditMode ? "updated" : "created"), icon: "success" });
            onSuccess();
          } else {
            const apiError = actionResult.payload;
            if (apiError && apiError.data && apiError.data.error_message) {
              const errorMessage = apiError.data.error_message;
              setErrors(extractErrorMessage(errorMessage));
            }
          }
        });
      };
      values.user = userData?.id || "";
      if (isEditMode) {
        completeAction(dispatch(updateRule({ ruleId: ruleData.id, data: values })));
      } else {
        completeAction(dispatch(createRule(values)));
      }
    },
  });

  function resetModal() {
    formik.resetForm();
    setErrors(null);
  }

  return (
    <React.Fragment>
      <Modal isOpen={showModal} toggle={toggleModal} size="lg" modalClassName="zoomIn" backdrop="static" centered>
        <ModalHeader toggle={toggleModal} className="p-3">
          {isEditMode ? "Update" : "Create"} Rule
        </ModalHeader>
        <Form onSubmit={formik.handleSubmit}>
          <ModalBody style={{ minHeight: "300px", overflowY: "auto" }}>
            <FormGroup>
              <Label for="name">Rule Title *</Label>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="Rule Title"
                onChange={formik.handleChange}
                value={formik.values.title}
                invalid={formik.touched.title && !!formik.errors.title}
                required
              />
              {formik.touched.name && formik.errors.title ? (
                <div className="invalid-feedback">{formik.errors.title}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                rows="5"
                placeholder="Rule Description"
                onChange={formik.handleChange}
                value={formik.values.description}
                invalid={formik.touched.description && !!formik.errors.description}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="invalid-feedback">{formik.errors.description}</div>
              ) : null}
            </FormGroup>
            {errors && (
              <div className="alert alert-danger mt-3" role="alert">
                {errors}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="success" type="submit">
            {isEditMode ? "Update" : "Create"} Rule
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
