import React, { useState, useRef, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import { ComplexObjectDisplay } from "@/components/complexObjectDisplay/TreeDataList";




export function ShowDetailsModal({ showModal, toggleModal, title, data }) {
    const timerRef = useRef(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setCopied(false);
    }, [showModal]);

    function copyJson() {
        setCopied(true);
        navigator.clipboard.writeText(JSON.stringify(data));
        // Clear the old timer
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        // Set a new timer
        timerRef.current = setTimeout(() => {
            setCopied(false);
        }, 2000);
    }


    // Different ways to display complex object

    // const ComplexObjectDisplay = ({ obj }) => {
    //     if (typeof obj === 'object' && obj !== null) {
    //       return (
    //         <ul className="list-group">
    //           {Object.entries(obj).map(([key, value]) => (
    //             <li className="list-group-item" key={key}>
    //               <strong>{key}</strong>: {typeof value === 'object' ? <ComplexObjectDisplay obj={value} /> : value.toString()}
    //             </li>
    //           ))}
    //         </ul>
    //       );
    //     }
    //     return <span>{obj.toString()}</span>;
    // };

    // const ComplexObjectDisplay = ({ obj }) => {
    //     return (
    //       Object.entries(obj).map(([key, value]) => (
    //         <Collapsible trigger={`${key}:`} key={key}>
    //           {typeof value === 'object' ? <ComplexObjectDisplay obj={value} /> : value.toString()}
    //         </Collapsible>
    //       ))
    //     );
    //   };

    // const ComplexObjectDisplay = ({ obj }) => {
    //     const renderValue = (value) => {
    //       if (typeof value === 'object' && value !== null) {
    //         return <ComplexObjectDisplay obj={value} />;
    //       }
    //       return value.toString();
    //     };
      
    //     return (
    //       <ListGroup className="nested-list nested-sortable-handle">
    //         {Object.entries(obj).map(([key, value], index) => (
    //           <React.Fragment key={index}>
    //             <ListGroupItem className="nested-1">
    //               <i className="ri-drag-move-fill align-bottom handle"></i>
    //               {`${key}: `}
    //               {renderValue(value)}
    //             </ListGroupItem>
    //           </React.Fragment>
    //         ))}
    //       </ListGroup>
    //     );
    //   };

    return (
        <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
            <ModalHeader toggle={toggleModal}>
                {title}
            </ModalHeader>
            <ModalBody>
                <SimpleBar style={{ maxHeight: "400px" }}>
                <ComplexObjectDisplay obj={data} />
                </SimpleBar>
            </ModalBody>
            <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                    <Button className="btn-ghost-dark shadow-none border"  onClick={copyJson}>
                        <span>{copied ? "Copied" : "Copy Json"}</span>
                    </Button>
                    <Button className="btn-ghost-dark shadow-none border" onClick={toggleModal}>
                        <span>Close</span>
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}