import React from "react";
import { FormGroup, Input } from "reactstrap";

export const DataSourceFixedSingleValue = ({ formik }) => (
  <FormGroup>
    <Input
      id="dataSourceConfig.fixedSingleValue"
      name="dataSourceConfig.fixedSingleValue"
      placeholder="Please enter a value"
      value={formik.values.dataSourceConfig.fixedSingleValue || ""}
      onChange={(e) => formik.setFieldValue("dataSourceConfig.fixedSingleValue", e.target.value)}
      invalid={!!formik.errors.dataSourceConfig?.fixedSingleValue}
    />
    {formik.errors.dataSourceConfig?.fixedSingleValue && (
      <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
        {formik.errors.dataSourceConfig.fixedSingleValue}
      </div>
    )}
  </FormGroup>
);
