import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export function SmsNotifyTab({ formik }) {
  return (
    <React.Fragment>
      <FormGroup>
        <Label for="smsMessage">SMS Notify Message</Label>
        <p className="text-muted">
          Messages support the use of variables for personalization. Please
          see the{" "}
          <a
            className="text-secondary text-decoration-underline"
            href="https://docs.linkocloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>{" "}
          for detailed guidance on templating.
        </p>

        <Input
          type="textarea"
          name="smsMessage"
          id="smsMessage"
          rows="5"
          placeholder="Message"
          onChange={formik.handleChange}
          value={formik.values.smsMessage}
          invalid={formik.touched.smsMessage && !!formik.errors.smsMessage}
        />
        {formik.touched.smsMessage && formik.errors.smsMessage ? (
          <div className="invalid-feedback">{formik.errors.smsMessage}</div>
        ) : null}
      </FormGroup>
    </React.Fragment>
  );
}
