import React from "react";
import { useState } from "react";
import { Handle, Position, NodeToolbar, useOnSelectionChange } from "reactflow";
import { Button } from "reactstrap";
import { LimitConectionHandle } from "@/features/rulesEngine/components/LimitConectionHandle";
import { useRuleNodeContext } from "@/features/rulesEngine/components/RuleNodeContext";

export function BaseRuleNode({ id, data }) {
  const [selected, setSelected] = useState(false);
  const { onDelete, onEdit } = useRuleNodeContext();
  const handleStyle = (type) => ({
    width: "14px",
    height: "30px",
    borderRadius: "3px",
    backgroundColor: type == "source" ? "#1982c4" : "#ff595e",
    zIndex: 1,
  });

  useOnSelectionChange({
    onChange: ({ nodes, _ }) => {
      setSelected(nodes.some(node => node.id === id));
    },
  });

  return (
    <React.Fragment>
      <NodeToolbar
        isVisible={selected}
        position={Position.Top}
        align="end"
        offset={-2}
      >
        <Button color="success" className="btn-sm py-1 me-1" onClick={() => onEdit(id)}>
          <i className="ri-pencil-fill"></i>
        </Button>
        <Button color="danger" className="btn-sm py-1" onClick={() => onDelete(id)}>
          <i className="ri-close-line"></i>
        </Button>
      </NodeToolbar>

      <LimitConectionHandle
        type="source"
        position={Position.Left}
        style={handleStyle("source")}
        id={`source-${id}`}
        isConnectable={1}
      />
      <div className={`rule-node ${data.color ? "rule-node-" + data.color : ""}`}>
        <i className={`${data.icon} rule-node-icon`}></i>
        <span className="rule-node-title">{data.title}</span>
      </div>
      <Handle
        type="target"
        position={Position.Right}
        style={handleStyle("target")}
        id={`target-${id}`}
      />
    </React.Fragment>
  );
}
