export function SearchBox({ search, handleSearch, placeholder, className }) {
  return (
    <div className={`search-box ms-2 ${className}`}>
      <input
        type="text"
        className={`form-control search`}
        placeholder={placeholder}
        onChange={handleSearch}
        value={search}
      />
      <i className="ri-search-line search-icon"></i>
    </div>
  );
}