import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";

import { getRulesData } from "@/features/rulesEngine/thunk";

import Select from "react-select";

export function RuleEngineRuleSettingTab({ formik, active=false }) {
    if (!active) return null;
  const dispatch = useDispatch();
  const [configurations, setConfigrations] = useState({
    type: "ruleChain",
    ruleChain: [],
    notifyOn: [{ value: "failure", label: "Rule Chain Failure" }],
  });

  const ruleChains = useSelector((state) => state.rulesEngine.rulesData);
  const { ruleChainsList } = useMemo(
    () => ({
      ruleChainsList: ruleChains?.results || [],
    }),
    [ruleChains]
  );

  function updateConfig(key, value) {
    setConfigrations((prev) => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    formik.setFieldValue("configurations", configurations);
  }, [configurations]);

  useEffect(() => {
    dispatch(getRulesData({}));
  }, []);

  return (
    <React.Fragment>
      <FormGroup>
        <Label for="type">Rule Chain *</Label>
        <Select
          value={configurations.ruleChain}
          isMulti={true}
          isClearable={true}
          onChange={(e) => updateConfig("ruleChain", e)}
          placeholder="If no rule chain is selected, the rule will be applied to all rule chains."
          options={ruleChainsList.map((ruleChain) => ({
            value: ruleChain.id,
            label: ruleChain.title,
          }))}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">Notify on *</Label>
        <Select
          value={configurations.notifyOn}
          isMulti={true}
          isClearable={true}
          onChange={(e) => updateConfig("notifyOn", e)}
          options={[
            { value: "started", label: "Rule Chain Started" },
            { value: "stopped", label: "Rule Chain Stopped" },
            { value: "updated", label: "Rule Chain Updated" },
            { value: "failure", label: "Rule Chain Failure" },
          ]}
        />
      </FormGroup>
    </React.Fragment>
  );
}
