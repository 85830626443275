

const DeviceProfiles = () => {
    return (
        <div>
        <h1>Device Profiles</h1>
        </div>
    );
};

export default DeviceProfiles;