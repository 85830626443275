import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Tooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  getNotificationsData,
  markNotificationsAsRead,
  getMessagesNotificationsData,
  markMessagesNotificationsAsRead,
} from "@/features/notifications/thunk";
import { formatTimeDifference } from "@/common/utils/dateFormatOptions";
import SimpleBar from "simplebar-react";

import bell from "@/assets/images/svg/bell.svg";

const notificationTypeIcon = {
  devices: "mdi mdi-devices",
  alarms: "mdi mdi-cloud-alert",
  ruleEngineEvent: "mdi mdi-lan",
  general: "mdi mdi-server",
};

const notificationTypeColor = {
  devices: "primary",
  alarms: "warning",
  ruleEngineEvent: "success",
  general: "danger",
};

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [needRefresh, setNeedRefresh] = useState(false);
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const [messagesNotificationsData, setMessagesNotificationsData] = useState([]);

  const toggleTooltip = (notificationId) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [notificationId]: !prev[notificationId],
    }));
  };

  useEffect(() => {
    dispatch(getNotificationsData({ page: 1, search: "", unreadFilter: true })).then((actionResult) => {
      if (getNotificationsData.fulfilled.match(actionResult)) {
        setNeedRefresh(false);
      }
    });
    dispatch(getMessagesNotificationsData({ page: 1, search: "", unreadFilter: true })).then((actionResult) => {
      if (getMessagesNotificationsData.fulfilled.match(actionResult)) {
        setNeedRefresh(false);
      }
    });
  }, [needRefresh]);

  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  
  const notificationsData = useSelector((state) => state.notifications.unreadNotificationsData);
  console.log(notificationsData);
  const { notificationsList, totalDataLength } = useMemo(
    () => ({
      notificationsList: notificationsData?.results || [],
      totalDataLength: notificationsData?.total_count || 0,
    }),
    [notificationsData]
  );

  const { messagesNotificationsList, totalMessagesDataLength } = useMemo(
    () => ({
      messagesNotificationsList: messagesNotificationsData?.results || [],
      totalMessagesDataLength: messagesNotificationsData?.total_count || 0,
    }),
    [messagesNotificationsData]
  );

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function handleMarkAsRead(notificationId) {
    dispatch(markNotificationsAsRead({ notificationIds: [notificationId] }));
    setNeedRefresh(true);
  }

  function handleMarkMessagesAsRead(notificationId) {
    dispatch(markMessagesNotificationsAsRead({ notificationIds: [notificationId] }));
    setNeedRefresh(true);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
        >
          <i className="bx bx-bell fs-22"></i>
          {totalDataLength > 0 && (
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
              {totalDataLength}
              <span className="visually-hidden">unread messages</span>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0 border" style={{ minWidth: "400px" }}>
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  <span className="badge bg-light-subtle text-body fs-13"> {totalDataLength} New</span>
                </div>
              </Row>
            </div>

            <div className="px-2 pt-2">
              <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    All ({totalDataLength})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Messages
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "400px" }} className="pe-2">
                {notificationsList.map((notification, index) => (
                  <div key={index} className="text-reset notification-item d-block dropdown-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 avatar-sm me-3">
                        <span
                          className={`avatar-title bg-${notificationTypeColor[notification.type]}-subtle text-${
                            notificationTypeColor[notification.type]
                          } rounded-circle fs-24`}
                        >
                          <i className={notificationTypeIcon[notification.type]}></i>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-2 lh-base">{notification.subject}</h6>
                        <p className="mt-0 mb-2 lh-base" style={{ whiteSpace: 'pre-line' }}>{notification.message}</p>
                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                          <span>
                            <i className="mdi mdi-clock-outline"></i> {formatTimeDifference(notification.created_time)}
                          </span>
                        </p>
                      </div>
                      <div className="">
                        <Button
                          id={`markAsRead-${notification.id}`}
                          color="info"
                          className="btn-soft-info shadow-none rounded-circle px-2 py-1"
                          onClick={() => handleMarkAsRead(notification.id)}
                        >
                          <i className="mdi mdi-check fs-20"></i>
                        </Button>
                        <Tooltip
                          placement="auto"
                          isOpen={tooltipOpen[notification.id]}
                          target={`markAsRead-${notification.id}`}
                          toggle={() => toggleTooltip(notification.id)}
                        >
                          Mark as read
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))}

                {notificationsList.length === 0 && (
                  <>
                    <div className="w-25 w-sm-50 pt-3 mx-auto">
                      <img src={bell} className="img-fluid" alt="user-pic" />
                    </div>
                    <div className="text-center pb-5 mt-2">
                      <h6 className="fs-18 fw-semibold lh-base">You have no any notifications </h6>
                    </div>
                  </>
                )}

                <div className="my-3 text-center">
                  <Link to="notifications/" className="btn btn-soft-success waves-effect waves-light">
                    View All Notifications <i className="ri-arrow-right-line align-middle"></i>
                  </Link>
                </div>
              </SimpleBar>
            </TabPane>
            <TabPane tabId="2" className="p-4">
              {messagesNotificationsList.map((notification, index) => (
                <div key={index} className="text-reset notification-item d-block dropdown-item">
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-sm me-3">
                      <span
                        className={`avatar-title bg-info-subtle text-info rounded-circle fs-24`}
                      >
                        <i className="mdi mdi-email"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-2 lh-base">{notification.message}</h6>
                      <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span>
                          <i className="mdi mdi-clock-outline"></i> {formatTimeDifference(notification.created_time)}
                        </span>
                      </p>
                    </div>
                    <div className="">
                      <Button
                        id={`markAsRead-${notification.id}`}
                        color="info"
                        className="btn-soft-info shadow-none rounded-circle px-2 py-1"
                        onClick={() => handleMarkMessagesAsRead(notification.id)}
                      >
                        <i className="mdi mdi-check fs-20"></i>
                      </Button>
                      <Tooltip
                        placement="auto"
                        isOpen={tooltipOpen[notification.id]}
                        target={`markAsRead-${notification.id}`}
                        toggle={() => toggleTooltip(notification.id)}
                      >
                        Mark as read
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
              {messagesNotificationsList.length === 0 && (
                <>
                  <div className="w-25 w-sm-50 pt-3 mx-auto">
                    <img src={bell} className="img-fluid" alt="user-pic" />
                  </div>
                  <div className="text-center pb-5 mt-2">
                    <h6 className="fs-18 fw-semibold lh-base">You have no any messages </h6>
                  </div>
                </>
              )}
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
