import Turnstile from "react-turnstile";

import * as Yup from "yup";
import { useFormik } from "formik";

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import AuthenticationLayout from '@/components/layouts/AuthenticationLayout';
import { registerUser } from '@/features/auth/register/thunk';
import { extractErrorMessage } from '@/common/utils/stringUtils';
import { resetRegistrationStatus } from '@/features/auth/register/slice';
import OauthLoginButtonsSet from '@/features/auth/oauth/components/OauthLoginButtonsSet'; 


const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Please Enter Your Email"),
    firstName: Yup.string().required("Please Enter Your First Name"),
    lastName: Yup.string().required("Please Enter Your Last Name"),
    password: Yup.string()
      .required('Please enter your password')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
      .matches(/\d/, 'Must contain at least one number'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords don't match")
      .required('Please confirm your password'),
  });

const RegisterPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [passwordFocus, setPasswordFocus] = useState(false);
    const handlePasswordFocus = () => setPasswordFocus(true);
    const handlePasswordBlur = () => setPasswordFocus(false);

    const [token, setToken] = useState('');
    const [errors, setErrors] = useState('');
    const loginStatus = useSelector(state => state.auth.status);
    const registerError = useSelector(state => state.register.error);
    const registerSuccess = useSelector(state => state.register.success);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            values.token = token;
            dispatch(registerUser(values));
        }
    });

    const handleEmailInput = (e) => {
        setEmail(e.target.value);
        validation.handleChange(e);
    };

    const handleTurnstileVerify = (turnstileToken) => {
        setToken(turnstileToken);
    };

    useEffect(() => {
        if (loginStatus === 'authenticated') {
            navigate("/dashboard");
        }
    }, [loginStatus]);
    
    useEffect(() => {
        if (registerSuccess) {
            dispatch(resetRegistrationStatus());
            navigate("/sent-email-verification?email=" + email);
        }
    }, [registerSuccess]);

    useEffect(() => {
        if (registerError && registerError.data && registerError.data.error_message) {
            const errorMessage = registerError.data.error_message;
            setErrors(extractErrorMessage(errorMessage));
        }
    }, [registerError]);

    useEffect(() => {
        setErrors('');
    }, [location]);


    return (
        <AuthenticationLayout title="Welcome," subTitle=" looks like you're new here!" withMask={false}>
            <Row className="g-0">
                <Col className="mx-auto" xs="11" md="8" lg="7" xl="6">
                    <p className="text-2 text-white">Already a member? <Link to="/login" className="fw-500">Login</Link></p>
                    <h3 className="text-white mb-4">Register Your Account</h3>
                    <OauthLoginButtonsSet />
                    <div className="d-flex align-items-center my-4">
                        <hr className="col-1 border-secondary" />
                        <span className="mx-3 text-2 text-white-50">OR</span>
                        <hr className="flex-grow-1 border-secondary" />
                    </div>
                    <Form className="form-dark needs-validation" id='register-form' onSubmit={validation.handleSubmit} noValidate>
                        {errors && <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            {errors}
                        </div>}
                        <Row className='mb-3'>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName" className="text-white">First Name</Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Enter Your First Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.firstName || ""}
                                        invalid={
                                            validation.touched.firstName && validation.errors.firstName ? true : false
                                        }
                                    />
                                    {validation.touched.firstName && validation.errors.firstName ? (
                                        <FormFeedback type="invalid"><div>{validation.errors.firstName}</div></FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName" className="text-white">Last Name</Label>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Enter Your Last Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.lastName || ""}
                                        invalid={
                                            validation.touched.lastName && validation.errors.lastName ? true : false
                                        }
                                    />
                                    {validation.touched.lastName && validation.errors.lastName ? (
                                        <FormFeedback type="invalid"><div>{validation.errors.lastName}</div></FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="email" className="text-white">Email Address</Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        placeholder="Enter Email Address"
                                        value={validation.values.email || ""}
                                        onChange={handleEmailInput}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="password" className="text-white">Password</Label>
                                    <Input
                                        id="password"
                                        type="password"
                                        name="password"
                                        placeholder="Enter Password"
                                        onPaste={(e) => { e.preventDefault(); }}
                                        onChange={validation.handleChange}
                                        onBlur={handlePasswordBlur}
                                        onFocus={handlePasswordFocus}
                                        value={validation.values.password || ""}
                                        invalid={validation.touched.password && !!validation.errors.password}
                                    />
                                    {validation.touched.password && validation.errors.password ? (
                                        <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-2 rounded" id="password-validation" style={{ display: passwordFocus ? 'block' : 'none' }}>
                                    <h6 className="text-white text-2">Password must contain:</h6>
                                    <p className={validation.values.password.length >= 8 ? 'valid' : 'invalid'}>Minimum 8 characters</p>
                                    <p className={/[a-z]/.test(validation.values.password) ? 'valid' : 'invalid'}>At least one lowercase letter</p>
                                    <p className={/[A-Z]/.test(validation.values.password) ? 'valid' : 'invalid'}>At least one uppercase letter</p>
                                    <p className={/\d/.test(validation.values.password) ? 'valid' : 'invalid'}>At least one number</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="confirmPassword" className="text-white">Confirm Password</Label>
                                    <Input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        onPaste={(e) => { e.preventDefault(); }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.confirmPassword || ""}
                                        invalid={
                                            validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                        }
                                    />
                                    {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                        <FormFeedback type="invalid"><div>{validation.errors.confirmPassword}</div></FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <div className="mb-1">
                                <p className="mb-0 text-white fs-12fst-italic me-3">By registering you agree to the Linko Cloud
                                    <Link to="https://docs.linkocloud.com/terms-and-privacy-policy/privacy-policy" className="text-primary text-decoration-underline fst-normal fw-medium ms-2" target="_blank">Privay Policy</Link>
                                </p>
                            </div>
                        </Row>
                        <Turnstile sitekey="0x4AAAAAAARA5EMX2LllUB6N" onVerify={handleTurnstileVerify}/>
                        <Button color="primary" className="shadow-none my-2 mt-3" type="submit">Register</Button>
                    </Form>
                </Col>
            </Row>
        </AuthenticationLayout>
    );
};

export default RegisterPage;
