export const extractErrorMessage = (error) => {
    if (typeof error === 'object' && error !== null) {
      const errorKeys = Object.keys(error);
      if (errorKeys.length > 0) {
        return error[errorKeys[0]][0];
      }
    }
    return error;
  };


export function generateRandomToken(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function truncateText(text, maxLength = 50) {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
}