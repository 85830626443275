import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { Card, CardBody, Col, Row } from "reactstrap";
import { DeviceStatusCard } from "@/features/devices/components/DeviceStatusCard";

export function DeviceStatus() {
  const deviceData = useSelector((state) => state.devices.deviceDetailData);

  const deviceStatusData = useMemo(() => {
    return [
      {
        title: "Status",
        value: deviceData.status,
        icon: deviceData.status === "active" ? "ri-signal-wifi-line" : "ri-wifi-off-line",
        textColor: deviceData.status === "inactive" ? "text-danger" : "",
      },
      { title: "Last Online", value: "never", icon: "ri-time-line" },
      { title: "IP Address", value: "0.0.0.0", icon: "ri-global-line" },
      { title: "Received Data", value: 0, icon: "ri-download-cloud-line", unit: "bytes" },
      { title: "Transmitted Data", value: 0, icon: "ri-upload-cloud-line", unit: "bytes" },
    ];
  }, [deviceData]);

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card className="border">
          <CardBody className="p-0">
            <Row className="row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
              {deviceStatusData.map((status, index) => {
                return (
                  <DeviceStatusCard
                    key={index}
                    title={status.title}
                    value={status.value}
                    icon={status.icon}
                    textColor={status?.textColor}
                    unit={status?.unit}
                  />
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}
