import React from "react";

import { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Button, Tooltip } from "reactstrap";

export function WidgetCard({ title, description, onClick, imgSrc }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  return (
    <React.Fragment>
      <Card className="widget-card border" style={{ cursor: "pointer", maxWidth: "350px" }} onClick={onClick}>
        <CardHeader className="py-1 d-flex align-items-center justify-content-between">
          <h6 className="card-title mb-0">{title}</h6>
          <Button className="btn-ghost-dark shadow-none" id="toolTipBtn">
            <i className="mdi mdi-information-outline fs-18"></i>
          </Button>
          <Tooltip placement="auto" isOpen={tooltipOpen} target="toolTipBtn" toggle={toggleTooltip} trigger="hover">
            {description}
          </Tooltip>
        </CardHeader>
        <CardBody className="p-3 d-flex justify-content-center" style={{ height: "200px"}}>
          <img src={imgSrc} alt="" className="img-fluid" width={600} height={400} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
