import React, { useEffect } from "react";
import { useState } from "react";
import { useModals } from "@/hooks/useModals";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Row,
  Col,
  Button,
} from "reactstrap";
import { WidgetCard } from "@/features/dashboards/components/WidgetSelectPanel/WidgetCard";

import {
  widgetCardConfig,
  widgetCategoryConfig,
} from "@/features/dashboards/components/WidgetSelectPanel/widgetCardConfig";
import {
  widgetModalConfig,
  WidgetModalManager,
} from "@/features/dashboards/components/widgetModals/WidgetModalManagerModal";

export function WidgetSelectPanel({ showPanel, togglePanel }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [modals, modalData, toggleModal] = useModals(widgetModalConfig);

  const filteredWidgets = widgetCardConfig.filter(
    (widget) =>
      widget.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      widgetCategoryConfig
        .find((category) => category.type === widget.category)
        ?.title.toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  return (
    <React.Fragment>
      <Offcanvas
        isOpen={showPanel}
        direction="end"
        toggle={togglePanel}
        className="border-bottom top-70"
        backdrop={false}
        scrollable
        style={{ overflowY: "auto", maxHeight: "100vh" }}
      >
        <OffcanvasHeader toggle={togglePanel} tag="h3">
          Select <span className="text-capitalize">{selectedCategory}</span>{" "}
          Widget
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row className="d-flex justify-content-between">
            <Col xs="3" className="d-flex">
              {selectedCategory && !searchQuery && (
                <Button
                  color="dark"
                  outline
                  className="px-3 py-1"
                  onClick={() => setSelectedCategory(null)}
                >
                  <i className="mdi mdi-arrow-collapse-left label-icon align-middle fs-20"></i>
                </Button>
              )}
              {/* <Button color="dark" outline className="ms-3 px-3 py-1">
                <i className="mdi mdi-upload label-icon align-middle me-2 fs-20"></i>
                Import Widget
              </Button> */}
            </Col>
            <Col xs="9">
              <div className="search-box ms-2">
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search Widget"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </Col>
          </Row>

          {!selectedCategory && !searchQuery && (
            <Row className="mt-4">
              {widgetCategoryConfig.map((category, index) => (
                <Col xxl="3" xl="6" md="12" key={index} className=" d-flex justify-content-center">
                  <WidgetCard
                    title={category.title}
                    description={category.description}
                    imgSrc={category.imgSrc}
                    onClick={() => setSelectedCategory(category.type)}
                  />
                </Col>
              ))}
            </Row>
          )}

          {searchQuery && (
            <Row className="mt-4">
              {filteredWidgets.map((widget, index) => (
                <Col xxl="3" xl="6" md="12" key={index}>
                  <WidgetCard
                    title={widget.panelTitle || widget.title}
                    description={widget.description}
                    imgSrc={widget.imgSrc}
                    onClick={() =>
                      toggleModal(widget.modal, {
                        widgetData: widget,
                      })
                    }
                  />
                </Col>
              ))}
            </Row>
          )}

          {selectedCategory && !searchQuery && (
            <Row className="mt-4">
              {widgetCardConfig
                .filter((widget) => widget.category === selectedCategory)
                .map((widget, index) => (
                  <Col xxl="3" xl="6" md="12" key={index}>
                    <WidgetCard
                      title={widget.panelTitle || widget.title}
                      description={widget.description}
                      imgSrc={widget.imgSrc}
                      onClick={() => 
                        toggleModal(widget.modal, {
                          widgetData: widget,
                        })}
                    />
                  </Col>
                ))}
            </Row>
          )}
          {selectedCategory && !searchQuery && (
            <Button
              color="secondary"
              className="px-3 py-1"
              onClick={() => setSelectedCategory(null)}
            >
              <i className="mdi mdi-arrow-collapse-left label-icon align-middle me-2 fs-20"></i>
              Back to Categories
            </Button>
          )}
        </OffcanvasBody>
      </Offcanvas>
      <WidgetModalManager
        toggleModal={toggleModal}
        modalData={modalData}
        modals={modals}
      />
    </React.Fragment>
  );
}
