import {LordIcon} from "@/components/common/LordIcon";

import ICON from '@/assets/json/lord-icon/zoom-search.json';

export function NoResultTip({dataName, className}) {
    return (
    <div className={className}>
        <div className="text-center">
            <div className="d-flex justify-content-center">
            <LordIcon icon={ICON} size={75} mode="once" />
            </div>
            <h5 className="mt-2">No {dataName} found</h5>
        </div>
    </div>
    );
}