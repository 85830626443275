import React from "react";
import { Card, CardBody } from "reactstrap";

export function InfoPanel({ title, description, documentURL, image, imageWidth = 220, height }) {
  const cardStyle = height ? { height: `${height}px` } : {};
  const cardClasses = `p-2 ${height ? "border" : "card-height-100 border"}`;

  return (
    <Card className={cardClasses} style={cardStyle}>
      <CardBody className="d-flex align-items-center justify-content-between">
        <div>
          <h1>{title}</h1>
          <p className="mt-3 fs-16" dangerouslySetInnerHTML={{ __html: description }}></p>
          <a className="btn btn-secondary btn-md mt-3" href={documentURL}>
            Read Documentation
          </a>
        </div>
        <div className="intro-image-container">
          <img src={image} alt={title} width={imageWidth} style={{ height: "auto" }} />
        </div>
      </CardBody>
    </Card>
  );
}
