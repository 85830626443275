import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { TimeRangeChart } from "@/components/charts/TimeRangeChart";

export function RulesEngineActivity() {
  const dispatch = useDispatch();
  document.title = "Rules Engine | Linko Cloud";
  const [distributionTimeRange, setDistributionTimeRange] = useState("minutely");

  const distributionTimeRangeDefine = ["minutely", "hourly", "daily", "weekly", "monthly", "yearly"];

  function handleDistributionTimeRangeChange(timeRange) {
    setDistributionTimeRange(timeRange);
  }

  return (
    <TimeRangeChart
      type="line"
      title="Rules Engine Activity"
      data={[]}
      dataName="activity data"
      timeRange={distributionTimeRange}
      timeRangeTypes={distributionTimeRangeDefine}
      onTimeRangeChange={handleDistributionTimeRangeChange}
      minHeight={395}
    />
  );
}
