import React from "react";
import { useEffect } from "react";
import { useParams, useBlocker } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import { Container } from "reactstrap";

import { TopToolBar } from "@/features/dashboards/components/TopToolBar"; 
import { WidgetSelectPanel } from "@/features/dashboards/components/WidgetSelectPanel";
import { DashboardEditArea } from "@/features/dashboards/components/DashboardEditArea";

import { setMode } from "@/features/dashboards/slice";
import { getDashboardDetailData } from "@/features/dashboards/thunk";
import { setSelectWidgetPanelOpen, clearDashboardDetailData } from "@/features/dashboards/slice";

const DashboardDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const dashboardId = params.dashboardId;
  const mode = useSelector((state) => state.dashboards.mode);

  const dashboardDetailData = useSelector(
    (state) => state.dashboards.dashboardDetailData
  );

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
    mode === "edit" &&
      currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
        Swal.fire({
            title: 'Unsaved Changes',
            text: 'You have unsaved changes in your dashboard. Leaving this page will discard any unsaved changes.',
            showCancelButton: true,
            confirmButtonColor: '#3577f1',
            confirmButtonText: 'leave',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => { 
            if (result.isConfirmed) {
              dispatch(setMode("view"));
                blocker.proceed();
            } else {
                blocker.reset();
            }
        });
    }
}, [blocker]);


  useEffect(() => {
    dispatch(getDashboardDetailData({ dashboardId }));
    return () => {
      dispatch(clearDashboardDetailData());
    };
  }, []);

  const selectWidgetOpen = useSelector(
    (state) => state.dashboards.selectWidgetPanelOpen
  );
  function toggleSelectWidgetPanel() {
    dispatch(setSelectWidgetPanelOpen(!selectWidgetOpen));
  }

  document.title = `${dashboardDetailData.title} | Linko Cloud`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="px-0">
          <TopToolBar dashboardId={dashboardId} />
          <DashboardEditArea />
        </Container>
      </div>
      <WidgetSelectPanel
        showPanel={selectWidgetOpen}
        togglePanel={toggleSelectWidgetPanel}
      />
    </React.Fragment>
  );
};

export default DashboardDetail;
