import React from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import illustarator from "@/assets/images/illustration/dashboards.svg";

export function DashboardIntroPanel() {
  return (
    <Card className="p-2 border card-height-100">
      <CardBody className="d-flex align-items-center justify-content-between">
        <Row>
          <Col xxl="6" xl="8">
            <h1>Dashboards</h1>
            <p className="mt-3 fs-16">
              Empower your decision-making with{" "}
              <strong>custom dashboards</strong>. Utilize drag-and-drop
              simplicity, <strong>configure data sources</strong>, and set{" "}
              <strong>time windows</strong> to build dynamic, visually
              compelling analytics interfaces. Tailor your dashboard to{" "}
              <strong>present complex data</strong> in an easily digestible
              format, enabling quick insights and smart decisions.
            </p>
            <Button color="secondary" className="mt-3 btn-md">
              View Document
            </Button>
          </Col>
          <Col xxl="6" xl="4">
            <div className="d-none d-xl-flex ms-xl-5 justify-content-center">
              <img
                src={illustarator}
                alt="..."
                width={230}
                style={{ height: "auto" }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
