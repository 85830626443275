import { createSlice } from '@reduxjs/toolkit';
import { requestResetPassword } from '@/features/auth/resetPassword/thunk';

const initialState = {
    error: null,
    status: "failed",
    isLoading: false,
};

const ResetPasswordSlice = createSlice({
    name: 'requestResetPassword',
    initialState,
    reducers: {
        clearError(state) {
            state.error = null;
        },
        resetState(state) {
            state.error = null;
            state.status = "failed";
            state.isLoading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestResetPassword.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(requestResetPassword.fulfilled, (state) => {
                state.isLoading = false;
                state.status = "success";
            })
            .addCase(requestResetPassword.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.payload || 'Failed to request reset password';
            });
    },
});

export const { resetState } = ResetPasswordSlice.actions;
export default ResetPasswordSlice.reducer;