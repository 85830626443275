import React from 'react';
import { Link } from 'react-router-dom';

export function Paginator({ currentPage, totalPages, onPageChange }) {
  const pageNumbers = [];
  const MAX_PAGE_NUMBERS_DISPLAYED = 5;
  let startPage = Math.max(currentPage - 2, 1);
  let endPage = Math.min(startPage + MAX_PAGE_NUMBERS_DISPLAYED - 1, totalPages);

  if (endPage - startPage + 1 < MAX_PAGE_NUMBERS_DISPLAYED) {
    startPage = Math.max(endPage - MAX_PAGE_NUMBERS_DISPLAYED + 1, 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
        <Link to="#" className="page-link" onClick={(e) => { e.preventDefault(); onPageChange(currentPage - 1); }}>Previous</Link>
      </li>
      {pageNumbers.map(number => (
        <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
          <Link to="#" className="page-link" onClick={(e) => { e.preventDefault(); onPageChange(number); }}>
            {number}
          </Link>
        </li>
      ))}
      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
        <Link to="#" className="page-link" onClick={(e) => { e.preventDefault(); onPageChange(currentPage + 1); }}>Next</Link>
      </li>
    </ul>
  );
};

