import React from "react";
import { useModals } from "@/hooks/useModals";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";

import { Container, Row, Col, Card, CardHeader, Button, CardBody } from "reactstrap";

import { BreadCrumb } from "@/components/common/BreadCrumb";
import { StatisticsPanel } from "@/components/panels/StatisticsPanel";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { ShowDetailsModal } from "@/components/modals/ShowDetailsModal";

import { getAuditLogs, getStatisticsData } from "@/features/auditLogs/thunk";

const AuditLogs = () => {
  const dispatch = useDispatch();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [modals, modalData, toggleModal] = useModals({showDetailModal: false});
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();

  function toggleShowDetailsModal() {
    setShowDetailsModal(!showDetailsModal);
  }

  useEffect(() => {
    dispatch(getAuditLogs({ page, search }));
  }, [page, search]);
  useEffect(() => {
    dispatch(getStatisticsData());
  }, []);

  const statisticsData = useSelector((state) => state.auditLogs.statisticsData);
  const { todayLogs, criticalActivities, failedLogs, yesterdayLogs } = useMemo(
    () => ({
      todayLogs: statisticsData?.today_logs || 0,
      failedLogs: statisticsData?.failed_logs || 0,
      yesterdayLogs: statisticsData?.yesterday_logs || 0,
      criticalActivities: statisticsData?.critical_activities || 0,
    }),
    [statisticsData]
  );

  const auditLogs = useSelector((state) => state.auditLogs.auditLogs);
  const { auditLogsList, totalPages, totalDataLength } = useMemo(
    () => ({
      auditLogsList: auditLogs?.results || [],
      totalPages: auditLogs?.total_pages || 0,
      totalDataLength: auditLogs?.total_count || 0,
    }),
    [auditLogs]
  );

  function refreshData() {
    resetPageAndSearch();
    dispatch(getAuditLogs({ page, search }));
    dispatch(getStatisticsData());
  }

  const columns = useMemo(
    () => [
      {
        header: "Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Entity Type",
        accessorKey: "entity_type",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return <span className="text-capitalize">{cell.getValue().replace(/_/g, " ")}</span>;
        },
      },
      {
        header: "Entity name",
        accessorKey: "entity_name",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Action Type",
        accessorKey: "action_type",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return <span className="text-uppercase">{cell.getValue()}</span>;
        },
      },
      {
        header: "User",
        accessorKey: "user",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <span
              className={`badge text-uppercase ${
                cell.getValue() === "success"
                  ? "bg-success-subtle text-success"
                  : cell.getValue() === "fail"
                  ? "bg-danger-subtle text-danger"
                  : "bg-warning-subtle text-warning"
              }`}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Details",
        accessorKey: "details",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <Button color="dark" outline className="btn btn-ghost-dark" onClick={() => toggleModal("showDetailModal", {detailData: cell.getValue()})}>
              <i className="ri-eye-fill"></i>
            </Button>
          );
        },
      },
    ],
    [auditLogs]
  );

  document.title = "Audit Logs | Linko Cloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Audit Logs" pageTitle="Security" />
          <Row>
            <Col lg={4}>
              <StatisticsPanel
                title="Today's Audit Logs"
                value={todayLogs}
                icon="check-circle"
                iconColor="success"
                compareValue={yesterdayLogs}
                compareTime="yesterday"
              />
            </Col>
            <Col lg={4}>
              <StatisticsPanel
                title="Critical or Anomalous Activities"
                value={criticalActivities}
                icon="activity"
                iconColor="warning"
              />
            </Col>
            <Col lg={4}>
              <StatisticsPanel title="Failed Logs" value={failedLogs} icon="alert-octagon" iconColor="danger" />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="border">
                <CardHeader className="d-flex justify-content-between">
                  <Col md="2" className="d-flex justify-content-begin">
                    <Button className="btn-ghost-dark shadow-none me-2 border fs-5" onClick={refreshData}>
                      <i className="ri-refresh-line"></i>
                    </Button>
                  </Col>
                  <Col md="6">
                    <div className="search-box ms-2">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Audit Logs"
                        onChange={handleSearch}
                        value={search}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                </CardHeader>
                <CardBody>
                  <DynamicDataTable
                    columns={columns}
                    data={auditLogsList}
                    currentPage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    totalDataLength={totalDataLength}
                    dataName="audit log"
                    onRowClick={(row) => {
                      if (row) {
                        toggleModal("showDetailModal", {detailData: row.details});
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ShowDetailsModal
        showModal={modals.showDetailModal}
        toggleModal={() => toggleModal("showDetailModal")}
        title="Audit Log Details"
        data={modalData.showDetailModal?.detailData}
      />
    </React.Fragment>
  );
};

export default AuditLogs;
