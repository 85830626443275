import React from "react";
import { useModals } from "@/hooks/useModals";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useTableSelectionControl } from "@/hooks/useTableSelectionControl";

import { truncateText } from "@/common/utils/stringUtils";
import { BreadCrumb } from "@/components/common/BreadCrumb";
import { SearchBox } from "@/components/tables/headerToolSet/SearchBox";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { EditButton } from "@/components/tables/rowActionButtons/EditButton";
import { DeleteButton } from "@/components/tables/rowActionButtons/DeleteButton";
import { DashboardIntroPanel } from "@/features/dashboards/components/IntroPanel";
import { AddEntityButton } from "@/components/tables/headerToolSet/AddEntityButton";
import { RefreshDataButton } from "@/components/tables/headerToolSet/RefreshDataButton";
import { ActionButtonGroup } from "@/components/tables/rowActionButtons/ActionButtonGroup";
import { DownloadJsonButton } from "@/components/tables/rowActionButtons/DownloadJsonButton";
import { DeleteMultipleButton } from "@/components/tables/headerToolSet/DeleteMultipleButton";

import { DeleteModal } from "@/components/modals/DeleteModal";
import { ShowDescriptionModal } from "@/components/modals/ShowDescriptionModal";
import { DeleteMultipileModal } from "@/components/modals/DeleteMultipileModal";
import { AddDashboardModal } from "@/features/dashboards/components/AddDashboardModal";

import { Container, Row, Col, Card, CardHeader, Button, Input, CardBody } from "reactstrap";
import { getDashboardsListData, deleteDashboard, deleteMultipleDashboards } from "@/features/dashboards/thunk";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modals, modalData, toggleModal] = useModals({
    addDashboardModal: false,
    deleteModal: false,
    showDescriptionModal: false,
    deleteMultipleModal: false,
  });
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const [selectedIds, handleSelectedAll, handleSelectedSingle, resetSelectedIds] = useTableSelectionControl();

  useEffect(() => {
    dispatch(getDashboardsListData({ page, search }));
  }, [page, search]);

  const dashboards = useSelector((state) => state.dashboards.dashboardsListData);
  const { dashboardsList, allDashboardIds, totalPages, totalDataLength } = useMemo(
    () => ({
      dashboardsList: dashboards?.results || [],
      allDashboardIds: dashboards?.results?.map((dashboard) => dashboard.id) || [],
      totalPages: dashboards?.total_pages || 0,
      totalDataLength: dashboards?.total_count || 0,
    }),
    [dashboards]
  );

  function refreshData() {
    dispatch(getDashboardsListData({ page, search }));
    resetPageAndSearch();
    resetSelectedIds();
  }

  const handleDeleteSingleDashboard = (dashboardId) => {
    if (dashboardId) {
      dispatch(deleteDashboard({ dashboardId })).then((actionResult) => {
        if (deleteDashboard.fulfilled.match(actionResult)) {
          toggleModal("deleteModal");
          refreshData();
        }
      });
    }
  };

  function handleRowClick(row) {
    const navigateDashboardId = row.id;
    if (navigateDashboardId) {
      navigate("/dashboards/" + navigateDashboardId);
    }
  }

  function handleDeleteMultipleDashboards() {
    dispatch(deleteMultipleDashboards({ dashboardIds: selectedIds })).then((actionResult) => {
      if (deleteMultipleDashboards.fulfilled.match(actionResult)) {
        refreshData();
      }
    });
    toggleModal("deleteMultipleModal");
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              checked={selectedIds.length === dashboardsList.length && dashboardsList.length > 0}
              onChange={(e) => handleSelectedAll(e, allDashboardIds)}
            />
          </div>
        ),
        cell: (cell) => {
          return (
            <div className="form-check">
              <Input
                type="checkbox"
                className="form-check-input"
                value={cell.getValue()}
                checked={selectedIds.includes(cell.getValue())}
                onChange={(e) => handleSelectedSingle(e, cell.getValue())}
              />
            </div>
          );
        },
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Title",
        accessorKey: "title",
        enableSorting: true,
        enableColumnFilter: false,
      },
      // {
      //   header: "Group",
      //   accessorKey: "group",
      //   enableSorting: true,
      //   enableColumnFilter: false,
      // },
      {
        header: "Description",
        accessorKey: "description",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <Button
              color="link"
              className="shadow-none"
              outline
              onClick={(e) =>
                toggleModal("showDescriptionModal", {
                  description: cell.getValue(),
                })
              }
            >
              <a className="text-decoration-underline">{truncateText(cell.getValue(), 80)}</a>
            </Button>
          );
        },
      },
      {
        header: "Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Action",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <ActionButtonGroup>
              <EditButton
                onClick={() => toggleModal("addDashboardModal", { isEditMode: true, dashboardData: cell.row.original })}
                tooltip="Edit Dashboard"
              />
              <DeleteButton
                onClick={() =>
                  toggleModal("deleteModal", {
                    dashboardId: cell.row.original.id,
                  })
                }
                tooltip="Delete Dashboard"
              />
              <DownloadJsonButton
                jsonData={cell.row.original.configuration}
                fileName={cell.row.original.title}
                tooltip="Download Dashboard Configuration"
              />
            </ActionButtonGroup>
          );
        },
      },
    ],
    [dashboardsList, selectedIds]
  );

  document.title = "Dashboards | Linko Cloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboards" pageTitle="Management" />
          <DashboardIntroPanel />
          <Card className="border">
            <CardHeader className="d-flex justify-content-between">
              <Col md="2" className="d-flex justify-content-begin">
                <AddEntityButton
                  onClick={() => toggleModal("addDashboardModal", { isEditMode: false })}
                  text="Add Dashboard"
                />
                <RefreshDataButton onClick={refreshData} />
                <DeleteMultipleButton
                  onClick={() => toggleModal("deleteMultipleModal")}
                  tooltip="Delete Multiple Dashboard"
                  show={selectedIds.length > 0}
                />
              </Col>
              <Col md="6">
                <SearchBox handleSearch={handleSearch} search={search} placeholder="Search Dashboards" />
              </Col>
            </CardHeader>
            <CardBody>
              <DynamicDataTable
                columns={columns}
                data={dashboardsList}
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                totalDataLength={totalDataLength}
                dataName="dashboard"
                onRowClick={handleRowClick}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
      <AddDashboardModal
        showModal={modals.addDashboardModal}
        toggleModal={() => toggleModal("addDashboardModal")}
        onSuccess={refreshData}
        isEditMode={modalData.addDashboardModal?.isEditMode}
        dashboardData={modalData.addDashboardModal?.dashboardData}
      />
      <DeleteModal
        showModal={modals.deleteModal}
        toggleModal={() => toggleModal("deleteModal")}
        title="Delete Dashboard"
        subtitle="Are you sure you want to delete this dashboards?"
        onDelete={() => handleDeleteSingleDashboard(modalData.deleteModal?.dashboardId)}
      />
      <DeleteMultipileModal
        showModal={modals.deleteMultipleModal}
        toggleModal={() => toggleModal("deleteMultipleModal")}
        type="dashboard"
        numberSelected={selectedIds.length}
        onDelete={handleDeleteMultipleDashboards}
      />
      <ShowDescriptionModal
        showModal={modals.showDescriptionModal}
        toggleModal={() => toggleModal("showDescriptionModal")}
        title="Dashboard Description"
        description={modalData.showDescriptionModal?.description}
      />
    </React.Fragment>
  );
};

export default Dashboard;
