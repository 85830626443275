import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSelectWidgetPanelOpen, addWidget, updateWidget } from "@/features/dashboards/slice";
import { getCurrentColsCount, findPositionForNewWidget } from "@/features/dashboards/widgetLayout";
import { WidgetModalContainer } from "@/features/dashboards/components/widgetModals/common/WidgetModalContainer";

import {
  WidgetSetting,
  widgetSettingInitialValues,
  widgetSettingValidationSchema,
} from "@/features/dashboards/components/widgetModals/settingSections/WidgetSetting";

import {
  DataSourceSetting,
  dataSourceInitialValues,
  dataSourceValidationSchema,
} from "@/features/dashboards/components/widgetModals/settingSections/DataSourceSetting";

import {
  columnsSettingInitialValues,
  columnsSettingValidationSchema,
  ColumnsSetting,
} from "@/features/dashboards/components/widgetModals/settingSections/ColumnsSetting";

const formSchema = Yup.object().shape({
  ...dataSourceValidationSchema,
  ...widgetSettingValidationSchema,
  ...columnsSettingValidationSchema,
});

const newWidgetWidth = 5;
const newWidgetHeight = 9;
const newWidgetMinW = 1;
const newWidgetMinH = 3;

export function AlarmsTableModal({ showModal, toggleModal, widgetData, isEditMode = false }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const widgets = useSelector((state) => state.dashboards.widgets);

  const formik = useFormik({
    initialValues: {
      ...dataSourceInitialValues({ widgetData }),
      ...widgetSettingInitialValues({ widgetData }),
      ...columnsSettingInitialValues({ widgetData }),
    },
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const cols = getCurrentColsCount(window.innerWidth);
      const { x, y } = findPositionForNewWidget(widgets, newWidgetWidth, newWidgetHeight, cols);

      const newWidget = {
        id: new Date().getTime().toString(),
        title: values.widgetTitle,
        showHeader: values.showHeader,
        type: widgetData?.type,
        dataSourceType: values.dataSourceType,
        dataSourceConfig: values.dataSourceConfig,
        widgetModal: "alarmsTable",
        category: "table",
        configuration: {
          columns: values.columns,
        },
        layout: widgetData.layout || {
          x: x,
          y: y,
          w: newWidgetWidth,
          h: newWidgetHeight,
          minW: newWidgetMinW,
          minH: newWidgetMinH,
        },
      };

      if (isEditMode) {
        dispatch(updateWidget({ id: widgetData?.id, widget: newWidget }));
      } else {
        dispatch(addWidget(newWidget));
      }
      toggleModal();
      resetModal();
      dispatch(setSelectWidgetPanelOpen(false));
    },
  });

  function resetModal() {
    formik.resetForm();
    setErrors(null);
  }

  return (
    <WidgetModalContainer
      showModal={showModal}
      toggleModal={toggleModal}
      modalTitle={formik.values.widgetTitle}
      onSubmit={formik.handleSubmit}
      errors={errors}
      isEditMode={isEditMode}
    >
      <WidgetSetting formik={formik} />
      <DataSourceSetting formik={formik} allowDataSourceType={["devices"]} />
      <ColumnsSetting
        formik={formik}
        defaultColumnOptions={[
          { label: "status", value: "status" },
          { label: "device", value: "device_name" },
          { label: "severity", value: "severity" },
          { label: "details", value: "details" },
          { label: "created Time", value: "created_time" },
        ]}
      />
    </WidgetModalContainer>
  );
}
