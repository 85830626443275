import React, { useEffect, useState, useRef, useContext  } from "react";
import { Card, CardBody } from "reactstrap";
import Clock from "react-clock";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { TimeContext } from "@/contexts/TimeContext";

export function AnalogClockWidget({ widget }) {
  const containerRef = useRef(null);
  const currentTime = useContext(TimeContext);
  const [clockSize, setClockSize] = useState(200);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        const size = Math.min(width, height) * 0.9;
        setClockSize(size);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Card className="h-100 w-100 border">
        <WidgetHeader widget={widget} />
        <CardBody>
          <div
            ref={containerRef}
            className="w-100 h-100 d-flex justify-content-center align-items-center"
          >
            <div>
              <Clock
                value={currentTime}
                size={clockSize}
                renderNumbers={widget?.configuration?.showNumbers || false}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
