import { ALARM_API_URLS } from "@/api/apiConfig";
import { apiClient } from "@/api/axiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getAlarmsStatisticsData = createAsyncThunk(
    "alarm/getAlarmsStatisticsData",
    async (_, { rejectWithValue }) => {
        try {
        const response = await apiClient.get(ALARM_API_URLS.GET_STATISTICS_DATA);
        if (response) {
            return response;
        }
        } catch (errors) {
        return rejectWithValue(errors);
        }
    }
);


export const getAlarmsByDevices = createAsyncThunk(
    "alarm/getAlarmsByDevices",
    async ({ deviceIds, page, pageSize }, { rejectWithValue }) => {
        try {
        const response = await apiClient.post(ALARM_API_URLS.GET_ALARMS_BY_DEVICES, { device_ids: deviceIds, page, page_size: pageSize});
        if (response) {
            return response;
        }
        } catch (errors) {
        return rejectWithValue(errors);
        }
    }
);


export const getDistributionData = createAsyncThunk(
    "alarm/getDistributionData",
    async ( range , { rejectWithValue }) => {
        try {
            const params = new URLSearchParams({ range });
            const response = await apiClient.get(`${ALARM_API_URLS.GET_DISTRIBUTION_DATA}?${params.toString()}`);
            if (response) {
                return response;
            }
        } catch (errors) {
        return rejectWithValue(errors);
        }
    }
);


export const getAlarms = createAsyncThunk(
    "alarm/getAlarms",
    async ({ page, search }, { rejectWithValue }) => {
        try {
        const params = new URLSearchParams({ page, search });
        const response = await apiClient.get(`${ALARM_API_URLS.GET_ALARMS}?${params.toString()}`);
        if (response) {
            return response;
        }
        } catch (errors) {
        return rejectWithValue(errors);
        }
    }
);