import React from "react";
import { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import { NotifyRules } from "@/features/notifyHub/components/NotifyRules";
import { NotifyTemplates } from "@/features/notifyHub/components/NotifyTemplates";

export function NotifyConfigurations() {
  const [activeTab, setActiveTab] = useState("templates");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Card className="card-height-100 border">
      <CardHeader className="border-0">
        <Row className="d-flex justify-content-between">
          <Col md="4">
            <h4>Configurations</h4>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-0">
        <Nav
          className="nav-tabs nav-border-top nav-justified nav-border-top-success"
          role="tablist"
        >
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "templates" })}
              onClick={() => {
                toggleTab("templates");
              }}
              href="#"
            >
              <i className="ri-artboard-2-fill me-1 align-bottom"></i>
              Templates
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "rules" })}
              onClick={() => {
                toggleTab("rules");
              }}
              href="#"
            >
              <i className="ri-scan-2-line me-1 align-bottom"></i>
              Rules
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="text-muted">
          <TabPane tabId="templates">
            <NotifyTemplates />
          </TabPane>
          <TabPane tabId="rules">
            <NotifyRules />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
}
