// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';

import layoutReducer from '@/features/layout/slice';
import alarmsReducer from '@/features/alarms/slice';
import deviceReducer from '@/features/devices/slice';
import loginReducer from '@/features/auth/login/slice';
import authLogsReducer from '@/features/auditLogs/slice';
import notifyHubReducer from '@/features/notifyHub/slice';
import profileReducer from '@/features/auth/profile/slice';
import dashboardsReducer from '@/features/dashboards/slice';
import registerReducer from '@/features/auth/register/slice';
import rulesEngineReducer from '@/features/rulesEngine/slice';
import notificationsReducer from '@/features/notifications/slice';
import resourceUsageReducer from '@/features/resourcesUsage/slice';
import accountSettingsReducer from '@/features/auth/accountSettings/slice';
import requestResetPasswordReducer from '@/features/auth/resetPassword/slice';
import requestEmailVerificationReducer from '@/features/auth/emailVerification/slice';


export const store = configureStore({
  reducer: {
    auth: loginReducer,
    layout: layoutReducer,
    alarms: alarmsReducer,
    devices: deviceReducer,
    profile: profileReducer,
    register: registerReducer,
    auditLogs: authLogsReducer,
    notifyHub: notifyHubReducer,
    dashboards: dashboardsReducer,
    rulesEngine: rulesEngineReducer,
    settings: accountSettingsReducer,
    notifications: notificationsReducer,
    resourcesUsage: resourceUsageReducer,
    requestResetPassword: requestResetPasswordReducer,
    requestEmailVerification: requestEmailVerificationReducer,
  },
  devTools: true,
});
