import React from "react";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Nav } from "reactstrap";
import classnames from "classnames";

export function ShowConfigurationsModal({
  showModal,
  toggleModal,
  configurations,
}) {
  const [ruleTypeTab, setRuleTypeTab] = useState(configurations?.type);
  function toggleTypeTab(tab) {
    if (ruleTypeTab !== tab) setRuleTypeTab(tab);
  }
  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
      <ModalHeader toggle={toggleModal} tag={"h5"}>
        {configurations?.type == "devices" &&
          "Devices Notify Rule Configration"}
        {configurations?.type == "ruleChain" &&
          "Rules Chain Event Rule Configration"}
        {configurations?.type == "alarm" && "Alarms Notify Rule Configration"}
      </ModalHeader>
      <ModalBody>
          {configurations?.type == "devices" && (
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Devices</strong>:{" "}
                {configurations?.devices && configurations?.devices.length > 0
                  ? configurations?.devices
                      .map((device) => device.label)
                      .join(", ")
                  : "Any Device"}
              </li>
              <li className="list-group-item">
                <strong>Notify on</strong>:{" "}
                {configurations?.notifyOn.map((notifyOn) => notifyOn.label).join(", ")}
              </li>
                
            </ul>
          )}
          {configurations?.type == "ruleChain" && (
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Rule Chain</strong>:{" "}
                {configurations?.ruleChain && configurations?.ruleChain.length > 0
                  ? configurations?.ruleChain
                  : "Any Rule Chain"}
              </li>
              <li className="list-group-item">
                <strong>Notify on</strong>:{" "}
                {configurations?.notifyOn.map((notifyOn) => notifyOn.label).join(", ")}
              </li>
            </ul>
          )}
          {configurations?.type == "alarm" && (
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Alarm Severity</strong>:{" "}
                {configurations?.alarmSeverity && configurations?.alarmSeverity.length > 0
                 ? configurations?.alarmSeverity : "Any Severity"}
              </li>
              <li className="list-group-item">
                <strong>Notify on</strong>:{" "}
                {configurations?.notifyOn.map((notifyOn) => notifyOn.label).join(", ")}
              </li>
            </ul>
          )}
      </ModalBody>
    </Modal>
  );
}
