import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '@/api/axiosClient';
import { AUTH_URLS } from '@/api/apiConfig';

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async ({ email, password, confirmPassword, firstName, lastName, token }, { rejectWithValue }) => {

    try {
      const response = await apiClient.post(AUTH_URLS.REGISTER, {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        confirm_password: confirmPassword,
        username: firstName + " " + lastName,
        token
      });
      return response;
      
    } catch (errorDetails) {
      return rejectWithValue(errorDetails || 'Failed to register');
    }
  }
);
