import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSettings, updateSettings } from "@/features/auth/accountSettings/thunk";

export function NotificationSettingsTabContent() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const settingsData = useSelector((state) => state.settings.settingsData);

  // Initialize state with explicit false values to ensure inputs are controlled
  const [webNotification, setWebNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);

  useEffect(() => {
    if (settingsData) {
      setWebNotification(!!settingsData.web_notification);
      setEmailNotification(!!settingsData.email_notification);
      setSmsNotification(!!settingsData.sms_notification);
    }
  }, [settingsData]);

  const handleNotificationChange = (e) => {
    const { name, checked } = e.target;
    dispatch(updateSettings({ [name]: checked }));
  };

  return (
    <React.Fragment>
      <div className="mb-3">
        <h5 className="card-title text-decoration-underline mb-3">
          System Notifications:
        </h5>
        <ul className="list-unstyled mb-0">
          <li className="d-flex">
            <div className="flex-grow-1">
              <label htmlFor="webNotification" className="form-check-label fs-14">
                Enable Web notifications
              </label>
              <p className="text-muted">
                Receive instant alerts directly in your browser while working on
                the IoT Platform
              </p>
            </div>
            <div className="flex-shrink-0">
              <div className="form-check form-switch">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="webNotification"
                  id="webNotification"
                  checked={webNotification}
                  onChange={handleNotificationChange}
                />
              </div>
            </div>
          </li>
          <li className="d-flex mt-2">
            <div className="flex-grow-1">
              <Label
                className="form-check-label fs-14"
                htmlFor="emailNotification"
              >
                Enable email notifications
              </Label>
              <p className="text-muted">
                {" "}
                Activate to receive important notifications and updates directly
                to your email.{" "}
              </p>
            </div>
            <div className="flex-shrink-0">
              <div className="form-check form-switch">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="emailNotification"
                  id="emailNotification"
                  checked={emailNotification}
                  onChange={handleNotificationChange}
                />
              </div>
            </div>
          </li>
          <li className="d-flex mt-2">
            <div className="flex-grow-1">
              <Label
                className="form-check-label fs-14"
                htmlFor="smsNotification"
              >
                Enable SMS notifications
              </Label>
              <p className="text-muted">
                Activate SMS alerts for real-time updates. Please verify your
                phone number to begin receiving messages.
              </p>
            </div>
            <div className="flex-shrink-0">
              <div className="form-check form-switch">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="smsNotification"
                  id="smsNotification"
                  checked={smsNotification}
                  onChange={handleNotificationChange}
                  disabled
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
