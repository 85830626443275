import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { getBulkDevices } from "@/features/devices/thunk";

export function GoogleMapWidget({ widget }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  const [coordinates, setCoordinates] = useState([]);

  const parseAttributeValue = (value) => {
    if (typeof value === "object" || typeof value === "boolean") {
      return null;
    }
    const parsedNumber = Number(value);
    return isNaN(parsedNumber) ? null : parsedNumber;
  };

  function checkValidCoordinate(latitude, longitude) {
    return (
      latitude !== null &&
      longitude !== null &&
      latitude >= -90 &&
      latitude <= 90 &&
      longitude >= -180 &&
      longitude <= 180
    );
  }

  useEffect(() => {
    if (widget.dataSourceType === "currentLocation") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCoordinates([{ lat: position.coords.latitude, lng: position.coords.longitude }]);
          },
          (error) => {
            setErrors("Error getting the current location");
          }
        );
      }
    } else if (widget.dataSourceType === "customLocation") {
      const lat = Number(widget.dataSourceConfig.customLocation.latitude);
      const lng = Number(widget.dataSourceConfig.customLocation.longitude);
      setCoordinates([{ lat, lng }]);
    } else if (widget.dataSourceType === "devicesKeys") {
      const deviceIds = widget.dataSourceConfig.devices;
      if (deviceIds.length === 0) {
        setErrors("No data source devices selected");
        return;
      }
      dispatch(getBulkDevices({ deviceIds })).then((actionResult) => {
        if (getBulkDevices.fulfilled.match(actionResult)) {
          const devicesData = actionResult.payload.results;
          const coordinatesData = devicesData.reduce((acc, device) => {
            const latitudeData = parseAttributeValue(device?.latitude);
            const longitudeData = parseAttributeValue(device?.longitude);
            if (checkValidCoordinate(latitudeData, longitudeData)) {
              acc.push({ lat: latitudeData, lng: longitudeData });
            }
            return acc;
          }, []);
          if (coordinatesData.length === 0) {
            setErrors("No valid coordinates found");
          }
          setCoordinates(coordinatesData);
        } else {
          setErrors("Error fetching devices data");
        }
      });
    }
  }, [widget.dataSourceType, widget.dataSourceConfig.customLocation, dispatch, widget.dataSourceConfig.devices]);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coord) => {
        bounds.extend(coord);
      });
      map.fitBounds(bounds);
    }
  }, [map, coordinates])


  return (
    <Card className="h-100 w-100 border">
      <WidgetHeader widget={widget} />
      <CardBody className="d-flex flex-column justify-content-center align-items-center p-0">
        {errors ? (
          <div className="d-flex justify-content-center align-items-center h-100 w-100 text-center fs-18">{errors}</div>
        ) : isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
            onLoad={(map) => {setMap(map)}}
            onUnmount={() => {setMap(null);}}
            center={{ lat: 41.5, lng: -81.68 }}
            zoom={18}
          >
            {coordinates.map((coordinate, index) => (
              <Marker key={index} position={coordinate} />
            ))}
          </GoogleMap>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 w-100 text-center fs-18">
            Loading...
          </div>
        )}
      </CardBody>
    </Card>
  );
}
