import React from "react";
import { useModals } from "@/hooks/useModals";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useTableSelectionControl } from "@/hooks/useTableSelectionControl";

import { truncateText } from "@/common/utils/stringUtils";
import { BreadCrumb } from "@/components/common/BreadCrumb";
import { StatisticsPanel } from "@/components/panels/StatisticsPanel";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { DownloadJsonButton } from "@/components/tables/rowActionButtons/DownloadJsonButton";

import { DeleteButton } from "@/components/tables/rowActionButtons/DeleteButton";
import { DeleteMultipleButton } from "@/components/tables/headerToolSet/DeleteMultipleButton";
import { EntityUsagePanel } from "@/components/panels/EntityUsagePanel";
import { DeleteModal } from "@/components/modals/DeleteModal";
import { EditButton } from "@/components/tables/rowActionButtons/EditButton";
import { AddRuleModal } from "@/features/rulesEngine/components/AddRuleModal";
import { AddEntityButton } from "@/components/tables/headerToolSet/AddEntityButton";
import { RefreshDataButton } from "@/components/tables/headerToolSet/RefreshDataButton";
import { ShowDescriptionModal } from "@/components/modals/ShowDescriptionModal";
import { DeleteMultipileModal } from "@/components/modals/DeleteMultipileModal";
import { InfoPanel } from "@/components/panels/InfoPanel";

import { ActionButtonGroup } from "@/components/tables/rowActionButtons/ActionButtonGroup";
import { RulesEngineActivity } from "@/features/rulesEngine/components/RuleEngineActivity";

import { Container, Row, Col, Card, CardHeader, Button, Input, CardBody } from "reactstrap";
import { getRulesData, deleteMultipleRules, deleteRule } from "@/features/rulesEngine/thunk";

import infoPanelImage from "@/assets/images/illustration/rules.svg";

const RulesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modals, modalData, toggleModal] = useModals({
    deleteModal: false,
    addRuleModal: false,
    showDescriptionModal: false,
    deleteMultipleModal: false,
  });
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const [selectedIds, handleSelectedAll, handleSelectedSingle, resetSelectedIds] = useTableSelectionControl();

  useEffect(() => {
    dispatch(getRulesData({ page, search }));
  }, [page, search]);

  const userData = useSelector((state) => state.auth.user);
  const rulesLimit = useMemo(() => userData?.limit?.max_rules, [userData]);

  const rules = useSelector((state) => state.rulesEngine.rulesData);
  const { rulesList, allRuleIds, totalPages, totalDataLength } = useMemo(
    () => ({
      rulesList: rules?.results || [],
      totalPages: rules?.total_pages || 0,
      totalDataLength: rules?.total_count || 0,
      allRuleIds: rules?.results?.map((rule) => rule.id) || [],
    }),
    [rules]
  );

  function refreshData() {
    resetSelectedIds();
    resetPageAndSearch();
    dispatch(getRulesData({ page, search }));
  }

  const handleDeleteSingleRule = (ruleId) => {
    if (ruleId) {
      dispatch(deleteRule({ ruleId })).then((actionResult) => {
        if (deleteRule.fulfilled.match(actionResult)) {
          toggleModal("deleteModal");
          refreshData();
        }
      });
    }
  };

  function handleDeleteMultipleRules() {
    dispatch(deleteMultipleRules({ ruleIds: selectedIds })).then((actionResult) => {
      if (deleteMultipleRules.fulfilled.match(actionResult)) {
        refreshData();
      }
    });
    toggleModal("deleteMultipleModal");
  }

  function handleRowClick(row) {
    const navigateRuleId = row.id;
    if (navigateRuleId) {
      navigate("/rules-engine/" + navigateRuleId);
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              checked={selectedIds.length === rulesList.length && rulesList.length > 0}
              onChange={(e) => handleSelectedAll(e, allRuleIds)}
            />
          </div>
        ),
        cell: (cell) => {
          return (
            <div className="form-check">
              <Input
                type="checkbox"
                className="form-check-input"
                value={cell.getValue()}
                checked={selectedIds.includes(cell.getValue())}
                onChange={(e) => handleSelectedSingle(e, cell.getValue())}
              />
            </div>
          );
        },
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Title",
        accessorKey: "title",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Description",
        accessorKey: "description",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <Button
              color="link"
              className="shadow-none"
              outline
              onClick={(e) => {
                toggleModal("showDescriptionModal", {
                  description: cell.getValue(),
                });
              }}
            >
              <a className="text-decoration-underline">{truncateText(cell.getValue(), 100)}</a>
            </Button>
          );
        },
      },
      {
        header: "Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Action",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <ActionButtonGroup>
              <EditButton
                onClick={() => toggleModal("addRuleModal", { isEditMode: true, ruleData: cell.row.original })}
                tooltip="Edit Rule"
              />
              <DeleteButton
                onClick={() =>
                  toggleModal("deleteModal", {
                    ruleId: cell.row.original.id,
                  })
                }
                tooltip="Delete Rule"
              />

              <DownloadJsonButton
                jsonData={cell.row.original.configuration}
                fileName={cell.row.original.title}
                tooltip="Download Rule Configuration"
              />
            </ActionButtonGroup>
          );
        },
      },
    ],
    [rulesList, selectedIds]
  );

  document.title = "Rules Engine | Linko Cloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Rules Engine" pageTitle="Management" />
          <Row>
            <Col xxl={4}>
              <InfoPanel
                title="Rules Engine"
                description="Define and manage rules to control and automate your<strong> IoT devices</strong> workflows, enhancing operational efficiency without complex coding."
                image={infoPanelImage}
                documentURL="#"
                height={400}
              />
              <EntityUsagePanel
                title="Rules Usage"
                currentValue={totalDataLength}
                totalValue={rulesLimit || 0}
                dataType="Rules"
              />
            </Col>
            <Col xxl={8}>
              <Row>
                <Col md={6}>
                  <StatisticsPanel
                    title="Successful rules executed"
                    value={0}
                    icon="check-circle"
                    iconColor="success"
                    compareValue={1}
                    compareTime="Last Month"
                  />
                </Col>
                <Col md={6}>
                  <StatisticsPanel
                    title="failed rules executed"
                    value={0}
                    icon="alert-octagon"
                    iconColor="danger"
                    compareTime={"yeserday"}
                    compareValue={1}
                  />
                </Col>
                <Col xs={12}>
                  <RulesEngineActivity />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="border">
                <CardHeader className="d-flex justify-content-between">
                  <Col md="2" className="d-flex justify-content-begin">
                    <AddEntityButton
                      onClick={() => toggleModal("addRuleModal", { isEditMode: false })}
                      text="Add Rule"
                    />
                    <RefreshDataButton onClick={refreshData} />
                    <DeleteMultipleButton
                      onClick={() => toggleModal("deleteMultipleModal")}
                      tooltip="Delete Multiple Rules"
                      show={selectedIds.length > 0}
                    />
                  </Col>
                  <Col md="6">
                    <div className="search-box ms-2">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Rules"
                        onChange={handleSearch}
                        value={search}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                </CardHeader>
                <CardBody>
                  <DynamicDataTable
                    columns={columns}
                    data={rulesList}
                    currentPage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    totalDataLength={totalDataLength}
                    dataName="rule"
                    onRowClick={handleRowClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteModal
        showModal={modals.deleteModal}
        toggleModal={() => {
          toggleModal("deleteModal");
        }}
        title="Delete Rule"
        subtitle="Are you sure you want to delete this rule?"
        onDelete={() => handleDeleteSingleRule(modalData.deleteModal?.ruleId)}
      />
      <DeleteMultipileModal
        showModal={modals.deleteMultipleModal}
        toggleModal={() => {
          toggleModal("deleteMultipleModal");
        }}
        type="rule"
        numberSelected={selectedIds.length}
        onDelete={handleDeleteMultipleRules}
      />
      <ShowDescriptionModal
        showModal={modals.showDescriptionModal}
        toggleModal={() => {
          toggleModal("showDescriptionModal");
        }}
        title="Rule Description"
        description={modalData.showDescriptionModal?.description}
      />
      <AddRuleModal
        showModal={modals.addRuleModal}
        toggleModal={() => {
          toggleModal("addRuleModal");
        }}
        isEditMode={modalData.addRuleModal?.isEditMode}
        ruleData={modalData.addRuleModal?.ruleData}
        onSuccess={refreshData}
      />
    </React.Fragment>
  );
};

export default RulesList;
