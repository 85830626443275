import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

import '@/assets/scss/main/index.scss';


const Layout = () => {
    return (
        <div className="platform-container" id="layout-wrapper">
            <Header />
            <Sidebar />
            <div className="main-content">
                <Outlet />
                <Footer />
            </div>
        </div>
    );
};

export default Layout;