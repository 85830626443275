import React, { useMemo } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

export function RefreshDataButton({ onClick, tooltip = "Refresh Data" }) {
  const tooltipId = useMemo(() => "refresh-button-" + uuidv4(), []);

  return (
    <React.Fragment>
      <Button
        className="btn-ghost-dark shadow-none me-2 border fs-5"
        onClick={onClick}
        id={tooltipId}
      >
        <i className="ri-refresh-line"></i>
      </Button>
      <UncontrolledTooltip placement="auto" target={tooltipId} trigger="hover">
        {tooltip}
      </UncontrolledTooltip>
    </React.Fragment>
  );
}