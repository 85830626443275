import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

export function DownloadJsonButton({ jsonData, fileName, tooltip }) {
  if (!jsonData || !fileName) return null;
  const tooltipId = "json-" + uuidv4();

  const newFileName = fileName
    .toLowerCase()
    .replace(/\s/g, "_")
    .replace(/[^\w-]+/g, "");
  const handleDownload = (e) => {
    e.stopPropagation();
    const jsonString = JSON.stringify(jsonData);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", newFileName + ".json");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <React.Fragment>
      <Button
        color="link"
        id={tooltipId}
        onClick={(e) => {
          handleDownload(e);
        }}
        className="shadow-none"
      >
        <i className="ri-download-2-fill align-bottom text-muted fs-5"></i>
      </Button>
      <UncontrolledTooltip placement="auto" target={tooltipId} trigger="hover">
        {tooltip}
      </UncontrolledTooltip>
    </React.Fragment>
  );
}
