import React, { useMemo, useEffect, useState } from "react";
import { FormGroup, Input, Button, Table } from "reactstrap";
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { deviceAvailablePropertieSources } from "@/features/devices/services";
import { getDeviceAttributes } from "@/features/devices/thunk";

export function DataSourceKeys({ formik, multiDevices }) {
  const dispatch = useDispatch();
  const [keyOptions, setKeyOptions] = useState([]);

  const deviceData = useMemo(() => {
    if (multiDevices) {
      return formik.values.dataSourceConfig.devices;
    }
    return formik.values.dataSourceConfig.device;
  }, [formik.values.dataSourceConfig, multiDevices]);

  useEffect(() => {
    if (deviceData && deviceData.length > 0) {
      dispatch(getDeviceAttributes({ deviceIds: deviceData })).then((actionResult) => {
        if (getDeviceAttributes.fulfilled.match(actionResult)) {
          const attributes = actionResult.payload.results;

          const updatedKeyOptions = attributes.map((attribute) => ({
            label: attribute.key,
            value: attribute.key,
          }));

          setKeyOptions([...deviceAvailablePropertieSources, ...updatedKeyOptions]);
        }
      });
    }
  }, [deviceData, dispatch]);

  const handleRemoveKey = (index) => {
    const newDeviceKeys = formik.values.dataSourceConfig.deviceKeys.filter((_, i) => i !== index);
    formik.setFieldValue("dataSourceConfig.deviceKeys", newDeviceKeys);
  };

  function handleAddKey() {
    const newKeys = [...formik.values.dataSourceConfig.deviceKeys, ""];
    formik.setFieldValue("dataSourceConfig.deviceKeys", newKeys);
  }

  const handleChangeKey = (index, option) => {
    // Update the specific key value in the array based on the selected option
    const newKeys = formik.values.dataSourceConfig.deviceKeys.map((k, i) => (i === index ? option?.value : k));
    formik.setFieldValue("dataSourceConfig.deviceKeys", newKeys);
  };

  return (
    <React.Fragment>
      <FormGroup>
        <h4 className="mb-3">Data Keys *</h4>
        <Table className="align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th>Key</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.dataSourceConfig.deviceKeys.map((key, index) => (
              <tr key={index}>
                <td>
                  <CreatableSelect
                    value={keyOptions.find((option) => option.value === key)}
                    onChange={(option) => handleChangeKey(index, option)}
                    options={keyOptions}
                    placeholder="Create or select a key"
                    isClearable
                  />
                  {formik.touched.dataSourceConfig?.deviceKeys &&
                    formik.touched.dataSourceConfig?.deviceKeys[index] &&
                    formik.errors.dataSourceConfig?.deviceKeys &&
                    formik.errors.dataSourceConfig?.deviceKeys[index] && (
                      <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
                        {formik.errors.dataSourceConfig?.deviceKeys[index]}
                      </div>
                    )}
                </td>
                <td>
                  <Button className="btn-soft-danger" onClick={() => handleRemoveKey(index)}>
                    <i className="mdi mdi-delete"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {formik.touched.dataSourceConfig?.deviceKeys &&
          formik.errors.dataSourceConfig?.deviceKeys &&
          typeof formik.errors.dataSourceConfig?.deviceKeys === "string" && (
            <div
              className="ms-1 mt-1 d-flex justify-content-center"
              style={{ fontSize: "0.8125rem", color: "#f3846d" }}
            >
              {formik.errors.dataSourceConfig.deviceKeys}
            </div>
          )}
        <div className="d-flex justify-content-center">
          <Button color="info" className="mt-3" onClick={handleAddKey}>
            Add Key
          </Button>
        </div>
      </FormGroup>
    </React.Fragment>
  );
}
