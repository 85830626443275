import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setMode, resetDashboardDetailData } from "@/features/dashboards/slice";
import { updateDashboard } from "@/features/dashboards/thunk";
import Swal from "sweetalert2";
import { extractErrorMessage } from '@/common/utils/stringUtils';

export function SaveButtonSet({ dashboardId, show }) {
  if (!show) return null;

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const layoutConfig = useSelector((state) => state.dashboards.layoutConfig);
  const widgets = useSelector((state) => state.dashboards.widgets);
  const title = useSelector((state) => state.dashboards.dashboardDetailData.title);
  const description = useSelector((state) => state.dashboards.dashboardDetailData.description);

  function cancelEdit() {
    dispatch(resetDashboardDetailData());
    dispatch(setMode("view"));
  }

  function saveDashboard() {
    const data = {
      user: userData?.id || "",
      title,
      description,
      widgets,
      layout_configuration: layoutConfig,
    };

    dispatch(updateDashboard({dashboardId, data}))
    .then((actionResult) => {
      if (updateDashboard.fulfilled.match(actionResult)) {
        dispatch(setMode("view"));
      }
      else{
        const apiError = actionResult.payload;
        if (apiError && apiError.data && apiError.data.error_message) {
          const errorMessage = apiError.data.error_message;
          Swal.fire({
            icon: "error",
            title: "Save Failed!",
            text: extractErrorMessage(errorMessage),
          });
        }
      }
    });
  }

  function updateMode(newMode) {
    dispatch(setMode(newMode));
  }

  return (
    <ButtonGroup className="mt-0 mt-sm-3 mt-md-0 mt-lg-3 mt-xl-0">
      <Button
        size="lg"
        color="dark"
        outline
        className="me-3 btn"
        onClick={() => cancelEdit()}
      >
        <i className="mdi mdi-close"></i><span className="d-none d-lg-inline-block">Cancel</span>
      </Button>
      <Button size="lg" color="success" onClick={saveDashboard}>
        <i className="mdi mdi-check fs-18"></i><span className="d-none d-lg-inline-block">Save</span>
      </Button>
    </ButtonGroup>
  );
}
