import { useState, useCallback } from 'react';

export function useModals(initialValues = {}) {
  const [modals, setModals] = useState(initialValues);
  const [modalData, setModalData] = useState({});

  // Adjusted to optionally accept data for the modal being toggled
  const toggleModal = useCallback((modalName, data = null) => {
    setModals(prev => ({ ...prev, [modalName]: !prev[modalName] }));
    if (data !== null) {
      setModalData(prev => ({ ...prev, [modalName]: data }));
    }
  }, []);

  // Adjusted to optionally accept data for the modal being shown
  const showModal = useCallback((modalName, data = null) => {
    setModals(prev => ({ ...prev, [modalName]: true }));
    if (data !== null) {
      setModalData(prev => ({ ...prev, [modalName]: data }));
    }
  }, []);

  const hideModal = useCallback((modalName) => {
    setModals(prev => ({ ...prev, [modalName]: false }));
    setModalData(prev => ({ ...prev, [modalName]: undefined })); // Clears any associated data when modal is hidden
  }, []);

  const resetModals = useCallback(() => {
    const resetState = Object.keys(modals).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setModals(resetState);
    setModalData({}); // Resets all modal data
  }, [modals]);

  // Returning modalData and extended functions to interact with it
  return [ modals, modalData, toggleModal, showModal, hideModal, resetModals ];
};
