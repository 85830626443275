import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { getAlarmsByDevices } from "@/features/alarms/thunk";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useModals } from "@/hooks/useModals";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { ShowDetailsModal } from "@/components/modals/ShowDetailsModal";

export function DeviceAlarmsTable({ deviceId }) {
  if (!deviceId)  return null;
  
  const dispatch = useDispatch();
  const [alarmsList, setAlarmsList] = useState([]);
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const [totalPages, setTotalPages] = useState(0);
  const [totalDataLength, setTotalDataLength] = useState(0);
  const [modals, modalData, toggleModal] = useModals({showDetailModal: false});

  useEffect(() => {
    dispatch(getAlarmsByDevices({ deviceIds: [deviceId] })).then((actionResult) => {
      if (getAlarmsByDevices.fulfilled.match(actionResult)) {
        const results = actionResult.payload;
        setAlarmsList(results.results);
        setTotalPages(results.total_pages);
        setTotalDataLength(results.total_count);
      }
    });
  }, [deviceId]);

  const serverityTextColors = {
    critical: "bg-danger-subtle text-danger",
    major: "bg-warning-subtle text-warning",
    minor: "bg-info-subtle text-info",
    warning: "bg-light-subtle text-body",
    indeterminate: "bg-light-subtle text-body",
    default: "bg-light-subtle text-body",
  };

  function refreshData() {
    resetPageAndSearch();
  }

  const columns = useMemo(
    () => [
      {
        header: "Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return ["active", "unacknowledged"].includes(cell.getValue()) ? (
            <Badge color={cell.getValue() === "active" ? "danger" : "info"} className="text-uppercase">
              {cell.getValue()}
            </Badge>
          ) : (
            <span className="text-uppercase">{cell.getValue()}</span>
          );
        },
      },
      {
        header: "Severity",
        accessorKey: "severity",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <span
              className={`badge round-pill text-uppercase ${
                serverityTextColors[cell.getValue()] || serverityTextColors.default
              }`}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Assigned to",
        accessorKey: "assigned_to",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Details",
        accessorKey: "details",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <Button
              color="dark"
              outline
              className="btn btn-ghost-dark"
              onClick={() => toggleModal("showDetailModal", { detailData: cell.getValue() })}
            >
              <i className="ri-eye-fill"></i>
            </Button>
          );
        },
      },
    ],
    [alarmsList]
  );

  return (
    <React.Fragment>
      <Card className="border card-height-100">
        <CardHeader className="d-flex justify-content-between">
          <Col md="2" className="d-flex justify-content-begin">
            <Button className="btn-ghost-dark shadow-none me-2 border fs-5" onClick={refreshData}>
              <i className="ri-refresh-line"></i>
            </Button>
          </Col>
          <Col md="6">
            <div className="search-box ms-2">
              <input
                type="text"
                className="form-control search"
                placeholder="Search Alarms"
                onChange={handleSearch}
                value={search}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </Col>
        </CardHeader>
        <CardBody>
          <DynamicDataTable
            columns={columns}
            data={alarmsList}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalDataLength={totalDataLength}
            dataName="alarm"
          />
        </CardBody>
      </Card>
      <ShowDetailsModal
        showModal={modals.showDetailModal}
        toggleModal={() => toggleModal("showDetailModal")}
        title="Alarms Details"
        data={modalData.showDetailModal?.detailData}
      />
    </React.Fragment>
  );
}
