import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import Select from "react-select";

export function BasicInfoTab({ formik }) {
  return (
    <React.Fragment>
      <FormGroup>
        <Label for="name">Notify Template Name *</Label>
        <Input
          type="text"
          name="name"
          id="name"
          placeholder="Notify Template Name"
          onChange={formik.handleChange}
          value={formik.values.name}
          invalid={formik.touched.name && !!formik.errors.name}
          required
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="invalid-feedback">{formik.errors.name}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="description">Description</Label>
        <Input
          type="textarea"
          name="description"
          id="description"
          rows="5"
          placeholder="Notify Template Description"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">Type</Label>
        <select
          className="form-select"
          value={formik.values.type}
          name="type"
          onChange={formik.handleChange}
        >
          <option value="general">General</option>
          <option value="devices">Devices Acitivity</option>
          <option value="alarms">Alarms Activity</option>
          <option value="ruleEngineEvent">Rules Engine Event</option>
        </select>
      </FormGroup>
      <FormGroup>
        <Label>Delivery Method *</Label>
        {formik.errors.deliveryMethodSelected && (
          <div className="text-danger mb-2">
            {formik.errors.deliveryMethodSelected}
          </div>
        )}
        <Row>
          <Col md="4">
            <div className="form-check form-switch form-switch-lg" dir="ltr">
              <Input
                type="checkbox"
                className="form-check-input"
                id="webSwitch"
                name="webSwitch"
                onChange={formik.handleChange}
                checked={formik.values.webSwitch}
              />
              <Label className="form-check-label" htmlFor="webSwitch">
                Web
              </Label>
            </div>
          </Col>
          <Col md="4">
            <div className="form-check form-switch form-switch-lg" dir="ltr">
              <Input
                type="checkbox"
                className="form-check-input"
                id="emailSwitch"
                name="emailSwitch"
                onChange={formik.handleChange}
                checked={formik.values.emailSwitch}
              />
              <Label className="form-check-label" htmlFor="emailSwitch">
                Email
              </Label>
            </div>
          </Col>
          <Col md="4">
            <div className="form-check form-switch form-switch-lg" dir="ltr">
              <Input
                type="checkbox"
                className="form-check-input"
                id="smsSwitch"
                name="smsSwitch"
                onChange={formik.handleChange}
                checked={formik.values.smsSwitch}
              />
              <Label className="form-check-label" htmlFor="smsSwitch">
                SMS
              </Label>
            </div>
          </Col>
        </Row>
      </FormGroup>
    </React.Fragment>
  );
}
