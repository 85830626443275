
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getColorMode } from "@/features/layout/layoutService";
import { getUserProfile } from "@/features/auth/authService";
import { updateUserInfo } from "@/features/auth/login/slice";
import { changeColorMode } from "@/features/layout/slice";
import { initLayoutSettings } from "@/features/layout/layoutInit";

import publicRoutes from "@/routes/publicRoutes";
import privateRoutes from "@/routes/privateRoutes";
import AuthGuard from "@/features/auth/AuthGuard";
import PlatformLayout from "@/components/layouts/PlatformLayout";
import { Navigate } from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  ...publicRoutes,
  {
    element: <AuthGuard />,
    children: [
      {
        element: <PlatformLayout />,
        children: privateRoutes,
      },
    ],
  },
  { path: "/", element: <Navigate to="/overview" replace /> },
  { path: "*", element: <Navigate to="/404" replace /> },
]);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const user = getUserProfile();
    const colorMode = getColorMode() || "light";

    dispatch(changeColorMode(colorMode));

    if (user) {
      dispatch(updateUserInfo(user));
    }

    initLayoutSettings();
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
