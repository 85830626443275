import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";

import Select from "react-select";

export function AlarmsRuleSettingTab({ formik, active=false }) {
  if (!active) return null;
  const [configurations, setConfigurations] = useState({
    type: "alarm",
    alarmSeverity: [],
    notifyOn: [{ value: "created", label: "Alarm Created" }],
  });

  function updateConfig(key, value) {
    setConfigurations((prev) => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    formik.setFieldValue("configurations", configurations);
  }, [configurations]);

  return (
    <React.Fragment>
      <FormGroup>
        <Label for="type">Alarm Severity</Label>
        <Select
          value={configurations.alarmSeverity}
          isMulti={true}
          isClearable={true}
          onChange={(e) => updateConfig("alarmSeverity", e)}
          placeholder="If no severity is selected, the rule will be applied to all severity."
          options={[
            { value: "critical", label: "Critical" },
            { value: "major", label: "Major" },
            { value: "minor", label: "Minor" },
            { value: "warning", label: "Warning" },
            { value: "indeterminate", label: "Indeterminate" },
            { value: "cleared", label: "Cleared" },
          ]}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">Notify on *</Label>
        <Select
          value={configurations.notifyOn}
          isMulti={true}
          isClearable={true}
          onChange={(e) => updateConfig("notifyOn", e)}
          options={[
            { value: "created", label: "Alarm Created" },
            { value: "severityChanged", label: "Alarm Severity Changed" },
            { value: "acknowledged", label: "Alarm Acknowledged" },
            { value: "cleared", label: "Alarm Cleared" },
          ]}
        />
      </FormGroup>
    </React.Fragment>
  );
}
