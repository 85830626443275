import classnames from "classnames";
import { NavItem, NavLink } from "reactstrap";

export function MenuNavItem({
  title,
  stepIndex,
  tabIndex,
  activeMenuTab,
  toggleMenuTab,
  verified=false,
  isVisible=false,
}) {
  if (!isVisible) {
    return null;
  }
  return (
    <NavItem>
      <NavLink
        href="#"
        className={classnames({
          active: activeMenuTab === tabIndex,
          done: verified,
          unverified: !verified && activeMenuTab != tabIndex,
        })}
        onClick={() => {
          toggleMenuTab(tabIndex);
        }}
        
      >
        <span className="step-title me-2">
          <i className="ri-close-circle-fill step-icon me-2"></i>
          Step {stepIndex}
        </span>
        {title}
      </NavLink>
    </NavItem>
  );
}
