import { useState, useCallback } from "react";

export function useTableSelectionControl(initialIds = []) {
  const [selectedIds, setSelectedIds] = useState(initialIds);

  const handleSelectedAll = useCallback((event, allIds) => {
    setSelectedIds(event.target.checked ? allIds : []);
  }, []);

  const handleSelectedSingle = useCallback((event, id) => {
    event.stopPropagation();
    event.preventDefault();

    const isChecked = event.target.checked;
    setSelectedIds((prev) =>
      isChecked ? [...prev, id] : prev.filter((prevId) => prevId !== id)
    );
  }, []);

  const resetSelectedIds = useCallback(() => {
    setSelectedIds([]);
  }, []);

  return [selectedIds, handleSelectedAll, handleSelectedSingle, resetSelectedIds];
}