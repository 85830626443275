

const UserGroups = () => {
    return (
        <div>
        <h1>User Groups</h1>
        </div>
    );
};

export default UserGroups;