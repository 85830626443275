import React, { useState, useRef, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export function ShowDescriptionModal({ showModal, toggleModal, title, description }) {
    const timerRef = useRef(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setCopied(false);
    }, [showModal]);

    function copyDescription() {
        setCopied(true);
        navigator.clipboard.writeText(description);
        // Clear the old timer
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        // Set a new timer
        timerRef.current = setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    return (
        <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
            <ModalHeader toggle={toggleModal}>
                {title}
            </ModalHeader>
            <ModalBody>
                <textarea className="form-control" rows="5" readOnly value={description}></textarea>
            </ModalBody>
            <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                    <button type="button" className={`btn btn-light`} onClick={copyDescription}>
                        <span>{copied ? "Copied" : "Copy Content"}</span>
                    </button>
                    <button type="button" className="btn btn-light" onClick={toggleModal}>
                        <span>Close</span>
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
}