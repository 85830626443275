import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState, useCallback } from "react";
import ReactFlow, { Panel, MiniMap, addEdge, Controls, Background, useNodesState, useEdgesState } from "reactflow";
import isEqual from "lodash/isEqual";

import { BaseRuleNode } from "@/features/rulesEngine/components/ruleNodes/BaseRuleNode";
import { SaveToolBar } from "@/features/rulesEngine/components/SaveToolBar";
import { updateRuleChainConfiguration } from "@/features/rulesEngine/thunk";
import Swal from "sweetalert2";
import { extractErrorMessage } from "@/common/utils/stringUtils";
import { BaseRuleEdge } from "@/features/rulesEngine/components/ruleEdges/BaseRuleEdge";
import { RuleNodeProvider } from "@/features/rulesEngine/components/RuleNodeContext";
import { MessageInputNode } from "@/features/rulesEngine/components/ruleNodes/MessageInputNode";
import { setIn } from "formik";

const nodeTypes = {
  ruleNode: BaseRuleNode,
  messageInput: MessageInputNode,
};

const edgeTypes = {
  custom: BaseRuleEdge,
};

function nodesEdgesChanged(initialNodes, currentNodes, initialEdges, currentEdges) {
  initialNodes = initialNodes || [];
  currentNodes = currentNodes || [];
  initialEdges = initialEdges || [];
  currentEdges = currentEdges || [];
  if (initialNodes.length !== currentNodes.length || initialEdges.length !== currentEdges.length) {
    return true;
  }

  for (let i = 0; i < currentNodes.length; i++) {
    const currentNode = currentNodes[i];
    const initialNode = initialNodes.find((n) => n.id === currentNode.id);

    // compare key attributes of node, such as type, data, position
    if (
      !initialNode ||
      initialNode.type !== currentNode.type ||
      !isEqual(initialNode.data, currentNode.data) ||
      initialNode.position.x !== currentNode.position.x ||
      initialNode.position.y !== currentNode.position.y
    ) {
      return true;
    }
  }

  for (let i = 0; i < currentEdges.length; i++) {
    const currentEdge = currentEdges[i];
    const initialEdge = initialEdges.find((e) => e.id === currentEdge.id);

    // compare key attributes of edge, such as source and target
    if (!initialEdge || initialEdge.source !== currentEdge.source || initialEdge.target !== currentEdge.target) {
      return true;
    }
  }

  return false;
}

export function RuleEditArea({ ruleData }) {
  const dispatch = useDispatch();

  const [initialNodes, setInitialNodes] = useState([
    {
      id: "messgaeInput",
      position: { x: 200, y: window.innerHeight / 2 - 150 },
      type: "messageInput",
      draggable: false,
      height: 57,
      width: 254,
    },
  ]);

  const [initialEdges, setInitialEdges] = useState([]);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [ruleChainChange, setRuleChainChange] = useState(false);

  useEffect(() => {
    setInitialNodes(
      ruleData.configuration?.nodes || [
        {
          id: "messgaeInput",
          position: { x: 200, y: window.innerHeight / 2 - 150 },
          type: "messageInput",
          draggable: false,
          height: 57,
          width: 254,
        },
      ]
    );
    setInitialEdges(ruleData.configuration?.edges || []);
    setNodes(
      ruleData.configuration?.nodes || [
        {
          id: "messgaeInput",
          position: { x: 200, y: window.innerHeight / 2 - 150 },
          type: "messageInput",
          draggable: false,
          height: 57,
          width: 254,
        },
      ]
    );
    setEdges(ruleData.configuration?.edges || []);
  }, [ruleData]);

  const onConnect = useCallback(
    (params) => {
      const sourceNode = nodes.find((node) => node.id === params.source);
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onEditNode = useCallback((nodeId) => {
    
  }, []);

  const onDeleteNode = useCallback((nodeId) => {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    setEdges((eds) => eds.filter((edge) => edge.source !== nodeId));
    setRuleChainChange(true);
  }, []);

  const nodeCallbacks = {
    onDelete: onDeleteNode,
    onEdit: onEditNode,
  };

  const onSave = () => {
    const ruleChain = {
      nodes: [],
      edges: [],
    };
    nodes.forEach((node) => {
      ruleChain.nodes.push({
        id: node.id,
        type: node.type,
        position: node.position,
        data: node.data,
      });
    });
    edges.forEach((edge) => {
      ruleChain.edges.push({
        id: edge.id,
        source: edge.source,
        target: edge.target,
      });
    });

    dispatch(updateRuleChainConfiguration({ ruleId: ruleData.id, data: ruleChain })).then((actionResult) => {
      if (updateRuleChainConfiguration.fulfilled.match(actionResult)) {
        setInitialNodes(nodes);
        setInitialEdges(edges);
        setRuleChainChange(false);
        Swal.fire({
          icon: "success",
          title: "Saved!",
          text: "Rule chain configuration saved successfully.",
        });
      } else {
        const apiError = actionResult.payload;
        if (apiError && apiError.data && apiError.data.error_message) {
          const errorMessage = apiError.data.error_message;
          Swal.fire({
            icon: "error",
            title: "Save Failed!",
            text: extractErrorMessage(errorMessage),
          });
        }
      }
    });
  };

  const onCancelChanges = () => {
    setNodes(initialNodes);
    setEdges(initialEdges);
    setRuleChainChange(false);
  };

  useEffect(() => {
    if (nodesEdgesChanged(initialNodes, nodes, initialEdges, edges)) {
      setRuleChainChange(true);
    } else {
      setRuleChainChange(false);
    }
  }, [nodes, edges, initialNodes, initialEdges]);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const data = JSON.parse(event.dataTransfer.getData("application/reactflow"));
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: Date.now().toString(),
        type: "ruleNode",
        position,
        data: data,
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  return (
    <React.Fragment>
      <div className="rules-editarea border">
        <RuleNodeProvider value={nodeCallbacks}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            deleteKeyCode={["Backspace", "Delete"]}
            defaultViewport={{ x: 0, y: 0, zoom: window.innerWidth < 768 ? 0.8 : 1 }}
          >
            <Background />
            <Controls />
            <Panel position="top-right">
              <SaveToolBar disabled={!ruleChainChange} onSave={onSave} onCancel={onCancelChanges} />
            </Panel>
            {/* <MiniMap zoomable pannable /> */}
          </ReactFlow>
        </RuleNodeProvider>
      </div>
    </React.Fragment>
  );
}
