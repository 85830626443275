import React from "react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Modal, Row, Col, ModalBody, Button } from "reactstrap";
import { notifyUpdate } from "@/features/auth/accountSettings/thunk";
import inllustrator from "@/assets/images/illustration/updating.svg";

export function UpdatePlanModal({showModal, toggleModal}) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [notified, setNotified] = useState(false);

  useEffect(() => {
    setNotified(false);
    setErrors(null);
}, [showModal]);

  function onNotifyUpdate() {
    dispatch(notifyUpdate())
    .then((actionResult) => {
      if (notifyUpdate.fulfilled.match(actionResult)) {
        setNotified(true);
      }
      else{
        const apiError = actionResult.payload;
        if (apiError && apiError.data && apiError.data.error_message) {
            const errorMessage = apiError.data.error_message;
            setErrors(errorMessage);
        }
      }
    })
  }

  return (
    <React.Fragment>
      <Modal
        id="updatePlanModal"
        tabIndex="-1"
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName='zoomIn'
        centered
        size="lg"
      >
        <Row className="g-0">
          <Col lg={7}>
            <ModalBody className="p-5">
              <h2 className="lh-base">Future <span className="text-secondary">Expansions</span> Ahead</h2>
              <p className="text-muted">
                Our commitment to innovation is unwavering. We are on the brink of enhancing our platform with more features and higher capacity. 
              </p>
              <p className="text-muted">Currently, due to computational constraints, there is a limit of 10 devices and rule chains per user. This is a temporary measure to ensure quality service for all our users. </p>
              <p className="text-secondary">Exciting updates, including increased capacity and new functionalities, are coming soon.</p>
              <Button color="secondary" className="w-100" onClick={onNotifyUpdate}>{notified ? ['Subscribed', <i className="ri-check-line ms-2"></i>] : "Notify Me"}</Button>
              {errors && <div className="alert alert-danger mt-3" role="alert">{errors}</div>}
            </ModalBody>
          </Col>
          <Col lg={5}>
            <div className="subscribe-modals-cover h-100 py-3 d-none d-lg-flex">
              <img
                src={inllustrator}
                alt=""
                className="h-100 object-fit-cover"
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
}
