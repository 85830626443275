import React from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const navigate = useNavigate();

  const menuItems = [
    {
      label: "statistics",
      isHeader: true,
    },
    {
      id: "overview",
      label: "Overview",
      icon: "mdi mdi-speedometer",
      link: "/overview",
      click: function (e) {
        e.preventDefault();
        navigate("/overview");
      },
    },
    {
      label: "management",
      isHeader: true,
    },
    {
      id: "devices",
      label: "Devices",
      icon: "mdi mdi-devices",
      link: "/devices",
      click: function (e) {
        e.preventDefault();
        navigate("/devices");
      },
    },
    // {
    //   id: "devicesProfile",
    //   label: "Device Profiles",
    //   icon: "mdi mdi-text-box-plus-outline",
    //   link: "/device-profile",
    //   click: function (e) {
    //     e.preventDefault();
    //     navigate("/device-profile");
    //   },
    // },
    {
      id: "dashboards",
      label: "Dashboards",
      icon: "mdi mdi-monitor-dashboard",
      link: "/dashboards",
      click: function (e) {
        e.preventDefault();
        navigate("/dashboards");
      },
    },
    {
      id: "rulesEngine",
      label: "Rules Engine",
      icon: "mdi mdi-lan",
      link: "/rules-engine",
      click: function (e) {
        e.preventDefault();
        navigate("/rules-engine");
      },
    },
    {
      id: "resourcesUsage",
      label: "Resources Usage",
      icon: "mdi mdi-chart-box",
      link: "/resources-usage",
      click: function (e) {
        e.preventDefault();
        navigate("/resources-usage");
      },
    },
    {
      label: "messages",
      isHeader: true,
    },
    {
      id: "alarms",
      label: "Alarms",
      icon: "mdi mdi-cloud-alert",
      link: "/alarms",
      click: function (e) {
        e.preventDefault();
        navigate("/alarms");
      },
    },
    {
      id: "notifyHub",
      label: "Notify Hub",
      icon: "mdi mdi-message-arrow-right",
      link: "/notify-hub",
      click: function (e) {
        e.preventDefault();
        navigate("/notify-hub");
      },
    },
    {
      id: "notifications",
      label: "Notifications",
      icon: "mdi mdi-bell",
      link: "/notifications",
      click: function (e) {
        e.preventDefault();
        navigate("/notifications");
      },
    },
    // {
    //   id: "intergationCenter",
    //   label: "Intergration Center",
    //   icon: "mdi mdi-cloud-sync",
    //   link: "/intergation-center",
    //   click: function (e) {
    //     e.preventDefault();
    //     navigate("/intergation-center");
    //   },
    // },
    {
      label: "security",
      isHeader: true,
    },
    {
      id: "auditLogs",
      label: "Audit Logs",
      icon: "mdi mdi-file-lock",
      link: "/audit-logs",
      click: function (e) {
        e.preventDefault();
        navigate("/audit-logs");
      },
    },
    {
      id: "settings",
      label: "Settings",
      icon: "mdi mdi-cog",
      link: "/settings",
      click: function (e) {
        e.preventDefault();
        navigate("/settings");
      },
    },
    {
      label: "support",
      isHeader: true,
    },
    {
      id: "documentation",
      label: "Documentation",
      icon: "mdi mdi-file-document",
      link: "https://docs.linkocloud.com/",
      click: function (e) {
        e.preventDefault();
        window.open('https://docs.linkocloud.com/', '_blank');
      },
    },
    {
      id: "help",
      label: "Help",
      icon: "mdi mdi-help-circle",
      link: "https://linkocloud.freshdesk.com",
      click: function (e) {
        e.preventDefault();
        window.open('https://linkocloud.freshdesk.com', '_blank');
      },
    },
    {
      id: "contact",
      label: "Contact",
      icon: "mdi mdi-card-account-mail",
      link: "mailto:support@linkocloud.com",
      click: function (e) {
        e.preventDefault();
        window.open('mailto:support@linkocloud.com', '_blank');
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
