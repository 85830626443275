import React, { useEffect } from "react";
import { useState } from "react";
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, UncontrolledAlert, Button } from "reactstrap";
import { RuleNodeDisplay } from "@/features/rulesEngine/components/RuleNodeDisplay";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import { getRuleNodeType } from "@/features/rulesEngine/thunk";

export function RuleNodeSelect() {
  const dispatch = useDispatch();
  const [openGroups, setOpenGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    dispatch(getRuleNodeType());
  }, []);

  const ruleNodeTypes = useSelector((state) => state.rulesEngine.ruleNodeTypes);

  useEffect(() => {
    if (ruleNodeTypes.length > 0) {
      setOpenGroups(ruleNodeTypes.map((group, _) => group.id.toString()));
    }
  }, [ruleNodeTypes]);

  const toggleGroup = (item) => {
    const isOpen = openGroups.includes(item);
    if (isOpen) {
      setOpenGroups(openGroups.filter((i) => i !== item));
    } else {
      setOpenGroups([...openGroups, item]);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value) {
      const matchedGroupIds = ruleNodeTypes
        .filter((group) => group.nodes.some((node) => node.title.toLowerCase().includes(value)))
        .map((group) => group.id.toString());

      setOpenGroups(matchedGroupIds);
    } else {
      setOpenGroups(ruleNodeTypes.map((group) => group.id.toString()));
    }
  };

  const filteredGroups = searchTerm
    ? ruleNodeTypes
        .map((group) => ({
          ...group,
          nodes: group.nodes.filter((node) => node.title.toLowerCase().includes(searchTerm.toLowerCase())),
        }))
        .filter((group) => group.nodes.length > 0)
    : ruleNodeTypes;

  return (
    <React.Fragment>
      <div>
        <button color="secondary" className={`collapse-button ${isSidebarOpen ? "open" : "close"}`} onClick={() => setSidebarOpen(!isSidebarOpen)}>
          {isSidebarOpen ? "<" : ">"}
        </button>
        <div className={`rules-leftsidebar border ${isSidebarOpen ? "" : "collapsed"}`}>
          <UncontrolledAlert className="shadow" color="success">
            More <strong>Rule Node</strong> will be add on soon!
          </UncontrolledAlert>
          <div className="px-2 mb-4 mt-3">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h5 className="mb-4 px-2">Rule Node</h5>
              </div>
            </div>
            <div className="search-box border">
              <input
                id="search-user"
                type="text"
                className="form-control bg-light border-light"
                placeholder="Search Rule Nodes"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <i className="ri-search-2-line search-icon"></i>
            </div>
          </div>
          <div className="px-2">
            <SimpleBar className="simplebar-track-primary" style={{ maxHeight: "958px" }}>
              <Accordion open={openGroups} toggle={(group) => toggleGroup(group)}>
                {filteredGroups.map((group, index) => (
                  <AccordionItem key={group.id}>
                    <AccordionHeader targetId={group.id.toString()}>
                      <i className={group.icon + " me-2 fs-20"}></i> {group.name}
                    </AccordionHeader>
                    <AccordionBody accordionId={group.id.toString()}>
                      <ul className="list-unstyled">
                        {group.nodes.map((node) => (
                          <li key={node.id} className="rule-node-item mb-3">
                            <RuleNodeDisplay data={node} />
                          </li>
                        ))}
                      </ul>
                    </AccordionBody>
                  </AccordionItem>
                ))}
              </Accordion>
            </SimpleBar>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
