import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import AuthenticationLayout from '@/components/layouts/AuthenticationLayout';
import { requestResetPassword } from '@/features/auth/resetPassword/thunk';
import OauthLoginButtonsSet from '@/features/auth/oauth/components/OauthLoginButtonsSet';


const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const apiError = useSelector(state => state.requestResetPassword.error);
  const requestStatus = useSelector(state => state.requestResetPassword.status);

  useEffect(() => {
      if (apiError && apiError.data && apiError.data.error_message) {
          const errorMessage = apiError.data.error_message;
          if (errorMessage.email) {
              setErrorMessage(errorMessage.email);
          }
          else{
              setErrorMessage(errorMessage);
          }
      }
  }, [apiError]);

  useEffect(() => {
      setErrorMessage(null);
  }, [location]);

  useEffect(() => {
      if (requestStatus === 'success') {
        console.log("@@@", email)
          navigate('/sent-reset-password-email', { state: { email: email} });
      }
  }, [requestStatus]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(requestResetPassword(email));
  }; 

  return (
    <AuthenticationLayout title="Don't worry," subTitle=" we are here help you to recover your password." withMask={false}>
      <Row className="g-0">
        <Col className="col-11 col-md-8 col-lg-7 col-xl-6 mx-auto">
          <p className="text-2 text-white">Return to <Link to="/login" className="fw-500">Login</Link></p>
          <h3 className="text-white mb-4">Forgot password?</h3>
          <p className="text-white-50 mb-4">Enter the email address associated with your account.</p>
          <Form className="form-dark" onSubmit={handleSubmit}>
            {errorMessage && <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
            {errorMessage}
            </div>}
            <FormGroup className="mb-3">
              <Label className="form-label text-white" htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                placeholder='Enter Your Email'
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>
            <Button color="primary" mt="2" type='submit'>Continue</Button>
          </Form>
          <div className="d-flex align-items-center my-4">
              <hr className="col-1 border-secondary" />
              <span className="mx-3 text-2 text-white-50">OR</span>
              <hr className="flex-grow-1 border-secondary" />
          </div>
          <OauthLoginButtonsSet />
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default ForgotPassword;
