import { createSlice } from "@reduxjs/toolkit";
import { setColorMode } from "@/features/layout/layoutService";

export const initialState = {
    colorMode:  window?.innerWidth < 768 ? 'light' : 'dark'
};

const LayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        changeColorMode(state, action) {
            state.colorMode = action.payload;
            setColorMode(action.payload);
        }
    }
});

export default LayoutSlice.reducer;
export const { changeColorMode } = LayoutSlice.actions;