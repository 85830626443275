import React from "react";
import { useModals } from "@/hooks/useModals";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { Paginator } from "@/components/tables/Pagination";
import { getAlarmsByDevices } from "@/features/alarms/thunk";
import { Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { ShowDetailsModal } from "@/components/modals/ShowDetailsModal";

const serverityTextColors = {
  critical: "bg-danger-subtle text-danger",
  major: "bg-warning-subtle text-warning",
  minor: "bg-info-subtle text-info",
  warning: "bg-light-subtle text-body",
  indeterminate: "bg-light-subtle text-body",
  default: "bg-light-subtle text-body",
};

export function AlarmsTable({ widget }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [alarmsData, setAlarmsData] = useState([]);
  const [totalDataLength, setTotalDataLength] = useState(0);
  const { page, handlePageChange } = usePaginationAndSearch();
  const [totalPages, setTotalPages] = useState(0);

  const [modals, modalData, toggleModal] = useModals({
    showDetailModal: false,
  });

  const devices = widget.dataSourceConfig.devices;
  useEffect(() => {
    dispatch(getAlarmsByDevices({ deviceIds: devices, page, pageSize: 6 })).then((actionResult) => {
      if (getAlarmsByDevices.fulfilled.match(actionResult)) {
        const alarmsResults = actionResult.payload;
        if (alarmsResults && alarmsResults.results && alarmsResults.results.length > 0) {
          setAlarmsData(alarmsResults.results);
          setTotalPages(alarmsResults.total_pages);
          setTotalDataLength(alarmsResults.total_count);
        }
      } else {
        const apiError = actionResult.payload;
        if (apiError && apiError.data && apiError.data.error_message) {
          setErrors("Failed to get alarms data");
        }
      }
    });
  }, [devices, page]);

  const defaultColumns = useMemo(
    () => [
      {
        header: "Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return ["active", "unacknowledged"].includes(cell.getValue()) ? (
            <Badge color={cell.getValue() === "active" ? "danger" : "info"} className="text-uppercase">
              {cell.getValue()}
            </Badge>
          ) : (
            <span className="text-uppercase">{cell.getValue()}</span>
          );
        },
      },
      {
        header: "Device",
        accessorKey: "device_name",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <Link
              to={`/devices/${cell.row.original.device}`}
              className="link-body-emphasis link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-75-hover"
            >
              {cell.getValue()}
            </Link>
          );
        },
      },
      {
        header: "Severity",
        accessorKey: "severity",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <span
              className={`badge round-pill text-uppercase ${
                serverityTextColors[cell.getValue()] || serverityTextColors.default
              }`}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Assigned to",
        accessorKey: "assigned_to",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Details",
        accessorKey: "details",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <Button
              color="dark"
              outline
              className="btn btn-ghost-dark"
              onClick={() => toggleModal("showDetailModal", { data: cell.getValue() })}
            >
              <i className="ri-eye-fill"></i>
            </Button>
          );
        },
      },
    ],
    [alarmsData]
  );

  const columns = useMemo(() => {
    const userConfigColumns = widget.configuration.columns;
    return userConfigColumns.map((column) => {
      // Find if the user configured column exists in defaultColumns
      const defaultColumn = defaultColumns.find((dc) => dc.accessorKey === column.key);
      if (defaultColumn) {
        // If it exists, use the default column configuration
        return defaultColumn;
      } else {
        // If it doesn't exist, use a generic configuration
        return {
          header: column.label,
          accessorKey: column.key,
          enableSorting: true,
          enableColumnFilter: false,
        };
      }
    });
  }, [widget.configuration.columns, defaultColumns]);

  const filteredData = useMemo(() => {
    return alarmsData.map((device) => {
      const filteredDevice = {};
      columns.forEach(({ accessorKey }) => {
        filteredDevice[accessorKey] = device[accessorKey];
      });
      return filteredDevice;
    });
  }, [alarmsData, columns]);

  return (
    <React.Fragment>
      <Card className="h-100 w-100 border">
        <WidgetHeader widget={widget} />
        <CardBody className="d-flex flex-column justify-content-center align-items-center overflow-hidden p-0">
          <div className="h-100 w-100 d-flex flex-column">
            {errors ? (
              <div className="text-center mt-3">{errors}</div>
            ) : (
              <>
                <DynamicDataTable
                  columns={columns}
                  data={filteredData}
                  totalDataLength={totalDataLength}
                  dataName="alarm"
                  showPagination={false}
                />
                <div className="mt-auto mb-3 me-3 d-flex justify-content-end">
                  <Paginator currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
              </>
            )}
          </div>
        </CardBody>
      </Card>
      <ShowDetailsModal
        showModal={modals.showDetailModal}
        toggleModal={() => toggleModal("showDetailModal")}
        title="Alarms Details"
        data={modalData.showDetailModal?.data || []}
      />
    </React.Fragment>
  );
}
