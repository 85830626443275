import React from "react";
import { Button } from "reactstrap";

export function AddEntityButton({ text, onClick }) {
  return (
    <React.Fragment>
      <Button color="success" className="me-2" onClick={onClick}>
        <i className="ri-add-line align-bottom me-md-1"></i>
        <span className="hide-text-sm">{text}</span>
      </Button>
    </React.Fragment>
  );
}
