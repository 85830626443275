import React from "react";
import { FormGroup, Input, Button, Table } from "reactstrap";

export function DataSourceSeries({ formik, seriesLength }) {
  const fields = ["Key", "Label", "Unit"];

  const handleRemoveSeries = (index) => {
    const newSeries = formik.values.dataSourceConfig.deviceSeries.filter((s, i) => i !== index);
    formik.setFieldValue("dataSourceConfig.deviceSeries", newSeries);
  };

  function handleAddSeries() {
    const newEntry = fields.reduce((acc, field) => ({ ...acc, [field.toLowerCase()]: "" }), {});
    formik.setFieldValue("dataSourceConfig.deviceSeries", [...formik.values.dataSourceConfig.deviceSeries, newEntry]);
  }

  return (
    <React.Fragment>
      <h4 className="mb-3">Series *</h4>
      <FormGroup>
        <Table className="align-middle table-nowrap mb-0">
          <thead>
            <tr>
              {fields.map((field) => (
                <th key={field} scope="col">
                  {field}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.dataSourceConfig.deviceSeries.map((entry, index) => (
              <tr key={index}>
                {fields.map((field) => (
                  <td key={field} scope="row">
                    <Input
                      type="text"
                      name={`dataSourceConfig.deviceSeries[${index}].${field.toLowerCase()}`}
                      placeholder={field}
                      value={entry[field.toLowerCase()] || ""}
                      onChange={formik.handleChange}
                      invalid={
                        formik.touched.dataSourceConfig?.deviceSeries &&
                        formik.touched.dataSourceConfig?.deviceSeries[index] &&
                        formik.errors.dataSourceConfig?.deviceSeries &&
                        formik.errors.dataSourceConfig?.deviceSeries[index] &&
                        !!formik.errors.dataSourceConfig?.deviceSeries[index][field.toLowerCase()]
                      }
                    />
                    {formik.touched.dataSourceConfig?.deviceSeries &&
                      formik.touched.dataSourceConfig?.deviceSeries[index] &&
                      formik.errors.dataSourceConfig?.deviceSeries &&
                      formik.errors.dataSourceConfig?.deviceSeries[index] &&
                      formik.errors.dataSourceConfig?.deviceSeries[index][field.toLowerCase()] && (
                        <div className="invalid-feedback d-block">
                          {formik.errors.dataSourceConfig?.deviceSeries[index][field.toLowerCase()]}
                        </div>
                      )}
                  </td>
                ))}
                <td>
                  <Button className="btn-soft-danger" onClick={() => handleRemoveSeries(index)}>
                    <i className="mdi mdi-delete"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {formik.touched.dataSourceConfig?.deviceSeries &&
          formik.errors.dataSourceConfig?.deviceSeries &&
          typeof formik.errors.dataSourceConfig?.deviceSeries === "string" && (
            <div
              className="ms-1 mt-1 d-flex justify-content-center"
              style={{ fontSize: "0.8125rem", color: "#f3846d" }}
            >
              {formik.errors.dataSourceConfig.deviceSeries}
            </div>
          )}
        {(seriesLength && seriesLength > 0 && formik.values.dataSourceConfig.deviceSeries.length < seriesLength) && (
          <div className="d-flex justify-content-center">
            <Button color="info" className="mt-3" onClick={handleAddSeries}>
              Add Series
            </Button>
          </div>
        )}
      </FormGroup>
    </React.Fragment>
  );
}
