import React from "react";
import { LordIcon } from "@/components/common/LordIcon";
import { Modal, ModalBody } from "reactstrap";
import ICON from "@/assets/json/lord-icon/trash-bin.json";

export function DeleteMultipileModal({ showModal, toggleModal, onDelete, numberSelected, type }){
  return (
    <Modal isOpen={showModal} toggle={toggleModal} size="lg"  centered={true}>
      <ModalBody className="py-3 px-1">
        <div className="mt-2 text-center">
          <div className="d-flex justify-content-center">
            <LordIcon icon={ICON} size={100} colorize="#f06548"/>
          </div>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure you want to delete {numberSelected} {type} {numberSelected > 1 ? "s" : ""}? </h4>
            <p className="text-muted mx-4 mb-0">Please note that deletion is irreversible. Once these {type} are deleted, they cannot be retrieved or restored.</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={toggleModal}>
            Close
          </button>
          <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={onDelete}>
            Yes
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

