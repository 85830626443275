import Users from "@/pages/Users";
import Alarms from "@/pages/Alarms";
import OverView from "@/pages/overview";
import AuditLogs from "@/pages/AuditLogs";
import NotifyHub from "@/pages/NotifyHub";
import UserGroups from "@/pages/UserGroups";
import Notifications from "@/pages/Notifications";
import AccountSetting from "@/pages/AccountSetting";
import ResourcesUsage from "@/pages/ResourcesUsage";
import RulesList from "@/pages/rulesEngine/RulesList";
import RuleDetail from "@/pages/rulesEngine/RuleDetail";
import DevicesList from "@/pages/devices/DevicesList";
import DeviceDetail from "@/pages/devices/DeviceDetail";
import DeviceProfiles from "@/pages/devices/DeviceProfiles";
import IntergrationCenter  from "@/pages/IntergrationCenter";
import DashboardsList from "@/pages/dashboards/DashboardsList";
import DashboardDetail from "@/pages/dashboards/DashboardDetail";

const privateRoutes = [
    { path: "/alarms", element: <Alarms /> },
    { path: "/overview", element: <OverView /> },
    { path: "/audit-logs", element: <AuditLogs /> },
    { path: "/notify-hub", element: <NotifyHub /> },
    { path: "/settings", element: <AccountSetting /> },
    { path: "/notifications", element: <Notifications /> },
    { path: "/device-profile", element: <DeviceProfiles />},
    { path: "/resources-usage", element: <ResourcesUsage /> },
    { path: "/intergation-center", element: <IntergrationCenter />},

    { path: "/users", element: <Users /> },
    { path: "/user/groups", element: <UserGroups /> },

    { path: "/devices/:deviceId", element: <DeviceDetail />},
    { path: "/devices", element: <DevicesList />},

    { path: "/dashboards/:dashboardId", element: <DashboardDetail />},
    { path: "/dashboards", element: <DashboardsList />},

    { path: "/rules-engine/:ruleId", element: <RuleDetail /> },
    { path: "/rules-engine", element: <RulesList /> },
]


export default privateRoutes;