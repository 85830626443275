import Turnstile from "react-turnstile";
import { Link, useNavigate, useLocation  } from 'react-router-dom'; 
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

import { loginUser } from '@/features/auth/login/thunk';
import AuthenticationLayout from '@/components/layouts/AuthenticationLayout';
import OauthLoginButtonsSet from '@/features/auth/oauth/components/OauthLoginButtonsSet';


const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [token, setToken] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '', token: '', other: '', unverified: ''});
    const from = location.state?.from?.pathname || new URLSearchParams(location.search).get('redirect') || '/overview';

    const apiError = useSelector(state => state.auth.error);
    const loginStatus = useSelector(state => state.auth.status);

    useEffect(() => {
        if (apiError && apiError.data && apiError.data.error_message) {
            const errorCode = apiError.data.error_code;
            const errorMessage = apiError.data.error_message;
            
            if (errorMessage.token || errorMessage.email || errorMessage.password) {
                setErrors({
                    email: errorMessage.email || '',
                    password: errorMessage.password || '',
                    token: errorMessage.token || '',
                });
            }
            else if (errorCode === 'AUTH_001') {
                setErrors({ unverified: errorMessage });
            }
            else {
                setErrors({ other: errorMessage });
            }
        }
    }, [apiError]);

    useEffect(() => {
        setErrors({ email: '', password: '', token: '', other: '', unverified: ''});
    }, [location]);

    useEffect(() => {
        if (loginStatus === 'authenticated') {
            navigate(from);
        }
      }, [loginStatus]);

    const handleTurnstileVerify = (turnstileToken) => {
        setToken(turnstileToken);
    };

    const handleEmailInput  = (event) => {
        setEmailInput(event.target.value);
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        const password = event.target.password.value;

        dispatch(loginUser({ email, password, token }));
    };

    return (
        <AuthenticationLayout title="Welcome," subTitle=" we are glad to see you again!" withMask={true}>
            <Row className="g-0">
                <Col className="mx-auto" xs="11" md="8" lg="7" xl="6">
                <p className="text-2 text-white">
                    Not a member? <Link to="/register" className="fw-500">Register</Link>
                </p>
                <h3 className="text-white mb-4">Log In to Your Account</h3>
                <OauthLoginButtonsSet />
                <div className="d-flex align-items-center my-4">
                    <hr className="col-1 border-secondary" />
                    <span className="mx-3 text-2 text-white-50">OR</span>
                    <hr className="flex-grow-1 border-secondary" />
                </div>
                <Form className="form-dark" onSubmit={handleSubmit}>
                        {errors.unverified && <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            {errors.unverified} 
                            <Link to={`/sent-email-verification?email=${emailInput}`} className='alert-link mx-2'>Resend verification code</Link>
                        </div>}
                        {errors.other && <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            {errors.other}
                        </div>}
                    <FormGroup>
                        <Label for="email" className="text-white">Email Address</Label>
                        <Input type="email" name="email" id="email" placeholder="Enter Your Email" onChange={handleEmailInput} required/>
                        {errors.email && <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            {errors.email}
                        </div>}
                    </FormGroup>
                    <FormGroup>
                        <Label for="password" className="text-white">Password</Label>
                        <Link to="/forgot-password" className="float-end text-2">Forgot Password ?</Link>
                        <Input type="password" name="password" id="password" placeholder="Enter Password" required/>
                        {errors.password && <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            {errors.password}
                        </div>}
                    </FormGroup>
                        {errors.token && <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            Token: {errors.token}
                        </div>}
                    <Turnstile sitekey="0x4AAAAAAARA5EMX2LllUB6N" onVerify={handleTurnstileVerify}/>
                    <Button color="primary" className="my-2" type='submit'>Log in</Button>
                </Form>
                </Col>
            </Row>
        </AuthenticationLayout>
    );
}

export default Login;