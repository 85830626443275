import React from "react";
import CountUp from "react-countup";

export function DeviceStatusCard({ title, value, icon, textColor, unit }) {
  return (
    <React.Fragment>
      <div className="device-status-card">
        <div className="py-4 px-3 ">
          <h5 className="text-muted text-uppercase fs-13">
            {title}
          </h5>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${icon} display-6  ${textColor ? textColor : "text-muted"}`}></i>
            </div>
            <div className="flex-grow-1 ms-3">
              <h2 className={`mb-0 text-capitalize ${textColor}`}>{value} {unit}</h2>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
