import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { SearchBox } from "@/components/tables/headerToolSet/SearchBox";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";

export function NotifyRequestHistory() {
  const {page, search, handlePageChange, handleSearch} = usePaginationAndSearch();

  const columns = [
    {
      header: "Time",
      accessorKey: "created_time",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Type",
      accessorKey: "type",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Message",
      accessorKey: "message",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Status",
      accessorKey: "status",
      enableSorting: true,
      enableColumnFilter: false,
    },
  ];

  return (
    <Card className="card-height-100 border">
      <CardHeader>
        <Row className="d-flex justify-content-between">
          <Col md="4">
            <h4>Sent</h4>
          </Col>
          <Col md="8" className="d-flex justify-content-end">
            <SearchBox
              handleSearch={handleSearch}
              search={search}
              placeholder="Search Notification Sent Record"
              className="flex-grow-1"
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <DynamicDataTable
          columns={columns}
          data={[]}
          currentPage={page}
          totalPages={0}
          onPageChange={handlePageChange}
          totalDataLength={0}
          dataName="notification request"
          // style={{ height: '85%' }}
        />
      </CardBody>
    </Card>
  );
}
