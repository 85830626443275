import React from 'react';
import { useDispatch } from 'react-redux';
import { changeColorMode } from '@/features/layout/slice';
import { useSelector } from 'react-redux';

const SystemColorModeSwitcher = () => {
    const dispatch = useDispatch();

    const colorMode = useSelector((state) => state.layout.colorMode);

    function changeMode() {
        dispatch(changeColorMode(colorMode === 'light' ? 'dark' : 'light'));
    };

    return (
        <div className="ms-1 header-item d-flex">
            <button
                onClick={changeMode}
                type="button" 
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none">
                <i className='bx bx-moon fs-22'></i>
            </button>
        </div>
    );
};

export default SystemColorModeSwitcher;