import React from 'react';
import { PseudoHandle } from '@/features/rulesEngine/components/PseudoHandle';

export function RuleNodeDisplay({ data}) {

  const onDragStart = (event) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };
  
    return (
        <React.Fragment>
            {/* <PseudoHandle position="left" type='source' /> */}
            <div className={`rule-node ${data.color? "rule-node-" + data.color: ""}`} draggable onDragStart={onDragStart}>
                <i className={`${data.icon} rule-node-icon`}></i>
                <span className="rule-node-title">{data.title}</span>
            </div>
            {/* <PseudoHandle position="right" type='target'/> */}
        </React.Fragment>
    )
}