import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CSVLink } from "react-csv";
import { LordIcon } from "@/components/common/LordIcon";

import ICON from "@/assets/json/lord-icon/document.json";

export function ExportCSVModal({ showModal, toggleModal, data, fileName }) {
  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
      <ModalHeader toggle={toggleModal}></ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="d-flex justify-content-center">
            <LordIcon
              icon={ICON}
              mode="loop"
              colorize="#0ab39c"
              size={100}
            ></LordIcon>
          </div>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to export CSV file?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={toggleModal}
          >
            Close
          </button>
          <CSVLink
            data={data}
            filename={fileName ? `${fileName}.csv` : "export.csv"}
            type="button"
            onClick={toggleModal}
            className="btn w-sm btn-success "
            id="delete-record"
          >
            Download
          </CSVLink>
        </div>
      </ModalBody>
    </Modal>
  );
}
