// ChartComponent.js
import React, { useMemo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { getLatestTelemetryByDevice } from "@/features/devices/thunk";
import { useDispatch } from "react-redux";

export function CommonGauge({ widget }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [seriesValue, setSeriesValue] = useState([]);
  const editmode = useSelector((state) => state.dashboards.mode);
  const colorMode = useSelector((state) => state.layout.colorMode);
  const seriesConfigData = widget.dataSourceConfig.deviceSeries?.[0] ?? {};

  const { deviceId } = useMemo(() => {
    return { deviceId: widget.dataSourceConfig.device };
  }, [widget.dataSourceConfig]);

  useEffect(() => {
    dispatch(getLatestTelemetryByDevice({ deviceId, limit: 1 })).then((actionResult) => {
      if (getLatestTelemetryByDevice.fulfilled.match(actionResult)) {
        const results = actionResult.payload;
        if (results.length > 0) {
          if (results[0]?.data && seriesConfigData.key in results[0]?.data) {
            const data = results[0]?.data[seriesConfigData.key];
            setSeriesValue([data]);
          } else {
            setErrors(`Key "${seriesConfigData.key}" not found in device telemetry data.`);
          }
        }
      } else if (getLatestTelemetryByDevice.rejected.match(actionResult)) {
        setErrors("Error fetching data");
      }
    });
  }, [deviceId]);

  const layoutConfig = useSelector((state) => state.dashboards.layoutConfig);
  // use ref to keep track of the mounted state
  const isMounted = useRef(true);

  const triggerResizeEvent = () => {
    window.dispatchEvent(new Event("resize"));
  };

  useEffect(() => {
    // set the component as mounted
    isMounted.current = true;

    // cleanup
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // trigger window resize event to re-render the chart; fix the "Uncaught TypeError: Cannot read properties of null (reading '45')" bug
    if (isMounted.current) {
      triggerResizeEvent();
    }
  }, [layoutConfig, editmode]);

  const chartOptions = useMemo(() => {
    const isDark = colorMode === "dark";
    return {
      series: seriesValue,
      chart: {
        type: "radialBar",
        height: "100%",
        width: "100%",
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        background: isDark ? "#212529" : "#fff",
        toolbar: {
          show: widget?.configuration?.chartToolbar ? true : false,
        },
      },
      theme: {
        mode: colorMode, // 'light' or 'dark'
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          hollow: {
            margin: 0,
            size: "70%",
            background: isDark ? "#212529" : "#fff",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: isDark ? 0.7 : 0.24,
            },
          },
          track: {
            background: isDark ? "#444" : "#fff",
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: isDark ? 0.7 : 0.35,
            },
          },
          dataLabels: {
            name: {
              offsetY: -10,
              color: isDark ? "#ddd" : "#888",
              fontSize: "17px",
            },
            value: {
              color: isDark ? "#eee" : "#111",
              fontSize: "36px",
              formatter: function (val) {
                return `${parseInt(val)}${seriesConfigData.unit || ""}`;
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -20,
          bottom: -30,
          left: -30,
          right: -30,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: isDark ? "dark" : "light",
          gradientToColors: isDark ? ["#88E0EF"] : ["#ABE5A1"],
        },
      },
      stroke: {
        lineCap: "round",
      },
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -20,
        style: {
          fontSize: "18px",
        },
      },
      labels: [seriesConfigData.label || "Gauge"],
    };
  }, [colorMode]);

  return (
    <React.Fragment>
      <Card className="h-100 w-100 border">
        <WidgetHeader widget={widget} />
        <CardBody>
          {errors ? (
            <div className="d-flex justify-content-center align-items-center h-100 w-100 text-center fs-18">
              {errors}
            </div>
          ) : (
            <Chart
              options={chartOptions}
              series={seriesValue}
              type="radialBar"
              className="apex-charts"
              dir="ltr"
              height="100%"
              width="100%"
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
