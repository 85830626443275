import React from 'react';
import {
    generateGithubOAuthURL,
    generateGoogleOAuthURL,
} from '@/common/utils/getOauthURLs';


const OauthLoginButtonsSet = () => {

    const requestOauthCode = (oauth_provider) => {
        if (oauth_provider === 'google') {
            return () => {
                window.location.href = generateGoogleOAuthURL();
            }
        } else if (oauth_provider === 'github') {
            return () => {
                window.location.href = generateGithubOAuthURL();
            }
        }
    }

    return (
        <div className="d-flex">
            <button type="button"className="btn btn-oauth btn-google fw-500 rounded-3 shadow-none" onClick={() => requestOauthCode('google')()}>
                <i className="mdi mdi-google"></i>
                <span className="mx-2">Login in with Google</span>
            </button>
            <ul className="social-icons d-inline-block social-icons-rounded">
                <li className="social-icons-github mb-0">
                    <a className="text-dark text-6 oauth-btn" data-bs-toggle="tooltip" data-bs-original-title="Login in with github" onClick={() => requestOauthCode('github')()}>
                        <i className="mdi mdi-github"></i>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default OauthLoginButtonsSet;