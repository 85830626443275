import React from "react";

export function ActionButtonGroup({ children }) {
  return (
    <div className="flex-shrink-0">
      <ul className="list-inline mb-0">
        {React.Children.map(children, (child) => (
          <li className="list-inline-item">{child}</li>
        ))}
      </ul>
    </div>
  );
}
