import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";

const ProfileDropdown = () => {
  const user = useSelector((state) => state.auth.user);

  const [userName, setUserName] = useState("Unknown User");
  const [userAvatar, setUserAvatar] = useState(avatar1);

  useEffect(() => {
    if (user) {
      setUserName(user.username);
      setUserAvatar(user.avatar);
    }
  }, [user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <i
              className="mdi mdi-account-circle d-block text-muted"
              style={{ fontSize: 36 }}
            ></i>
            {/* <img className="rounded-circle header-profile-user" src={userAvatar}
                            alt="Header Avatar" /> */}
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                Administrator
              </span>
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-block ms-2 text-muted fs-16"></i>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userName}!</h6>
          <Link to="/settings">
            <DropdownItem>
              <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Settings</span>
            </DropdownItem>
          </Link>
          <Link to="/settings/#changePassword">
            <DropdownItem>
              <i className="mdi mdi-key text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Change password</span>
            </DropdownItem>
          </Link>
          <div className="dropdown-divider"></div>
          <DropdownItem
            href="https://galaxyiot.freshdesk.com/support/home"
            target="_blank"
          >
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Help</span>
          </DropdownItem>
          <Link to="/logout">
            <DropdownItem>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </DropdownItem>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
