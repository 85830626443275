export const rowHeightConfig = 50;
export const minRowHeightConfig = 50;

export const colsConfig = { xxl: 12, xl: 12, lg: 9, md: 6, sm: 2, xs: 2 };

export const breakpointsConfig = {
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 480,
  xs: 0,
};

export function getCurrentLayoutBreakpoint(windowWidth) {
  if (windowWidth < 480) return "xs";
  else if (windowWidth >= 480 && windowWidth < 768) return "sm";
  else if (windowWidth >= 768 && windowWidth < 992) return "md";
  else if (windowWidth >= 992 && windowWidth < 1200) return "lg";
  else if (windowWidth >= 1200 && windowWidth < 1400) return "xl";
  return "xxl";
}

export function getCurrentColsCount(windowWidth) {
  return colsConfig[getCurrentLayoutBreakpoint(windowWidth)];
}

export function findPositionForNewWidget(widgets, newWidgetWidth, newWidgetHeight, cols) {
    let rowsOccupied = new Array(1000).fill(0).map(() => new Array(cols).fill(false));
  
    // mark the cells occupied by existing widgets
    widgets.forEach(widget => {
      for (let y = widget.layout.y; y < widget.layout.y + widget.layout.h; y++) {
        for (let x = widget.layout.x; x < widget.layout.x + widget.layout.w; x++) {
          if (y < rowsOccupied.length && x < cols) {
            rowsOccupied[y][x] = true;
          }
        }
      }
    });
  
    // find the first available space for the new widget
    for (let y = 0; y < rowsOccupied.length; y++) {
      for (let x = 0; x <= cols - newWidgetWidth; x++) {
        let spaceAvailable = true;
        for (let dx = 0; dx < newWidgetWidth && spaceAvailable; dx++) {
          if (rowsOccupied[y][x + dx]) {
            spaceAvailable = false;
            break;
          }
        }
  
        if (spaceAvailable) {
          return { x, y };
        }
      }
    }
  
    // if no space is available, place the widget at the bottom of the grid
    return { x: 0, y: rowsOccupied.length };
  }
  