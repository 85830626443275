import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from "reactstrap";
import SimpleBar from "simplebar-react";

export function WidgetModalContainer({
  showModal,
  toggleModal,
  modalTitle,
  children,
  onSubmit,
  errors,
  isEditMode = false,
}) {
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      size="lg"
      modalClassName="zoomIn non-draggable"
      backdrop="static"
      centered
    >
      <ModalHeader toggle={toggleModal} className="p-3">
        Add Widget - <span className="fs-18">{modalTitle}</span>
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <SimpleBar style={{ maxHeight: "600px" }}>
          <ModalBody style={{ minHeight: "500px" }}>
            {children}
            {errors && (
              <div className="alert alert-danger mt-3" role="alert">
                {errors}
              </div>
            )}
          </ModalBody>
        </SimpleBar>
        <ModalFooter>
          <Button color="light" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            {isEditMode ? "Save" : "Add Widget"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
