import React from "react";
import { useMemo, useEffect } from "react";
import { useModals } from "@/hooks/useModals";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useTableSelectionControl } from "@/hooks/useTableSelectionControl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Card, CardHeader, CardBody, Input } from "reactstrap";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { SearchBox } from "@/components/tables/headerToolSet/SearchBox";
import { DeleteButton } from "@/components/tables/rowActionButtons/DeleteButton";
import { AddEntityButton } from "@/components/tables/headerToolSet/AddEntityButton";
import { RefreshDataButton } from "@/components/tables/headerToolSet/RefreshDataButton";
import { DeleteMultipleButton } from "@/components/tables/headerToolSet/DeleteMultipleButton";
import { ActionButtonGroup } from "@/components/tables/rowActionButtons/ActionButtonGroup";

import { DeleteModal } from "@/components/modals/DeleteModal";
import { EditButton } from "@/components/tables/rowActionButtons/EditButton";
import { AddNotifyRuleModal } from "@/features/notifyHub/components/addNotifyRule/AddNotifyRuleModal";
import { DeleteMultipileModal } from "@/components/modals/DeleteMultipileModal";
import { ShowDescriptionModal } from "@/components/modals/ShowDescriptionModal";
import { ShowConfigurationsModal } from "@/features/notifyHub/components/addNotifyRule/ShowConfigurationsModal";

import { getNotifyRules, deleteNotifyRule, deleteMultipleNotifyRules } from "@/features/notifyHub/thunk";

export function NotifyRules() {
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const dispatch = useDispatch();

  const [modals, modalData, toggleModal] = useModals({
    deleteModal: false,
    deleteMultipleModal: false,
    addNotifyRuleModal: false,
    showConfigurationsModal: false,
  });

  const [selectedIds, handleSelectedAll, handleSelectedSingle, resetSelectedIds] = useTableSelectionControl();

  useEffect(() => {
    dispatch(getNotifyRules({ page, search }));
  }, [page, search]);

  const notifyRules = useSelector((state) => state.notifyHub.notifyRules);
  const { rulesList, allRulesIds, totalPages, totalDataLength } = useMemo(
    () => ({
      rulesList: notifyRules?.results || [],
      allRulesIds: notifyRules?.results?.map((rule) => rule.id) || [],
      totalPages: notifyRules?.total_pages || 0,
      totalDataLength: notifyRules?.total_count || 0,
    }),
    [notifyRules]
  );

  const refreshData = () => {
    dispatch(getNotifyRules({ page, search }));
    resetSelectedIds();
    resetPageAndSearch();
  };

  function handleDeleteMultipleRules() {
    dispatch(deleteMultipleNotifyRules({ ids: selectedIds })).then((actionResult) => {
      if (deleteMultipleNotifyRules.fulfilled.match(actionResult)) {
        refreshData();
      }
    });
    toggleModal("deleteMultipleModal");
  }

  function handleDeleteRule(ruleId) {
    if (ruleId) {
      dispatch(deleteNotifyRule({ ruleId })).then((actionResult) => {
        if (deleteNotifyRule.fulfilled.match(actionResult)) {
          toggleModal("deleteModal");
          refreshData();
        }
      });
    }
  }

  const columns = [
    {
      header: (
        <div className="form-check">
          <Input
            type="checkbox"
            className="form-check-input"
            checked={selectedIds.length === rulesList.length && rulesList.length > 0}
            onChange={(e) => handleSelectedAll(e, allRulesIds)}
          />
        </div>
      ),
      cell: (cell) => {
        return (
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              value={cell.getValue()}
              checked={selectedIds.includes(cell.getValue())}
              onChange={(e) => handleSelectedSingle(e, cell.getValue())}
            />
          </div>
        );
      },
      id: "#",
      accessorKey: "id",
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "Name",
      accessorKey: "name",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Type",
      accessorKey: "type",
      enableSorting: true,
      enableColumnFilter: false,
      cell: (cell) => {
        return <span className="text-capitalize">{cell.getValue()}</span>;
      },
    },
    {
      header: "Template",
      accessorKey: "template_name",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Configurations",
      accessorKey: "configurations",
      enableSorting: true,
      enableColumnFilter: false,
      cell: (cell) => {
        return (
          <Button
            color="link"
            className="shadow-none"
            outline
            onClick={(e) =>
              toggleModal("showConfigurationsModal", {
                configurations: cell.getValue(),
              })
            }
          >
            <a className="text-decoration-underline">View Configurations</a>
          </Button>
        );
      },
    },
    {
      header: "Description",
      accessorKey: "description",
      enableSorting: true,
      enableColumnFilter: false,
      cell: (cell) => {
        return (
          <Button
            color="link"
            className="shadow-none"
            outline
            onClick={(e) =>
              toggleModal("showDescriptionModal", {
                description: cell.getValue(),
              })
            }
          >
            <a className="text-decoration-underline">View Description</a>
          </Button>
        );
      },
    },
    {
      header: "Time",
      accessorKey: "created_time",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Action",
      disableFilters: true,
      enableSorting: false,
      cell: (cell) => {
        return (
          <ActionButtonGroup>
              <EditButton
                onClick={() => toggleModal("addNotifyRuleModal", { isEditMode: true, ruleData: cell.row.original })}
                tooltip="Edit Rule"
              />
            <DeleteButton
              onClick={() =>
                toggleModal("deleteModal", {
                  ruleId: cell.row.original.id,
                })
              }
              tooltip="Delete Notify Rule"
            />
          </ActionButtonGroup>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Card className="pb-4">
        <CardHeader className="d-flex justify-content-between ps-0">
          <Col md="2" className="d-flex justify-content-begin">
            <AddEntityButton onClick={() => toggleModal("addNotifyRuleModal", { isEditMode: false })} text="Add Notify Rule" />
            <RefreshDataButton onClick={refreshData} />
            <DeleteMultipleButton
              onClick={() => toggleModal("deleteMultipleModal")}
              tooltip="Delete Multiple Notify Rules"
              show={selectedIds.length > 0}
            />
          </Col>
          <Col md="6">
            <SearchBox handleSearch={handleSearch} search={search} placeholder="Search Notify Rules" />
          </Col>
        </CardHeader>
        <DynamicDataTable
          columns={columns}
          data={rulesList}
          currentPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalDataLength={totalDataLength}
          dataName="notify rule"
        />
      </Card>
      <DeleteModal
        showModal={modals.deleteModal}
        toggleModal={() => toggleModal("deleteModal")}
        title="Delete Notify Rules"
        subtitle="Are you sure you want to delete this notify rules?"
        onDelete={() => handleDeleteRule(modalData.deleteModal?.ruleId)}
      />
      <DeleteMultipileModal
        showModal={modals.deleteMultipleModal}
        toggleModal={() => toggleModal("deleteMultipleModal")}
        type="notify rule"
        numberSelected={selectedIds.length}
        onDelete={handleDeleteMultipleRules}
      />
      <AddNotifyRuleModal
        showModal={modals.addNotifyRuleModal}
        toggleModal={() => toggleModal("addNotifyRuleModal")}
        onSuccess={refreshData}
        isEditMode={modalData.addNotifyRuleModal?.isEditMode}
        ruleData={modalData.addNotifyRuleModal?.ruleData}
      />
      <ShowDescriptionModal
        showModal={modals.showDescriptionModal}
        toggleModal={() => toggleModal("showDescriptionModal")}
        title="Notify Rule Description"
        description={modalData.showDescriptionModal?.description}
      />
      <ShowConfigurationsModal
        showModal={modals.showConfigurationsModal}
        toggleModal={() => toggleModal("showConfigurationsModal")}
        title="Notify Rule Configurations"
        configurations={modalData.showConfigurationsModal?.configurations}
      />
    </React.Fragment>
  );
}
