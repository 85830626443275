import { createSlice } from "@reduxjs/toolkit";
import { getDistributionData, getAlarmsStatisticsData, getAlarms } from "@/features/alarms/thunk";

export const initialState = {
    alarms: [],
    statisticsData: {},
    distributionData: [],
    isLoading: false,
    fetchError: null,
};

const alarmsSlice = createSlice({
    name: "alarms",
    initialState,
    reducers: {
        resetFetchError(state) {
            state.fetchError = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAlarmsStatisticsData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAlarmsStatisticsData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.statisticsData = action.payload;
        });
        builder.addCase(getAlarmsStatisticsData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getDistributionData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getDistributionData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.distributionData = action.payload;
        });
        builder.addCase(getDistributionData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getAlarms.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAlarms.fulfilled, (state, action) => {
            state.isLoading = false;
            state.alarms = action.payload;
        });
        builder.addCase(getAlarms.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
    },
});

export default alarmsSlice.reducer;