import { isLoggedIn } from "@/features/auth/authService";
import { logout } from "@/features/auth/login/slice";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";


const AuthGuard = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  if (!isLoggedIn()) {
    dispatch(logout());
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default AuthGuard;