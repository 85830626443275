import React from "react";
import { useModals } from "@/hooks/useModals";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import { getNotifyTemplates } from "@/features/notifyHub/thunk";
import { AddNotifyTemplateModal } from "@/features/notifyHub/components/addNotifyTemplate/AddNotifyTemplateModal";

export function BasicInfoTab({ formik }) {
  const dispatch = useDispatch();
  const ruleType = formik.values.type;
  const [modals, modalData, toggleModal] = useModals({
    addNotifyTemplateModal: false,
  });

  useEffect(() => {
    dispatch(getNotifyTemplates({ type: ruleType }));
  }, [ruleType]);

  const notifyTemplates = useSelector(
    (state) => state.notifyHub.notifyTemplates
  );

  const { notifyTemplatesList } = useMemo(
    () => ({
      notifyTemplatesList: notifyTemplates?.results || [],
    }),
    [notifyTemplates]
  );

  return (
    <React.Fragment>
      <FormGroup>
        <Label for="name">Notify Rule Name *</Label>
        <Input
          type="text"
          name="name"
          id="name"
          placeholder="Notify Rule Name"
          onChange={formik.handleChange}
          value={formik.values.name}
          invalid={formik.touched.name && !!formik.errors.name}
          required
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="invalid-feedback">{formik.errors.name}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="description">Description</Label>
        <Input
          type="textarea"
          name="description"
          id="description"
          rows="5"
          placeholder="Notify Template Description"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">Type *</Label>
        <select
          className="form-select"
          value={ruleType}
          name="type"
          onChange={formik.handleChange}
        >
          <option value="devices">Devices Acitivity</option>
          <option value="alarms">Alarms Activity</option>
          <option value="ruleEngineEvent">Rules Engine Event</option>
        </select>
      </FormGroup>
      <FormGroup>
        <Label for="template">Template *</Label>
        <div className="d-flex">
          <select
            className="form-select"
            value={formik.values.template}
            name="template"
            onChange={formik.handleChange}
          >
            <option value="">Please select a template</option>
            {notifyTemplatesList.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
          <Button
            color="link"
            className="text-nowrap ms-3 text-decoration-underline"
            onClick={() => toggleModal("addNotifyTemplateModal")}
          >
            Create New Template
          </Button>
        </div>
        {formik.errors.template ? (
          <div style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
            {formik.errors.template}
          </div>
        ) : null}
      </FormGroup>
      <AddNotifyTemplateModal
        showModal={modals.addNotifyTemplateModal}
        toggleModal={() => toggleModal("addNotifyTemplateModal")}
        onSuccess={() => dispatch(getNotifyTemplates({ type: ruleType }))}
      />
    </React.Fragment>
  );
}
