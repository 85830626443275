import React from "react";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { Paginator } from "@/components/tables/Pagination";
import { getBulkDevices } from "@/features/devices/thunk";
import { Link } from "react-router-dom";

export function DevicesTable({ widget }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [devicesData, setDevicesData] = useState([]);
  const { page, handlePageChange } = usePaginationAndSearch();
  const [totalPages, setTotalPages] = useState(0);

  const devices = widget.dataSourceConfig.devices;
  useEffect(() => {
    dispatch(getBulkDevices({ deviceIds: devices, page })).then((actionResult) => {
      if (getBulkDevices.fulfilled.match(actionResult)) {
        const devicesResults = actionResult.payload;
        if (devicesResults && devicesResults.results && devicesResults.results.length > 0) {
          setDevicesData(devicesResults.results);
          setTotalPages(devicesResults.total_pages);
        }
      } else {
        const apiError = actionResult.payload;
        if (apiError && apiError.data && apiError.data.error_message) {
          setErrors("Failed to get devices data");
        }
      }
    });
  }, [devices, dispatch, page]);

  const defaultColumns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <Link
              to={`/devices/${cell.row.original.id}`}
              className="link-body-emphasis link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-75-hover"
            >
              {cell.getValue()}
            </Link>
          );
        },
      },
      {
        header: "Profile",
        accessorKey: "profile",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <span
              className={`badge text-uppercase ${
                cell.getValue() === "active"
                  ? "text-success bg-success-subtle"
                  : "text-danger bg-danger-subtle"
              }`}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Label",
        accessorKey: "label",
        enableSorting: true,
        enableColumnFilter: false,
      },
      // {
      //   header: "Group",
      //   accessorKey: "group",
      //   enableColumnFilter: false,
      // },
      {
        header: "Is Gateway",
        accessorKey: "is_gateway",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <input
              className="form-check-input"
              type="checkbox"
              checked={cell.getValue()}
              disabled
              style={{ opacity: 1 }}
            />
          );
        },
      },
      {
        header: "Created Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
    ],
    [devicesData]
  );

  const columns = useMemo(() => {
    let userConfigColumns = widget.configuration.columns;
    const resultColumns = userConfigColumns.map((column) => {
      // Find if the user configured column exists in defaultColumns
      const defaultColumn = defaultColumns.find((dc) => dc.accessorKey === column.key);
      if (defaultColumn) {
        // If it exists, use the default column configuration
        return defaultColumn;
      } else {
        // If it doesn't exist, use a generic configuration
        return {
          header: column.label,
          accessorKey: column.key,
          enableSorting: true,
          enableColumnFilter: false,
        };
      }
    });
    resultColumns.unshift({
      header: "id",
      accessorKey: "id",
      enableSorting: true,
      enableColumnFilter: false,
      show: false,
    });
    return resultColumns;
  }, [widget.configuration.columns, defaultColumns]);

  const filteredData = useMemo(() => {
    return devicesData.map((device) => {
      const filteredDevice = {};
      columns.forEach(({ accessorKey }) => {
        filteredDevice[accessorKey] = device[accessorKey];
      });
      return filteredDevice;
    });
  }, [devicesData, columns]);

  return (
    <Card className="h-100 w-100 border">
      <WidgetHeader widget={widget} />
      <CardBody className="d-flex flex-column justify-content-center align-items-center overflow-hidden p-0">
        <div className="h-100 w-100 d-flex flex-column">
          {errors ? (
            <div className="text-center mt-3">{errors}</div>
          ) : (
            <>
              <DynamicDataTable
                columns={columns}
                data={filteredData}
                totalDataLength={10}
                dataName="device"
                showPagination={false}
              />
              <div className="mt-auto mb-3 me-3 d-flex justify-content-end">
                <Paginator currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
              </div>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
