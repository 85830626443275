import React from "react";
import { ChartWidgetModal } from "@/features/dashboards/components/widgetModals/ChartWidgetModal";
import { AnalogClockWidgetModal } from "@/features/dashboards/components/widgetModals/AnalogClockWidgetModal";
import { DigitalClockWidgetModal } from "@/features/dashboards/components/widgetModals/DigitalClockWidgetModal";
import { BasicMapWidgetModal } from "@/features/dashboards/components/widgetModals/BasicMapWidgetModal";
import { NavigationCardModal } from "@/features/dashboards/components/widgetModals/NavigationCardModal";
import { DocumentsCardModal } from "@/features/dashboards/components/widgetModals/DocumentsCardModal";
import { DevicesTableModal } from "@/features/dashboards/components/widgetModals/DevicesTableModal";
import { AlarmsTableModal } from "@/features/dashboards/components/widgetModals/AlarmsTableModal";
import { CommonGaugeModal } from "@/features/dashboards/components/widgetModals/CommonGaugeModal.js";

export const widgetModalConfig = {
  chartWidget: false,
  analogClockWidget: false,
  digitalClockWidget: false,
  basicMapWidget: false,
  navigationCard: false,
  documentsCard: false,
  devicesTable: false,
  alarmsTable: false,
  commonGauge: false,
};

export function WidgetModalManager({toggleModal, modalData, modals, isEditMode=false}) {
  return (
    <React.Fragment>
      <ChartWidgetModal
        showModal={modals.chartWidget}
        toggleModal={() => toggleModal("chartWidget")}
        widgetData={modalData.chartWidget?.widgetData}
        isEditMode={isEditMode}
      />
      <AnalogClockWidgetModal
        showModal={modals.analogClockWidget}
        toggleModal={() => toggleModal("analogClockWidget")}
        widgetData={modalData.analogClockWidget?.widgetData}
        isEditMode={isEditMode}
      />
      <DigitalClockWidgetModal
        showModal={modals.digitalClockWidget}
        toggleModal={() => toggleModal("digitalClockWidget")}
        widgetData={modalData.digitalClockWidget?.widgetData}
        isEditMode={isEditMode}
      />
      <BasicMapWidgetModal
        showModal={modals.basicMapWidget}
        toggleModal={() => toggleModal("basicMapWidget")}
        widgetData={modalData.basicMapWidget?.widgetData}
        isEditMode={isEditMode}
      />
      <NavigationCardModal
        showModal={modals.navigationCard}
        toggleModal={() => toggleModal("navigationCard")}
        widgetData={modalData.navigationCard?.widgetData}
        isEditMode={isEditMode}
      />
      <DocumentsCardModal
        showModal={modals.documentsCard}
        toggleModal={() => toggleModal("documentsCard")}
        widgetData={modalData.documentsCard?.widgetData}
        isEditMode={isEditMode}
      />
      <DevicesTableModal
        showModal={modals.devicesTable}
        toggleModal={() => toggleModal("devicesTable")}
        widgetData={modalData.devicesTable?.widgetData}
        isEditMode={isEditMode}
      />
      <AlarmsTableModal
        showModal={modals.alarmsTable}
        toggleModal={() => toggleModal("alarmsTable")}
        widgetData={modalData.alarmsTable?.widgetData}
        isEditMode={isEditMode}
      />
      <CommonGaugeModal
        showModal={modals.commonGauge}
        toggleModal={() => toggleModal("commonGauge")}
        widgetData={modalData.commonGauge?.widgetData}
        isEditMode={isEditMode}
      />
    </React.Fragment>
  );
}