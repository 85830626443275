// ChartComponent.js
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { getDevicesTelemetry } from "@/features/devices/thunk";

export function ChartWidget({ widget }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [series, setSeries] = useState([]);
  const layoutConfig = useSelector((state) => state.dashboards.layoutConfig);
  const colorMode = useSelector((state) => state.layout.colorMode);
  // use ref to keep track of the mounted state
  const isMounted = useRef(true);

  const triggerResizeEvent = () => {
    window.dispatchEvent(new Event("resize"));
  };

  useEffect(() => {
    // set the component as mounted
    isMounted.current = true;

    // cleanup
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // trigger window resize event to re-render the chart; fix the "Uncaught TypeError: Cannot read properties of null (reading '45')" bug
    if (isMounted.current) {
      triggerResizeEvent();
    }
  }, [layoutConfig]);

  useEffect(() => {
    dispatch(
      getDevicesTelemetry({
        deviceIds: [widget.dataSourceConfig.device],
        keys: widget.dataSourceConfig.deviceSeries.map((series) => series.key),
      })
    ).then((actionResult) => {
      if (actionResult.payload && actionResult.payload.length > 0) {
        const results = actionResult.payload;
        const seriesData = widget.dataSourceConfig.deviceSeries.map((serie) => {
          const dataPoints = results.map((result) => ({
            x: new Date(result.timestamp).toLocaleString(),
            y: result.data[serie.key] ?? null,
            unit: serie.unit,
            
          }));
          return {
            name: `${serie.label} (${serie.unit})`,
            data: dataPoints,
          };
        });
        setSeries(seriesData);
      } else if (actionResult.error) {
        setErrors("Failed to get telemetry data");
      }
    });
  }, [dispatch, widget.dataSourceConfig]);

  const chartOptions = {
    chart: {
      id: Date.now().toString(),
      height: "100%",
      width: "100%",
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      background: colorMode === "dark" ? "#212529" : "#fff",
      toolbar: {
        show: widget?.configuration?.chartToolbar ? true : false,
      },
    },
    theme: {
      mode: colorMode, // 'light' or 'dark'
    },

    plotOptions: {
      background: colorMode === "dark" ? "#212529" : "#fff",
    },
    colors: ["#3577f1", "#45CB85", "#4b38b3", "#ffbe0b", "#02a8b5", "f06548"],
    grid: {
      show: widget?.configuration?.chartGrid ? true : false,
    },
    legend: {
      show: widget?.configuration?.chartLegend ? true : false,
    },
    xaxis: {
      type: "datetime",
    },
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: -20,
      style: {
        fontSize: "18px",
      },
    },
  };

  return (
    <React.Fragment>
      <Card className="h-100 w-100 border">
        <WidgetHeader widget={widget} onRefreshWidget={triggerResizeEvent} />
        <CardBody>
          {errors ? (
            <div className="d-flex justify-content-center align-items-center h-100 w-100 text-center fs-18">
              {errors}
            </div>
          ) : (
            <Chart
              options={chartOptions}
              series={series}
              type={widget?.configuration?.chartType}
              className="apex-charts"
              dir="ltr"
              height="100%"
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
