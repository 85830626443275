import React from "react";
import { Card, CardBody, CardHeader, Progress } from "reactstrap";

export function UsageProgressBarPanel({
  title,
  currentValue,
  totalValue,
  className,
  unit,
  isAnnimated = false,
}) {
  let percentage;
  if (totalValue === 0) {
    percentage = 0;
  }
  percentage = Math.abs((currentValue / totalValue) * 100);

  return (
    <React.Fragment>
      <Card
        className={`card-height-100 border ${
          isAnnimated ? "card-animate" : ""
        } ${className}`}
      >
        <CardHeader className="border-0" tag={"h5"}>
          {title}
        </CardHeader>
        <CardBody>
          <div>
            <div className="mb-1">
              <Progress
                className="animated-progress"
                value={percentage}
                color={percentage > 90 ? "danger" : "secondary"}
              />
            </div>
            <div className="d-flex justify-content-between">
              <p
                className={`text-${
                  percentage > 90 ? "danger" : "muted"
                } mb-2 fs-16 ms-2 `}
              >
                {percentage}%
              </p>
              <p
                className={`text-${
                  percentage > 90 ? "danger" : "muted"
                } mb-2 fs-16 ms-2 `}
              >
                {currentValue} / {totalValue} {unit}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
