import React, { createContext, useContext } from 'react';

const RuleNodeContext = createContext();

export function useRuleNodeContext() {
  return useContext(RuleNodeContext);
}

export function RuleNodeProvider({ children, value }) {
  return <RuleNodeContext.Provider value={value}>{children}</RuleNodeContext.Provider>;
}
