import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { LordIcon } from "@/components/common/LordIcon";
import AuthenticationLayout from "@/components/layouts/AuthenticationLayout";
import { sendEmailVerification } from "@/features/auth/emailVerification/thunk";

import ICON from "@/assets/json/lord-icon/envelope-send.json";

const SentEmailVerification = () => {
  const location = useLocation();
  const [resend, setResend] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    handleSendVerification();
  }, []);

  const apiError = useSelector((state) => state.requestEmailVerification.error);
  useEffect(() => {
    if (apiError && apiError.data && apiError.data.error_message) {
      const errorMessage = apiError.data.error_message;
      if (errorMessage.email) {
        setErrorMessage(errorMessage.email);
      } else {
        setErrorMessage(errorMessage);
      }
    }
  }, [apiError]);

  useEffect(() => {
    setErrorMessage(null);
  }, [location]);

  const dispatch = useDispatch();
  const handleSendVerification = () => {
    const email = new URLSearchParams(window.location.search).get("email");
    if (email) {
      dispatch(sendEmailVerification(email));
    }
  };

  const handleResend = () => {
    setResend(true);
    handleSendVerification();
  };

  useEffect(() => {
    if (resend) {
      setTimeout(() => {
        setResend(false);
      }, 5000);
    }
  }, [resend]);

  return (
    <AuthenticationLayout subTitle="We care about your account security." withMask={false}>
      <div className="row g-0">
        <div className="col-11 col-md-8 col-lg-7 col-xl-6 mx-auto">
          <h3 className="text-white mb-4">Email verification</h3>
          <LordIcon icon={ICON} size={100} mode="loop" />
          <p className="text-white mb-4">An email with verification details was sent to the your email address.</p>
          <p className="text-white-50 mb-4">
            Note: if you haven't seen the email for a while, please check your 'spam' folder or try to resend email by
            clicking 'Resend' button.
          </p>
          {errorMessage && (
            <div className="alert alert-warning text-2 mt-2 d-flex align-items-center" role="alert">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                viewBox="0 0 16 16"
                role="img"
                aria-label="Warning:"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              {errorMessage}
            </div>
          )}
          {resend && (
            <div className="alert alert-info text-2 mt-2 d-flex align-items-center" role="alert">
              Email has been successfully sent.
            </div>
          )}
          {!errorMessage && (
            <Button color="primary" className="shadow-none mx-auto w-100 mb-3" onClick={handleResend}>
              {resend ? "Email has been sent" : "Resend"}
            </Button>
          )}
          <Link to="/login" className="text-white mt-3">
            Back to login
          </Link>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default SentEmailVerification;
