export function getDateFormatOptions(format) {
  switch (format) {
    case "YYYY-MM-DD":
      return { year: "numeric", month: "2-digit", day: "2-digit" };
    case "MM-DD-YYYY":
      return { month: "2-digit", day: "2-digit", year: "numeric" };
    case "DD-MM-YYYY":
      return { day: "2-digit", month: "2-digit", year: "numeric" };
    case "MMM DD, YYYY":
      return { year: "numeric", month: "short", day: "2-digit" };
    case "DD MMM YYYY":
      return { day: "2-digit", month: "short", year: "numeric" };
    case "MMMM DD, YYYY":
      return { year: "numeric", month: "long", day: "2-digit" };
    case "DD MMMM YYYY":
      return { day: "2-digit", month: "long", year: "numeric" };
    case "YYYY/MM/DD":
      return {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        formatMatcher: "basic",
      };
    case "MM/DD/YYYY":
      return {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        formatMatcher: "basic",
      };
    case "DD/MM/YYYY":
      return {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        formatMatcher: "basic",
      };
    default:
      return { year: "numeric", month: "2-digit", day: "2-digit" };
  }
}

export const getFormattedDate = (date, format) => {
  const day = date.getDate().toString().padStart(2, "0");
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthNamesFull = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  switch (format) {
    case "YYYY-MM-DD":
      return `${year}-${monthIndex + 1}-${day}`;
    case "MM-DD-YYYY":
      return `${monthIndex + 1}-${day}-${year}`;
    case "DD-MM-YYYY":
      return `${day}-${monthIndex + 1}-${year}`;
    case "MMM DD, YYYY":
      return `${monthNamesShort[monthIndex]} ${day}, ${year}`;
    case "DD MMM YYYY":
      return `${day} ${monthNamesShort[monthIndex]} ${year}`;
    case "MMMM DD, YYYY":
      return `${monthNamesFull[monthIndex]} ${day}, ${year}`;
    case "DD MMMM YYYY":
      return `${day} ${monthNamesFull[monthIndex]} ${year}`;
    case "YYYY/MM/DD":
      return `${year}/${monthIndex + 1}/${day}`;
    case "MM/DD/YYYY":
      return `${monthIndex + 1}/${day}/${year}`;
    case "DD/MM/YYYY":
      return `${day}/${monthIndex + 1}/${year}`;
    default:
      return date.toLocaleDateString();
  }
};


export function formatTimeDifference(created_time) {
  const currentTime = new Date();
  const createdTime = new Date(created_time);
  const differenceInSeconds = Math.floor((currentTime - createdTime) / 1000);

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} sec ago`;
  } else if (differenceInSeconds < 3600) {
    return `${Math.floor(differenceInSeconds / 60)} min ago`;
  } else if (differenceInSeconds < 86400) {
    return `${Math.floor(differenceInSeconds / 3600)} hours ago`;
  } else {
    return `${Math.floor(differenceInSeconds / 86400)} days ago`;
  }
}


export const getXAxisFormat = (timeSelection) => {
  switch (timeSelection) {
    case 'minute':
      return 'HH:mm:ss';
    case 'hour':
      return 'HH:mm';
    case 'day':
      return 'HH:mm';
    case 'week':
      return 'MMM dd';
    case 'month':
      return 'MMM dd';
    default:
      return 'HH:mm';
  }
};