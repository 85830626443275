import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Table, Button } from "reactstrap";
import { Link } from "react-router-dom";

import { useModals } from "@/hooks/useModals";
import { getDashboardsListData } from "@/features/dashboards/thunk";
import { useNavigate } from "react-router-dom";
import { ShowDescriptionModal } from "@/components/modals/ShowDescriptionModal";

export function DashboardListPanel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modals, modalData, toggleModal] = useModals({
    showDescriptionModal: false,
  });
  useEffect(() => {
    dispatch(getDashboardsListData({ page: 1, search: "" }));
  }, []);

  const dashboards = useSelector((state) => state.dashboards.dashboardsListData);
  const { dashboardsList } = useMemo(
    () => ({
      dashboardsList: (dashboards?.results || []).slice(0, 5),
    }),
    [dashboards]
  );

  function onRowClick(dashboardId) {
    navigate(`/dashboards/${dashboardId}`);
  }

  return (
    <React.Fragment>
      <Card className="card-height-100 border">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Dashboards</h4>
        </CardHeader>
        <CardBody>
          <div className="table-card" style={{ overflowX: "auto"}}>
            <Table className="table align-middle table-centered table-nowrap mb-0">
              <thead className="text-muted table-light">
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Last Views</th>
                </tr>
              </thead>
              <tbody>
                {dashboardsList.map((dashboard, index) => (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => onRowClick(dashboard.id)}
                  >
                    <td></td>
                    <td>
                      <h6 className="mb-1">{dashboard.title}</h6>
                    </td>
                    <td>
                      <h6 className="mb-1">
                        <Button
                          color="link"
                          className="shadow-none"
                          outline
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleModal("showDescriptionModal", {
                              description: dashboard.description,
                            });
                          }}
                        >
                          <a className="text-decoration-underline">
                            View Description
                          </a>
                        </Button>
                      </h6>
                    </td>
                    <td>
                      <p className="mb-0">{dashboard.last_viewed}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center mt-3 mb-4">
              <Link
                to="/dashboards"
                className="btn btn-secondary text-decoration-underline"
              >
                View All
              </Link>
            </div>
          </div>
        </CardBody>
      </Card>
      <ShowDescriptionModal
        showModal={modals.showDescriptionModal}
        toggleModal={() => toggleModal("showDescriptionModal")}
        title="Dashboard Description"
        description={modalData.showDescriptionModal?.description}
      />
    </React.Fragment>
  );
}
