import { ACCOUNT_SETTINGS_API_URLS } from "@/api/apiConfig";
import { apiClient } from "@/api/axiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateUserInfo } from "@/features/auth/login/slice";


export const changePassword = createAsyncThunk(
    "accountSettings/changePassword",
    async ({ oldPassword, newPassword, confirmPassword }, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(ACCOUNT_SETTINGS_API_URLS.CHANGE_PASSWORD, { 
                old_password: oldPassword, 
                new_password: newPassword, 
                confirm_password: confirmPassword });
            if (response) {
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const getSettings = createAsyncThunk(
    "accountSettings/getSettings",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(ACCOUNT_SETTINGS_API_URLS.GET_SETTINGS);
            if (response) {
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const updateSettings = createAsyncThunk(
    "accountSettings/updateSettings",
    async ({ emailNotification, smsNotification, webNotification }, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(ACCOUNT_SETTINGS_API_URLS.UPDATE_SETTINGS, { 
                email_notification: emailNotification, 
                sms_notification: smsNotification, 
                web_notification: webNotification });
            if (response) {
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const updateAccountDetails = createAsyncThunk(
    "accountSettings/updateAccountDetails",
    async ({ username, firstName, lastName, phone, phoneCountry }, { dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.post(ACCOUNT_SETTINGS_API_URLS.UPDATE_ACCOUNT_DETAILS, { 
                username, 
                first_name: firstName,
                last_name: lastName,
                phone,
                phone_country: phoneCountry });
            if (response) {
                dispatch(updateUserInfo(response.user));
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);

export const notifyUpdate = createAsyncThunk(
    "accountSettings/notifyUpdate",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(ACCOUNT_SETTINGS_API_URLS.NOTIFY_UPDATE);
            if (response) {
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);