import React from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { changePassword } from "@/features/auth/accountSettings/thunk";
import { extractErrorMessage } from '@/common/utils/stringUtils';

const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Please Enter Your Password"),
  newPassword: Yup.string()
    .required("Please enter your password")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Must contain at least one uppercase letter")
    .matches(/\d/, "Must contain at least one number"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
    .required("Please confirm your password"),
});

export function ChangePasswordTabContent() {
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState(null);

  const changePasswordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      dispatch(changePassword(values))
      .then((actionResult) => {
        if (changePassword.fulfilled.match(actionResult)) {
          changePasswordFormik.resetForm();
          setErrors(null);
          Swal.fire({ title: 'Success!', text: 'Your password has been changed successfully', icon: 'success', timer: 2000, showConfirmButton: false });
        } else {
          const apiError = actionResult.payload;
          if (apiError && apiError.data && apiError.data.error_message) {
            const errorMessage = apiError.data.error_message;
            setErrors(extractErrorMessage(errorMessage));
          }
        }
      });
    },
  });

  return (
    <Form onSubmit={changePasswordFormik.handleSubmit}>
      <Row className="g-2">
        <Col lg={4}>
          <div>
            <Label htmlFor="oldPassword" className="form-label">
              Old Password*
            </Label>
            <Input
              type="password"
              id="oldPassword"
              name="oldPassword"
              placeholder="Enter current password"
              onPaste={(e) => {
                e.preventDefault();
              }}
              onChange={changePasswordFormik.handleChange}
              onBlur={changePasswordFormik.handleBlur}
              value={changePasswordFormik.values.oldPassword || ""}
              invalid={
                changePasswordFormik.touched.oldPassword &&
                changePasswordFormik.errors.oldPassword
                  ? true
                  : false
              }
            />
            {changePasswordFormik.touched.oldPassword &&
            changePasswordFormik.errors.oldPassword ? (
              <FormFeedback type="invalid">{changePasswordFormik.errors.oldPassword}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={4}>
          <div>
            <Label htmlFor="newPassword" className="form-label">
              New Password*
            </Label>
            <Input
              type="password"
              id="newPassword"
              name="newPassword"
              placeholder="Enter new password"
              onPaste={(e) => {
                e.preventDefault();
              }}
              onChange={changePasswordFormik.handleChange}
              onBlur={changePasswordFormik.handleBlur}
              value={changePasswordFormik.values.newPassword || ""}
              invalid={
                changePasswordFormik.touched.newPassword &&
                !!changePasswordFormik.errors.newPassword
              }
            />
            {changePasswordFormik.touched.newPassword &&
            changePasswordFormik.errors.newPassword ? (
              <FormFeedback type="invalid">
                {changePasswordFormik.errors.newPassword}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={4}>
          <div>
            <Label htmlFor="confirmPassword" className="form-label">
              Confirm Password
            </Label>
            <Input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm password"
              onPaste={(e) => {
                e.preventDefault();
              }}
              onChange={changePasswordFormik.handleChange}
              onBlur={changePasswordFormik.handleBlur}
              value={changePasswordFormik.values.confirmPassword || ""}
              invalid={
                changePasswordFormik.touched.confirmPassword &&
                changePasswordFormik.errors.confirmPassword
                  ? true
                  : false
              }
            />
            {changePasswordFormik.touched.confirmPassword &&
            changePasswordFormik.errors.confirmPassword ? (
              <FormFeedback type="invalid">
                {changePasswordFormik.errors.confirmPassword}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col xs={12}>
          <div
            className="p-3 bg-light mb-2 rounded"
            id="changePasswordValidation"
          >
            <h5 className="fs-13">Password must contain:</h5>
            <p
              className={`fs-12 mb-2 ${
                changePasswordFormik.values.newPassword.length >= 8
                  ? "valid"
                  : "invalid"
              }`}
            >
              Minimum 8 characters
            </p>
            <p
              className={`fs-12 mb-2 ${
                /[a-z]/.test(changePasswordFormik.values.newPassword)
                  ? "valid"
                  : "invalid"
              }`}
            >
              At least one lowercase letter
            </p>
            <p
              className={`fs-12 mb-2 ${
                /[A-Z]/.test(changePasswordFormik.values.newPassword)
                  ? "valid"
                  : "invalid"
              }`}
            >
              At least one uppercase letter
            </p>
            <p
              className={`fs-12 mb-2 ${
                /\d/.test(changePasswordFormik.values.newPassword)
                  ? "valid"
                  : "invalid"
              }`}
            >
              At least one number
            </p>
          </div>
        </Col>

        <Col lg={12}>
          {errors && <div className="alert alert-danger mt-3" role="alert">
            {errors}
          </div>}
        </Col>
        <Col lg={12}>
          <div className="text-end">
            <Button type="submit" color="success">
              Change Password
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
