import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetNeedsRefresh } from '@/features/rulesEngine/slice';
import { apiClient } from "@/api/axiosClient";
import { RULES_ENGINE_API_URLS } from '@/api/apiConfig';


export const getRuleNodeType = createAsyncThunk(
    "rulesEngine/getRuleNodeType",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(RULES_ENGINE_API_URLS.GET_RULE_NODE_TYPES);
            if (response){
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const getRulesData = createAsyncThunk(
    "rulesEngine/getRulesData", 
    async ({ page, search }, { rejectWithValue }) => {
        try {
            const params = new URLSearchParams();
            if (page !== undefined) {
                params.append('page', page);
            }
            if (search !== undefined) {
                params.append('search', search);
            }

            const url = `${RULES_ENGINE_API_URLS.LIST_RULES}${params.toString() ? `?${params.toString()}` : ''}`;
            const response = await apiClient.get(url);
            if (response){
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const getRuleDetails = createAsyncThunk(
    "rulesEngine/getRuleDetails", 
    async ({ ruleId }, {rejectWithValue }) => {
        try {
            const response = await apiClient.get(RULES_ENGINE_API_URLS.RETRIEVE_RULE(ruleId));
            if (response){
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const createRule = createAsyncThunk(
    "rulesEngine/createRule", 
    async (data, {dispatch,  rejectWithValue }) => {
        try {
            const response = await apiClient.post(RULES_ENGINE_API_URLS.CREATE_RULE, data);
            if (response){
                dispatch(resetNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);

export const updateRule = createAsyncThunk(
    "rulesEngine/updateRule", 
    async ({ ruleId, data }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.patch(RULES_ENGINE_API_URLS.UPDATE_RULE(ruleId), data);
            if (response){
                dispatch(resetNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);

export const updateRuleChainConfiguration = createAsyncThunk(
    "rulesEngine/updateRuleChainConfiguration", 
    async ({ ruleId, data }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.patch(RULES_ENGINE_API_URLS.UPDATE_RULE(ruleId), {configuration: data});
            if (response){
                dispatch(resetNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const deleteRule = createAsyncThunk(
    "rulesEngine/deleteRule", 
    async ({ ruleId }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.delete(RULES_ENGINE_API_URLS.DELETE_RULE(ruleId));
            if (response){
                dispatch(resetNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const deleteMultipleRules = createAsyncThunk(
    "rulesEngine/bulkDeleteRules", 
    async ({ ruleIds }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.delete(RULES_ENGINE_API_URLS.BULK_DELETE_RULES, {data:{ ids: ruleIds }});
            if (response){
                dispatch(resetNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);