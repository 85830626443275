import React from "react";
import { ChartWidget } from "@/features/dashboards/components/widgets/Chart/ChartWidget";
import { DigitalClock } from "@/features/dashboards/components/widgets/Clock/DigitalClock";
import { AnalogClockWidget } from "@/features/dashboards/components/widgets/Clock/AnalogClockWidget";
import { DocumentsCard } from "@/features/dashboards/components/widgets/Navigation/DocumentsCard";
import { NavigationCard } from "@/features/dashboards/components/widgets/Navigation/NavigationCard";
import { GoogleMapWidget } from "@/features/dashboards/components/widgets/Map/GoogleMapWidget";
import { MapboxGLMapWidget } from "@/features/dashboards/components/widgets/Map/MapboxGLMapWidget";
import { DevicesTable } from "@/features/dashboards/components/widgets/tables/DevicesTable";
import { AlarmsTable } from "@/features/dashboards/components/widgets/tables/AlarmsTable";
import { CommonGauge } from "@/features/dashboards/components/widgets/guages/CommonGauge";


export function WidgetSelector({ widget }) {
  switch (widget.type) {
    case "lineChart":
      return <ChartWidget widget={widget} />;
    case "barChart":
      return <ChartWidget widget={widget} />;
    case "areaChart":
      return <ChartWidget widget={widget} />;
    case "heatmapChart":
      return <ChartWidget widget={widget} />;
    case "pieChart":
      return <ChartWidget widget={widget} />;
    case "analogClock":
      return <AnalogClockWidget widget={widget} />;
    case "digitalClock":
      return <DigitalClock widget={widget} />;
    case "navigationCard":
      return <NavigationCard widget={widget} />;
    case "documentsCard":
      return <DocumentsCard widget={widget} />;
    case "googleMap":
      return <GoogleMapWidget widget={widget} />;
    case "mapboxGLMap":
      return <MapboxGLMapWidget widget={widget} />;
    case "devicesTable":
      return <DevicesTable widget={widget} />;
    case "alarmsTable":
      return <AlarmsTable widget={widget} />;
    case "commonGauge":
      return <CommonGauge widget={widget} />;
    default:
      return null;
  }
}
