const country = [
    {
        id: 1,
        abbreviation: "AC",
        countryName: "Ascension Island",
        countryCode: "+247"
    },
    {
        id: 2,
        abbreviation: "AD",
        countryName: "Andorra",
        countryCode: "+376"
    },
    {
        id: 3,
        abbreviation: "AE",
        countryName: "United Arab Emirates",
        countryCode: "+971"
    },
    {
        id: 4,
        abbreviation: "AF",
        countryName: "Afghanistan",
        countryCode: "+93"
    },
    {
        id: 5,
        abbreviation: "AG",
        countryName: "Antigua and Barbuda",
        countryCode: "+1"
    },
    {
        id: 6,
        abbreviation: "AI",
        countryName: "Anguilla",
        countryCode: "+1"
    },
    {
        id: 7,
        abbreviation: "AM",
        countryName: "Armenia",
        countryCode: "+374"
    },
    {
        id: 8,
        abbreviation: "AO",
        countryName: "Angola",
        countryCode: "+244"
    },
    {
        id: 9,
        abbreviation: "AQ",
        countryName: "Antarctica",
        countryCode: "+672"
    },
    {
        id: 10,
        abbreviation: "AR",
        countryName: "Argentina",
        countryCode: "+54"
    },
    {
        id: 11,
        abbreviation: "AS",
        countryName: "American Samoa",
        countryCode: "+1"
    },
    {
        id: 12,
        abbreviation: "AT",
        countryName: "Austria",
        countryCode: "+43"
    },
    {
        id: 13,
        abbreviation: "AU",
        countryName: "Australia",
        countryCode: "+61"
    },
    {
        id: 14,
        abbreviation: "AW",
        countryName: "Aruba",
        countryCode: "+297"
    },
    {
        id: 15,
        abbreviation: "AX",
        countryName: "Aland Islands",
        countryCode: "+358"
    },
    {
        id: 16,
        abbreviation: "BA",
        countryName: "Bosnia and Herzegovina",
        countryCode: "+387"
    },
    {
        id: 17,
        abbreviation: "BB",
        countryName: "Barbados",
        countryCode: "+1"
    },
    {
        id: 18,
        abbreviation: "BD",
        countryName: "Bangladesh",
        countryCode: "+880"
    },
    {
        id: 19,
        abbreviation: "BE",
        countryName: "Belgium",
        countryCode: "+32"
    },
    {
        id: 20,
        abbreviation: "BF",
        countryName: "Burkina Faso",
        countryCode: "+226"
    },
    {
        id: 21,
        abbreviation: "BG",
        countryName: "Bulgaria",
        countryCode: "+359"
    },
    {
        id: 22,
        abbreviation: "BH",
        countryName: "Bahrain",
        countryCode: "+973"
    },
    {
        id: 23,
        abbreviation: "BI",
        countryName: "Burundi",
        countryCode: "+257"
    },
    {
        id: 24,
        abbreviation: "BJ",
        countryName: "Benin",
        countryCode: "+229"
    },
    {
        id: 25,
        abbreviation: "BL",
        countryName: "Saint Barthélemy",
        countryCode: "+590"
    },
    {
        id: 26,
        abbreviation: "BM",
        countryName: "Bermuda",
        countryCode: "+1"
    },
    {
        id: 27,
        abbreviation: "BN",
        countryName: "Brunei Darussalam",
        countryCode: "+673"
    },
    {
        id: 28,
        abbreviation: "BO",
        countryName: "Bolivia (Plurinational State of)",
        countryCode: "+591"
    },
    {
        id: 29,
        abbreviation: "BQ",
        countryName: "Bonaire, Sint Eustatius and Saba",
        countryCode: "+599"
    },
    {
        id: 30,
        abbreviation: "BR",
        countryName: "Brazil",
        countryCode: "+55"
    },
    {
        id: 31,
        abbreviation: "BS",
        countryName: "Bahamas",
        countryCode: "+1"
    },
    {
        id: 32,
        abbreviation: "BT",
        countryName: "Bhutan",
        countryCode: "+975"
    },
    {
        id: 33,
        abbreviation: "BV",
        countryName: "Bouvet Island",
        countryCode: "+47"
    },
    {
        id: 34,
        abbreviation: "BW",
        countryName: "Botswana",
        countryCode: "+267"
    },
    {
        id: 35,
        abbreviation: "BY",
        countryName: "Belarus",
        countryCode: "+375"
    },
    {
        id: 36,
        abbreviation: "BZ",
        countryName: "Belize",
        countryCode: "+501"
    },
    {
        id: 37,
        abbreviation: "CA",
        countryName: "Canada",
        countryCode: "+1"
    },
    {
        id: 38,
        abbreviation: "CC",
        countryName: "Cocos (Keeling) Island",
        countryCode: "+61"
    },
    {
        id: 39,
        abbreviation: "CD",
        countryName: "Democratic Republic of the Congo",
        countryCode: "+243"
    },
    {
        id: 40,
        abbreviation: "CF",
        countryName: "Central African Republic",
        countryCode: "+236"
    },
    {
        id: 41,
        abbreviation: "CG",
        countryName: "Republic of the Congo",
        countryCode: "+243"
    },
    {
        id: 42,
        abbreviation: "CH",
        countryName: "Switzerland",
        countryCode: "+41"
    },
    {
        id: 43,
        abbreviation: "CI",
        countryName: "Ivory Coast",
        countryCode: "+225"
    },
    {
        id: 44,
        abbreviation: "CK",
        countryName: "Cook Islands",
        countryCode: "+682"
    },
    {
        id: 45,
        abbreviation: "CL",
        countryName: "Chile",
        countryCode: "+56"
    },
    {
        id: 46,
        abbreviation: "CM",
        countryName: "Cameroon",
        countryCode: "+237"
    },
    {
        id: 47,
        abbreviation: "CN",
        countryName: "China",
        countryCode: "+86"
    },
    {
        id: 48,
        abbreviation: "CO",
        countryName: "Colombia",
        countryCode: "+57"
    },
    {
        id: 49,
        abbreviation: "CP",
        countryName: "Clipperton Island",
        countryCode: "+55"
    },
    {
        id: 50,
        abbreviation: "CR",
        countryName: "Costa Rica",
        countryCode: "+506"
    },
    {
        id: 51,
        abbreviation: "CU",
        countryName: "Cuba",
        countryCode: "+53"
    },
    {
        id: 52,
        abbreviation: "CV",
        countryName: "Cabo Verde",
        countryCode: "+238"
    },
    {
        id: 53,
        abbreviation: "CW",
        countryName: "Curaçao",
        countryCode: "+599"
    },
    {
        id: 54,
        abbreviation: "CX",
        countryName: "Christmas Island",
        countryCode: "+61"
    },
    {
        id: 55,
        abbreviation: "CY",
        countryName: "Cyprus",
        countryCode: "+357"
    },
    {
        id: 56,
        abbreviation: "CZ",
        countryName: "Czech Republic",
        countryCode: "+420"
    },
    {
        id: 57,
        abbreviation: "DE",
        countryName: "Germany",
        countryCode: "+49"
    },
    {
        id: 58,
        abbreviation: "DG",
        countryName: "Diego Garcia",
        countryCode: "+246"
    },
    {
        id: 59,
        abbreviation: "DJ",
        countryName: "Djibouti",
        countryCode: "+253"
    },
    {
        id: 60,
        abbreviation: "DK",
        countryName: "Denmark",
        countryCode: "+45"
    },
    {
        id: 61,
        abbreviation: "DM",
        countryName: "Dominica",
        countryCode: "+1"
    },
    {
        id: 62,
        abbreviation: "DOI",
        countryName: "Dominican Republic",
        countryCode: "+1"
    },
    {
        id: 63,
        abbreviation: "DZ",
        countryName: "Algeria",
        countryCode: "+213"
    },
    {
        id: 64,
        abbreviation: "EA",
        countryName: "Ceuta & Melilla",
        countryCode: "+34"
    },
    {
        id: 65,
        abbreviation: "EC",
        countryName: "Ecuador",
        countryCode: "+593"
    },
    {
        id: 66,
        abbreviation: "EE",
        countryName: "Estonia",
        countryCode: "+372"
    },
    {
        id: 67,
        abbreviation: "EG",
        countryName: "Egypt",
        countryCode: "+20"
    },
    {
        id: 68,
        abbreviation: "EH",
        countryName: "Western Sahara",
        countryCode: "+212"
    },
    {
        id: 69,
        abbreviation: "ER",
        countryName: "Eritrea",
        countryCode: "+291"
    },
    {
        id: 70,
        abbreviation: "ES",
        countryName: "Spain",
        countryCode: "+34"
    },
    {
        id: 71,
        abbreviation: "ESCT",
        countryName: "Catalonia",
        countryCode: "+34"
    },
    {
        id: 72,
        abbreviation: "ESGA",
        countryName: "Galicia",
        countryCode: "+34"
    },
    {
        id: 73,
        abbreviation: "ET",
        countryName: "Ethiopia",
        countryCode: "+251"
    },
    {
        id: 75,
        abbreviation: "FI",
        countryName: "Finland",
        countryCode: "+358"
    },
    {
        id: 76,
        abbreviation: "FJ",
        countryName: "Fiji",
        countryCode: "+679"
    },
    {
        id: 77,
        abbreviation: "FK",
        countryName: "Falkland Islands",
        countryCode: "+500"
    },
    {
        id: 78,
        abbreviation: "FM",
        countryName: "Federated States of Micronesia",
        countryCode: "+691"
    },
    {
        id: 79,
        abbreviation: "FO",
        countryName: "Faroe Islands",
        countryCode: "+298"
    },
    {
        id: 80,
        abbreviation: "FR",
        countryName: "France",
        countryCode: "+33"
    },
    {
        id: 81,
        abbreviation: "GA",
        countryName: "Gabon",
        countryCode: "+241"
    },
    {
        id: 86,
        abbreviation: "GD",
        countryName: "Grenada",
        countryCode: "+1"
    },
    {
        id: 87,
        abbreviation: "GE",
        countryName: "Georgia",
        countryCode: "+995"
    },
    {
        id: 88,
        abbreviation: "GF",
        countryName: "French Guiana",
        countryCode: "+594"
    },
    {
        id: 99,
        abbreviation: "GG",
        countryName: "Guernsey",
        countryCode: "+44"
    },
    {
        id: 90,
        abbreviation: "GH",
        countryName: "Ghana",
        countryCode: "+233"
    },
    {
        id: 91,
        abbreviation: "GI",
        countryName: "Gibraltar",
        countryCode: "+350"
    },
    {
        id: 92,
        abbreviation: "GL",
        countryName: "Greenland",
        countryCode: "+299"
    },
    {
        id: 93,
        abbreviation: "GM",
        countryName: "Gambia",
        countryCode: "+220"
    },
    {
        id: 94,
        abbreviation: "GN",
        countryName: "Guinea",
        countryCode: "+224"
    },
    {
        id: 95,
        abbreviation: "GP",
        countryName: "Guadeloupe",
        countryCode: "+590"
    },
    {
        id: 96,
        abbreviation: "GQ",
        countryName: "Equatorial Guinea",
        countryCode: "+240"
    },
    {
        id: 97,
        abbreviation: "GR",
        countryName: "Greece",
        countryCode: "+30"
    },
    {
        id: 98,
        abbreviation: "GS",
        countryName: "South Georgia and the South Sandwich Islands",
        countryCode: "+500"
    },
    {
        id: 99,
        abbreviation: "GT",
        countryName: "Guatemala",
        countryCode: "+502"
    },
    {
        id: 100,
        abbreviation: "GU",
        countryName: "Guam",
        countryCode: "+1"
    },
    {
        id: 101,
        abbreviation: "GW",
        countryName: "Guinea-Bissau",
        countryCode: "+245"
    },
    {
        id: 102,
        abbreviation: "GY",
        countryName: "Guyana",
        countryCode: "+592"
    },
    {
        id: 103,
        abbreviation: "HK",
        countryName: "Hong Kong",
        countryCode: "+852"
    },
    {
        id: 104,
        abbreviation: "HN",
        countryName: "Honduras",
        countryCode: "+504"
    },
    {
        id: 105,
        abbreviation: "HR",
        countryName: "Croatia",
        countryCode: "+385"
    },
    {
        id: 106,
        abbreviation: "HT",
        countryName: "Haiti",
        countryCode: "+509"
    },
    {
        id: 107,
        abbreviation: "HU",
        countryName: "Hungary",
        countryCode: "+36"
    },
    {
        id: 108,
        abbreviation: "ID",
        countryName: "Indonesia",
        countryCode: "+62"
    },
    {
        id: 109,
        abbreviation: "IE",
        countryName: "Ireland",
        countryCode: "+353"
    },
    {
        id: 110,
        abbreviation: "IL",
        countryName: "Israel",
        countryCode: "+972"
    },
    {
        id: 111,
        abbreviation: "IM",
        countryName: "Isle of Man",
        countryCode: "+44"
    },
    {
        id: 112,
        abbreviation: "IN",
        countryName: "India",
        countryCode: "+91"
    },
    {
        id: 113,
        abbreviation: "IO",
        countryName: "British Indian Ocean Territory",
        countryCode: "+246"
    },
    {
        id: 114,
        abbreviation: "IQ",
        countryName: "Iraq",
        countryCode: "+964"
    },
    {
        id: 115,
        abbreviation: "IR",
        countryName: "Iran (Islamic Republic of)",
        countryCode: "+98"
    },
    {
        id: 116,
        abbreviation: "IS",
        countryName: "Iceland",
        countryCode: "+354"
    },
    {
        id: 117,
        abbreviation: "IT",
        countryName: "Italy",
        countryCode: "+39"
    },
    {
        id: 118,
        abbreviation: "JE",
        countryName: "Jersey",
        countryCode: "+44"
    },
    {
        id: 119,
        abbreviation: "JM",
        countryName: "Jamaica",
        countryCode: "+1"
    },
    {
        id: 120,
        abbreviation: "JO",
        countryName: "Jordan",
        countryCode: "+962"
    },
    {
        id: 121,
        abbreviation: "JP",
        countryName: "Japan",
        countryCode: "+81"
    },
    {
        id: 122,
        abbreviation: "KE",
        countryName: "Kenya",
        countryCode: "+254"
    },
    {
        id: 123,
        abbreviation: "KG",
        countryName: "Kyrgyzstan",
        countryCode: "+996"
    },
    {
        id: 124,
        abbreviation: "KH",
        countryName: "Cambodia",
        countryCode: "+855"
    },
    {
        id: 125,
        abbreviation: "KI",
        countryName: "Kiribati",
        countryCode: "+686"
    },
    {
        id: 126,
        abbreviation: "KM",
        countryName: "Comoros",
        countryCode: "+269"
    },
    {
        id: 127,
        abbreviation: "KN",
        countryName: "Saint Kitts and Nevis",
        countryCode: "+1"
    },
    {
        id: 128,
        abbreviation: "KP",
        countryName: "North Korea",
        countryCode: "+850"
    },
    {
        id: 129,
        abbreviation: "KR",
        countryName: "South Korea",
        countryCode: "+82"
    },
    {
        id: 130,
        abbreviation: "KW",
        countryName: "Kuwait",
        countryCode: "+965"
    },
    {
        id: 131,
        abbreviation: "KY",
        countryName: "Cayman Islands",
        countryCode: "+1"
    },
    {
        id: 132,
        abbreviation: "KZ",
        countryName: "Kazakhstan",
        countryCode: "+7"
    },
    {
        id: 133,
        abbreviation: "LA",
        countryName: "Laos",
        countryCode: "+856"
    },
    {
        id: 134,
        abbreviation: "LB",
        countryName: "Lebanon",
        countryCode: "+961"
    },
    {
        id: 135,
        abbreviation: "LC",
        countryName: "Saint Lucia",
        countryCode: "+1"
    },
    {
        id: 136,
        abbreviation: "LI",
        countryName: "Liechtenstein",
        countryCode: "+423"
    },
    {
        id: 137,
        abbreviation: "LK",
        countryName: "Sri Lanka",
        countryCode: "+94"
    },
    {
        id: 138,
        abbreviation: "LR",
        countryName: "Liberia",
        countryCode: "+231"
    },
    {
        id: 139,
        abbreviation: "LS",
        countryName: "Lesotho",
        countryCode: "+266"
    },
    {
        id: 140,
        abbreviation: "LT",
        countryName: "Lithuania",
        countryCode: "+370"
    },
    {
        id: 141,
        abbreviation: "LU",
        countryName: "Luxembourg",
        countryCode: "+352"
    },
    {
        id: 142,
        abbreviation: "LV",
        countryName: "Latvia",
        countryCode: "+371"
    },
    {
        id: 143,
        abbreviation: "LY",
        countryName: "Libya",
        countryCode: "+218"
    },
    {
        id: 144,
        abbreviation: "MA",
        countryName: "Morocco",
        countryCode: "+212"
    },
    {
        id: 145,
        abbreviation: "MC",
        countryName: "Monaco",
        countryCode: "+377"
    },
    {
        id: 146,
        abbreviation: "MD",
        countryName: "Moldova",
        countryCode: "+373"
    },
    {
        id: 147,
        abbreviation: "ME",
        countryName: "Montenegro",
        countryCode: "+382"
    },
    {
        id: 148,
        abbreviation: "MF",
        countryName: "Saint Martin",
        countryCode: "+721"
    },
    {
        id: 149,
        abbreviation: "MG",
        countryName: "Madagascar",
        countryCode: "+261"
    },
    {
        id: 150,
        abbreviation: "MH",
        countryName: "Marshall Islands",
        countryCode: "+692"
    },
    {
        id: 151,
        abbreviation: "MK",
        countryName: "North Macedonia",
        countryCode: "+389"
    },
    {
        id: 152,
        abbreviation: "ML",
        countryName: "Mali",
        countryCode: "+223"
    },
    {
        id: 153,
        abbreviation: "MM",
        countryName: "Myanmar",
        countryCode: "+95"
    },
    {
        id: 154,
        abbreviation: "MN",
        countryName: "Mongolia",
        countryCode: "+976"
    },
    {
        id: 155,
        abbreviation: "MO",
        countryName: "Macau",
        countryCode: "+853"
    },
    {
        id: 156,
        abbreviation: "MP",
        countryName: "Northern Mariana Islands",
        countryCode: "+1"
    },
    {
        id: 157,
        abbreviation: "MQ",
        countryName: "Martinique",
        countryCode: "+596"
    },
    {
        id: 158,
        abbreviation: "MR",
        countryName: "Mauritania",
        countryCode: "+222"
    },
    {
        id: 159,
        abbreviation: "MS",
        countryName: "Montserrat",
        countryCode: "+1"
    },
    {
        id: 160,
        abbreviation: "MT",
        countryName: "Malta",
        countryCode: "+356"
    },
    {
        id: 161,
        abbreviation: "MU",
        countryName: "Mauritius",
        countryCode: "+230"
    },
    {
        id: 162,
        abbreviation: "MV",
        countryName: "Maldives",
        countryCode: "+960"
    },
    {
        id: 163,
        abbreviation: "MW",
        countryName: "Malawi",
        countryCode: "+265"
    },
    {
        id: 164,
        abbreviation: "MX",
        countryName: "Mexico",
        countryCode: "+52"
    },
    {
        id: 165,
        abbreviation: "MY",
        countryName: "Malaysia",
        countryCode: "+60"
    },
    {
        id: 166,
        abbreviation: "MZ",
        countryName: "Mozambique",
        countryCode: "+258"
    },
    {
        id: 167,
        abbreviation: "NA",
        countryName: "Namibia",
        countryCode: "+264"
    },
    {
        id: 168,
        abbreviation: "NC",
        countryName: "New Caledonia",
        countryCode: "+687"
    },
    {
        id: 169,
        abbreviation: "NE",
        countryName: "Niger",
        countryCode: "+227"
    },
    {
        id: 170,
        abbreviation: "NF",
        countryName: "Norfolk Island",
        countryCode: "+672"
    },
    {
        id: 171,
        abbreviation: "NG",
        countryName: "Nigeria",
        countryCode: "+234"
    },
    {
        id: 172,
        abbreviation: "NI",
        countryName: "Nicaragua",
        countryCode: "+505"
    },
    {
        id: 173,
        abbreviation: "NL",
        countryName: "Netherlands",
        countryCode: "+31"
    },
    {
        id: 174,
        abbreviation: "NO",
        countryName: "Norway",
        countryCode: "+47"
    },
    {
        id: 175,
        abbreviation: "NP",
        countryName: "Nepal",
        countryCode: "+977"
    },
    {
        id: 176,
        abbreviation: "NR",
        countryName: "Nauru",
        countryCode: "+674"
    },
    {
        id: 177,
        abbreviation: "NU",
        countryName: "Niue",
        countryCode: "+683"
    },
    {
        id: 178,
        abbreviation: "NZ",
        countryName: "New Zealand",
        countryCode: "+64"
    },
    {
        id: 179,
        abbreviation: "OM",
        countryName: "Oman",
        countryCode: "+968"
    },
    {
        id: 180,
        abbreviation: "PA",
        countryName: "Panama",
        countryCode: "+507"
    },
    {
        id: 181,
        abbreviation: "PE",
        countryName: "Peru",
        countryCode: "+51"
    },
    {
        id: 182,
        abbreviation: "PF",
        countryName: "French Polynesia",
        countryCode: "+689"
    },
    {
        id: 183,
        abbreviation: "PG",
        countryName: "Papua New Guinea",
        countryCode: "+675"
    },
    {
        id: 184,
        abbreviation: "PH",
        countryName: "Philippines",
        countryCode: "+63"
    },
    {
        id: 185,
        abbreviation: "PK",
        countryName: "Pakistan",
        countryCode: "+92"
    },
    {
        id: 186,
        abbreviation: "PL",
        countryName: "Poland",
        countryCode: "+48"
    },
    {
        id: 187,
        abbreviation: "PM",
        countryName: "Saint Pierre and Miquelon",
        countryCode: "+508"
    },
    {
        id: 188,
        abbreviation: "PN",
        countryName: "Pitcairn",
        countryCode: "+64"
    },
    {
        id: 189,
        abbreviation: "PR",
        countryName: "Puerto Rico",
        countryCode: "+1"
    },
    {
        id: 190,
        abbreviation: "PS",
        countryName: "State of Palestine",
        countryCode: "+970"
    },
    {
        id: 191,
        abbreviation: "PT",
        countryName: "Portugal",
        countryCode: "+351"
    },
    {
        id: 192,
        abbreviation: "PW",
        countryName: "Palau",
        countryCode: "+680"
    },
    {
        id: 193,
        abbreviation: "PY",
        countryName: "Paraguay",
        countryCode: "+595"
    },
    {
        id: 194,
        abbreviation: "QA",
        countryName: "Qatar",
        countryCode: "+974"
    },
    {
        id: 195,
        abbreviation: "RE",
        countryName: "Réunion",
        countryCode: "+262"
    },
    {
        id: 196,
        abbreviation: "RO",
        countryName: "Romania",
        countryCode: "+40"
    },
    {
        id: 197,
        abbreviation: "RS",
        countryName: "Serbia",
        countryCode: "+381"
    },
    {
        id: 198,
        abbreviation: "RU",
        countryName: "Russia",
        countryCode: "+7"
    },
    {
        id: 199,
        abbreviation: "RW",
        countryName: "Rwanda",
        countryCode: "+250"
    },
    {
        id: 200,
        abbreviation: "SA",
        countryName: "Saudi Arabia",
        countryCode: "+966"
    },
    {
        id: 201,
        abbreviation: "SB",
        countryName: "Solomon Islands",
        countryCode: "+677"
    },
    {
        id: 202,
        abbreviation: "SC",
        countryName: "Seychelles",
        countryCode: "+248"
    },
    {
        id: 203,
        abbreviation: "SD",
        countryName: "Sudan",
        countryCode: "+249"
    },
    {
        id: 204,
        abbreviation: "SE",
        countryName: "Sweden",
        countryCode: "+46"
    },
    {
        id: 205,
        abbreviation: "SG",
        countryName: "Singapore",
        countryCode: "+65"
    },
    {
        id: 207,
        abbreviation: "SI",
        countryName: "Slovenia",
        countryCode: "+386"
    },
    {
        id: 208,
        abbreviation: "SJ",
        countryName: "Svalbard and Jan Mayen",
        countryCode: "47"
    },
    {
        id: 209,
        abbreviation: "SK",
        countryName: "Slovakia",
        countryCode: "+421"
    },
    {
        id: 210,
        abbreviation: "SL",
        countryName: "Sierra Leone",
        countryCode: "+232"
    },
    {
        id: 212,
        abbreviation: "SM",
        countryName: "San Marino",
        countryCode: "+378"
    },
    {
        id: 213,
        abbreviation: "SN",
        countryName: "Senegal",
        countryCode: "+221"
    },
    {
        id: 214,
        abbreviation: "SO",
        countryName: "Somalia",
        countryCode: "+252"
    },
    {
        id: 215,
        abbreviation: "SR",
        countryName: "Suriname",
        countryCode: "+597"
    },
    {
        id: 216,
        abbreviation: "SS",
        countryName: "South Sudan",
        countryCode: "+211"
    },
    {
        id: 217,
        abbreviation: "ST",
        countryName: "Sao Tome and Principe",
        countryCode: "+239"
    },
    {
        id: 218,
        abbreviation: "SV",
        countryName: "El Salvador",
        countryCode: "+503"
    },
    {
        id: 219,
        abbreviation: "SX",
        countryName: "Sint Maarten",
        countryCode: "+721"
    },
    {
        id: 220,
        abbreviation: "SY",
        countryName: "Syria",
        countryCode: "+963"
    },
    {
        id: 221,
        abbreviation: "SZ",
        countryName: "Eswatini",
        countryCode: "+268"
    },
    {
        id: 222,
        abbreviation: "TA",
        countryName: "Tristan da Cunha",
        countryCode: "+290"
    },
    {
        id: 223,
        abbreviation: "TC",
        countryName: "Turks and Caicos Islands",
        countryCode: "+1"
    },
    {
        id: 224,
        abbreviation: "TD",
        countryName: "Chad",
        countryCode: "+235"
    },
    {
        id: 225,
        abbreviation: "TG",
        countryName: "Togo",
        countryCode: "+228"
    },
    {
        id: 226,
        abbreviation: "TH",
        countryName: "Thailand",
        countryCode: "+66"
    },
    {
        id: 227,
        abbreviation: "TJ",
        countryName: "Tajikistan",
        countryCode: "+992"
    },
    {
        id: 228,
        abbreviation: "TK",
        countryName: "Tokelau",
        countryCode: "+690"
    },
    {
        id: 229,
        abbreviation: "TL",
        countryName: "Timor-Leste",
        countryCode: "+670"
    },
    {
        id: 230,
        abbreviation: "TM",
        countryName: "Turkmenistan",
        countryCode: "+993"
    },
    {
        id: 231,
        abbreviation: "TN",
        countryName: "Tunisia",
        countryCode: "+216"
    },
    {
        id: 232,
        abbreviation: "TO",
        countryName: "Tonga",
        countryCode: "+676"
    },
    {
        id: 233,
        abbreviation: "TR",
        countryName: "Turkey",
        countryCode: "+90"
    },
    {
        id: 234,
        abbreviation: "TT",
        countryName: "Trinidad and Tobago",
        countryCode: "+1"
    },
    {
        id: 235,
        abbreviation: "TV",
        countryName: "Tuvalu",
        countryCode: "+688"
    },
    {
        id: 236,
        abbreviation: "TW",
        countryName: "Taiwan",
        countryCode: "+886"
    },
    {
        id: 237,
        abbreviation: "TZ",
        countryName: "Tanzania",
        countryCode: "+255"
    },
    {
        id: 238,
        abbreviation: "UA",
        countryName: "Ukraine",
        countryCode: "+380"
    },
    {
        id: 239,
        abbreviation: "UG",
        countryName: "Uganda",
        countryCode: "+256"
    },
    {
        id: 259,
        abbreviation: "GB",
        countryName: "United Kingdom",
        countryCode: "+44"
    },
    {
        id: 240,
        abbreviation: "US",
        countryName: "United States of America",
        countryCode: "+1"
    },
    {
        id: 242,
        abbreviation: "UY",
        countryName: "Uruguay",
        countryCode: "+598"
    },
    {
        id: 243,
        abbreviation: "UZ",
        countryName: "Uzbekistan",
        countryCode: "+998"
    },
    {
        id: 244,
        abbreviation: "VA",
        countryName: "Holy See",
        countryCode: "+379"
    },
    {
        id: 245,
        abbreviation: "VC",
        countryName: "Saint Vincent and the Grenadines",
        countryCode: "+1"
    },
    {
        id: 246,
        abbreviation: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
        countryCode: "+58"
    },
    {
        id: 247,
        abbreviation: "VG",
        countryName: "Virgin Islands (British)",
        countryCode: "+1"
    },
    {
        id: 248,
        abbreviation: "VI",
        countryName: "Virgin Islands (U.S.)",
        countryCode: "+1"
    },
    {
        id: 249,
        abbreviation: "VN",
        countryName: "Vietnam",
        countryCode: "+84"
    },
    {
        id: 250,
        abbreviation: "VU",
        countryName: "Vanuatu",
        countryCode: "+678"
    },
    {
        id: 251,
        abbreviation: "WF",
        countryName: "Wallis and Futuna",
        countryCode: "+681"
    },
    {
        id: 252,
        abbreviation: "WS",
        countryName: "Samoa",
        countryCode: "+685"
    },
    {
        id: 253,
        abbreviation: "XK",
        countryName: "Kosovo",
        countryCode: "+383"
    },
    {
        id: 254,
        abbreviation: "YE",
        countryName: "Yemen",
        countryCode: "+967"
    },
    {
        id: 255,
        abbreviation: "YT",
        countryName: "Mayotte",
        countryCode: "+262"
    },
    {
        id: 256,
        abbreviation: "ZA",
        countryName: "South Africa",
        countryCode: "+27"
    },
    {
        id: 257,
        abbreviation: "ZM",
        countryName: "Zambia",
        countryCode: "+260"
    },
    {
        id: 258,
        abbreviation: "ZW",
        countryName: "Zimbabwe",
        countryCode: "+263"
    }
]

export default country; 