import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { VectorMap } from "@south-paw/react-vector-maps";
import world from "@/assets/json/data/world.svg.json";
import { Card, CardBody, CardHeader, CardTitle, UncontrolledAlert } from "reactstrap";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

export function DeviceLocationMap() {
  const deviceDetailData = useSelector((state) => state.devices.deviceDetailData);

  const parseAttributeValue = (value) => {
    if (typeof value === 'object' || typeof value === 'boolean') {
      return null;
    }
    const parsedNumber = Number(value);
    return isNaN(parsedNumber) ? null : parsedNumber;
  };

  const { latitude, longitude } = useMemo(() => {
    const latitude = parseAttributeValue(deviceDetailData?.latitude);
    const longitude = parseAttributeValue(deviceDetailData?.longitude);
    return { latitude, longitude };
  }, [deviceDetailData]);

  const isValidCoordinates = useMemo(() => {
    return (
      latitude !== null &&
      longitude !== null &&
      latitude >= -90 &&
      latitude <= 90 &&
      longitude >= -180 &&
      longitude <= 180
    );
  }, [latitude, longitude]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  return (
    <React.Fragment>
      <Card className="card-height-100 border">
        <CardHeader className="align-items-center d-flex">
          <CardTitle>Device Location</CardTitle>
        </CardHeader>

        <CardBody>
          {isLoaded &&
            (latitude !== null && longitude !== null ? (
              isValidCoordinates ? (
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "400px" }}
                  center={{ lat: latitude, lng: longitude }}
                  zoom={10}
                >
                  <Marker position={{ lat: latitude, lng: longitude }} />
                </GoogleMap>
              ) : (
                <>
                  <UncontrolledAlert color="danger">
                    The latitude must be between -90 and 90, and the longitude must be between -180 and 180.
                  </UncontrolledAlert>
                  <div data-colors='["--vz-light", "--vz-success", "--vz-primary"]' dir="ltr">
                    <div id="world_map_line_markers" className="custom-vector-map">
                      <VectorMap {...world} />
                    </div>
                  </div>
                </>
              )
            ) : (
              <>
                <UncontrolledAlert color="info">
                  To view the <strong>device location</strong>, please ensure the{" "}
                  <strong>latitude and longitude</strong> attributes are set correctly in the device settings.
                </UncontrolledAlert>
                <div data-colors='["--vz-light", "--vz-success", "--vz-primary"]' dir="ltr">
                  <div id="world_map_line_markers" className="custom-vector-map">
                    <VectorMap {...world} />
                  </div>
                </div>
              </>
            ))}
          {!isLoaded && <div>Loading...</div>}
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
