import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { getDeviceTelemetryStatus } from "@/features/devices/thunk";
import { TimeRangeChart } from "@/components/charts/TimeRangeChart";


export function DeviceDataTransmissionChart({ deviceId }) {
  if (!deviceId) return null;
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const [timeRange, setTimeRange] = useState("day");
  const [data, setData] = useState([]); // [ { time: "2021-10-01T00:00:00Z", count: 0 }, ... ]

  const handleTimeRangeChange = (timeSelection) => {
    setTimeRange(timeSelection);
    dispatch(getDeviceTelemetryStatus({ deviceIds: [deviceId], timeRange: timeSelection })).then((actionResult) => {
      if (getDeviceTelemetryStatus.fulfilled.match(actionResult) && actionResult.payload) {
        setData(actionResult.payload);
      }
    });
  };

  const triggerResizeEvent = () => {
    window.dispatchEvent(new Event("resize"));
  };

  useEffect(() => {
    // set the component as mounted
    isMounted.current = true;

    // cleanup
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // trigger window resize event to re-render the chart; fix the "Uncaught TypeError: Cannot read properties of null (reading '45')" bug
    if (isMounted.current) {
      triggerResizeEvent();
    }
  }, []);

  useEffect(() => {
    handleTimeRangeChange(timeRange);

    const intervalId = setInterval(() => {
      handleTimeRangeChange(timeRange);
    }, 60000); // Update data every minute

    return () => clearInterval(intervalId);
  }, [timeRange]);

  return (
    <React.Fragment>
      <TimeRangeChart
        type="bar"
        title="Device Data Transmission"
        data={data}
        dataName="Received Data"
        timeRange={timeRange}
        timeRangeTypes={["minute", "hour", "day", "week", "month"]}
        onTimeRangeChange={handleTimeRangeChange}
        minHeight={350}
        className="card-height-100"
      />
    </React.Fragment>
  );
}
