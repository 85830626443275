import React from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';

//Import Icons
import FeatherIcon from "feather-icons-react";

//import images
import illustarator from "@/assets/images/illustration/user-illustarator-2.png";
import { Button } from 'reactstrap';
import { useState } from 'react';
import { UpdatePlanModal } from '@/components/modals/UpdatePlanModal';

export function UpgradeAccountNotise(){
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <Card className='border'>
                        <CardBody className="p-0">
                            <Alert className="alert alert-success border-2 rounded-0 m-0 d-flex align-items-center" role="alert">
                                <FeatherIcon
                                    icon="alert-triangle"
                                    className="text-success me-2 icon-sm"
                                />
                                <div className="flex-grow-1 text-truncate">
                                    Your are in  <b>beta</b> version.
                                </div>
                                <div className="flex-shrink-0">
                                    <Button color='link' className="text-reset text-decoration-underline p-0" onClick={toggleModal}><b>View</b></Button>
                                </div>
                            </Alert>

                            <Row className="align-items-end">
                                <Col sm={8}>
                                    <div className="p-3">
                                        <p className="fs-16 lh-base">Your account is currently in beta version</p>
                                        <p>More features and more resources capacity will be added soon
                                            <i className="mdi mdi-arrow-right ms-2"></i></p>
                                        <div className="mt-3">
                                            <Button color="success" onClick={toggleModal}>More Info</Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="px-3">
                                        <img src={illustarator} className="img-fluid" alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <UpdatePlanModal showModal={showModal} toggleModal={toggleModal} />
        </React.Fragment>
    );
};