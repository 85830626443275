import { createSlice } from '@reduxjs/toolkit';
import { loginUser, loginOauth } from '@/features/auth/login/thunk';
import { clearLoggedInInfo, setUserProfile } from '@/features/auth/authService';


const initialState = {
  user: null,
  error: null,
  status: 'unauthenticated',
  isLoading: false,
  accessToken: null,
  refreshToken: null,
};

const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.user = null;
      state.error = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.status= 'unauthenticated';
      clearLoggedInInfo();
    },
    updateUserInfo(state, action) {
      state.user = action.payload;
      setUserProfile(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = 'authenticated';
        state.user = action.payload.user;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to login';
      })
      .addCase(loginOauth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginOauth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = 'authenticated';
        state.user = action.payload.user;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
      })
      .addCase(loginOauth.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to oauth login';
      });
  },
});

export const { logout, updateUserInfo } = loginSlice.actions;
export default loginSlice.reducer;