import { createSlice } from '@reduxjs/toolkit';
import { getRulesData, getRuleNodeType } from '@/features/rulesEngine/thunk';


export const initialState = {
    isLoading: false,
    needsRefresh: false,
    ruleNodeTypes: [],
    rulesData: [],
};


const RulesEngineSlice = createSlice({
    name: 'rulesEngine',
    initialState,
    reducers: {
        resetNeedsRefresh(state) {
            state.needsRefresh = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRulesData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getRulesData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.rulesData = action.payload;
        });
        builder.addCase(getRulesData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getRuleNodeType.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ruleNodeTypes = action.payload;
        });
    }
});

export default RulesEngineSlice.reducer;
export const { resetNeedsRefresh } = RulesEngineSlice.actions;