export const BASE_API_URL = process.env.REACT_APP_API_URL;


export const AUTH_URLS = {
    LOGIN:                                                  `${BASE_API_URL}/accounts/login/`,
    REGISTER:                                               `${BASE_API_URL}/accounts/register/`,
    REQUEST_RESET_PASSWORD:                                 `${BASE_API_URL}/accounts/request-reset-password/`,
    REQUEST_EMAIL_VERIFICATION:                             `${BASE_API_URL}/accounts/request-email-verification/`,
    VERIFY_EMAIL:                                           `${BASE_API_URL}/accounts/verify-email/`, 
    RESET_PASSWORD:                                         `${BASE_API_URL}/accounts/reset-password/`,
    REFRESH_TOKEN:                                          `${BASE_API_URL}/accounts/token/refresh/`,
    VERIFY_TOKEN:                                           `${BASE_API_URL}/accounts/token/verify/`,
    EDIT_PROFILE:                                           `${BASE_API_URL}/accounts/edit-profile/`,
};

export const OAUTH_TOKEN_URLS = {
    'github':                                               `${BASE_API_URL}/accounts/oauth/github/`,
    'google':                                               `${BASE_API_URL}/accounts/oauth/google/`,
}

export const DEVICE_API_URLS = {
    GET_STATISTICS_DATA:                                    `${BASE_API_URL}/devices/statistics/`,
    GET_DEVICES:                                            `${BASE_API_URL}/devices/manage/`,
    CREATE_DEVICE:                                          `${BASE_API_URL}/devices/manage/`,
    RETRIEVE_DEVICE:                                (id) => `${BASE_API_URL}/devices/manage/${id}/`,
    UPDATE_DEVICE:                                  (id) => `${BASE_API_URL}/devices/manage/${id}/`,
    DELETE_DEVICE:                                  (id) => `${BASE_API_URL}/devices/manage/${id}/`,
    BULK_DELETE_DEVICES:                                    `${BASE_API_URL}/devices/manage/bulk-delete/`,
    BULK_RETRIEVE_DEVICES:                                  `${BASE_API_URL}/devices/manage/bulk-retrieve/`,
    GET_DEVICE_ATTRIBUTES:                                  `${BASE_API_URL}/devices/attributes/by-devices/`,
    CREATE_DEVICE_ATTRIBUTES:                               `${BASE_API_URL}/devices/attributes/`,
    DELETE_DEVICE_ATTRIBUTES:                       (id) => `${BASE_API_URL}/devices/attributes/${id}/`,
    UPDATE_DEVICE_ATTRIBUTES:                       (id) => `${BASE_API_URL}/devices/attributes/${id}/`,
    BULK_DELETE_DEVICE_ATTRIBUTES:                          `${BASE_API_URL}/devices/attributes/bulk-delete/`,
    GET_TELEMETRY_BY_DEVICES:                               `${BASE_API_URL}/devices/telemetry/`,
    GET_TELEMETRY_STATUS_BY_DEVICES:                        `${BASE_API_URL}/devices/telemetry/stats/`,
    GET_LATEST_TELEMETRY_BY_DEVICE:                         `${BASE_API_URL}/devices/telemetry/latest_by_device/`,
};

export const AUDIT_LOG_API_URLS = { 
    GET_AUDIT_LOGS:                                         `${BASE_API_URL}/audit/manage/`,
    GET_LOGIN_LOGS:                                         `${BASE_API_URL}/audit/login-logs/`,
    GET_STATISTICS_DATA:                                    `${BASE_API_URL}/audit/statistics/`,
    GET_AUDIT_LOGS_BY_DEVICE:                               `${BASE_API_URL}/audit/manage/by-devices/`,
};

export const ALARM_API_URLS = {
    GET_ALARMS:                                             `${BASE_API_URL}/alarms/manage/`,
    GET_ALARMS_BY_DEVICES:                                  `${BASE_API_URL}/alarms/manage/by-devices/`,
    UPDATE_ALARM:                                   (id) => `${BASE_API_URL}/alarms/manage/${id}/`,
    GET_STATISTICS_DATA:                                    `${BASE_API_URL}/alarms/statistics/`,
    GET_DISTRIBUTION_DATA:                                  `${BASE_API_URL}/alarms/distribution/`,
};

export const ACCOUNT_SETTINGS_API_URLS = {
    GET_ACCOUNT_SETTINGS:                                   `${BASE_API_URL}/accounts/settings/`,
    UPDATE_ACCOUNT_DETAILS:                                 `${BASE_API_URL}/accounts/update-details/`,
    CHANGE_PASSWORD:                                        `${BASE_API_URL}/accounts/change-password/`,
    GET_SETTINGS:                                           `${BASE_API_URL}/accounts/settings/`,
    UPDATE_SETTINGS:                                        `${BASE_API_URL}/accounts/settings/`,
    NOTIFY_UPDATE:                                          `${BASE_API_URL}/accounts/notify-update/`,
};

export const DASHBOARDS_API_URLS = {
    LIST_DASHBOARDS:                                        `${BASE_API_URL}/dashboards/manage/`,
    CREATE_DASHBOARD:                                       `${BASE_API_URL}/dashboards/manage/`,
    RETRIEVE_DASHBOARD:                             (id) => `${BASE_API_URL}/dashboards/manage/${id}/`,
    UPDATE_DASHBOARD:                               (id) => `${BASE_API_URL}/dashboards/manage/${id}/`,
    DELETE_DASHBOARD:                               (id) => `${BASE_API_URL}/dashboards/manage/${id}/`,
    BULK_DELETE_DASHBOARDS:                                 `${BASE_API_URL}/dashboards/manage/bulk-delete/`,

    LIST_WIDGETS:                                           `${BASE_API_URL}/dashboards/widgets/`,
    CREATE_WIDGET:                                          `${BASE_API_URL}/dashboards/widgets/`,
    RETRIEVE_WIDGET:                                (id) => `${BASE_API_URL}/dashboards/widgets/${id}/`,
    UPDATE_WIDGET:                                  (id) => `${BASE_API_URL}/dashboards/widgets/${id}/`,
    DELETE_WIDGET:                                  (id) => `${BASE_API_URL}/dashboards/widgets/${id}/`,
};

export const RULES_ENGINE_API_URLS = {
    LIST_RULES:                                             `${BASE_API_URL}/rules-engine/manage/`,
    CREATE_RULE:                                            `${BASE_API_URL}/rules-engine/manage/`,
    RETRIEVE_RULE:                                  (id) => `${BASE_API_URL}/rules-engine/manage/${id}/`,
    UPDATE_RULE:                                    (id) => `${BASE_API_URL}/rules-engine/manage/${id}/`,
    DELETE_RULE:                                    (id) => `${BASE_API_URL}/rules-engine/manage/${id}/`,
    BULK_DELETE_RULES:                                      `${BASE_API_URL}/rules-engine/manage/bulk-delete/`,
    GET_RULE_NODE_TYPES:                                    `${BASE_API_URL}/rules-engine/rule-node-types/`,
};

export const NOTIFICATIONS_API_URLS = {
    LIST_NOTIFICATIONS:                                     `${BASE_API_URL}/notifications/manage/`,
    RETRIEVE_NOTIFICATION:                           (id) => `${BASE_API_URL}/notifications/manage/${id}/`,
    UPDATE_NOTIFICATION:                             (id) => `${BASE_API_URL}/notifications/manage/${id}/`,
    DELETE_NOTIFICATION:                             (id) => `${BASE_API_URL}/notifications/manage/${id}/`,
    MARK_NOTIFICATIONS_AS_READ:                              `${BASE_API_URL}/notifications/manage/mark-read/`,
    BULK_DELETE_NOTIFICATIONS:                               `${BASE_API_URL}/notifications/manage/bulk-delete/`,
    LIST_MESSAGE_NOTIFICATIONS:                             `${BASE_API_URL}/notifications/messages/`,
    RETRIEVE_MESSAGE_NOTIFICATION:                   (id) => `${BASE_API_URL}/notifications/messages/${id}/`,
    UPDATE_MESSAGE_NOTIFICATION:                     (id) => `${BASE_API_URL}/notifications/messages/${id}/`,
    DELETE_MESSAGE_NOTIFICATION:                     (id) => `${BASE_API_URL}/notifications/messages/${id}/`,
    BULK_DELETE_MESSAGE_NOTIFICATIONS:                       `${BASE_API_URL}/notifications/messages/bulk-delete/`,
    MARK_MESSAGE_NOTIFICATIONS_AS_READ:                      `${BASE_API_URL}/notifications/messages/mark-read/`,
};

export const NOTIFY_HUB_API_URLS = {
    GET_TEMPLATES:                                          `${BASE_API_URL}/notifications/templates/`,
    CREATE_TEMPLATE:                                        `${BASE_API_URL}/notifications/templates/`,
    RETRIEVE_TEMPLATE:                              (id) => `${BASE_API_URL}/notifications/templates/${id}/`,
    UPDATE_TEMPLATE:                                (id) => `${BASE_API_URL}/notifications/templates/${id}/`,
    DELETE_TEMPLATE:                                (id) => `${BASE_API_URL}/notifications/templates/${id}/`,
    BULK_DELETE_TEMPLATES:                                  `${BASE_API_URL}/notifications/templates/bulk-delete/`,

    GET_RULES:                                              `${BASE_API_URL}/notifications/rules/`,
    CREATE_RULE:                                            `${BASE_API_URL}/notifications/rules/`,
    RETRIEVE_RULE:                                  (id) => `${BASE_API_URL}/notifications/rules/${id}/`,
    UPDATE_RULE:                                    (id) => `${BASE_API_URL}/notifications/rules/${id}/`,
    DELETE_RULE:                                    (id) => `${BASE_API_URL}/notifications/rules/${id}/`,
    BULK_DELETE_RULES:                                      `${BASE_API_URL}/notifications/rules/bulk-delete/`,
};

export const RESOURCES_USAGE_API_URLS = {
    GET_USAGE:                                              `${BASE_API_URL}/resources-monitoring/usage/`,
};