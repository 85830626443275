import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSelectWidgetPanelOpen, addWidget, updateWidget } from "@/features/dashboards/slice";
import { getCurrentColsCount, findPositionForNewWidget } from "@/features/dashboards/widgetLayout";
import { WidgetModalContainer } from "@/features/dashboards/components/widgetModals/common/WidgetModalContainer";

import {
  WidgetSetting,
  widgetSettingInitialValues,
  widgetSettingValidationSchema,
} from "@/features/dashboards/components/widgetModals/settingSections/WidgetSetting";
import {
  DataSourceSetting,
  dataSourceInitialValues,
  dataSourceValidationSchema,
} from "@/features/dashboards/components/widgetModals/settingSections/DataSourceSetting";


const formSchema = Yup.object().shape({
  ...dataSourceValidationSchema,
  ...widgetSettingValidationSchema,
});

export function BasicMapWidgetModal({ showModal, toggleModal, widgetData, isEditMode = false }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const widgets = useSelector((state) => state.dashboards.widgets);

  const formik = useFormik({
    initialValues: {
      ...dataSourceInitialValues({ widgetData }),
      widgetTitle: widgetData?.title || "",
      showHeader: widgetData?.showHeader !== undefined ? widgetData?.showHeader : false,
    },
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const newWidgetWidth = 5;
      const newWidgetHeight = 9;
      const cols = getCurrentColsCount(window.innerWidth);
      const { x, y } = findPositionForNewWidget(widgets, newWidgetWidth, newWidgetHeight, cols);

      const newWidget = {
        id: new Date().getTime().toString(),
        title: values.widgetTitle,
        type: widgetData?.type,
        showHeader: values.showHeader,
        dataSourceType: values.dataSourceType,
        dataSourceConfig: values.dataSourceConfig,
        widgetModal: "basicMapWidget",
        category: "map",

        configuration: {
          latitude: values?.latitude || 0,
          longitude: values?.longitude || 0,
        },
        layout: widgetData.layout || {
          x: x,
          y: y,
          w: newWidgetWidth,
          h: newWidgetHeight,
          minW: 2,
          minH: 5,
        },
      };
      
      if (isEditMode) {
        dispatch(updateWidget({ id: widgetData?.id, widget: newWidget }));
      } else {
        dispatch(addWidget(newWidget));
      }
      toggleModal();
      resetModal();
      dispatch(setSelectWidgetPanelOpen(false));
    },
  });

  function resetModal() {
    formik.resetForm();
    setErrors(null);
  }

  return (
    <WidgetModalContainer
      showModal={showModal}
      toggleModal={toggleModal}
      modalTitle={formik.values.widgetTitle}
      onSubmit={formik.handleSubmit}
      errors={errors}
      isEditMode={isEditMode}
    >
      <WidgetSetting formik={formik} />
      <DataSourceSetting formik={formik} allowDataSourceType={["devicesKeys", "customLocation", "currentLocation"]} />
    </WidgetModalContainer>
  );
}
