import React from "react";
import { Card, CardBody } from "reactstrap";
import { WidgetHeader } from "@/features/dashboards/components/widgets/common/WidgetHeader";
import { DocumentListItem } from "@/features/dashboards/components/widgets/Navigation/DocumentsCard/DocumentListItem";
import SimpleBar from "simplebar-react";

export function DocumentsCard({ widget }) {
  return (
    <Card className="h-100 w-100 border">
      <WidgetHeader widget={widget} />
      <CardBody className="d-flex flex-column justify-content-center align-items-center overflow-hidden">
        <SimpleBar style={{ width: "100%", height: "100%" }}>
        <div className="vstack gap-2">
          <DocumentListItem
            title="Getting Start"
            color="success"
            icon="mdi mdi-rocket"
            documentURL="https://docs.linkocloud.com/"
          />
          <DocumentListItem
            title="Devices"
            color="secdonary"
            icon="mdi mdi-devices"
            documentURL="https://docs.linkocloud.com/"
          />
          <DocumentListItem
            title="Rule Engine"
            color="danger"
            icon="mdi mdi-lan"
            documentURL="https://docs.linkocloud.com/"
          />
          <DocumentListItem
            title="Alarms"
            color="info"
            icon="mdi mdi-alarm-light"
            documentURL="https://docs.linkocloud.com/"
          />
        </div>
        </SimpleBar>
      </CardBody>
    </Card>
  );
}
