import React from "react";
import FeatherIcon from "feather-icons-react";
import { Card, CardBody } from "reactstrap";
import CountUp from "react-countup";

export function StatisticsPanel({
  title,
  value,
  icon,
  iconColor,
  iconTextWhite=false,
  compareValue,
  compareTime,
  isAnnimated = true,
}) {
  let comparePercentage = 0;
  if (compareValue === 0 && value > 0) {
    comparePercentage = 100;
  } else if (compareValue === 0 && value === 0) {
    comparePercentage = 0;
  } else {
    comparePercentage = Math.floor(
      Math.abs(((value - compareValue) / compareValue) * 100)
    );
  }
  return (
    <React.Fragment>
      <Card className={`card-height-100 border ${isAnnimated ? "card-animate" : ""}`}>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div>
              <p className="fw-medium text-muted mb-0">{title}</p>
              <h2 className="mt-4 ff-secondary fw-bold">
                <CountUp start={0} end={value} duration={1} />
              </h2>
              {compareValue !== undefined && (
                <p className="mb-0 text-muted">
                  <span
                    className={`badge bg-light text-${
                      value >= compareValue ? "success" : "danger"
                    } mb-0`}
                  >
                    <i
                      className={`ri-arrow-${
                        value >= compareValue ? "up" : "down"
                      }-line align-middle`}
                    ></i>
                    {comparePercentage} %
                  </span>
                  {compareValue} {compareTime}
                </p>
              )}
            </div>
            <div>
              <div className="avatar-sm flex-shrink-0">
                <span
                  className={`avatar-title bg-${iconTextWhite ? iconColor : iconColor+"-subtle"} rounded-circle fs-2`}
                >
                  <FeatherIcon
                    icon={icon}
                    className={`text-${iconTextWhite? "white":iconColor}`}
                  ></FeatherIcon>
                </span>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export function StatisticsPanelSmall({
  title,
  value,
  icon,
  compareValue,
  compareTime,
  iconColor,
  isAnnimated = true,
}) {
  const comparePercentage =
    compareValue === 0
      ? 0
      : Math.floor(Math.abs(((value - compareValue) / compareValue) * 100));
  return (
    <React.Fragment>
      <Card className={`border ${isAnnimated ? "card-animate" : ""}`}>
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="avatar-sm flex-shrink-0">
              <span
                className={`avatar-title bg-light text-${iconColor} rounded-circle fs-3`}
              >
                <i className={"align-middle " + icon}></i>
              </span>
            </div>
            <div className="flex-grow-1 ms-3">
              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                {title}
              </p>
              <h4 className=" mb-0">
                {" "}
                <CountUp start={0} end={value} duration={2} />
              </h4>
            </div>
            {compareValue !== undefined && (
              <div className="flex-shrink-0 align-self-end">
                <span
                  className={`badge bg-${
                    value >= compareValue ? "success-subtle" : "danger-subtle"
                  } text-${value >= compareValue ? "success" : "danger"} mb-0 `}
                >
                  <i
                    className={`ri-arrow-${
                      value >= compareValue ? "up" : "down"
                    }-line align-middle me-1`}
                  ></i>
                  {comparePercentage} %
                </span>
                {compareValue} {compareTime}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
