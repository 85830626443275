import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { WidgetModalContainer } from "@/features/dashboards/components/widgetModals/common/WidgetModalContainer";

import {
  setSelectWidgetPanelOpen,
  addWidget,
  updateWidget,
} from "@/features/dashboards/slice";

import {
  getCurrentColsCount,
  findPositionForNewWidget,
} from "@/features/dashboards/widgetLayout";

import {
  WidgetSetting,
  widgetSettingInitialValues,
  widgetSettingValidationSchema,
} from "@/features/dashboards/components/widgetModals/settingSections/WidgetSetting";

import {
  NavigationCardInitialValues,
  NavigationCardValidationSchema,
  NavigationCardSetting,
} from "@/features/dashboards/components/widgetModals/settingSections/NavigationCardSetting";


const formSchema = Yup.object().shape({
  ...widgetSettingValidationSchema,
  ...NavigationCardValidationSchema,
});

const newWidgetWidth = 1;
const newWidgetHeight = 3;
const newWidgetMinW = 1;
const newWidgetMinH = 3;


export function NavigationCardModal({
  showModal,
  toggleModal,
  widgetData,
  isEditMode = false,
}) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const widgets = useSelector((state) => state.dashboards.widgets);

  const formik = useFormik({
    initialValues: {
      widgetTitle: widgetData?.title || "",
      showHeader: widgetData?.showHeader !== undefined ? widgetData?.showHeader : false,
      ...NavigationCardInitialValues({ widgetData }),
    },
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const cols = getCurrentColsCount(window.innerWidth);
      const { x, y } = findPositionForNewWidget(
        widgets,
        newWidgetWidth,
        newWidgetHeight,
        cols
      );

      const newWidget = {
        id: new Date().getTime().toString(),
        title: values.widgetTitle,
        type: widgetData?.type,
        showHeader: values.showHeader,
        widgetModal: "navigationCard",
        category: "navigation",

        configuration: {
          navigateTo: values.navigateTo,
        },
        layout: widgetData.layout || {
          x: x,
          y: y,
          w: newWidgetWidth,
          h: newWidgetHeight,
          minW: newWidgetMinW,
          minH: newWidgetMinH,
        },
      };
      if (isEditMode) {
        dispatch(updateWidget({ id: widgetData?.id, widget: newWidget }));
      } else {
        dispatch(addWidget(newWidget));
      }
      toggleModal();
      resetModal();
      dispatch(setSelectWidgetPanelOpen(false));
    },
  });

  function resetModal() {
    formik.resetForm();
    setErrors(null);
  }

  return (
    <WidgetModalContainer
      showModal={showModal}
      toggleModal={toggleModal}
      modalTitle={formik.values.widgetTitle}
      onSubmit={formik.handleSubmit}
      errors={errors}
      isEditMode={isEditMode}
    >
      <WidgetSetting formik={formik} />
      <NavigationCardSetting formik={formik} />
    </WidgetModalContainer>
  );
}
