import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useDispatch } from "react-redux";
import { getLoginLogs } from "@/features/auditLogs/thunk";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

export function LoginHistoryPanel({ contentMaxHeight = 500, className = "" }) {
  const [timeRange, setTimeRange] = useState("today");
  const [loginLog, setLoginLog] = useState([]);
  const dispatch = useDispatch();

  const onTimeRangeChange = (period) => {
    setTimeRange(period);
  };

  useEffect(() => {
    dispatch(getLoginLogs({ timeRange })).then((actionResult) => {
      const data = actionResult.payload;
      setLoginLog(data);
    });
  }, [timeRange]);

  return (
    <Card className={`border ${className}`}>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Login History</h4>
        <div className="flex-shrink-0">
          <UncontrolledDropdown className="card-header-dropdown">
            <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
              <span className="fw-semibold text-uppercase fs-12">Data Form: </span>
              <span className="text-muted">
                {timeRange.replace("_", " ")}
                <i className="mdi mdi-chevron-down ms-1"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() => {
                  onTimeRangeChange("today");
                }}
                className={timeRange === "today" ? "active" : ""}
              >
                today
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  onTimeRangeChange("current_week");
                }}
                className={timeRange === "current_week" ? "active" : ""}
              >
                current week
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  onTimeRangeChange("current_month");
                }}
                className={timeRange === "current_month" ? "active" : ""}
              >
                current month
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  onTimeRangeChange("current_year");
                }}
                className={timeRange === "current_year" ? "active" : ""}
              >
                current year
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </CardHeader>
      <CardBody>
        <SimpleBar autoHide={false} className="simplebar-track-primary" style={{ maxHeight: `${contentMaxHeight}px` }}>
          <Container>
            {loginLog &&
              loginLog.length > 0 &&
              loginLog.map((item, index) => (
                <Row key={index} className="align-items-center mb-3">
                  <Col xs="auto">
                    <div className="avatar-sm">
                      <div className={`avatar-title bg-light text-info rounded-3 fs-18 shadow`}>
                        <i className="ri-computer-line"></i>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <h6>{`${item.details.device} (${item.details.OS} ${item.details["OS Version"]})`}</h6>
                    <p className="text-muted mb-0">{`${item.details.IP} - ${item.created_time}`}</p>
                  </Col>
                </Row>
              ))}
          </Container>
        </SimpleBar>
      </CardBody>
    </Card>
  );
}
