import React, { useEffect, useRef } from 'react';
import { Player } from '@lordicon/react';


export function LordIcon({ icon, size = 96, colorize = "#08C18A", mode='once' }){
    const playerRef = useRef(null);
    let onComplete = null;
  
    if (mode === 'loop') {
      onComplete = () => playerRef.current?.playFromBeginning();
    }
  
    useEffect(() => {
      playerRef.current?.playFromBeginning();
    }, []);
  
    return (
      <Player
          ref={playerRef}
          icon={icon}
          size={size}
          colorize={colorize}
          onComplete={onComplete}
      />
    );
  };
  
