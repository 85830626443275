import React, { useState, useMemo } from "react";
import { useModals } from "@/hooks/useModals";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Row, Col, Input, CardTitle } from "reactstrap";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useTableSelectionControl } from "@/hooks/useTableSelectionControl";
import { DeleteModal } from "@/components/modals/DeleteModal";
import { DeleteMultipileModal } from "@/components/modals/DeleteMultipileModal";
import { bulkDeleteDeviceAttributes, deleteDeviceAttribute } from "@/features/devices/thunk";
import { getDeviceDetailData } from "@/features/devices/thunk";

import { DeleteButton } from "@/components/tables/rowActionButtons/DeleteButton";
import { EditButton } from "@/components/tables/rowActionButtons/EditButton";
import { AddEntityButton } from "@/components/tables/headerToolSet/AddEntityButton";
import { RefreshDataButton } from "@/components/tables/headerToolSet/RefreshDataButton";
import { DeleteMultipleButton } from "@/components/tables/headerToolSet/DeleteMultipleButton";
import { ActionButtonGroup } from "@/components/tables/rowActionButtons/ActionButtonGroup";
import { AddAttributeModal } from "@/features/devices/components/AddAttributeModal";

export function DeviceAttributesTable() {
  const pageSize = 10;
  const dispatch = useDispatch();
  const { page, handlePageChange, resetPageAndSearch } = usePaginationAndSearch();
  const [selectedIds, handleSelectedAll, handleSelectedSingle, resetSelectedIds] = useTableSelectionControl();
  const [modals, modalData, toggleModal] = useModals({
    addAttributeModal: false,
    deleteModal: false,
    deleteMultipleModal: false,
  });

  const deviceDetailData = useSelector((state) => state.devices.deviceDetailData);
  const attributesData = useMemo(() => {
    if (deviceDetailData && deviceDetailData.attributes) {
      return deviceDetailData.attributes;
    }
    return [];
  }, [deviceDetailData]);

  const allAttributesIds = useMemo(() => {
    if (!attributesData) {
      return [];
    } else {
      return attributesData.map((item) => item.id);
    }
  }, [attributesData]);

  const pageniatedData = useMemo(() => {
    if (!attributesData) {
      return [];
    }
    const start = (page - 1) * pageSize;
    const end = page * pageSize;
    return attributesData.slice(start, end);
  }, [attributesData, page, pageSize]);

  const handleDeleteSingle = (attributeId) => {
    if (attributeId) {
      dispatch(deleteDeviceAttribute({ attributeId })).then((actionResult) => {
        if (deleteDeviceAttribute.fulfilled.match(actionResult)) {
          toggleModal("deleteModal");
          refreshData();
        }
      });
    }
  };

  function handleDeleteMultiple() {
    dispatch(bulkDeleteDeviceAttributes({ attributeIds: selectedIds })).then((actionResult) => {
      if (bulkDeleteDeviceAttributes.fulfilled.match(actionResult)) {
        refreshData();
      }
    });
    toggleModal("deleteMultipleModal");
  }

  function refreshData() {
    resetSelectedIds();
    resetPageAndSearch();
    dispatch(getDeviceDetailData({ deviceId: deviceDetailData.id }));
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="form-check">
            <Input
              type="checkbox"
              id="checkBoxAll"
              className="form-check-input"
              checked={selectedIds.length === pageniatedData.length && pageniatedData.length > 0}
              onChange={(e) => handleSelectedAll(e, allAttributesIds)}
            />
          </div>
        ),
        cell: (cell) => {
          return (
            <div className="form-check">
              <Input
                type="checkbox"
                className="form-check-input"
                value={cell.getValue()}
                checked={selectedIds.includes(cell.getValue())}
                onChange={(e) => handleSelectedSingle(e, cell.getValue())}
              />
            </div>
          );
        },
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Key",
        accessorKey: "key",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Value",
        accessorKey: "value",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Last Updated",
        accessorKey: "updated_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Action",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <ActionButtonGroup>
              <EditButton
                onClick={() => toggleModal("addAttributeModal", { isEditMode: true, attributeData: cell.row.original })}
                tooltip="Edit Device Attribute"
              />
              <DeleteButton
                onClick={() =>
                  toggleModal("deleteModal", {
                    attributeId: cell.row.original.id,
                  })
                }
                tooltip="Delete Device Attribute"
              />
            </ActionButtonGroup>
          );
        },
      },
    ],
    [pageniatedData, selectedIds]
  );

  return (
    <React.Fragment>
      <Card className="border">
        <CardHeader>
          <CardTitle>Device Attributes</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="d-flex justify-content-between mb-3">
            <Col md="2" className="d-flex justify-content-begin">
              <AddEntityButton
                onClick={() => toggleModal("addAttributeModal", {isEditMode :false})}
                text="Add Attribute"
              />
              <RefreshDataButton onClick={refreshData} />
              <DeleteMultipleButton
                onClick={() => toggleModal("deleteMultipleModal")}
                tooltip="Delete Multiple Device Attributes"
                show={selectedIds.length > 0}
              />
            </Col>
          </Row>
          <DynamicDataTable
            columns={columns}
            data={pageniatedData}
            currentPage={page}
            totalPages={Math.ceil(attributesData.length / pageSize)}
            onPageChange={handlePageChange}
            totalDataLength={attributesData.length}
            dataName="device attribute"
          />
        </CardBody>
      </Card>
      <AddAttributeModal
        showModal={modals.addAttributeModal}
        toggleModal={() => toggleModal("addAttributeModal")}
        onSuccess={refreshData}
        deviceId={deviceDetailData.id}
        isEditMode={modalData.addAttributeModal?.isEditMode}
        attributeData={modalData.addAttributeModal?.attributeData}
      />
      <DeleteModal
        showModal={modals.deleteModal}
        toggleModal={() => toggleModal("deleteModal")}
        title="Delete Device Attribute"
        subtitle="Are you sure you want to delete this attribute?"
        onDelete={() => handleDeleteSingle(modalData.deleteModal?.attributeId)}
      />
      <DeleteMultipileModal
        showModal={modals.deleteMultipleModal}
        toggleModal={() => toggleModal("deleteMultipleModal")}
        type="device attribute"
        numberSelected={selectedIds.length}
        onDelete={handleDeleteMultiple}
      />
    </React.Fragment>
  );
}
