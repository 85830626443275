import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import FadeOutMasks from '@/components/masks/FadeOutMasks';
import { Link } from 'react-router-dom';

import '@/assets/scss/auth/index.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import logo from '@/assets/images/logo/linkocloud/logo-text-light.png';
import authticationBg from '@/assets/images/authentication/authentication-bg.jpg';

const AuthenticationLayout = ({ title, subTitle, withMask = false, ...props }) => {
    return (
        <React.Fragment>
            <div className='auth-wrapper' id="main-wrapper">
                {withMask && <FadeOutMasks />}
                <Container fluid className='px-0'>
                    <Row className="g-0 min-vh-100">
                        <Col md={4}>
                            <div className="hero-wrap d-flex align-items-center h-100">
                                <div className="hero-mask opacity-5 bg-dark"></div>
                                <div className="hero-bg hero-bg-scroll" style={{backgroundImage: `url('${authticationBg}')`}}></div>
                                <div className="hero-content mx-auto w-100 h-100">
                                    <Container className='d-flex flex-column h-100'>
                                        <Row className="g-0">
                                            <Col lg={9} xs={11} className="mx-auto">
                                                <div className="logo mt-5 mb-5"> 
                                                    <Link className="d-flex" to="/" title="Linko Cloud">
                                                        <img src={logo} alt="Linko Cloud" style={{width: 'auto', height: '70px'}} />
                                                    </Link> 
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="g-0 mt-3">
                                            <Col lg={9} xs={11} className="mx-auto">
                                                <h1 className="text-9 text-white fw-300 mb-5"><span className="fw-500">{title}</span>{subTitle}</h1>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </Col>
                        <Col md={8} className="d-flex flex-column align-items-center bg-dark">
                            <Container className='my-auto py-5'>
                                {props.children}
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default AuthenticationLayout;