import React from "react";

import Oauth from "@/pages/authentication/Oauth";
import Login from "@/pages/authentication/Login";
import Logout from "@/pages/authentication/Logout";
import Register from "@/pages/authentication/Register";
import ResetPassword from "@/pages/authentication/ResetPassword";
import ForgotPassword from "@/pages/authentication/ForgotPassword";
import EmailVerification from "@/pages/authentication/EmailVerification";
import SentEmailVerification from "@/pages/authentication/SentEmailVerification";
import SentResetPasswordEmail from "@/pages/authentication/SentResetPasswordEmail";

import Error404 from "@/pages/system/Error404";
import Error500 from "@/pages/system/Error500";


const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/logout", element: <Logout /> },
  { path: "/register", element: <Register />},
  { path: "/verifiy-email", element: <EmailVerification />},
  { path: "/reset-password", element: <ResetPassword />},
  { path: "/forgot-password", element: <ForgotPassword />},
  { path: "/sent-email-verification", element: <SentEmailVerification />},
  { path: "/sent-reset-password-email", element: <SentResetPasswordEmail />},

  { path: "/500", element: <Error500 />},
  { path: "/404", element: <Error404 />},
  
  { path: "/oauth/github/", element: <Oauth /> },
  { path: "/oauth/google/", element: <Oauth /> },
]

export default publicRoutes;