import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import Select from "react-select";

export const NavigationCardInitialValues = ({ widgetData }) => {
    return {
        navigateTo: widgetData?.configuration?.navigateTo || "",
    };
};

export const NavigationCardValidationSchema = {
    navigateTo: Yup.string().required("Navigate Address is required"),
};

const navigationOptions = [
    { value: "/dashboards", label: "Dashboard" },
    { value: "/devices", label: "Devices" },
    { value: "/settings", label: "Settings" },
    { value: "/alarms", label: "Alarms" },
    { value: "/rules-engine", label: "Rules Engine" },
  ];

export function NavigationCardSetting({ formik }) {
  return (
    <React.Fragment>
      <h4 className="mb-4">Navigation Setting</h4>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="navigateTo" className="form-label fs-15">
              Navigate To
            </Label>
          </Col>
          <Col lg={8}>
            <Select
              id="navigateTo"
              name="navigateTo"
              value={navigationOptions.find(
                (option) => option.value === formik.values.navigateTo
              )}
              options={navigationOptions}
              onChange={(e) => {
                formik.setFieldValue("navigateTo", e.value);
              }}
            />
            {formik.errors.navigateTo && (
              <div
                className="ms-1 mt-1"
                style={{ fontSize: "0.8125rem", color: "#f3846d" }}
              >
                {formik.errors.navigateTo}
              </div>
            )}
          </Col>
        </Row>
      </FormGroup>
    </React.Fragment>
  );
}
