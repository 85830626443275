import React from "react";
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { setLayoutConfig } from "@/features/dashboards/slice";
import _ from 'lodash';
import { minRowHeightConfig } from '@/features/dashboards/widgetLayout';

export function EditLayoutSettingsButton({ show = true }) {
  if (!show) {
    return null;
  }

  const layoutConfig = useSelector((state) => state.dashboards.layoutConfig);
  const dispatch = useDispatch();
  function updateLayoutConfig(key, value) {
    const newConfig = { ...layoutConfig, [key]: value };
    dispatch(setLayoutConfig(newConfig));
  }

  const validateRowHeight = () => {
    // Apply validation onBlur
    const rowHeight = parseInt(layoutConfig.rowHeight, 10);
    if (isNaN(rowHeight) || rowHeight < minRowHeightConfig) {
      updateLayoutConfig("rowHeight", minRowHeightConfig);
    }
  };

  const preventNegative = (e) => {
    if (e.key === "-" || e.key === "+" || e.key === "e") {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <ButtonGroup>
        <UncontrolledDropdown>
          <DropdownToggle
            tag="button"
            className="btn btn-outline-dark btn-lg me-3"
          >
            <i className="mdi mdi-pencil-ruler me-md-2 fs-18"></i>
            <span className="text-decoration-underline d-none d-lg-inline-block">Layout Settings</span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-xl p-0">
            <Card className="mb-0 border">
              <CardBody>
                <Row className="d-flex justify-content-between">
                  <Col lg="4" className="d-flex align-items-center">
                    <Label for="compactType">Compact Type</Label>
                  </Col>
                  <Col lg="8">
                    <Select
                      id="compactType"
                      name="compactType"
                      value={{
                        value:
                          layoutConfig.compactType,
                        label: layoutConfig.compactType,
                      }}
                      options={[
                        { label: "vertical", value: "vertical" },
                        { label: "horizontal", value: "horizontal" },
                        { label: "no compact", value: "null" },
                      ]}
                      onChange={(e) => {
                        updateLayoutConfig("compactType", e.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between mt-4">
                  <Col lg="4" className="d-flex align-items-center">
                    <Label for="compactType">Items Margin</Label>
                  </Col>
                  <Col lg="8">
                    <Row>
                      <Col lg="6">
                        <Input
                          type="number"
                          id="verticalMargin"
                          name="verticalMargin"
                          placeholder="Vertical Margin"
                          value={layoutConfig?.margin[0]}
                          onKeyDown={preventNegative}
                          onChange={(e) =>
                            updateLayoutConfig("margin", [
                              e.target.value,
                              layoutConfig.margin[1],
                            ])
                          }
                        />
                      </Col>
                      <Col lg="6">
                        <Input
                          type="number"
                          id="horizontalMargin"
                          name="horizontalMargin"
                          placeholder="Horizontal Margin"
                          value={layoutConfig?.margin[1]}
                          onKeyDown={preventNegative}
                          onChange={(e) =>
                            updateLayoutConfig("margin", [
                              layoutConfig.margin[0],
                              e.target.value,
                            ])
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between mt-4">
                  <Col lg="4" className="d-flex align-items-center">
                    <Label for="rowHeight">Row Height (min:{minRowHeightConfig})</Label>
                  </Col>
                  <Col lg="8">
                    <Input
                      type="number"
                      id="rowHeight"
                      name="rowHeight"
                      placeholder={`Row Height (min:${minRowHeightConfig})`}
                      value={layoutConfig.rowHeight}
                      onKeyDown={preventNegative}
                      onBlur={validateRowHeight}
                      onChange={(e) =>
                        updateLayoutConfig("rowHeight", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>
    </React.Fragment>
  );
}
