import { createSlice } from "@reduxjs/toolkit";
import { getDevicesData, getDeviceStatisticsData, getDeviceDetailData } from '@/features/devices/thunk';

export const initialState = {
    isLoading: false,
    needsRefresh: false,
    devicesData: [],
    devicesStatisticsData: {},

    // Device Detail
    deviceDetailData: {},
    fetchDetailDataError: null,
};

const Deviceslice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        resetNeedsRefresh(state) {
            state.needsRefresh = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDevicesData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getDevicesData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.devicesData = action.payload;
        });
        builder.addCase(getDevicesData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getDeviceStatisticsData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getDeviceStatisticsData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.devicesStatisticsData = action.payload;
        });
        builder.addCase(getDeviceStatisticsData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getDeviceDetailData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getDeviceDetailData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.deviceDetailData = action.payload;
        });
        builder.addCase(getDeviceDetailData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchDetailDataError = action.payload || null;
        });
    }
});

export default Deviceslice.reducer;
export const { resetNeedsRefresh } = Deviceslice.actions;