import { createSlice } from '@reduxjs/toolkit';
import { getSettings, updateSettings } from '@/features/auth/accountSettings/thunk';


const initialState = {
    settingsData: null,
    error: null,
    isLoading: false,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        resetSettingsStatus(state) {
            state.error = null;
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getSettings.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getSettings.fulfilled, (state, action) => {
            state.isLoading = false;
            state.settingsData = action.payload;
        })
        .addCase(getSettings.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload || 'Failed to get settings';
        })
        .addCase(updateSettings.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateSettings.fulfilled, (state, action) => {
            state.isLoading = false;
            state.settingsData = action.payload;
        })
        .addCase(updateSettings.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload || 'Failed to update settings';
        });
    },
});

export default settingsSlice.reducer;