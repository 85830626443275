

export function setColorMode(mode) {
  localStorage.setItem('colorMode', mode);
  document.documentElement.setAttribute('data-bs-theme', mode);
}


export function getColorMode() {
    return localStorage.getItem('colorMode') || 'light';
}