import React from "react";
import { useModals } from "@/hooks/useModals";
import { CardHeader, CardTitle, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeWidget, copyWidget } from "@/features/dashboards/slice";
import {
  widgetModalConfig,
  WidgetModalManager,
} from "@/features/dashboards/components/widgetModals/WidgetModalManagerModal";

export function WidgetHeader({ widget }) {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.dashboards.mode);
  const [modals, modalData, toggleModal] = useModals(widgetModalConfig);

  if (!widget.showHeader && mode === "view") {
    return null;
  }

  function handleRemoveWidget(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(removeWidget(widget.id));
  }

  function handleCopyWidget(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(copyWidget(widget));
  }

  return (
    <React.Fragment>
      <CardHeader className="d-flex justify-content-between px-2 py-3">
        {widget.showHeader ? <CardTitle className="mb-0">{widget.title}</CardTitle> : <div></div>}
        <div>
          <Button
            color="link"
            className="btn-ghost-dark shadow-none px-1 py-0 fs-16 non-draggable me-1"
            outline
            onClick={() =>
              toggleModal(widget.widgetModal, {
                widgetData: widget,
                editMode: true,
              })
            }
            style={{ visibility: mode === "edit" ? "visible" : "hidden" }}
          >
            <i className="mdi mdi-pencil"></i>
          </Button>
          <Button
            color="link"
            className="btn-ghost-dark shadow-none px-1 py-0 fs-16 non-draggable me-1"
            outline
            onClick={handleCopyWidget}
            style={{ visibility: mode === "edit" ? "visible" : "hidden" }}
          >
            <i className="mdi mdi-content-copy"></i>
          </Button>
          <Button
            color="link"
            className="btn-ghost-dark shadow-none px-1 py-0 fs-16 non-draggable me-1"
            outline
            onClick={handleRemoveWidget}
            style={{ visibility: mode === "edit" ? "visible" : "hidden" }}
          >
            <i className="mdi mdi-window-close"></i>
          </Button>
        </div>
      </CardHeader>
      <WidgetModalManager toggleModal={toggleModal} modalData={modalData} modals={modals} isEditMode={true} />
    </React.Fragment>
  );
}
