import map from "@/assets/images/dashboard-widgets/map.png";
import clock from "@/assets/images/dashboard-widgets/clock.png";
import chart from "@/assets/images/dashboard-widgets/chart.png";
import navigation from "@/assets/images/dashboard-widgets/navigation.png";

import lineChart from "@/assets/images/dashboard-widgets/line-chart.png";
import barChart from "@/assets/images/dashboard-widgets/bar-chart.png";
import areaChart from "@/assets/images/dashboard-widgets/area-chart.png";
import pieChart from "@/assets/images/dashboard-widgets/pie-chart.png";
import columnChart from "@/assets/images/dashboard-widgets/column-chart.png";
import heatmapChart from "@/assets/images/dashboard-widgets/heatmap-chart.png";
import googleMap from "@/assets/images/dashboard-widgets/google-map.png";
import digitalClock from "@/assets/images/dashboard-widgets/digital-clock.png";
import analogClock from "@/assets/images/dashboard-widgets/analog-clock.png";
import navigationCard from "@/assets/images/dashboard-widgets/navigation-card.png";
import documentsCard from "@/assets/images/dashboard-widgets/documents-card.png";
import mapboxGlMap from "@/assets/images/dashboard-widgets/mapbox-gl-map.png";
import alarmsTable from "@/assets/images/dashboard-widgets/alarms-table.png";
import devicesTable from "@/assets/images/dashboard-widgets/devices-table.png";

export const widgetCategoryConfig = [
    {
        title: "Chart Widgets",
        type: "chart",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: chart,
    },
    {
        title: "Tables",
        type: "table",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: alarmsTable,
    },
    {
        title: "Map Widgets",
        type: "map",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: map,
    },
    {
        title: "Clock Widgets",
        type: "clock",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: clock,
    },
    {
        title: "Navigation Widgets",
        type: "navigation",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: navigation,
    },
    {
        title: "Count Widgets",
        type: "count",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: navigation,
    },
    {
        title: "Gauge Widgets",
        type: "gauge",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: chart,
    }
]

export const widgetCardConfig = [
    {
        title: "Line Chart",
        panelTitle: "Line Chart",
        type: "lineChart",
        category: "chart",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: lineChart,
        chartType: "line",
        modal: "chartWidget",
    },
    {
        title: "Bar Chart",
        panelTitle: "Bar Chart",
        type: "barChart",
        category: "chart",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: columnChart,
        chartType: "bar",
        modal: "chartWidget",
    },
    {
        title: "Area Chart",
        panelTitle: "Area Chart",
        type: "areaChart",
        category: "chart",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: areaChart,
        chartType: "area",
        modal: "chartWidget",
    },
    {
        title: "Heatmap Chart",
        panelTitle: "Heatmap Chart",
        type: "heatmapChart",
        category: "chart",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: heatmapChart,
        chartType: "heatmap",
        modal: "chartWidget",
    },
    {
        title: "Pie Chart",
        panelTitle: "Pie Chart",
        type: "pieChart",
        category: "chart",
        description: "Displays changes to time-series data over time—for example, temperature or humidity readings.",
        imgSrc: pieChart,
        chartType: "pie",
        modal: "chartWidget",
    },
    {
        title: "Digital Clock",
        panelTitle: "Digital Clock",
        type: "digitalClock",
        category: "clock",
        description: "Shows the current time and date.",
        imgSrc: digitalClock,
        modal: "digitalClockWidget",
    },
    {
        title: "Analog Clock",
        panelTitle: "Analog Clock",
        type: "analogClock",
        category: "clock",
        description: "Shows the current time and date.",
        imgSrc: analogClock,
        modal: "analogClockWidget",
    },
    {
        title: "Google Map",
        panelTitle: "Google Map",
        type: "googleMap",
        category: "map",
        description: "Shows the current time and date.",
        imgSrc: googleMap,
        modal: "basicMapWidget",
    },
    {
        title: "Mapbox GL Map",
        panelTitle: "Mapbox GL Map",
        type: "mapboxGLMap",
        category: "map",
        description: "Shows the current time and date.",
        imgSrc: mapboxGlMap,
        modal: "basicMapWidget",
    },
    {
        title: "Navigation",
        panelTitle: "Navigation Card",
        type: "navigationCard",
        category: "navigation",
        description: "Shows the current time and date.",
        imgSrc: navigationCard,
        modal: "navigationCard",
    },
    {
        title: "Documents",
        panelTitle: "Documents Card",
        type: "documentsCard",
        category: "navigation",
        description: "Shows the current time and date.",
        imgSrc: documentsCard,
        modal: "documentsCard",
    },
    {
        title: "Devices Table",
        panelTitle: "Devices Table",
        type: "devicesTable",
        category: "table",
        description: "Shows the current time and date.",
        imgSrc: devicesTable,
        modal: "devicesTable",
    },
    {
        title: "Alarms Table",
        panelTitle: "Alarms Table",
        type: "alarmsTable",
        category: "table",
        description: "Shows the current time and date.",
        imgSrc: alarmsTable,
        modal: "alarmsTable",
    },
    {
        title: "Common Gauge",
        panelTitle: "Common Gauge",
        type: "commonGauge",
        category: "gauge",
        description: "Shows the current time and date.",
        imgSrc: chart,
        modal: "commonGauge",
    },
]