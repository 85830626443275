import { createSlice } from '@reduxjs/toolkit';
import { getAuditLogs, getLoginLogs, getStatisticsData } from '@/features/auditLogs/thunk';

export const initialState = {
    isLoading: false,
    auditLogs: [],
    loginLogs: [],
    statisticsData: {},
    fetchError: null,
};

const authLogsSlice = createSlice({
    name: 'auditLogs',
    initialState,
    reducers: {
        resetFetchError(state) {
            state.fetchError = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAuditLogs.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAuditLogs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.auditLogs = action.payload;
        });
        builder.addCase(getAuditLogs.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getLoginLogs.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getLoginLogs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.loginLogs = action.payload;
        });
        builder.addCase(getLoginLogs.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getStatisticsData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getStatisticsData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.statisticsData = action.payload;
        });
        builder.addCase(getStatisticsData.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
    }
});

export default authLogsSlice.reducer;