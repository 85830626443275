import React from "react";
import { Button } from "reactstrap";

export function AddWidgetButton({ show, onClick }) {
  if (!show) {
    return null;
  }

  return (
    <React.Fragment>
    <Button
        color="dark"
        size="lg"
        outline
        onClick={onClick}
        className="me-3"
    >
        <i className="mdi mdi-plus me-md-2 fs-18"></i><span className="d-none d-lg-inline-block">Add Widget</span>
    </Button>
    </React.Fragment>
  );
}
