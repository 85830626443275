import React from "react";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "reactstrap";

import { BreadCrumb } from "@/components/common/BreadCrumb";
import { AlarmsActivity } from "@/features/resourcesUsage/components/AlarmsActivity";
import { RulesEngineActivity } from "@/features/resourcesUsage/components/RulesEngineActivity";
import { NotificationActivity } from "@/features/resourcesUsage/components/NotificationActivity";
import { DataTransportActivity } from "@/features/resourcesUsage/components/DataTransportActivity";
import { UsageProgressBarPanel } from "@/features/resourcesUsage/components/UsageProgressBarPanel";

import { getResourceUsage } from "@/features/resourcesUsage/thunk";

const ResourcesUsage = () => {
  const dispatch = useDispatch();
  document.title = "Resources Usage | Linko Cloud";

  const userData = useSelector((state) => state.auth.user);
  const resourcesLimitData = useMemo(() => userData?.limit, [userData]);
  const resourcesUsageData = useSelector(
    (state) => state.resourcesUsage.usageData
  );

  useEffect(() => {
    dispatch(getResourceUsage());
  }, []);

  document.title = "Resources Usage | Linko Cloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Resources Usage" pageTitle="Management" />
          <Row>
            <Col xl={3} md={6}>
              <UsageProgressBarPanel
                title="Data Transport"
                currentValue={resourcesUsageData?.data_transports || 0}
                totalValue={resourcesLimitData?.max_data_transports || 0}
                unit={"MB"}
              />
            </Col>
            <Col xl={3} md={6}>
              <UsageProgressBarPanel
                title="Sever Messages Transport"
                currentValue={resourcesUsageData?.server_messages_transports}
                totalValue={
                  resourcesLimitData?.max_server_messages_transports || 0
                }
                unit={"MB"}
              />
            </Col>
            <Col xl={3} md={6}>
              <UsageProgressBarPanel
                title="Rules Created"
                currentValue={resourcesUsageData?.rules || 0}
                totalValue={resourcesLimitData?.max_rules || 0}
              />
            </Col>
            <Col xl={3} md={6}>
              <UsageProgressBarPanel
                title="Dashboards"
                currentValue={resourcesUsageData?.dashboards || 0}
                totalValue={resourcesLimitData?.max_dashboards || 0}
              />
            </Col>
            <Col xl={3} md={6}>
              <UsageProgressBarPanel
                title="Devices Created"
                currentValue={resourcesUsageData?.devices || 0}
                totalValue={resourcesLimitData?.max_devices || 0}
              />
            </Col>
            <Col xl={3} md={6}>
              <UsageProgressBarPanel
                title="Alarms Created"
                currentValue={resourcesUsageData?.alarms || 0}
                totalValue={resourcesLimitData?.max_alarms || 0}
              />
            </Col>
            <Col xl={3} md={6}>
              <UsageProgressBarPanel
                title="SMS Notification Request"
                currentValue={resourcesLimitData?.sms_notifications || 0}
                totalValue={resourcesLimitData?.max_sms_notifications || 0}
              />
            </Col>
            <Col xl={3} md={6}>
              <UsageProgressBarPanel
                title="Email Notification Request"
                currentValue={resourcesLimitData?.email_notifications || 0}
                totalValue={resourcesLimitData?.max_email_notifications || 0}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <DataTransportActivity />
            </Col>
            <Col md={6}> 
              <RulesEngineActivity />
            </Col>
            <Col md={6}>
              <AlarmsActivity />
            </Col>
            <Col md={6}>
              <NotificationActivity />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResourcesUsage;
