import React from "react";
import { LordIcon } from "@/components/common/LordIcon";
import { Row, Col } from "reactstrap";
import AuthenticationLayout from "@/components/layouts/AuthenticationLayout";

import ICON from "@/assets/json/lord-icon/envelope-send.json";


const ResetPasswordConfirmationPage = () => {



  return (
    <AuthenticationLayout subTitle="We care about your account security." withMask={false}>
      <Row className="g-0">
        <Col className="col-11 col-md-8 col-lg-7 col-xl-6 mx-auto">
          <h3 className="text-white mb-4">Reset Password</h3>
          <LordIcon icon={ICON} size={100} mode="loop" />
          <p className="text-white mb-4">
            An email with password reset instructions has been sent to your email address.
          </p>
          <p className="text-white-50 mb-4">
            Note: If you don't receive the email within a few minutes, please check your 'spam' folder or try resending
            the email by clicking the 'Resend' button.
          </p>
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default ResetPasswordConfirmationPage;
