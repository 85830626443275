import React from "react";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "reactstrap";

import { BreadCrumb } from "@/components/common/BreadCrumb";
import { StatisticsPanel } from "@/components/panels/StatisticsPanel";
import { LoginHistoryPanel } from "@/components/panels/LoginHistoryPanel";
import { TimeRangeChart } from "@/components/charts/TimeRangeChart";
import { DocumentPanel } from "@/features/overview/components/DocumentPanel";
import { DashboardListPanel } from "@/features/overview/components/DashboardListPanel";
import { ResourcesUsagePanel } from "@/features/overview/components/ResourcesUsagePanel";
import { UpgradeAccountNotise } from "@/features/overview/components/UpgradeAccountNotise";

import { getDeviceStatisticsData } from "@/features/devices/thunk";
import { getAlarmsStatisticsData } from "@/features/alarms/thunk";

const OverView = () => {
  const dispatch = useDispatch();
  document.title = "Overview | Linko Cloud";
  const [distributionTimeRange, setDistributionTimeRange] =
    useState("minutely");

  const distributionTimeRangeDefine = [
    "minutely",
    "hourly",
    "daily",
    "weekly",
    "monthly",
    "yearly",
  ];

  useEffect(() => {
    dispatch(getDeviceStatisticsData());
  }, []);

  useEffect(() => { dispatch(getAlarmsStatisticsData()) }, []);

  const devicesStatisticsData = useSelector(
    (state) => state.devices.devicesStatisticsData
  );
  const { totalDevices, activeDevices } = useMemo(
    () => ({
      totalDevices: devicesStatisticsData?.total_devices || 0,
      activeDevices: devicesStatisticsData?.active_devices || 0,
    }),
    [devicesStatisticsData]
  );

  const alarmsStatisticsData = useSelector((state) => state.alarms.statisticsData);
  const { todayAlarms, activeAlarms } = useMemo(() => ({
    todayAlarms: alarmsStatisticsData?.todays_alarms || 0,
    activeAlarms: alarmsStatisticsData?.active_alarms || 0,
  }), [alarmsStatisticsData]);


  function handleDistributionTimeRangeChange(timeRange) {
    setDistributionTimeRange(timeRange);
  }


  document.title = "Overview | Linko Cloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Overview" pageTitle="Management" />
          <Row>
            <Col xxl={5}>
              <UpgradeAccountNotise />
              <Row>
                <Col md={6}>
                  <StatisticsPanel
                    title="Active Devices"
                    value={activeDevices}
                    icon="check-circle"
                    iconColor="success"
                    iconTextWhite={true}
                  />
                </Col>
                <Col md={6}>
                  <StatisticsPanel
                    title="Total Devices"
                    value={totalDevices}
                    icon="hard-drive"
                    iconColor="info"
                    iconTextWhite={true}
                  />
                </Col>
                <Col md={6}>
                  <StatisticsPanel
                    title="Active Alarms"
                    value={activeAlarms}
                    icon="alert-triangle"
                    iconColor="danger"
                    iconTextWhite={true}
                  />
                </Col>
                <Col md={6}>
                  <StatisticsPanel
                    title="Today Alarms"
                    value={todayAlarms}
                    icon="alert-octagon"
                    iconColor="warning"
                    iconTextWhite={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col xxl={7}>
              <TimeRangeChart
                type="line"
                title="Data Transport Activity"
                data={[]}
                dataName="activity data"
                timeRange={distributionTimeRange}
                timeRangeTypes={distributionTimeRangeDefine}
                onTimeRangeChange={handleDistributionTimeRangeChange}
                minHeight={492}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <DashboardListPanel />
            </Col>
            <Col lg={7}>
              <TimeRangeChart
                title="Sever Transport Messages"
                data={[]}
                dataName="activity data"
                className="card-height-100"
                timeRange={distributionTimeRange}
                timeRangeTypes={distributionTimeRangeDefine}
                onTimeRangeChange={handleDistributionTimeRangeChange}
                minHeight={400}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={4}>
              <ResourcesUsagePanel />
            </Col>
            <Col lg={4} className="mt-4 mt-lg-0">
              <DocumentPanel/>
            </Col>
            <Col lg={4} className="mt-4 mt-lg-0">
              <LoginHistoryPanel contentMaxHeight={270} className="h-100" />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OverView;
