import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import { WidgetSelector } from "@/features/dashboards/components/widgets/WidgetSelector";
import { colsConfig, breakpointsConfig } from "@/features/dashboards/widgetLayout";
import { Button } from "reactstrap";
import { setSelectWidgetPanelOpen, setWidgets } from "@/features/dashboards/slice";
import { rowHeightConfig } from "@/features/dashboards/widgetLayout";

import "react-resizable/css/styles.css";
import "react-grid-layout/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

export function DashboardEditArea() {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.dashboards.mode);
  const layoutConfig = useSelector((state) => state.dashboards.layoutConfig);
  const widgets = useSelector((state) => state.dashboards.widgets);

  function toggleSelectWidgetPanel() {
    dispatch(setSelectWidgetPanelOpen(true));
  }

  function onLayoutChange(newLayout) {
    const updatedWidgets = widgets.map((widget) => {
      const layoutItem = newLayout.find((item) => item.i === widget.id.toString());
      return layoutItem ? { ...widget, layout: { ...widget.layout, ...layoutItem } } : widget;
    });

    dispatch(setWidgets(updatedWidgets));
  }

  return (
    <React.Fragment>
      <div className="h-100">
        {widgets.length === 0 && mode === "view" && (
          <div className="centered-content">
            <h1>No Widget Found</h1>
          </div>
        )}
        {widgets.length === 0 && mode === "edit" && (
          <div className="centered-content">
            <Button
              size="lg"
              color="info"
              className="w-lg"
              onClick={() => {
                toggleSelectWidgetPanel();
              }}
            >
              Add Widget
            </Button>
          </div>
        )}
        {widgets.length > 0 && (
          <ResponsiveGridLayout
            className="layout"
            isDraggable={mode === "edit"}
            isResizable={mode === "edit"}
            resizeHandles={["se", "sw", "ne", "nw"]}
            draggableCancel=".non-draggable"
            margin={layoutConfig?.margin?.map(Number) || [10, 10]} // [x, y]
            rowHeight={Number(layoutConfig?.rowHeight) || rowHeightConfig}
            compactType={layoutConfig?.compactType === "null" ? null : layoutConfig?.compactType || null}
            preventCollision={layoutConfig?.compactType === "null" || false}
            cols={colsConfig}
            breakpoints={breakpointsConfig}
            onLayoutChange={(layout) => onLayoutChange(layout)}
          >
            {widgets.map((widget, index) => {
              return (
                <div key={widget.id} data-grid={widget.layout}>
                  <WidgetSelector widget={widget} />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        )}
      </div>
    </React.Fragment>
  );
}
