import { useState, useCallback } from "react";

export function usePaginationAndSearch(initialPage = 1, initialSearch = "") {
  const [page, setPage] = useState(initialPage);
  const [search, setSearch] = useState(initialSearch);

  const handlePageChange = useCallback((page) => setPage(page), []);
  const handleSearch = useCallback((event) => {
    setPage(1);
    setSearch(event.target.value);
  }, []);

  const resetPageAndSearch = useCallback(() => {
    setPage(1);
    setSearch("");
  }, []);

  return {page, search, handlePageChange, handleSearch, resetPageAndSearch} ;
}
