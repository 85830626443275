import React from "react";
import { useState } from "react";
import {
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";

export function ShowSubjectMessageModal({ showModal, toggleModal, data }) {
  const [activeTypeTab, setActiveTypeTab] = useState("web");
  function toggleTypeTab(tab) {
    if (activeTypeTab !== tab) setActiveTypeTab(tab);
  }
  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
      <ModalBody>
        <Nav pills className="nav-justified mb-3">
          {data?.web_enabled && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTypeTab === "web" })}
                onClick={() => {
                  toggleTypeTab("web");
                }}
              >
                Web Notify
              </NavLink>
            </NavItem>
          )}
          {data?.email_enabled && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTypeTab === "email" })}
                onClick={() => {
                  toggleTypeTab("email");
                }}
              >
                Email Notify
              </NavLink>
            </NavItem>
          )}
          {data?.sms_enabled && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTypeTab === "sms" })}
                onClick={() => {
                  toggleTypeTab("sms");
                }}
              >
                SMS Notify
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTypeTab} className="text-muted">
          <TabPane tabId="web">
            <FormGroup className="mb-4">
              <Label for="webSubject">Web Notify Subject</Label>
              <Input
                type="text"
                id="webSubject"
                readOnly
                value={data?.web_subject}
              />
            </FormGroup>
            <FormGroup>
              <Label for="webMessage">Web Notify Message</Label>
              <Input
                type="textarea"
                rows="5"
                id="webMessage"
                readOnly
                value={data?.web_message}
              />
            </FormGroup>
          </TabPane>
          <TabPane tabId="email">
            <FormGroup className="mb-4">
              <Label for="emailSubject">Email Notify Subject</Label>
              <Input
                type="text"
                id="emailSubject"
                readOnly
                value={data?.email_subject}
              />
            </FormGroup>
            <FormGroup>
              <Label for="emailMessage">Email Notify Message</Label>
              <Input
                type="textarea"
                rows="5"
                id="emailMessage"
                readOnly
                value={data?.email_message}
              />
            </FormGroup>
          </TabPane>
          <TabPane tabId="sms">
            <FormGroup>
              <Label for="smsMessage">SMS Notify Message</Label>
              <Input
                type="textarea"
                rows="5"
                id="smsMessage"
                readOnly
                value={data?.sms_message}
              />
            </FormGroup>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
}
