import React from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";

export const DataSourceCustomLocation = ({ formik }) => (
  <FormGroup>
    <Row>
      <Col md={6}>
        <Input
          id="dataSourceConfig.customLocation.latitude"
          name="dataSourceConfig.customLocation.latitude"
          placeholder="Latitude *"
          value={formik.values.dataSourceConfig.customLocation.latitude || ""}
          onChange={formik.handleChange}
          invalid={
            formik.touched.dataSourceConfig?.customLocation?.latitude &&
            !!formik.errors.dataSourceConfig?.customLocation?.latitude
          }
        />
        {formik.touched.dataSourceConfig?.customLocation?.latitude &&
          formik.errors.dataSourceConfig?.customLocation?.latitude && (
            <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
              {formik.errors.dataSourceConfig.customLocation.latitude}
            </div>
          )}
      </Col>
      <Col md={6}>
        <Input
          id="dataSourceConfig.customLocation.longitude"
          name="dataSourceConfig.customLocation.longitude"
          placeholder="Longitude *"
          value={formik.values.dataSourceConfig.customLocation.longitude || ""}
          onChange={formik.handleChange}
          invalid={
            formik.touched.dataSourceConfig?.customLocation?.longitude &&
            !!formik.errors.dataSourceConfig?.customLocation?.longitude
          }
        />
        {formik.touched.dataSourceConfig?.customLocation?.longitude &&
          formik.errors.dataSourceConfig?.customLocation?.longitude && (
            <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
              {formik.errors.dataSourceConfig.customLocation.longitude}
            </div>
          )}
      </Col>
    </Row>
  </FormGroup>
);
