import React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { Col, Row, Container } from "reactstrap";

import { BreadCrumb } from "@/components/common/BreadCrumb";
import { InfoPanel } from "@/components/panels/InfoPanel";
import { EntityUsagePanel } from "@/components/panels/EntityUsagePanel";
import { NotifyConfigurations } from "@/features/notifyHub/components/NotifyConfigurations";
import { NotifyRequestHistory } from "@/features/notifyHub/components/NotifyRequestHistory";

import infoPanelImage from "@/assets/images/illustration/notify.svg";


const NotifyHub = () => {
  const notificationsData = useSelector(
    (state) => state.notifications.notificationsData
  );

  const { totalDataLength } = useMemo(() => ({totalDataLength: notificationsData?.total_count || 0,}),[notificationsData]);

  const userData = useSelector((state) => state.auth.user);
  const SMSNotificationLimits = useMemo(() => userData?.limit?.max_sms_notifications, [userData]);
  const EmailNotificationLimits = useMemo(() => userData?.limit?.max_email_notifications, [userData]);

  document.title = "Notify Hub | Linko Cloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Notify Hub" pageTitle="Messages" />
          <Row>
            <Col xxl={4}>
              <InfoPanel
                title="Notify Hub"
                description="Enpower your users with the ability to send notifications via<strong> Email, SMS, and Web</strong> Notifications."
                documentURL="#"
                height={350}
                image={infoPanelImage}
              />
              <EntityUsagePanel
                title="Notification Request Usage"
                currentValue={totalDataLength}
                totalValue={(SMSNotificationLimits||0) + (EmailNotificationLimits||0)}
                dataType="Notifications Request"
              />
            </Col>
            <Col xxl={8} style={{ minHeight: "500px" }}>
              <NotifyRequestHistory />
            </Col>
          </Row>
          <Row>
            <Col>
              <NotifyConfigurations />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotifyHub;
