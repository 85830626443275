import React, { Fragment } from "react";
import { Row, Table } from "reactstrap";
import { NoResultTip } from "@/components/tables/NoResultTip";
import { Paginator } from "@/components/tables/Pagination";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

export function DynamicDataTable({
  columns,
  data,
  currentPage,
  totalPages,
  onPageChange,
  totalDataLength,
  dataName,
  onRowClick,
  style,
  showPagination = true,
}) {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility:
      {id: false}
    }
  });

  const { getHeaderGroups, getRowModel } = table;

  function handleRowClick(e, row) {
    if (e.target.type === "checkbox" || ["A", "I", "BUTTON"].includes(e.target.tagName)) return;
    if (onRowClick) {
      onRowClick(row.original);
    }
  }

  return (
    <Fragment>
      <div style={{ overflowX: "auto", ...style }}>
        <Table hover className="table-centered align-middle table-nowrap mb-0">
          <thead className="text-muted table-light">
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    {...{ onClick: header.column.getToggleSortingHandler() }}
                    className={header.column.getCanSort() ? "sort" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    {header.isPlaceholder ? null : (
                      <React.Fragment>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: " ",
                          desc: " ",
                        }[header.column.getIsSorted()] ?? null}
                      </React.Fragment>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  onClick={(e) => handleRowClick(e, row)}
                  style={{ cursor: onRowClick ? "pointer" : "default" }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {getRowModel().rows.length === 0 && <NoResultTip dataName={dataName} className="mt-5" />}
      </div>

      {showPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              Showing<span className="fw-semibold ms-1">{data.length}</span> of{" "}
              <span className="fw-semibold">{totalDataLength}</span> Results
            </div>
          </div>
          <div className="col-sm-auto">
            <Paginator currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
          </div>
        </Row>
      )}
    </Fragment>
  );
}
