import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createDeviceAttribute, updateDeviceAttribute } from "@/features/devices/thunk";
import { extractErrorMessage } from "@/common/utils/stringUtils";
import Select from "react-select";

Yup.addMethod(Yup.string, "json", function (message) {
  return this.test("json", message, (value) => {
    try {
      JSON.parse(value);
      return true; // return true if JSON.parse succeeds
    } catch (e) {
      return false; // return false if JSON.parse fails
    }
  });
});

const formSchema = Yup.object().shape({
  key: Yup.string().required("Attribute Key is required"),
  type: Yup.string().oneOf(["string", "number", "boolean", "json"]).required("Attribute Type is required"),
  value: Yup.string()
    .required("Attribute Value is required")
    .when("type", {
      is: "json",
      then: (schema) => schema.json("Invalid JSON format"), // Use the custom JSON validator
    }),
});

const AttributeType = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "boolean", label: "Boolean" },
  { value: "json", label: "Json" },
];

export function AddAttributeModal({ showModal, toggleModal, onSuccess, deviceId, isEditMode = false, attributeData }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);

  const initialValues = isEditMode
    ? {
        key: attributeData.key,
        value: attributeData.value,
        type: attributeData.type,
      }
    : {
        key: "",
        value: "",
        type: "string",
      };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const completeAction = (actionPromise) => {
        actionPromise.then((actionResult) => {
          if (actionResult.meta.requestStatus === "fulfilled") {
            toggleModal();
            resetModal();
            onSuccess();
          } else {
            const apiError = actionResult.payload;
            if (apiError && apiError.data && apiError.data.error_message) {
              const errorMessage = apiError.data.error_message;
              setErrors(extractErrorMessage(errorMessage));
            }
          }
        });
      };

      values.device = deviceId;
      if (isEditMode) {
        completeAction(dispatch(updateDeviceAttribute({ attributeId: attributeData.id, data: values })));
      } else {
        completeAction(dispatch(createDeviceAttribute(values)));
      }
    },
  });

  function resetModal() {
    formik.resetForm();
    setErrors(null);
  }

  function onToggleModal() {
    toggleModal();
    resetModal();
  }

  return (
    <React.Fragment>
      <Modal isOpen={showModal} toggle={onToggleModal} size="lg" modalClassName="zoomIn" backdrop="static" centered>
        <ModalHeader toggle={onToggleModal} className="p-3">
          Create Device Attribute
        </ModalHeader>
        <Form onSubmit={formik.handleSubmit}>
          <ModalBody style={{ minHeight: "300px", overflowY: "auto" }}>
            <FormGroup>
              <Label for="name">Key *</Label>
              <Input
                type="text"
                name="key"
                id="key"
                placeholder="Key"
                onChange={formik.handleChange}
                value={formik.values.key}
                invalid={formik.touched.key && !!formik.errors.key}
                required
              />
              {formik.touched.name && formik.errors.key ? (
                <div className="invalid-feedback">{formik.errors.key}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="type">Type *</Label>
              <Select
                value={AttributeType.find((option) => option.value === formik.values.type)}
                onChange={(option) => formik.setFieldValue("type", option.value)}
                options={AttributeType}
                placeholder="Select a type"
                invalid={formik.touched.type && !!formik.errors.type}
              />
            </FormGroup>

            <FormGroup>
              <Label for="value">Value</Label>
              {formik.values.type === "json" && (
                <Input
                  type="textarea"
                  name="value"
                  id="value"
                  rows="5"
                  placeholder="Json Value"
                  onChange={formik.handleChange}
                  value={formik.values.value}
                  invalid={formik.touched.value && !!formik.errors.value}
                />
              )}
              {formik.values.type === "boolean" && (
                <Input
                  type="select"
                  name="value"
                  id="value"
                  onChange={formik.handleChange}
                  value={formik.values.value}
                  invalid={formik.touched.value && !!formik.errors.value}
                >
                  <option value="">Select a value</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </Input>
              )}
              {formik.values.type !== "json" && formik.values.type !== "boolean" && (
                <Input
                  type="text"
                  name="value"
                  id="value"
                  placeholder="Value"
                  onChange={formik.handleChange}
                  value={formik.values.value}
                  invalid={formik.touched.value && !!formik.errors.value}
                />
              )}
              {formik.touched.value && formik.errors.value ? (
                <div className="invalid-feedback">{formik.errors.value}</div>
              ) : null}
            </FormGroup>
            {errors && (
              <div className="alert alert-danger mt-3" role="alert">
                {errors}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={onToggleModal}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              {isEditMode ? "Update" : "Create"} Device Attribute
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
