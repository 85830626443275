import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";


export const analogClockInitialValues = ({ widgetData }) => {
    return {
        showNumbers: widgetData?.configuration?.showNumbers !== undefined ? widgetData?.configuration?.showNumbers : true,
    };
};

export const analogClockValidationSchema = {
    showNumbers: Yup.boolean(),
};

export function AnalogClockSetting({ formik }) {
  return (
    <React.Fragment>
      <h4 className="mb-4">Clock Setting</h4>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="showNumbers" className="form-label fs-15">
              Show Numbers
            </Label>
          </Col>
          <Col lg={8}>
            <div className="form-check form-switch form-switch-primary form-switch-lg mb-2">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="showNumbers"
                id="showNumbers"
                value={formik.values.showNumbers || ""}
                checked={formik.values.showNumbers || false}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
    </React.Fragment>
  );
}
