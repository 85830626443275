import React, { useMemo } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';

export function EditButton({ onClick, tooltip }) {
  const tooltipId = useMemo(() => "delete-button-" + uuidv4(), []);

  return (
    <React.Fragment>
      <Button color="link" onClick={onClick} id={tooltipId} className="shadow-none">
        <i className="ri-pencil-fill align-bottom fs-5"></i>
      </Button>
      <UncontrolledTooltip placement="auto" target={tooltipId} trigger="hover">
        {tooltip}
      </UncontrolledTooltip>
    </React.Fragment>
  );
}
