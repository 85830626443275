import { createSlice } from '@reduxjs/toolkit';
import { getNotifyRules, getNotifyTemplates } from '@/features/notifyHub/thunk';

export const initialState = {
    isLoading: false,
    needsRefresh: false,
    notifyTemplates : [],
    notifyRules: [],
};

const NotifyHubSlice = createSlice({
    name: 'notifyHub',
    initialState,
    reducers: {
        resetNeedsRefresh(state) {
            state.needsRefresh = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifyRules.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getNotifyRules.fulfilled, (state, action) => {
            state.isLoading = false;
            state.notifyRules = action.payload;
        });
        builder.addCase(getNotifyRules.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
        builder.addCase(getNotifyTemplates.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getNotifyTemplates.fulfilled, (state, action) => {
            state.isLoading = false;
            state.notifyTemplates = action.payload;
        });
        builder.addCase(getNotifyTemplates.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
    }
});

export default NotifyHubSlice.reducer;
export const { resetNeedsRefresh } = NotifyHubSlice.actions;