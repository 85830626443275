import React from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { setMode } from "@/features/dashboards/slice";

export function EditButton({ show }) {
  if (!show) {
    return null;
  }

  const dispatch = useDispatch();
  function updateMode(newMode) {
    dispatch(setMode(newMode));
  }

  return (
    <React.Fragment>
      <Button
        type="button"
        color="dark"
        size="lg"
        outline
        onClick={() => updateMode("edit")}
      >
        <i className="mdi mdi-pencil me-md-2 fs-18"></i>
        <span className="d-none d-lg-inline-block">Edit</span>
      </Button>
    </React.Fragment>
  );
}
