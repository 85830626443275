import axios from "axios";
import Swal from "sweetalert2";
import { clearLoggedInInfo } from "@/features/auth/authService";
import { BASE_API_URL, AUTH_URLS } from "@/api/apiConfig";
import { getAccessToken, getRefreshToken, setAccessToken } from "@/features/auth/authService";

let isNoNetworkTipShown = false;

export const apiClient = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        Authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : '',
    },
});

apiClient.interceptors.response.use(
    response => response.data,
    async (error) => {
        if (error.response) {
            const errorDetails = {
                status: error.response.status,
                data: error.response.data,
                message: error.message,
            };

            switch (errorDetails.status) {
                case 401:
                    if (!error.response.config.__isRefreshTokenRequest){
                        await refreshAccessToken();
                        error.response.config.headers.Authorization = `Bearer ${getAccessToken()}`;
                        const response = await apiClient.request(error.response.config);
                        console.log("refreshed token and retrying request");
                        return response;
                    }
                    else{
                        clearLoggedInInfo();
                        window.location.href = "/login";
                    }
                case 500:
                    errorDetails.data = {
                        error_code: 500,
                        error_message: "Internal Server Error",
                    };
                    break;
                case 404:
                    errorDetails.data = {
                        error_code: 404,
                        error_message:"Sorry! The data you are looking for could not be found",
                    };
                    break;
                default:
                    errorDetails.message = error.response.data || error.message;
                    break;
            }
            return Promise.reject(errorDetails);
        }
        else {
            if (!isNoNetworkTipShown) {
                isNoNetworkTipShown = true;
                Swal.fire({
                    icon: "error",
                    title: "Network Error",
                    text: "Please check your internet connection and try again. If you are still unable to access the site, please contact support.",
                }).then(() => {
                    isNoNetworkTipShown = false;
                });
            }
            return Promise.reject({message: error.message || "Network Error",});
        }
    }
);

let refreshPromise;

async function refreshAccessToken() {
    if (refreshPromise) {
        return refreshPromise;
    }
    refreshPromise = new Promise(async (resolve) => {
        const response = await apiClient.post(AUTH_URLS.REFRESH_TOKEN, {
            refresh: getRefreshToken()
        },{
            headers: {
                "Content-Type": "application/json",
            },
            __isRefreshTokenRequest: true,
        });
        const newAccessToken = response.access;
        setAccessToken(newAccessToken);
        apiClient.defaults.headers.Authorization = `Bearer ${response.access}`;
        resolve(newAccessToken);
    });
    refreshPromise.finally(() => {
        refreshPromise = null;
    });
    return refreshPromise;
};