import React from "react";
import * as Yup from "yup";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

export const widgetSettingInitialValues = ({ widgetData }) => {
  return {
    widgetTitle: widgetData?.title || "",
    showHeader: widgetData?.showHeader !== undefined ? widgetData?.showHeader : true,
  };
};

export const widgetSettingValidationSchema = {
  showHeader: Yup.boolean(),
  widgetTitle: Yup.string(),
};

export function WidgetSetting({ formik }) {
  return (
    <React.Fragment>
      <h4 className="mb-4">Widget Setting</h4>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="showHeader" className="form-label fs-15">
              Show Header
            </Label>
          </Col>
          <Col lg={8}>
            <div className="form-check form-switch form-switch-primary form-switch-lg mb-2">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="showHeader"
                id="showHeader"
                value={formik.values.showHeader || ""}
                checked={formik.values.showHeader || false}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col lg={4} className="d-flex align-items-center">
            <Label
              htmlFor="widgetTitle"
              className="form-label fs-15"
              style={{
                textDecoration: formik.values.showHeader ? "none" : "line-through",
              }}
            >
              Widget Title
            </Label>
          </Col>
          <Col lg={8}>
            <Input
              id="widgetTitle"
              name="widgetTitle"
              placeholder="Timeseries Line Chart"
              value={formik.values.widgetTitle || ""}
              disabled={!formik.values.showHeader}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
      </FormGroup>
    </React.Fragment>
  );
}
