import React from "react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Container } from "reactstrap";

import Swal from "sweetalert2";
import { ReactFlowProvider } from "reactflow";
import { BreadCrumb } from "@/components/common/BreadCrumb";
import { RuleEditArea } from "@/features/rulesEngine/components/RuleEditArea";
import { RuleNodeSelect } from "@/features/rulesEngine/components/RuleNodeSelect";
import { getRuleDetails } from "@/features/rulesEngine/thunk";

import "reactflow/dist/style.css";

const RuleDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const ruleId = params.ruleId;
  const [ruleData, setRuleData] = useState({});

  useEffect(() => {
    dispatch(getRuleDetails({ ruleId })).then((actionResult) => {
      if (getRuleDetails.fulfilled.match(actionResult)) {
        setRuleData(actionResult.payload);
      } else {
        Swal.fire({ title: "Error!", text: "Failed to fetch rule details." });
      }
    });
  }, [dispatch, ruleId]);

  document.title = `${ruleData.title} | Linko Cloud`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={ruleData.title} pageTitle="Rule Egine" />
          <div className="rules-wrapper d-flex gap-1 mx-n4 mt-n4 p-1">
            <ReactFlowProvider>
              <RuleNodeSelect />
              <RuleEditArea ruleData={ruleData} />
            </ReactFlowProvider>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RuleDetail;
