import React from "react";
import { useEffect, useState } from "react";
import { Button, FormGroup, Input, Table } from "reactstrap";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";

export const columnsSettingInitialValues = ({ widgetData }) => {
  return {
    columns: widgetData?.configuration?.columns || [],
  };
};

export const columnsSettingValidationSchema = {
  columns: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required("Key is required"),
        label: Yup.string().required("Label is required"),
      })
    )
    .min(1, "At least one column data is required"),
};

export function ColumnsSetting({
  formik,
  defaultColumnOptions,
}) {
  const fields = ["Key", "Label"];
  const [columnOptions, setColumnOptions] = useState(defaultColumnOptions);

  useEffect(() => {
    setColumnOptions(defaultColumnOptions);
  }, [defaultColumnOptions]);

  const handleAddColumn = () => {
    const newEntry = fields.reduce((acc, field) => ({ ...acc, [field.toLowerCase()]: "" }), {});
    formik.setFieldValue("columns", [...formik.values.columns, newEntry]);
  };

  const handleRemoveColumn = (index) => {
    const newColumns = formik.values.columns.filter((s, i) => i !== index);
    formik.setFieldValue("columns", newColumns);
  };

  const handleChangeKey = (index, option) => {
    formik.setFieldValue(`columns[${index}].key`, option?.value);
    if (!columnOptions.find((o) => o.value === option?.value)) {
      setColumnOptions([...columnOptions, { label: option?.label, value: option?.value }]);
    }
  };

  return (
    <React.Fragment>
      <h4 className="mb-3">Columns *</h4>
      <FormGroup>
        <Table className="align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th>Key</th>
              <th>Label</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.columns.map((entry, index) => (
              <tr key={index}>
                <td scope="row">
                  <CreatableSelect
                    value={columnOptions.find((option) => option.value === entry.key)}
                    onChange={(option) => handleChangeKey(index, option)}
                    options={columnOptions}
                    placeholder="Create or select a key"
                    isClearable
                  />
                  {formik.touched.columns &&
                    formik.touched.columns[index] &&
                    formik.errors.columns &&
                    formik.errors.columns[index] &&
                    formik.errors.columns[index].key && (
                      <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
                        {formik.errors.columns[index].key}
                      </div>
                    )}
                </td>
                <td scope="row">
                  <Input
                    type="text"
                    name={`columns[${index}].label`}
                    placeholder="Label"
                    value={entry.label || ""}
                    onChange={formik.handleChange}
                    invalid={
                      formik.touched.columns &&
                      formik.touched.columns[index] &&
                      formik.errors.columns &&
                      formik.errors.columns[index] &&
                      !!formik.errors.columns[index].label
                    }
                  />
                  {formik.touched.columns &&
                    formik.touched.columns[index] &&
                    formik.errors.columns &&
                    formik.errors.columns[index] &&
                    formik.errors.columns[index].label && (
                      <div className="invalid-feedback d-block">{formik.errors.columns[index].label}</div>
                    )}
                </td>
                <td>
                  <Button className="btn-soft-danger" onClick={() => handleRemoveColumn(index)}>
                    <i className="mdi mdi-delete"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </FormGroup>
      {formik.touched.columns && formik.errors.columns && typeof formik.errors.columns === "string" && (
        <div className="ms-1 mt-1 d-flex justify-content-center" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
          {formik.errors.columns}
        </div>
      )}
      <div className="d-flex justify-content-center">
        <Button color="info" className="mt-3" onClick={handleAddColumn}>
          Add Column
        </Button>
      </div>
    </React.Fragment>
  );
}
