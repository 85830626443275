import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { extractErrorMessage } from "@/common/utils/stringUtils";
import { createNotifyRule, updateNotifyRule } from "@/features/notifyHub/thunk";
import { BasicInfoTab } from "@/features/notifyHub/components/addNotifyRule/BasicInfoTab";
import { DevicesRuleSettingTab } from "@/features/notifyHub/components/addNotifyRule/DevicesRuleSettingTab";
import { MenuNavItem } from "@/features/notifyHub/components/MenuNavItem";
import { AlarmsRuleSettingTab } from "@/features/notifyHub/components/addNotifyRule/AlarmsRuleSettingTab";
import { RuleEngineRuleSettingTab } from "@/features/notifyHub/components/addNotifyRule/RuleEngineRuleSettingTab";

const formSchema = Yup.object().shape({
  name: Yup.string().required("Rule name is required"),
  description: Yup.string(),
  type: Yup.string().required("Rule type is required"),
  template: Yup.number().required("At least one template must be selected"),
  configurations: Yup.object(),
});

export function AddNotifyRuleModal({ showModal, toggleModal, onSuccess, isEditMode = false, ruleData }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [activeMenuTab, setActiveMenuTab] = useState(1);

  const userData = useSelector((state) => state.auth.user);

  async function toggleMenuTab(tab) {
    const fileds = {
      name: true,
      description: true,
      type: true,
      template: true,
    };

    formik.setTouched(fileds);
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      if (activeMenuTab !== tab) setActiveMenuTab(tab);
    }
  }

  const initialValues = useMemo(() => {
    return isEditMode
      ? {
          name: ruleData?.name || "",
          description: ruleData?.description || "",
          type: ruleData?.type || "devices",
          template: ruleData?.template || "",
          configurations: ruleData?.configurations || {},
        }
      : {
          name: "",
          description: "",
          type: "devices",
          template: "",
          configurations: {},
        };
  }, [isEditMode, ruleData]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const completeAction = (actionPromise) => {
        actionPromise.then((actionResult) => {
          if (actionResult.meta.requestStatus === "fulfilled") {
            toggleModal();
            resetModal();
            Swal.fire({ title: "Success!", text: "Notify Rule has been added." });
            onSuccess();
          } else {
            const apiError = actionResult.payload;
            if (apiError && apiError.data && apiError.data.error_message) {
              const errorMessage = apiError.data.error_message;
              setErrors(extractErrorMessage(errorMessage));
            }
          }
        });
      };
      values.user = userData?.id || "";
      if (isEditMode) {
        completeAction(dispatch(updateNotifyRule({ ruleId: ruleData.id, data: values })));
      } else {
        completeAction(dispatch(createNotifyRule(values)));
      }
    },
  });

  function resetModal() {
    formik.resetForm();
    setActiveMenuTab(1);
    setErrors(null);
  }

  function onToggleModal() {
    toggleModal();
    resetModal();
  }

  return (
    <React.Fragment>
      <Modal isOpen={showModal} toggle={onToggleModal} size="xl" modalClassName="zoomIn" backdrop="static" centered>
        <ModalHeader toggle={onToggleModal} className="p-3">
        {isEditMode ? "Update" : "Create"} Notify Rule
        </ModalHeader>
        <Form className="vertical-navs-step form-steps" onSubmit={formik.handleSubmit}>
          <ModalBody style={{ minHeight: "400px", overflowY: "auto" }}>
            <Row>
              <Col lg={3}>
                <Nav className="flex-column custom-nav nav-pills">
                  <MenuNavItem
                    title="Basic Settings"
                    stepIndex={1}
                    tabIndex={1}
                    isVisible={true}
                    verified={true}
                    activeMenuTab={activeMenuTab}
                    toggleMenuTab={toggleMenuTab}
                  />
                  <MenuNavItem
                    title="Devices Rule Settings"
                    stepIndex={2}
                    tabIndex={2}
                    isVisible={formik.values.type === "devices"}
                    verified={true}
                    activeMenuTab={activeMenuTab}
                    toggleMenuTab={toggleMenuTab}
                  />
                  <MenuNavItem
                    title="Alarms Rule Settings"
                    stepIndex={2}
                    tabIndex={3}
                    isVisible={formik.values.type === "alarms"}
                    verified={true}
                    activeMenuTab={activeMenuTab}
                    toggleMenuTab={toggleMenuTab}
                  />
                  <MenuNavItem
                    title="Rule Engine Event Rule Settings"
                    stepIndex={2}
                    tabIndex={4}
                    isVisible={formik.values.type === "ruleEngineEvent"}
                    verified={true}
                    activeMenuTab={activeMenuTab}
                    toggleMenuTab={toggleMenuTab}
                  />
                </Nav>
              </Col>
              <Col lg={9}>
                <TabContent activeTab={activeMenuTab}>
                  <TabPane tabId={1}>
                    <BasicInfoTab formik={formik} />
                  </TabPane>
                  <TabPane tabId={2}>
                    <DevicesRuleSettingTab formik={formik} active={formik.values.type === "devices"} />
                  </TabPane>
                  <TabPane tabId={3}>
                    <AlarmsRuleSettingTab formik={formik} active={formik.values.type === "alarms"} />
                  </TabPane>
                  <TabPane tabId={4}>
                    <RuleEngineRuleSettingTab formik={formik} active={formik.values.type === "ruleEngineEvent"} />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={onToggleModal}>
              Cancel
            </Button>
            <Button color="success" type="submit">
            {isEditMode ? "Update" : "Create"} Notify Rule
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
