import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Row, Col, Input, CardTitle } from "reactstrap";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { getLatestTelemetryByDevice } from "@/features/devices/thunk";

import { RefreshDataButton } from "@/components/tables/headerToolSet/RefreshDataButton";

export function DeviceLastestTelemetryTable({ deviceId }) {
  if (!deviceId) return null;

  const dispatch = useDispatch();
  const [ data, setData ] = useState([]);

  useEffect(() => {
    dispatch(getLatestTelemetryByDevice({ deviceId: deviceId, limit: 1 }))
    .then((actionResult) => {
        if (getLatestTelemetryByDevice.fulfilled.match(actionResult)) { 
            const results = actionResult.payload;
            if (results.length > 0) {
                const originalData = results[0];
                const result = Object.keys(originalData.data).map(key => ({
                    key,
                    value: originalData.data[key],
                    timestamp: originalData.timestamp
                }));
                setData(result);
            }
        }
    });
    }, [deviceId]);

  function refreshData() {
  }

  const columns = useMemo(
    () => [
      {
        header: "Key",
        accessorKey: "key",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Value",
        accessorKey: "value",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Last Updated",
        accessorKey: "timestamp",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          const date = new Date(cell.getValue());
          return date.toLocaleString('en-US');
        }
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <Card className="border card-height-100">
        <CardHeader>
        <Row className="d-flex justify-content-between mb-3">
            <Col md="2" className="d-flex justify-content-begin">
              <RefreshDataButton onClick={refreshData} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>

          <DynamicDataTable
            columns={columns}
            data={data}
            currentPage={1}
            totalPages={1}
            totalDataLength={data.length}
            dataName="device latest telemetry"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
