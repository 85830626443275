import { createSlice } from '@reduxjs/toolkit';
import { getResourceUsage } from '@/features/resourcesUsage/thunk';


export const initialState = {
    isLoading: false,
    usageData: null,
};


const ResourcesUsageSlice = createSlice({
    name: 'resourcesUsage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getResourceUsage.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getResourceUsage.fulfilled, (state, action) => {
            state.isLoading = false;
            state.usageData = action.payload;
        });
        builder.addCase(getResourceUsage.rejected, (state, action) => {
            state.isLoading = false;
            state.fetchError = action.payload || null;
        });
    }
});

export default ResourcesUsageSlice.reducer;