import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimeRangeChart } from "@/components/charts/TimeRangeChart";

export function RulesEngineActivity() {
  const dispatch = useDispatch();
  const [timeRange, setTimeRange] = useState("minutely");
  const timeRangeDefine = ["minutely", "hourly" ,"daily", "weekly"];

  function handleTimeRangeChange(timeRange) {
    setTimeRange(timeRange);
  }

  return (
    <TimeRangeChart
      type="line"
      title="Rules Engine Activity"
      data={[]}
      dataName="activity data"
      timeRange={timeRange}
      timeRangeTypes={timeRangeDefine}
      onTimeRangeChange={handleTimeRangeChange}
      minHeight={350}
    />
  );
}
