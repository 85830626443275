import React from "react";
import { useModals } from "@/hooks/useModals";
import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useTableSelectionControl } from "@/hooks/useTableSelectionControl";
import { Button, Col, Row, Input, Card, CardHeader } from "reactstrap";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { SearchBox } from "@/components/tables/headerToolSet/SearchBox";
import { EditButton } from "@/components/tables/rowActionButtons/EditButton";
import { DeleteButton } from "@/components/tables/rowActionButtons/DeleteButton";
import { ActionButtonGroup } from "@/components/tables/rowActionButtons/ActionButtonGroup";
import { truncateText } from "@/common/utils/stringUtils";
import { AddEntityButton } from "@/components/tables/headerToolSet/AddEntityButton";
import { RefreshDataButton } from "@/components/tables/headerToolSet/RefreshDataButton";
import { DeleteMultipleButton } from "@/components/tables/headerToolSet/DeleteMultipleButton";
import { getNotifyTemplates, deleteNotifyTemplate, deleteMultipleNotifyTemplates } from "@/features/notifyHub/thunk";
import { ShowSubjectMessageModal } from "@/features/notifyHub/components/addNotifyTemplate/ShowSubjectMessageModal";

import { DeleteModal } from "@/components/modals/DeleteModal";
import { ShowDescriptionModal } from "@/components/modals/ShowDescriptionModal";
import { DeleteMultipileModal } from "@/components/modals/DeleteMultipileModal";
import { AddNotifyTemplateModal } from "@/features/notifyHub/components/addNotifyTemplate/AddNotifyTemplateModal";

export function NotifyTemplates() {
  const dispatch = useDispatch();
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const [selectedIds, handleSelectedAll, handleSelectedSingle, resetSelectedIds] = useTableSelectionControl();

  useEffect(() => {
    dispatch(getNotifyTemplates({ page, search }));
  }, [page, search]);

  const notifyTemplates = useSelector((state) => state.notifyHub.notifyTemplates);
  const { notifyTemplatesList, allTemplateIds, totalPages, totalDataLength } = useMemo(
    () => ({
      notifyTemplatesList: notifyTemplates?.results || [],
      allTemplateIds: notifyTemplates?.results?.map((template) => template.id) || [],
      totalPages: notifyTemplates?.total_pages || 0,
      totalDataLength: notifyTemplates?.total_count || 0,
    }),
    [notifyTemplates]
  );

  const [modals, modalData, toggleModal] = useModals({
    deleteModal: false,
    deleteMultipleModal: false,
    addNotifyTemplateModal: false,
    showSubjectMessageModal: false,
  });

  const refreshData = () => {
    dispatch(getNotifyTemplates({ page, search }));
    resetSelectedIds();
    resetPageAndSearch();
  };

  function handleDeleteMultipleTemplates() {
    dispatch(deleteMultipleNotifyTemplates({ ids: selectedIds })).then((actionResult) => {
      if (deleteMultipleNotifyTemplates.fulfilled.match(actionResult)) {
        toggleModal("deleteMultipleModal");
        refreshData();
      }
    });
  }

  function handleDeleteTemplate(templateId) {
    if (templateId) {
      dispatch(deleteNotifyTemplate({ templateId })).then((actionResult) => {
        if (deleteNotifyTemplate.fulfilled.match(actionResult)) {
          toggleModal("deleteModal");
          refreshData();
        }
      });
    }
  }

  const columns = [
    {
      header: (
        <div className="form-check">
          <Input
            type="checkbox"
            className="form-check-input"
            checked={selectedIds.length === notifyTemplatesList.length && notifyTemplatesList.length > 0}
            onChange={(e) => handleSelectedAll(e, allTemplateIds)}
          />
        </div>
      ),
      cell: (cell) => {
        return (
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              value={cell.getValue()}
              checked={selectedIds.includes(cell.getValue())}
              onChange={(e) => handleSelectedSingle(e, cell.getValue())}
            />
          </div>
        );
      },
      id: "#",
      accessorKey: "id",
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "Name",
      accessorKey: "name",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Type",
      accessorKey: "type",
      enableSorting: true,
      enableColumnFilter: false,
      cell: (cell) => {
        return <span className="text-capitalize">{cell.getValue()}</span>;
      },
    },
    {
      header: "Delivery Method",
      accessorKey: "web_enabled, email_enabled,sms_enabled",
      enableSorting: true,
      enableColumnFilter: false,
      cell: (cell) => {
        return (
          <div>
            {cell.row.original.web_enabled && <span>Web</span>}
            {cell.row.original.email_enabled && <span>, Email</span>}
            {cell.row.original.sms_enabled && <span>, SMS</span>}
          </div>
        );
      },
    },
    {
      header: "Subject & Message",
      accessorKey: "subject, message",
      enableSorting: true,
      enableColumnFilter: false,
      cell: (cell) => {
        return (
          <Button
            color="link"
            className="shadow-none"
            outline
            onClick={(e) =>
              toggleModal("showSubjectMessageModal", {
                data: cell.row.original,
              })
            }
          >
            <a className="text-decoration-underline">View Subjects & Messages</a>
          </Button>
        );
      },
    },
    {
      header: "Description",
      accessorKey: "description",
      enableSorting: true,
      enableColumnFilter: false,
      cell: (cell) => {
        return (
          <Button
            color="link"
            className="shadow-none"
            outline
            onClick={(e) =>
              toggleModal("showDescriptionModal", {
                description: cell.getValue(),
              })
            }
          >
            <a className="text-decoration-underline">{truncateText(cell.getValue(), 50)}</a>
          </Button>
        );
      },
    },
    {
      header: "Time",
      accessorKey: "created_time",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Action",
      disableFilters: true,
      enableSorting: false,
      cell: (cell) => {
        return (
          <ActionButtonGroup>
            <EditButton
              onClick={() =>
                toggleModal("addNotifyTemplateModal", { isEditMode: true, templateData: cell.row.original })
              }
              tooltip="Edit Notify Template"
            />
            <DeleteButton
              onClick={() =>
                toggleModal("deleteModal", {
                  templateId: cell.row.original.id,
                })
              }
              tooltip="Delete Notify Template"
            />
          </ActionButtonGroup>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Card className="pb-4">
        <CardHeader className="d-flex justify-content-between ps-0">
          <Col md="2" className="d-flex justify-content-begin">
            <AddEntityButton
              onClick={() => toggleModal("addNotifyTemplateModal", { isEditMode: false })}
              text="Add Notify Template"
            />
            <RefreshDataButton onClick={refreshData} />
            <DeleteMultipleButton
              onClick={() => toggleModal("deleteMultipleModal")}
              tooltip="Delete Multiple Notify Templates"
              show={selectedIds.length > 0}
            />
          </Col>
          <Col md="6">
            <SearchBox handleSearch={handleSearch} search={search} placeholder="Search Notify Templates" />
          </Col>
        </CardHeader>
        <DynamicDataTable
          columns={columns}
          data={notifyTemplatesList}
          currentPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalDataLength={totalDataLength}
          dataName="notify template"
        />
      </Card>
      <DeleteModal
        showModal={modals.deleteModal}
        toggleModal={() => toggleModal("deleteModal")}
        title="Delete Notify Templates"
        subtitle="Are you sure you want to delete this notify Templates?"
        onDelete={() => handleDeleteTemplate(modalData.deleteModal?.templateId)}
      />
      <DeleteMultipileModal
        showModal={modals.deleteMultipleModal}
        toggleModal={() => toggleModal("deleteMultipleModal")}
        type="notify template"
        numberSelected={selectedIds.length}
        onDelete={handleDeleteMultipleTemplates}
      />
      <AddNotifyTemplateModal
        showModal={modals.addNotifyTemplateModal}
        toggleModal={() => toggleModal("addNotifyTemplateModal")}
        onSuccess={refreshData}
        isEditMode={modalData.addNotifyTemplateModal?.isEditMode}
        templateData={modalData.addNotifyTemplateModal?.templateData}
      />
      <ShowDescriptionModal
        showModal={modals.showDescriptionModal}
        toggleModal={() => toggleModal("showDescriptionModal")}
        title="Notify Template Description"
        description={modalData.showDescriptionModal?.description}
      />
      <ShowSubjectMessageModal
        showModal={modals.showSubjectMessageModal}
        toggleModal={() => toggleModal("showSubjectMessageModal")}
        data={modalData.showSubjectMessageModal?.data}
      />
    </React.Fragment>
  );
}
