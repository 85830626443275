import { createSlice } from '@reduxjs/toolkit';
import { editProfile } from '@/features/auth/profile/thunk';

const initialState = {
    error: null,
    user: null,
    status: 'failed',
    isLoading: false,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        resetRegistrationStatus(state) {
            state.success =  false;
            state.error = null;
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(editProfile.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(editProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = 'success';
            state.user = action.payload;
        })
        .addCase(editProfile.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'failed';
            state.error = action.payload || 'Failed to edit profile';
        });
    },
});

export default profileSlice.reducer;