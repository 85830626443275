import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export function EmailNotifyTab({ formik }) {
  return (
    <React.Fragment>
      <FormGroup>
        <Label for="emailSubject">Email Notify Subject *</Label>
        <Input
          type="text"
          name="emailSubject"
          id="emailSubject"
          placeholder="Subject"
          onChange={formik.handleChange}
          value={formik.values.emailSubject}
          invalid={formik.touched.emailSubject && !!formik.errors.emailSubject}
        />
        {formik.touched.emailSubject && formik.errors.emailSubject ? (
          <div className="invalid-feedback">{formik.errors.emailSubject}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="emailMessage">Email Notify Message</Label>
        <p className="text-muted">
          Messages support the use of variables for personalization. Please
          see the{" "}
          <a
            className="text-secondary text-decoration-underline"
            href="https://docs.linkocloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>{" "}
          for detailed guidance on templating.
        </p>

        <Input
          type="textarea"
          name="emailMessage"
          id="emailMessage"
          rows="5"
          placeholder="Message"
          onChange={formik.handleChange}
          value={formik.values.emailMessage}
          invalid={formik.touched.emailMessage && !!formik.errors.emailMessage}
        />
        {formik.touched.emailMessage && formik.errors.emailMessage ? (
          <div className="invalid-feedback">{formik.errors.emailMessage}</div>
        ) : null}
      </FormGroup>
    </React.Fragment>
  );
}
