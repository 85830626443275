import React from 'react';
import styles from './DotLoader.module.scss';

const Dotloader = () => {
    return (
        <div className={styles.dotsContainer}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
        </div>
    )
}

export default Dotloader;