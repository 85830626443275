import { createAsyncThunk } from "@reduxjs/toolkit"; 
import { apiClient } from "@/api/axiosClient";
import { RESOURCES_USAGE_API_URLS } from "@/api/apiConfig";


export const getResourceUsage = createAsyncThunk(
    "resourceUsage/getResourceUsage",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(RESOURCES_USAGE_API_URLS.GET_USAGE);
            if (response){
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);