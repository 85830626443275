import React, { useMemo } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

export function DeleteMultipleButton({ onClick, tooltip, show }) {
  const tooltipId = useMemo(() => "delete-mutiple-" + uuidv4(), []);

  return (
    <React.Fragment>
      {show && (
        <>
          <Button
            color="danger"
            className="btn-ghost-danger shadow-none me-2 border fs-5"
            onClick={onClick}
            id={tooltipId}
          >
            <i className="ri-delete-bin-2-line"></i>
          </Button>
          <UncontrolledTooltip
            placement="auto"
            target={tooltipId}
            trigger="hover"
          >
            {tooltip}
          </UncontrolledTooltip>
        </>
      )}
    </React.Fragment>
  );
}
