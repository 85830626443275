import React, { useEffect, useState } from "react";
import Dotloader from "@/components/loaders/DotLoader";
import { useNavigate, useLocation } from "react-router-dom";
import { OAUTH_TOKEN_URLS } from "@/api/apiConfig";
import { extractErrorMessage } from "@/common/utils/stringUtils";
import { loginOauth } from "@/features/auth/login/thunk";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "reactstrap";

const Oauth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const provider = window.location.pathname.split("/")[2];

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loginStatus = useSelector((state) => state.auth.status);
  const apiError = useSelector((state) => state.auth.error);

  useEffect(() => {
    if (loginStatus === "authenticated") {
      navigate("/overview");
    }
  }, [loginStatus]);

  useEffect(() => {
    if (apiError) {
      if (apiError.data && apiError.data.error_message) {
        const errorMessage = apiError.data.error_message;
        setError(extractErrorMessage(errorMessage));
        setLoading(false);
      } else {
        setError("Failed to login");
        setLoading(false);
      }
    }
  }, [apiError]);

  useEffect(() => {
    setError("");
  }, [location]);

  useEffect(() => {
    const searchParams = new URL(window.location.href).searchParams;
    const code = searchParams.get("code");
    const errorParam = searchParams.get("error");
    const errorDescription = searchParams.get("error_description");

    if (errorParam && errorDescription) {
      setError(decodeURIComponent(errorDescription));
      setLoading(false);
      return;
    }

    if (!code) {
      setError("No authorization code found, please try again.");
      setLoading(false);
      return;
    }

    if (!OAUTH_TOKEN_URLS.hasOwnProperty(provider)) {
      setError(`Unknown oauth provider ${provider}`);
      setLoading(false);
      return;
    }

    const redirectUri = process.env.REACT_APP_SITE_URL + `/oauth/${provider}/`;

    dispatch(loginOauth({ code, provider, redirectUri }));
  }, []);

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <Row>
        <Col className="text-center">
          <Dotloader />
          {loading && <p>Redirecting...</p>}
          {error && <p>Error: {error}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default Oauth;
