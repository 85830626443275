import { DEVICE_API_URLS } from "@/api/apiConfig";
import { apiClient } from "@/api/axiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { resetNeedsRefresh } from "@/features/devices/slice";

export const getDevicesData = createAsyncThunk(
  "devices/getDevicesData",
  async ({ page, search }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (page !== undefined) {
        params.append("page", page);
      }
      if (search !== undefined) {
        params.append("search", search);
      }

      const response = await apiClient.get(`${DEVICE_API_URLS.GET_DEVICES}?${params.toString()}`);

      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const getDeviceStatisticsData = createAsyncThunk(
  "devices/getDeviceStatisticsData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(DEVICE_API_URLS.GET_STATISTICS_DATA);
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const getBulkDevices = createAsyncThunk(
  "devices/getBulkDevices",
  async ({ deviceIds, page }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(DEVICE_API_URLS.BULK_RETRIEVE_DEVICES, { ids: deviceIds, page });
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const getDeviceDetailData = createAsyncThunk(
  "devices/getDeviceDetailData",
  async ({ deviceId }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(DEVICE_API_URLS.RETRIEVE_DEVICE(deviceId));
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const createDevice = createAsyncThunk("devices/createDevice", async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiClient.post(DEVICE_API_URLS.CREATE_DEVICE, data);
    if (response) {
      dispatch(resetNeedsRefresh());
      return response;
    }
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const updateDevice = createAsyncThunk(
  "devices/updateDevice",
  async ({ deviceId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.patch(DEVICE_API_URLS.UPDATE_DEVICE(deviceId), data);
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteDevice = createAsyncThunk(
  "devices/deleteDevice",
  async ({ deviceId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(DEVICE_API_URLS.DELETE_DEVICE(deviceId));
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const bulkDeleteDevices = createAsyncThunk(
  "devices/bulkDeleteDevices",
  async ({ deviceIds }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(DEVICE_API_URLS.BULK_DELETE_DEVICES, {
        data: { device_ids: deviceIds },
      });
      if (response) {
        dispatch(resetNeedsRefresh());
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const getDeviceAttributes = createAsyncThunk(
  "devices/getDeviceAttributes",
  async ({ deviceIds }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(DEVICE_API_URLS.GET_DEVICE_ATTRIBUTES, { device_ids: deviceIds });
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const createDeviceAttribute = createAsyncThunk(
  "devices/createDeviceAttribute",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.post(DEVICE_API_URLS.CREATE_DEVICE_ATTRIBUTES, data);
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteDeviceAttribute = createAsyncThunk(
  "devices/deleteDeviceAttribute",
  async ({ attributeId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(DEVICE_API_URLS.DELETE_DEVICE_ATTRIBUTES(attributeId));
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const updateDeviceAttribute = createAsyncThunk(
  "devices/updateDeviceAttribute",
  async ({ attributeId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.put(DEVICE_API_URLS.UPDATE_DEVICE_ATTRIBUTES(attributeId), data);
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const bulkDeleteDeviceAttributes = createAsyncThunk(
  "devices/bulkDeleteDeviceAttributes",
  async ({ attributeIds }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(DEVICE_API_URLS.BULK_DELETE_DEVICE_ATTRIBUTES, {
        data: { ids: attributeIds },
      });
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);


export const getDevicesTelemetry = createAsyncThunk(
  "devices/getDevicesTelemetry",
  async ({ deviceIds, timeRange, keys }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (deviceIds !== undefined) {
        params.append("devices", deviceIds);
      }
      if (timeRange !== undefined) {
        params.append("time_range", timeRange);
      }
      if (keys !== undefined) {
        params.append("keys", keys);
      }
      const response = await apiClient.get(`${DEVICE_API_URLS.GET_TELEMETRY_BY_DEVICES}?${params.toString()}`);

      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);


export const getDeviceTelemetryStatus = createAsyncThunk(
  "devices/getDeviceTelemetryStatus",
  async ({ deviceIds, timeRange }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (deviceIds !== undefined) {
        params.append("devices", deviceIds);
      }
      if (timeRange !== undefined) {
        params.append("time_range", timeRange);
      }
      const response = await apiClient.get(`${DEVICE_API_URLS.GET_TELEMETRY_STATUS_BY_DEVICES}?${params.toString()}`);

      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);


export const getLatestTelemetryByDevice = createAsyncThunk(
  "devices/getLatestTelemetryByDevice",
  async ({ deviceId }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`${DEVICE_API_URLS.GET_LATEST_TELEMETRY_BY_DEVICE}?device=${deviceId}`);

      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);
