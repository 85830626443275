import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import { getDevicesData } from "@/features/devices/thunk";

export function DataSourceDevices({ formik }) {
  const [deviceOptions, setDeviceOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDevicesData({}));
  }, []);

  const devicesList = useSelector((state) => state.devices.devicesData);

  useEffect(() => {
    if (devicesList && devicesList.results && devicesList.results.length > 0) {
      setDeviceOptions(
        devicesList.results.map((device) => {
          return { label: device.name, value: device.id };
        })
      );
    }
  }, [devicesList]);

  return (
    <React.Fragment>
      <h4 className="mb-3">Data Source Devices*</h4>
      <FormGroup>
        <Select
          value={formik.values.dataSourceConfig.devices.map((deviceId) =>
            deviceOptions.find((option) => option.value === deviceId)
          )}
          onChange={(selectedOptions) => {
            const deviceIds = selectedOptions ? selectedOptions.map((option) => option.value) : [];
            formik.setFieldValue("dataSourceConfig.devices", deviceIds);
          }}
          options={deviceOptions}
          placeholder="Select Devices"
          isMulti={true}
          isClearable={true}
          invalid={formik.touched.dataSourceConfig?.devices && Boolean(formik.errors.dataSourceConfig?.devices)}
        />
        {formik.touched.dataSourceConfig?.devices && formik.errors.dataSourceConfig?.devices && (
          <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
            {formik.errors.dataSourceConfig?.devices}
          </div>
        )}
      </FormGroup>
    </React.Fragment>
  );
}
