import React from "react";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Treebeard } from "react-treebeard";

function transformObjectToTreebeardFormat(obj, name = "root", isRoot = true) {
  const node = { name, children: [] };
  node.toggled = isRoot; // Only root node is expanded by default

  if (typeof obj !== "object" || obj === null) {
    return { ...node, name: `${name}: ${obj}` };
  }

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object" && value !== null) {
      node.children.push(transformObjectToTreebeardFormat(value, key, false));
    } else {
      node.children.push({ name: `${key}: ${value}` });
    }
  }

  return node;
}

export function ComplexObjectDisplay({ obj }) {
  const colorMode = useSelector((state) => state.layout.colorMode);
  const [data, setData] = useState(transformObjectToTreebeardFormat(obj));

  const onToggle = (node, toggled) => {
    if (node.children) {
      node.toggled = toggled;
    }
    setData({ ...data });
  };

  const style = useMemo(
    () => ({
      tree: {
        base: {
          backgroundColor: colorMode === "dark" ? "#212529" : "#fff",
          color: colorMode === "dark" ? "#adb5bd" : "#343a40",
          fontSize: "14px",
        },
        node: {
          activeLink: {
            background: colorMode === "dark" ? "#343a40" : "#f8f9fa",
          },
          link: {
            cursor: 'pointer',
            position: 'relative',
            padding: '0px 10px',
            display: 'block',
            color: colorMode === "dark" ? "#adb5bd" : "#343a40",
        },
         header: {
          base: {
            display: 'inline-block',
            verticalAlign: 'top',
            color: colorMode === "dark" ? "#adb5bd" : "#343a40",
          }
         }
        },
      },
    }),
    [colorMode]
  );

  return <Treebeard data={data} onToggle={onToggle} style={style} />;
}
