import React from "react";
import { DataSourceDevice } from "./DataSourceDevice";
import { DataSourceSeries } from "./DataSourceSeries";

export function DataSourceDeviceSingleSeries({ formik }) {
  return (
    <React.Fragment>
      <DataSourceDevice formik={formik} />
      <DataSourceSeries formik={formik} seriesLength={1}/>
    </React.Fragment>
  );
}
