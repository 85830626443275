import { createSlice } from "@reduxjs/toolkit";
import {
  getDashboardsListData,
  getDashboardDetailData,
} from "@/features/dashboards/thunk";
import { rowHeightConfig } from "@/features/dashboards/widgetLayout";

export const initialState = {
  mode: "view", // [view, edit]
  isLoading: false,
  dashboardsListData: [],
  fetchListDataError: null,

  // Dashboard Detail
  dashboardDetailData: {},
  fetchDetailDataError: null,
  selectWidgetPanelOpen: false,

  title: "",
  description: "",
  widgets: [],
  layoutConfig: {
    compactType: "vertical",
    margin: [10, 10],
    rowHeight: rowHeightConfig,
  },
};

const DashboardsSlice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {
    setMode(state, action) {
      state.mode = action.payload;
    },
    clearDashboardDetailData(state) {
      state.dashboardDetailData = {};
      state.title = "";
      state.widgets = [];
      state.description = "";
      state.layoutConfig = {
        compactType: "vertical",
        margin: [10, 10],
        rowHeight: rowHeightConfig,
      };
    },
    resetDashboardDetailData(state) {
      state.title = state.dashboardDetailData?.title || "";
      state.widgets = state.dashboardDetailData?.widgets || [];
      state.description = state.dashboardDetailData?.description || "";
      state.layoutConfig =
        state.dashboardDetailData?.layout_configuration || {};
    },
    setLayoutConfig(state, action) {
      state.layoutConfig = action.payload;
    },
    setSelectWidgetPanelOpen(state, action) {
      state.selectWidgetPanelOpen = action.payload;
    },
    setWidgets(state, action) {
      state.widgets = action.payload;
    },
    addWidget(state, action) {
      state.widgets.push(action.payload);
    },
    removeWidget(state, action) {
      state.widgets = state.widgets.filter(
        (widget) => widget.id !== action.payload
      );
    },
    updateWidget(state, action) {
      const { id, widget } = action.payload;
      const widgetIndex = state.widgets.findIndex((w) => w.id === id);
      state.widgets[widgetIndex] = widget;
    },
    copyWidget(state, action) {
      const widget = action.payload;
      const newWidget = { ...widget, id: new Date().getTime().toString() };
      state.widgets.push(newWidget);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardsListData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboardsListData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardsListData = action.payload;
    });
    builder.addCase(getDashboardsListData.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchListDataError = action.payload || null;
    });
    builder.addCase(getDashboardDetailData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboardDetailData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardDetailData = action.payload;
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.widgets = action.payload.widgets;
      state.layoutConfig = action.payload.layout_configuration;
    });
    builder.addCase(getDashboardDetailData.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchDetailDataError = action.payload || null;
    });
  },
});

export default DashboardsSlice.reducer;
export const {
  resetDashboardDetailData,
  clearDashboardDetailData,
  setMode,
  setLayoutConfig,
  setSelectWidgetPanelOpen,
  setWidgets,
  addWidget,
  updateWidget,
  removeWidget,
  copyWidget,
} = DashboardsSlice.actions;
