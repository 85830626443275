import { createSlice } from '@reduxjs/toolkit';
import { sendEmailVerification } from '@/features/auth/emailVerification/thunk';

const initialState = {
    isLoading: false,
    error: null,
};

const requestEmailVerificationSlice = createSlice({
    name: 'requestEmailVerification',
    initialState,
    reducers: {
        clearError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendEmailVerification.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendEmailVerification.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(sendEmailVerification.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to resend verification';
            });
    },
});

export const { clearError } = requestEmailVerificationSlice.actions;
export default requestEmailVerificationSlice.reducer;