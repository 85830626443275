import React from 'react'
import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import { DocumentListItem } from "@/features/overview/components/DocumentListItem";


export function DocumentPanel() {
    return (
    <Card className="card-height-100 h-100 border">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Documents</h4>
        </CardHeader>
        <CardBody className="position-relative">
            <div className="vstack gap-2">
                <DocumentListItem title="Getting Start" color="success" icon="mdi mdi-rocket" documentURL="https://docs.linkocloud.com/"/>
                <DocumentListItem title="Devices"  color="secondary" icon="mdi mdi-devices" documentURL="https://docs.linkocloud.com/"/>
                <DocumentListItem title="Rule Engine"  color="danger" icon="mdi mdi-lan" documentURL="https://docs.linkocloud.com/"/>
                <DocumentListItem title="Alarms" color="info" icon="mdi mdi-alarm-light" documentURL="https://docs.linkocloud.com/"/>
            </div>
        </CardBody>
    </Card>
    )
}