import React, { useMemo } from "react";
import { Col, Row, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import { EditButton } from "@/features/dashboards/components/TopToolBar/EditButton";
import { TimeWindow } from "@/features/dashboards/components/TopToolBar/TimeWindow";
import { SaveButtonSet } from "@/features/dashboards/components/TopToolBar/SaveButtonSet";
import { AddWidgetButton } from "@/features/dashboards/components/TopToolBar/AddWidgetButton";
import { EditLayoutSettingsButton } from "@/features/dashboards/components/TopToolBar/EditLayoutSettingsButton";
import { setSelectWidgetPanelOpen } from "@/features/dashboards/slice";

export function TopToolBar({ dashboardId }) {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.dashboards.mode);
  const dashboardDetailData = useSelector(
    (state) => state.dashboards.dashboardDetailData
  );

  const selectWidgetOpen = useSelector(
    (state) => state.dashboards.selectWidgetPanelOpen
  );
  function toggleSelectWidgetPanel() {
    dispatch(setSelectWidgetPanelOpen(!selectWidgetOpen));
  }

  return (
    <React.Fragment>
      <div className="top-toolbar d-sm-flex align-items-center justify-content-between">
        <h3 className="mb-sm-0">{dashboardDetailData.title}</h3>
        <div>
          <EditLayoutSettingsButton show={mode == "edit"} />
          <AddWidgetButton
            show={mode == "edit"}
            onClick={toggleSelectWidgetPanel}
          />
          {/* <TimeWindow /> */}
          <EditButton show={mode == "view"} />
          <SaveButtonSet dashboardId={dashboardId} show={mode == "edit"} />
        </div>
      </div>
    </React.Fragment>
  );
}
