import React from "react";
import { useModals } from "@/hooks/useModals";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useTableSelectionControl } from "@/hooks/useTableSelectionControl";

import Swal from "sweetalert2";
import classnames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Row,
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";

import { truncateText } from "@/common/utils/stringUtils";
import { BreadCrumb } from "@/components/common/BreadCrumb";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";
import { DeleteButton } from "@/components/tables/rowActionButtons/DeleteButton";
import { ActionButtonGroup } from "@/components/tables/rowActionButtons/ActionButtonGroup";
import { DownloadJsonButton } from "@/components/tables/rowActionButtons/DownloadJsonButton";

import { DeleteModal } from "@/components/modals/DeleteModal";
import { ExportCSVModal } from "@/components/modals/ExportCSVModal";
import { DeleteMultipileModal } from "@/components/modals/DeleteMultipileModal";
import { NotificationMessageModal } from "@/features/notifications/components/showMessageModal";

import {
  getNotificationsData,
  deleteMultipleNotifications,
  deleteNotification,
  updateNotification,
  markNotificationsAsRead,
} from "@/features/notifications/thunk";

const Notifications = () => {
  const dispatch = useDispatch();
  const [unreadFilter, setUnreadFilter] = useState(true);
  const [criticalFilter, setCriticalFilter] = useState(false);
  const [activeTab, setActiveTab] = useState("unread");
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const [selectedIds, handleSelectedAll, handleSelectedSingle, resetSelectedIds] = useTableSelectionControl();

  const [modals, modalData, toggleModal] = useModals({
    exportModal: false,
    deleteModal: false,
    showMessageModal: false,
    deleteMultipleModal: false,
  });

  const needsRefresh = useSelector((state) => state.notifications.needsRefresh);
  useEffect(() => {
    dispatch(getNotificationsData({ page, search, unreadFilter, criticalFilter }));
  }, [page, search, unreadFilter, criticalFilter]);

  let notificationsData;
  if (unreadFilter && activeTab === "all") {
    notificationsData = useSelector((state) => state.notifications.unreadNotificationsData);
  } else {
    notificationsData = useSelector((state) => state.notifications.notificationsData);
  }

  const { notificationsList, allNotificationIds, totalPages, totalDataLength } = useMemo(
    () => ({
      notificationsList: notificationsData?.results || [],
      allNotificationIds: notificationsData?.results?.map((notification) => notification.id) || [],
      totalPages: notificationsData?.total_pages || 0,
      totalDataLength: notificationsData?.total_count || 0,
    }),
    [notificationsData]
  );

  useEffect(() => {
    if (needsRefresh) {
      refreshData();
    }
  }, [needsRefresh]);

  function refreshData() {
    dispatch(getNotificationsData({ page, search, unreadFilter, criticalFilter }));
    resetPageAndSearch();
    resetSelectedIds();
  }

  const handleDeleteSingleNotification = (notificationId) => {
    if (notificationId) {
      dispatch(deleteNotification({ notificationId })).then((actionResult) => {
        if (deleteNotification.fulfilled.match(actionResult)) {
          toggleModal("deleteModal");
          refreshData();
        }
      });
    }
  };

  function handleDeleteMultipleNotifications() {
    dispatch(deleteMultipleNotifications({ notificationIds: selectedIds })).then((actionResult) => {
      if (deleteMultipleNotifications.fulfilled.match(actionResult)) {
        refreshData();
      }
    });
    toggleModal("deleteMultipleModal");
  }

  function handleReadStatusChange(notificationId, isRead) {
    toggleModal("showMessageModal");
    dispatch(updateNotification({ notificationId, data: { is_read: isRead } }));
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (tab === "unread") {
        setUnreadFilter(true);
        setCriticalFilter(false);
      } else if (tab === "all") {
        setUnreadFilter(false);
        setCriticalFilter(false);
      } else if (tab === "critical") {
        setCriticalFilter(true);
      }
    }
  };

  const handleReadAll = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to mark all notifications as read?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const ids = notificationsList.map((notification) => notification.id);
        dispatch(markNotificationsAsRead({ notificationIds: ids }));
      }
    });
  };

  function handleMarkAsRead(notificationId) {
    dispatch(markNotificationsAsRead({ notificationIds: [notificationId] }));
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              checked={selectedIds.length === notificationsList.length && notificationsList.length > 0}
              onChange={(e) => handleSelectedAll(e, allNotificationIds)}
            />
          </div>
        ),
        cell: (cell) => {
          return (
            <div className="form-check">
              <Input
                type="checkbox"
                className="form-check-input"
                value={cell.getValue()}
                checked={selectedIds.includes(cell.getValue())}
                onChange={(e) => handleSelectedSingle(e, cell.getValue())}
              />
            </div>
          );
        },
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Type",
        accessorKey: "type",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return <span className="text-capitalize">{cell.getValue()}</span>;
        },
      },
      {
        header: "From",
        accessorKey: "sent_from",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Subject",
        accessorKey: "subject",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Message",
        accessorKey: "message",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <Button
              color="link"
              className="shadow-none"
              outline
              onClick={() => toggleModal("showMessageModal", { message: cell.row.original })}
            >
              <a className="text-decoration-underline">{truncateText(cell.getValue(), 50)}</a>
            </Button>
          );
        },
      },
      {
        header: "Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          const date = new Date(cell.getValue());
          const formattedDate = date.toLocaleString("en-US", { hour12: true });
          return <>{formattedDate}</>;
        },
      },
      {
        header: "Action",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <ActionButtonGroup>
              {!cell.row.original.is_read && (
                <>
                  <Button
                    id={`markAsRead-${cell.row.original.id}`}
                    color="info"
                    className="btn-soft-info shadow-none rounded-circle px-2 py-1"
                    onClick={() => handleMarkAsRead(cell.row.original.id)}
                  >
                    <i className="mdi mdi-check fs-20"></i>
                  </Button>
                  <UncontrolledTooltip placement="auto" target={`markAsRead-${cell.row.original.id}`} trigger="hover">
                    Mark as Read
                  </UncontrolledTooltip>
                </>
              )}

              <DeleteButton
                onClick={() =>
                  toggleModal("deleteModal", {
                    notificationId: cell.row.original.id,
                  })
                }
                tooltip="Delete Notification"
              />
              <DownloadJsonButton
                jsonData={cell.row.original}
                fileName={cell.row.original.subject}
                tooltip="Download Notification Content"
              />
            </ActionButtonGroup>
          );
        },
      },
    ],
    [notificationsList, selectedIds]
  );

  document.title = "Notifications | Linko Cloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Notifications" pageTitle="Messages" />
          <Card className="border">
            <CardHeader className="border-0">
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0">Notifications</h5>
                </div>
                <div className="col-sm-auto">
                  <div className="d-flex gap-1 flex-wrap">
                    <Button color="info" onClick={() => toggleModal("exportModal")}>
                      <i className="ri-file-download-line me-2"></i>Export
                    </Button>
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody className="pt-0">
              <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "unread" })}
                    onClick={() => {
                      toggleTab("unread");
                    }}
                    href="#"
                  >
                    <i className="ri-store-2-fill me-1 align-bottom"></i>
                    Unread Notifications
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "all" })}
                    onClick={() => {
                      toggleTab("all");
                    }}
                    href="#"
                  >
                    <i className="ri-checkbox-circle-line me-1 align-bottom"></i>
                    All Notifications
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "critical" })}
                    onClick={() => {
                      toggleTab("critical");
                    }}
                    href="#"
                  >
                    <i className="ri-checkbox-circle-line me-1 align-bottom"></i>
                    Critical Notifications
                  </NavLink>
                </NavItem>
              </Nav>
              <Row className="mt-3 mb-3 d-flex justify-content-between">
                <Col md="2" xs="4" className="d-flex justify-content-begin">
                  <Button className="btn-ghost-dark shadow-none me-2 border fs-5" onClick={refreshData}>
                    <i className="ri-refresh-line"></i>
                  </Button>
                  {activeTab === "unread" && (
                    <Button className="btn-ghost-dark shadow-none me-2 border" onClick={handleReadAll}>
                      <i className="ri-check-line me-md-2"></i>
                      <span className="hide-text-sm">Read All</span>
                    </Button>
                  )}
                  {selectedIds.length > 0 && (
                    <Button
                      color="danger"
                      className="btn-ghost-danger shadow-none me-2 border fs-5"
                      onClick={() => {
                        toggleModal("deleteMultipleModal");
                      }}
                    >
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                  )}
                </Col>
                <Col md="6" xs="8">
                  <div className="search-box ms-2">
                    <input
                      type="text"
                      className="form-control search"
                      placeholder="Search Notification"
                      onChange={handleSearch}
                      value={search}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
              </Row>
              <div>
                <DynamicDataTable
                  columns={columns}
                  data={notificationsList}
                  currentPage={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  totalDataLength={totalDataLength}
                  dataName="notification"
                  onRowClick={(row) => {
                    if (row) {
                      toggleModal("showMessageModal", { message: row });
                    }
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <ExportCSVModal
        showModal={modals.exportModal}
        toggleModal={() => toggleModal("exportModal")}
        data={notificationsList}
        fileName="notifications"
      />
      <DeleteModal
        showModal={modals.deleteModal}
        toggleModal={() => toggleModal("deleteModal")}
        title="Delete Notification"
        subtitle="Are you sure you want to delete this notification??"
        onDelete={() => handleDeleteSingleNotification(modalData.deleteModal?.notificationId)}
      />
      <DeleteMultipileModal
        showModal={modals.deleteMultipleModal}
        toggleModal={() => toggleModal("deleteMultipleModal")}
        type="notification"
        numberSelected={selectedIds.length}
        onDelete={handleDeleteMultipleNotifications}
      />
      <NotificationMessageModal
        showModal={modals.showMessageModal}
        toggleModal={() => toggleModal("showMessageModal")}
        messageData={modalData.showMessageModal?.message}
        OnReadStatusChange={handleReadStatusChange}
      />
    </React.Fragment>
  );
};

export default Notifications;
