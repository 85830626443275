import React from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { ResourcesUsageItem } from "@/features/overview/components/ResourcesUsageItem";
import { useDispatch, useSelector } from "react-redux";
import { getResourceUsage } from "@/features/resourcesUsage/thunk";
import { useEffect, useMemo } from "react";


export function ResourcesUsagePanel() {
    const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const resourcesLimitData = useMemo(() => userData?.limit, [userData]);
  const resourcesUsageData = useSelector(
    (state) => state.resourcesUsage.usageData
  );

  useEffect(() => {
    dispatch(getResourceUsage());
  }, []);

  return (
    <Card className="card-height-100 h-100 border">
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Entities Usage</h4>
      </CardHeader>
      <CardBody className="position-relative">
        <div className="vstack gap-2" style={{overflowY:"auto"}}>
          <ResourcesUsageItem
            title="Devices"
            icon="mdi mdi-devices"
            currentValue={resourcesUsageData?.devices || 0}
            totalValue={resourcesLimitData?.max_devices || 0}
          />
          <ResourcesUsageItem
            title="Rules Engine"
            icon="mdi mdi-lan"
            currentValue={resourcesUsageData?.rules || 0}
            totalValue={resourcesLimitData?.max_rules || 0}
          />
          <ResourcesUsageItem
            title="Alarms"
            icon="mdi mdi-cloud-alert"
            currentValue={resourcesUsageData?.alarms || 0}
            totalValue={resourcesLimitData?.max_alarms || 0}
          />
          <ResourcesUsageItem
            title="Dashboards"
            icon="mdi mdi-monitor-dashboard"
            currentValue={resourcesUsageData?.dashboards || 0}
            totalValue={resourcesLimitData?.max_dashboards || 0}
          />
        </div>
      </CardBody>
    </Card>
  );
}
