import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  TabContent,
  TabPane,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { extractErrorMessage } from "@/common/utils/stringUtils";

import { createNotifyTemplate, updateNotifyTemplate } from "@/features/notifyHub/thunk";
import { MenuNavItem } from "@/features/notifyHub/components/MenuNavItem";
import { BasicInfoTab } from "@/features/notifyHub/components/addNotifyTemplate/BasicInfoTab";
import { WebNotifyTab } from "@/features/notifyHub/components/addNotifyTemplate/WebNotifyTab";
import { EmailNotifyTab } from "@/features/notifyHub/components/addNotifyTemplate/EmailNotifyTab";
import { SmsNotifyTab } from "@/features/notifyHub/components/addNotifyTemplate/SmsNotifyTab";

const formSchema = Yup.object()
  .shape({
    name: Yup.string().required("Template name is required"),
    description: Yup.string(),
    type: Yup.string().required("Template type is required"),
    webSwitch: Yup.boolean(),
    emailSwitch: Yup.boolean(),
    smsSwitch: Yup.boolean(),
    webSubject: Yup.string().when('webSwitch', {
      is: true,
      then: (schema) => schema.required('Web subject is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    webMessage: Yup.string().when('webSwitch', {
      is: true,
      then: (schema) => schema.required('Web message is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    emailSubject: Yup.string().when('emailSwitch', {
      is: true,
      then: (schema) => schema.required('Email subject is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    emailMessage: Yup.string().when('emailSwitch', {
      is: true,
      then: (schema) => schema.required('Email message is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    smsMessage: Yup.string().when('smsSwitch', {
      is: true,
      then: (schema) => schema.required('SMS message is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    deliveryMethodSelected: Yup.boolean().test(
      "deliveryMethodSelected",
      "At least one delivery method must be selected",
      function (value) {
        // this.parent can be used to access the formik.values object
        const { webSwitch, emailSwitch, smsSwitch } = this.parent;
        // Check if at least one delivery method is selected
        return webSwitch || emailSwitch || smsSwitch;
      }
    ),
  })
  .noUnknown(); // This is to ensure that the formik.values only contains the properties defined in the schema

export function AddNotifyTemplateModal({ showModal, toggleModal, onSuccess, isEditMode = false, templateData  }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [activeMenuTab, setActiveMenuTab] = useState(1);
  const [verifiedTab, setVerifiedTab] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  const initialValues = useMemo(() => {
    return isEditMode
      ? {
          name: templateData?.name || "",
          description: templateData?.description || "",
          type: templateData?.type || "general",
          webSwitch: templateData?.web_enabled || false,
          emailSwitch: templateData?.email_enabled || false,
          smsSwitch: templateData?.sms_enabled || false,
          webSubject: templateData?.web_subject || "",
          webMessage: templateData?.web_message || "",
          emailSubject: templateData?.email_subject || "",
          emailMessage: templateData?.email_message || "",
          smsMessage: templateData?.sms_message || "",
        }
      : {
        name: "",
        description: "",
        type: "general",
        webSwitch: true,
        emailSwitch: false,
        smsSwitch: false,
        deliveryMethodSelected: false,
        webSubject: "",
        webMessage: "",
        emailSubject: "",
        emailMessage: "",
        smsMessage: "",
      };
  }, [isEditMode, templateData]);


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const completeAction = (actionPromise) => {
        actionPromise.then((actionResult) => {
          if (actionResult.meta.requestStatus === "fulfilled") {
          toggleModal();
          resetModal();
          onSuccess();
          Swal.fire({
            title: "Success!",
            text: "Notify Template has been added.",
          });
        } else {
          const apiError = actionResult.payload;
          if (apiError && apiError.data && apiError.data.error_message) {
            const errorMessage = apiError.data.error_message;
            setErrors(extractErrorMessage(errorMessage));
          }
        }
      });
    };
        values.user = userData?.id || "";
        if (isEditMode) {
          completeAction(dispatch(updateNotifyTemplate({ templateId: templateData.id, data: values })));
        } else {
          completeAction(dispatch(createNotifyTemplate(values)));
        }
      },
    });

  async function toggleMenuTab(tab) {
    let fieldsToValidate = {};
  
    switch (activeMenuTab) {
      case 1: 
        fieldsToValidate = {
          name: true,
          description: true,
          type: true,
          deliveryMethodSelected: true, 
        };
        break;
      case 2: 
        fieldsToValidate = {
          webSubject: true,
          webMessage: true,
        };
        break;
      case 3:
        fieldsToValidate = {
          emailSubject: true,
          emailMessage: true,
        };
        break;
      case 4:
        fieldsToValidate = {
          smsMessage: true,
        };
        break;
      default:
        break;
    }
  
    formik.setTouched(fieldsToValidate);
    const errors = await formik.validateForm();
    
    let validationErrors = {};
    for (let field in fieldsToValidate) {
      if (errors?.[field] !== undefined) {
        validationErrors[field] = errors[field];
      }
    }

  
    if (Object.keys(validationErrors).length === 0) {
      setVerifiedTab([...verifiedTab, activeMenuTab]);
      if (activeMenuTab !== tab) setActiveMenuTab(tab);
    }
  }
  
  function onToggleModal() {
    toggleModal();
    resetModal();
  }
  

  function resetModal() {
    formik.resetForm();
    setVerifiedTab([]);
    setActiveMenuTab(1);
    setErrors(null);
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={onToggleModal}
        size="xl"
        backdrop="static"
        modalClassName="zoomIn"
        centered
      >
        <ModalHeader toggle={onToggleModal} className="p-3">
        {isEditMode ? "Update" : "Create"} Notify Template
        </ModalHeader>
        <Form
          className="vertical-navs-step form-steps"
          onSubmit={formik.handleSubmit}
        >
          <ModalBody style={{ minHeight: "400px", overflowY: "auto" }}>
            <Row>
              <Col lg={3}>
                <Nav className="flex-column custom-nav nav-pills">
                  <MenuNavItem
                    title="Basic Settings"
                    stepIndex={1}
                    tabIndex={1}
                    isVisible={true}
                    verified={verifiedTab.includes(1)}
                    activeMenuTab={activeMenuTab}
                    toggleMenuTab={toggleMenuTab}
                  />
                  <MenuNavItem
                    title="Web Notify Settings"
                    tabIndex={2}
                    verified={verifiedTab.includes(2)}
                    stepIndex={1+formik.values.webSwitch}
                    activeMenuTab={activeMenuTab}
                    toggleMenuTab={toggleMenuTab}
                    isVisible={formik.values.webSwitch}
                  />
                  <MenuNavItem
                    title="Email Notify Settings"
                    tabIndex={3}
                    verified={verifiedTab.includes(3)}
                    stepIndex={1+formik.values.webSwitch+formik.values.emailSwitch}
                    activeMenuTab={activeMenuTab}
                    toggleMenuTab={toggleMenuTab}
                    isVisible={formik.values.emailSwitch}
                  />
                  <MenuNavItem
                    title="SMS Notify Settings"
                    tabIndex={4}
                    verified={verifiedTab.includes(4)}
                    stepIndex={1+formik.values.webSwitch+formik.values.emailSwitch+formik.values.smsSwitch}
                    activeMenuTab={activeMenuTab}
                    toggleMenuTab={toggleMenuTab}
                    isVisible={formik.values.smsSwitch}
                  />
                </Nav>
              </Col>
              <Col lg={9}>
                <div className="px-lg-4">
                  <TabContent activeTab={activeMenuTab}>
                    <TabPane tabId={1}>
                      <BasicInfoTab formik={formik} />
                    </TabPane>
                    <TabPane tabId={2}>
                      <WebNotifyTab formik={formik} />
                    </TabPane>
                    <TabPane tabId={3}>
                      <EmailNotifyTab formik={formik} />
                    </TabPane>
                    <TabPane tabId={4}>
                      <SmsNotifyTab formik={formik} />
                    </TabPane>
                  </TabContent>
                  {errors && (
                    <div className="alert alert-danger mt-3" role="alert">
                      {errors}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={onToggleModal}>
              Cancel
            </Button>
            <Button color="success" type="submit">
            {isEditMode ? "Update" : "Create"} Notify Template
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
