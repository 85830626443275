import { DASHBOARDS_API_URLS } from "@/api/apiConfig";
import { apiClient } from "@/api/axiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getDashboardsListData = createAsyncThunk(
  "dashboards/getDashboardsListData",
  async ({ page, search }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams({ page, search });
      const response = await apiClient.get(`${DASHBOARDS_API_URLS.LIST_DASHBOARDS}?${params.toString()}`);
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const getDashboardDetailData = createAsyncThunk(
  "dashboards/getDashboardDetailData",
  async ({ dashboardId }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(DASHBOARDS_API_URLS.RETRIEVE_DASHBOARD(dashboardId));
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const createDashboard = createAsyncThunk("dashboards/createDashboard", async (data, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(DASHBOARDS_API_URLS.CREATE_DASHBOARD, data);
    if (response) {
      return response;
    }
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

export const updateDashboard = createAsyncThunk(
  "dashboards/updateDashboard",
  async ({ dashboardId, data }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(DASHBOARDS_API_URLS.UPDATE_DASHBOARD(dashboardId), data);
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteDashboard = createAsyncThunk(
  "dashboards/deleteDashboard",
  async ({ dashboardId }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(DASHBOARDS_API_URLS.DELETE_DASHBOARD(dashboardId));
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteMultipleDashboards = createAsyncThunk(
  "dashboards/deleteMultipleDashboards",
  async ({ dashboardIds }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(DASHBOARDS_API_URLS.BULK_DELETE_DASHBOARDS, {
        data: { dashboard_ids: dashboardIds },
      });
      if (response) {
        return response;
      }
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);
