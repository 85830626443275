import React from "react";
import { FormGroup, Input } from "reactstrap";
import { DataSourceDevice } from "./DataSourceDevice";

export function DataSourceDeviceKey({ formik }) {
  return (
    <React.Fragment>
      <DataSourceDevice formik={formik} />
      <FormGroup>
        <h4 className="mb-3">Data Key *</h4>
        <Input
          id="dataSourceConfig.deviceKey"
          name="dataSourceConfig.deviceKey"
          placeholder="Data Key"
          value={formik.values.dataSourceConfig.deviceKey || ""}
          onChange={formik.handleChange}
        />
        {formik.errors.dataSourceConfig?.deviceKey && (
          <div className="ms-1 mt-1" style={{ fontSize: "0.8125rem", color: "#f3846d" }}>
            {formik.errors.dataSourceConfig.deviceKey}
          </div>
        )}
      </FormGroup>
    </React.Fragment>
  );
}
