import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";

import { getDevicesData } from "@/features/devices/thunk";
import Select from "react-select";

export function DevicesRuleSettingTab({ formik, active=false }) {
  if (!active) return null;
  const devicesData = useSelector((state) => state.devices.devicesData);
  const dispatch = useDispatch();
  const [configurations, setConfigrations] = useState({ type:"devices", devices: [], notifyOn: [{ value: "inactive", label: "Inactive" },] });
  const { devicesList } = useMemo(
    () => ({
      devicesList: devicesData?.results || [],
    }),
    [devicesData]
  );

  function updateConfig(key, value) {
    setConfigrations((prev) => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    formik.setFieldValue("configurations", configurations);
  }, [configurations]);

  useEffect(() => {
    dispatch(getDevicesData({}));
  }, []);

  return (
    <React.Fragment>
      <FormGroup>
        <Label for="type">Devices *</Label>
        <Select
          value={configurations.devices}
          isMulti={true}
          isClearable={true}
          onChange={(e) => updateConfig("devices", e)}
          placeholder="If no devices are selected, the rule will be applied to all devices."
          options={devicesList.map((device) => ({
            value: device.id,
            label: device.name,
          }))}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">Notify on *</Label>
        <Select
          value={configurations.notifyOn}
          isMulti={true}
          isClearable={true}
          onChange={(e) => updateConfig("notifyOn", e)}
          options={[
            { value: "active", label: "Active" },
            { value: "inactive", label: "Inactive" },
          ]}
        />
      </FormGroup>
    </React.Fragment>
  );
}
