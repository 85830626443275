import React from "react";
import { useMemo } from "react";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useIsSmallScreen } from "@/hooks/useIsSmallScreen";
import { getXAxisFormat } from "@/common/utils/dateFormatOptions";

export function TimeRangeChart({
  type = "bar",
  title,
  data = [],
  dataName,
  timeRange,
  timeRangeTypes = [],
  onTimeRangeChange,
  className,
  minHeight = 300,
}) {

  const seriesData = [
    {
      name: dataName,
      data: data.map((item) => ({
        x: new Date(item.time).toLocaleString(),
        y: item.count ?? null,
      })),
    },
  ];

  const colorMode = useSelector((state) => state.layout.colorMode);
  const isSmallScreen = useIsSmallScreen(768);

  const options = useMemo(
    () => ({
      chart: {
        type: type,
        height: "100%",
        width: "100%",
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        background: colorMode === "dark" ? "#212529" : "#fff",
      },
      theme: {
        mode: colorMode, // 'light' or 'dark'
      },
      plotOptions: {
        background: colorMode === "dark" ? "#212529" : "#fff",
      },
      colors: ["#3577f1", "#45CB85", "#4b38b3", "#ffbe0b", "#02a8b5", "f06548"],
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
            week: 'dd MMM',
            day: 'HH:mm',
            hour: 'HH:mm',
          },
          format: getXAxisFormat(timeRange),
        },
      },
      yaxis: {
        min: 0,
        tickAmount: undefined,
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            return Math.round(value);
          },
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: true,
      },
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -20,
        style: {
          fontSize: "18px",
        },
      },
    }),
    [colorMode, timeRange]
  );

  return (
    <Card style={{ minHeight: minHeight }} className={`border ${className}`}>
      <CardHeader className="border-0 align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
        {!isSmallScreen &&
          timeRangeTypes &&
          timeRangeTypes.length > 0 &&
          timeRangeTypes.map((range, index) => {
            return (
              <Button
                key={index}
                className={`btn btn-soft-${timeRange == range ? "success" : "secondary"} btn-sm shadow-none me-1`}
                onClick={() => {
                  onTimeRangeChange(range);
                }}
              >
                {range}
              </Button>
            );
          })}
        {isSmallScreen && timeRangeTypes && timeRangeTypes.length > 0 && (
          <select
            className="form-select form-select-md"
            value={timeRange}
            onChange={(e) => onTimeRangeChange(e.target.value)}
          >
            {timeRangeTypes.map((range, index) => (
              <option key={index} value={range}>
                {range}
              </option>
            ))}
          </select>
        )}
      </CardHeader>
      <CardBody>
        <Chart dir="ltr" options={options} type={type} series={seriesData} height="100%" className="apex-charts" />
      </CardBody>
    </Card>
  );
}
