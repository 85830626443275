import React, { useState, useEffect } from 'react';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Row, Button, FormFeedback } from 'reactstrap';
import SimpleBar from "simplebar-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import country from "@/common/country/country";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountDetails } from "@/features/auth/accountSettings/thunk";
import Swal from "sweetalert2";
import { useDebounce } from "@/hooks/useDebounce";

const updateAccountDetailsSchema = Yup.object({
    username: Yup.string().min(2, 'Username must be at least 2 characters').required("Please Enter Your Username"),
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().matches(/^\d+$/, 'Phone number should only contain digits'),
    phoneCountry: Yup.string(),
});


export function AccountDetailsTabContent() {
    const dispatch = useDispatch();
    const [errors, setErrors] = React.useState(null);
    const [phoneCountryDropdownOpen, setphoneCountryDropdownOpen] = useState(false);
    const [seletedPhoneCountry, setSeletedPhoneCountry] = useState({
        id: 240,
        abbreviation: "US",
        countryName: "United States of America",
        countryCode: "+1"
      });
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const filteredCountries = country.filter(item => 
        item.countryName.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) || 
        item.abbreviation.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        item.countryCode.includes(debouncedSearchTerm)
      );
    
    const user = useSelector((state) => state.auth.user);
    useEffect(() => {
        if (user) {
            const selectedCountry = country.find((item) => item.countryName === user.phone_country);
            if (selectedCountry) {
                setSeletedPhoneCountry(selectedCountry);
            }
        }
    }, [user]);
    
    const updateAccountDetailsFormik = useFormik({
        initialValues: {
            username: user ? user.username : "",
            firstName: user ? user.first_name : "",
            lastName: user ? user.last_name : "",
            phone: user ? user.phone : "",
            phoneCountry: user ? user.phone_country : "",
        },
        validationSchema: updateAccountDetailsSchema,
        onSubmit: (values) => {
            dispatch(updateAccountDetails(values))
            .then((actionResult) => {
                if (updateAccountDetails.fulfilled.match(actionResult)) {
                    updateAccountDetailsFormik.resetForm();
                    setErrors(null);
                    Swal.fire({ title: 'Success!', text: 'Your account details has been updated successfully', icon: 'success', timer: 2000, showConfirmButton: false });
                } else {
                    const apiError = actionResult.payload;
                    if (apiError && apiError.data && apiError.data.error_message) {
                        const errorMessage = apiError.data.error_message;
                        setErrors(errorMessage);
                    }
                }
            });
        },
        enableReinitialize: true
    });

    function togglephoneCountryDropdown() {
        setphoneCountryDropdownOpen(!phoneCountryDropdownOpen);
    }

    return (
        <Form onSubmit={updateAccountDetailsFormik.handleSubmit}>
            <Row>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="username" className="form-label">Username</Label>
                        <Input type="text" className="form-control"
                            id="username"
                            name="username"
                            onChange={updateAccountDetailsFormik.handleChange}
                            onBlur={updateAccountDetailsFormik.handleBlur}
                            value={updateAccountDetailsFormik.values.username}
                            placeholder="Enter your username"
                            invalid={updateAccountDetailsFormik.touched.username && updateAccountDetailsFormik.errors.username ? true : false} />
                        {updateAccountDetailsFormik.touched.username && updateAccountDetailsFormik.errors.username ? (
                            <FormFeedback type="invalid">{updateAccountDetailsFormik.errors.username}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label">Email Address</Label>
                        <Input type="email" className="form-control" id="emailInput"
                            placeholder="Enter your email"
                            defaultValue={user ? user.email : ""} readOnly/>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="firstName" className="form-label">First Name</Label>
                        <Input 
                            type="text" 
                            className="form-control" 
                            id="firstName"
                            name="firstName"
                            placeholder="Enter your firstname" 
                            onChange={updateAccountDetailsFormik.handleChange}
                            onBlur={updateAccountDetailsFormik.handleBlur}
                            value={updateAccountDetailsFormik.values.firstName}
                            invalid={updateAccountDetailsFormik.touched.firstName && updateAccountDetailsFormik.errors.firstName ? true : false}
                            />
                        {updateAccountDetailsFormik.touched.firstName && updateAccountDetailsFormik.errors.firstName ? (
                            <FormFeedback type="invalid">{updateAccountDetailsFormik.errors.firstName}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="lastName" className="form-label">Last Name</Label>
                        <Input 
                            type="text" 
                            className="form-control" 
                            id="lastName"
                            name="lastName"
                            placeholder="Enter your lastname" 
                            onChange={updateAccountDetailsFormik.handleChange}
                            onBlur={updateAccountDetailsFormik.handleBlur}
                            value={updateAccountDetailsFormik.values.lastName}
                            invalid={updateAccountDetailsFormik.touched.lastName && updateAccountDetailsFormik.errors.lastName ? true : false}
                             />
                        {updateAccountDetailsFormik.touched.lastName && updateAccountDetailsFormik.errors.lastName ? (
                            <FormFeedback type="invalid">{updateAccountDetailsFormik.errors.lastName}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <Label htmlFor="phone" className="form-label">Phone Number</Label>
                    <Dropdown className='input-group' isOpen={phoneCountryDropdownOpen} toggle={togglephoneCountryDropdown}>
                        <DropdownToggle as="button" className="btn btn-light arrow-none" style={{border: '1px solid #878a99'}}>
                            <span className='me-2'>{seletedPhoneCountry.abbreviation}</span>
                            <span className="countrylist-codeno">{seletedPhoneCountry.countryCode}</span>
                        </DropdownToggle>
                        <Input 
                            type="number" 
                            id="phone" 
                            className="form-control rounded-end flag-input" 
                            placeholder="Enter number"
                            onChange={updateAccountDetailsFormik.handleChange} 
                            onBlur={updateAccountDetailsFormik.handleBlur}
                            value={updateAccountDetailsFormik.values.phone}
                            invalid={updateAccountDetailsFormik.touched.phone && updateAccountDetailsFormik.errors.phone ? true : false}/>
                        {updateAccountDetailsFormik.touched.phone && updateAccountDetailsFormik.errors.phone ? (
                            <FormFeedback type="invalid">{updateAccountDetailsFormik.errors.phone}</FormFeedback>
                        ) : null}
                        <DropdownMenu as='ul' className="list-unstyled w-100 dropdown-menu-list mb-0">
                            <div className='p-2 px-3 pt-1'>
                                <Input
                                    type="text"
                                    placeholder="Search country..."
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <SimpleBar style={{ maxHeight: "220px" }} className="px-3">
                            {(filteredCountries ).map((item, key) => (
                                <DropdownItem as='li'          
                                    onClick={() => {
                                        updateAccountDetailsFormik.setFieldValue('phoneCountry', item.countryName);
                                        setSeletedPhoneCountry(item);}} 
                                    key={key} 
                                    className="dropdown-item d-flex">
                                <div className="flex-shrink-0 me-2">
                                    <span>{item.abbreviation}</span>
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex">
                                    <div className="country-name me-1">{item.countryName}</div>
                                    <span className="countrylist-codeno text-muted">{item.countryCode}</span>
                                    </div>
                                </div>
                                </DropdownItem>
                            ))}
                            </SimpleBar>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col lg={12}>
                    {errors && <div className="alert alert-danger mt-3" role="alert">
                        {errors}
                    </div>}
                </Col>
                <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end mt-4">
                        <Button type="submit" color="primary">Updates</Button>
                        <Button type="button" className="btn-soft-success">Cancel</Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}