import React from "react";
import { useNavigate } from "react-router-dom";
import { useModals } from "@/hooks/useModals";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationAndSearch } from "@/hooks/usePaginationAndSearch";
import { useTableSelectionControl } from "@/hooks/useTableSelectionControl";

import { truncateText } from "@/common/utils/stringUtils";
import { InfoPanel } from "@/components/panels/InfoPanel";
import { BreadCrumb } from "@/components/common/BreadCrumb";
import { StatisticsPanel } from "@/components/panels/StatisticsPanel";
import { EntityUsagePanel } from "@/components/panels/EntityUsagePanel";
import { DynamicDataTable } from "@/components/tables/DynamicDataTable";

import { DeleteModal } from "@/components/modals/DeleteModal";
import { AddDeviceModal } from "@/features/devices/components/AddDeviceModal";
import { ShowDescriptionModal } from "@/components/modals/ShowDescriptionModal";
import { DeleteMultipileModal } from "@/components/modals/DeleteMultipileModal";

import { EditButton } from "@/components/tables/rowActionButtons/EditButton";
import { DeleteButton } from "@/components/tables/rowActionButtons/DeleteButton";
import { AddEntityButton } from "@/components/tables/headerToolSet/AddEntityButton";
import { RefreshDataButton } from "@/components/tables/headerToolSet/RefreshDataButton";
import { ActionButtonGroup } from "@/components/tables/rowActionButtons/ActionButtonGroup";
import { DeleteMultipleButton } from "@/components/tables/headerToolSet/DeleteMultipleButton";

import infoPanelImage from "@/assets/images/illustration/devices.svg";

import { Container, Row, Col, Card, CardHeader, Input, CardBody, Button } from "reactstrap";
import { getDevicesData, getDeviceStatisticsData, bulkDeleteDevices, deleteDevice } from "@/features/devices/thunk";

const DevicesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, search, handlePageChange, handleSearch, resetPageAndSearch } = usePaginationAndSearch();
  const [selectedIds, handleSelectedAll, handleSelectedSingle, resetSelectedIds] = useTableSelectionControl();

  const userData = useSelector((state) => state.auth.user);
  const devicesLimit = useMemo(() => userData?.limit?.max_devices, [userData]);

  const [modals, modalData, toggleModal] = useModals({
    addDeviceModal: false,
    deleteModal: false,
    showDescriptionModal: false,
    deleteMultipleModal: false,
  });

  const devicesData = useSelector((state) => state.devices.devicesData);
  const { devicesList, allDeviceIds, totalPages, totalDataLength } = useMemo(
    () => ({
      devicesList: devicesData?.results || [],
      allDeviceIds: devicesData?.results?.map((device) => device.id) || [],
      totalPages: devicesData?.total_pages || 0,
      totalDataLength: devicesData?.total_count || 0,
    }),
    [devicesData]
  );

  useEffect(() => {
    dispatch(getDevicesData({ page, search }));
  }, [page, search]);

  useEffect(() => {
    dispatch(getDeviceStatisticsData());
  }, []);

  const devicesStatisticsData = useSelector((state) => state.devices.devicesStatisticsData);
  const { totalDevices, activeDevices } = useMemo(
    () => ({
      totalDevices: devicesStatisticsData?.total_devices || 0,
      activeDevices: devicesStatisticsData?.active_devices || 0,
    }),
    [devicesStatisticsData]
  );

  const handleDeleteSingleDevice = (deviceId) => {
    if (deviceId) {
      dispatch(deleteDevice({ deviceId })).then((actionResult) => {
        if (deleteDevice.fulfilled.match(actionResult)) {
          toggleModal("deleteModal");
          refreshData();
        }
      });
    }
  };

  function handleDeleteMultipleDevices() {
    dispatch(bulkDeleteDevices({ deviceIds: selectedIds })).then((actionResult) => {
      if (bulkDeleteDevices.fulfilled.match(actionResult)) {
        refreshData();
      }
    });
    toggleModal("deleteMultipleModal");
  }

  function handleRowClick(row) {
    const navigateDeviceId = row.id;
    if (navigateDeviceId) {
      navigate("/devices/" + navigateDeviceId);
    }
  }

  function refreshData() {
    resetPageAndSearch();
    resetSelectedIds();
    dispatch(getDevicesData({ page, search }));
    dispatch(getDeviceStatisticsData());
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="form-check">
            <Input
              type="checkbox"
              id="checkBoxAll"
              className="form-check-input"
              checked={selectedIds.length === devicesList.length && devicesList.length > 0}
              onChange={(e) => handleSelectedAll(e, allDeviceIds)}
            />
          </div>
        ),
        cell: (cell) => {
          return (
            <div className="form-check">
              <Input
                type="checkbox"
                className="deviceCheckbox form-check-input"
                value={cell.getValue()}
                checked={selectedIds.includes(cell.getValue())}
                onChange={(e) => handleSelectedSingle(e, cell.getValue())}
              />
            </div>
          );
        },
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Name",
        accessorKey: "name",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <span
              className={`badge text-uppercase ${
                cell.getValue() === "active" ? "text-success bg-success-subtle" : "text-danger bg-danger-subtle"
              }`}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Label",
        accessorKey: "label",
        enableSorting: true,
        enableColumnFilter: false,
      },
      // {
      //   header: "Group",
      //   accessorKey: "group",
      //   enableColumnFilter: false,
      // },
      {
        header: "Is Gateway",
        accessorKey: "is_gateway",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <input
              className="form-check-input"
              type="checkbox"
              checked={cell.getValue()}
              disabled
              style={{ opacity: 1 }}
            />
          );
        },
      },
      {
        header: "Description",
        accessorKey: "description",
        enableSorting: true,
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            <Button
              color="link"
              className="shadow-none"
              outline
              onClick={(e) =>
                toggleModal("showDescriptionModal", {
                  description: cell.getValue(),
                })
              }
            >
              <a className="text-decoration-underline">{truncateText(cell.getValue(), 50)}</a>
            </Button>
          );
        },
      },
      {
        header: "Created Time",
        accessorKey: "created_time",
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        header: "Action",
        disableFilters: true,
        enableSorting: false,
        cell: (cell) => {
          return (
            <ActionButtonGroup>
              <EditButton
                onClick={() => toggleModal("addDeviceModal", { isEditMode: true, deviceData: cell.row.original })}
                tooltip="Edit Device"
              />
              <DeleteButton
                onClick={() =>
                  toggleModal("deleteModal", {
                    deviceId: cell.row.original.id,
                  })
                }
                tooltip="Delete Device"
              />
            </ActionButtonGroup>
          );
        },
      },
    ],
    [devicesList, selectedIds]
  );

  document.title = "Devices | Linko Cloud";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Devices" pageTitle="Management" />
          <Row>
            <Col xxl={4}>
              <InfoPanel
                title="Devices Management"
                description="Manage your devices and their profiles, status, and groups."
                image={infoPanelImage}
                documentURL="#"
              />
            </Col>
            <Col xxl={8}>
              <Row>
                <Col xs={12}>
                  <EntityUsagePanel
                    title="Devices"
                    currentValue={totalDataLength}
                    totalValue={devicesLimit || 0}
                    dataType="Devices"
                  />
                </Col>
                <Col md={6}>
                  <StatisticsPanel title="Total Devices" value={totalDevices} icon="hard-drive" iconColor="info" />
                </Col>
                <Col md={6}>
                  <StatisticsPanel title="Active Devices" value={activeDevices} icon="activity" iconColor="success" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card className="border">
                <CardHeader className="d-flex justify-content-between">
                  <Col md="2" className="d-flex justify-content-begin">
                    <AddEntityButton
                      onClick={() => toggleModal("addDeviceModal", { isEditMode: false })}
                      text="Add Device"
                    />
                    <RefreshDataButton onClick={refreshData} />
                    <DeleteMultipleButton
                      onClick={() => toggleModal("deleteMultipleModal")}
                      tooltip="Delete Multiple Devices"
                      show={selectedIds.length > 0}
                    />
                  </Col>
                  <Col md="6">
                    <div className="search-box ms-2">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder="Search Devices"
                        onChange={handleSearch}
                        value={search}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                </CardHeader>
                <CardBody>
                  <DynamicDataTable
                    columns={columns}
                    data={devicesList}
                    currentPage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    totalDataLength={totalDataLength}
                    dataName="device"
                    onRowClick={handleRowClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AddDeviceModal
        showModal={modals.addDeviceModal}
        toggleModal={() => toggleModal("addDeviceModal")}
        onSuccess={refreshData}
        isEditMode={modalData.addDeviceModal?.isEditMode}
        deviceData={modalData.addDeviceModal?.deviceData}
      />
      <DeleteModal
        showModal={modals.deleteModal}
        toggleModal={() => toggleModal("deleteModal")}
        title="Delete Device"
        subtitle="Are you sure you want to delete this device?"
        onDelete={() => handleDeleteSingleDevice(modalData.deleteModal?.deviceId)}
      />
      <DeleteMultipileModal
        showModal={modals.deleteMultipleModal}
        toggleModal={() => toggleModal("deleteMultipleModal")}
        type="device"
        numberSelected={selectedIds.length}
        onDelete={handleDeleteMultipleDevices}
      />
      <ShowDescriptionModal
        showModal={modals.showDescriptionModal}
        toggleModal={() => toggleModal("showDescriptionModal")}
        title="Dashboard Description"
        description={modalData.showDescriptionModal?.description}
      />
    </React.Fragment>
  );
};

export default DevicesList;
