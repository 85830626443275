import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNeedsRefresh, setUnreadNotificationsData } from '@/features/notifications/slice';
import { apiClient } from '@/api/axiosClient';
import { NOTIFICATIONS_API_URLS } from '@/api/apiConfig';

export const getNotificationsData = createAsyncThunk(
    "notifications/getNotificationsData", 
    async ({ page, search, unreadFilter, criticalFilter }, { dispatch, rejectWithValue }) => {
        try {
            const params = new URLSearchParams();
            if (page !== undefined) {
                params.append('page', page);
            }
            if (search !== undefined) {
                params.append('search', search);
            }
            if (unreadFilter !== undefined) {
                params.append('is_read', !unreadFilter);
            }
            if (criticalFilter !== undefined && criticalFilter === true) {
                params.append('severity', 'critical');
            }

            const url = `${NOTIFICATIONS_API_URLS.LIST_NOTIFICATIONS}${params.toString() ? `?${params.toString()}` : ''}`;
            const response = await apiClient.get(url);
            if (response){
                if (unreadFilter !== undefined && unreadFilter === true) {
                    dispatch(setUnreadNotificationsData(response));
                }
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const getNotificationDetails = createAsyncThunk(
    "notifications/getNotificationDetails", 
    async ({ notificationId }, {rejectWithValue }) => {
        try {
            const response = await apiClient.get(NOTIFICATIONS_API_URLS.RETRIEVE_NOTIFICATION(notificationId));
            if (response){
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const createNotification = createAsyncThunk(
    "notifications/createNotification", 
    async (data, {dispatch,  rejectWithValue }) => {
        try {
            const response = await apiClient.post(NOTIFICATIONS_API_URLS.CREATE_NOTIFICATION, data);
            if (response){
                dispatch(setNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const updateNotification = createAsyncThunk(
    "notifications/updateNotification", 
    async ({ notificationId, data }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.patch(NOTIFICATIONS_API_URLS.UPDATE_NOTIFICATION(notificationId), data);
            if (response){
                dispatch(setNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const deleteNotification = createAsyncThunk(
    "notifications/deleteNotification", 
    async ({ notificationId }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.delete(NOTIFICATIONS_API_URLS.DELETE_NOTIFICATION(notificationId));
            if (response){
                dispatch(setNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const deleteMultipleNotifications = createAsyncThunk(
    "notifications/deleteMultipleNotifications", 
    async ({ notificationIds }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.post(NOTIFICATIONS_API_URLS.BULK_DELETE_NOTIFICATIONS, {data:{ ids: notificationIds }});
            if (response){
                dispatch(setNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const markNotificationsAsRead = createAsyncThunk(
    "notifications/markNotificationsAsRead", 
    async ({ notificationIds }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.post(NOTIFICATIONS_API_URLS.MARK_NOTIFICATIONS_AS_READ, {ids: notificationIds});
            if (response){
                dispatch(setNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);

export const getMessagesNotificationsData = createAsyncThunk(
    "notifications/getMessagesNotificationsData", 
    async ({ page, search, unreadFilter }, { rejectWithValue }) => {
        try {
            const params = new URLSearchParams();
            if (page !== undefined) {
                params.append('page', page);
            }
            if (search !== undefined) {
                params.append('search', search);
            }
            if (unreadFilter !== undefined) {
                params.append('is_read', !unreadFilter);
            }

            const url = `${NOTIFICATIONS_API_URLS.LIST_MESSAGE_NOTIFICATIONS}${params.toString() ? `?${params.toString()}` : ''}`;
            const response = await apiClient.get(url);
            if (response){
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);


export const markMessagesNotificationsAsRead = createAsyncThunk(
    "notifications/markMessagesNotificationsAsRead", 
    async ({ notificationIds }, {dispatch, rejectWithValue }) => {
        try {
            const response = await apiClient.post(NOTIFICATIONS_API_URLS.MARK_MESSAGE_NOTIFICATIONS_AS_READ, {ids: notificationIds});
            if (response){
                dispatch(setNeedsRefresh());
                return response;
            }
        } catch (errors) {
            return rejectWithValue(errors);
        }
    }
);